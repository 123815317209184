import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { NormalModal } from "component/common";
import "./style.scss";
import { NormalButton, CommonPageLoader } from "component/common";
import CancelWorkModel from "component/workShedule/cancelWorkModel";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getSingleWork,
  deleteWork,
  cancelWork,
  approveStaff,
  rejectStaff,
  completeWorkClient,
} from "action/workRequest";
import Moment from "moment";
// import { TimeSheetMark } from "component/workShedule/timeSheetMark/index";
import { StaffDetails } from "component/workShedule/staffDetails/index";
// import editIcon from "assets/svg/white-edit.svg";
// import deleteIcon from "assets/svg/white-delete.svg";
// import deleteIconDisable from "assets/svg/bin.svg";
import bagIcon from "assets/svg/bag.svg";
import markerIcon from "assets/svg/mdi_location_on.svg";
// import tickIcon from "assets/svg/tick.svg";
import { colorByStatus } from "assets/WRColorSchema";
import axios from "axios";
import { getServiceUrl } from "service/api";
import { documentDownloadFromMDB } from "service/apiVariables";
import { StaffStatusLabels } from "pages/Admin/workSchedule";
import RejectAccordian from "component/common/RejectAccordian/RejectAccordian";
import {Toast} from 'service/toast';

function WorkAssignment({
  setVisible,
  workAssign,
  setWorkAssign,
  getSingleWork,
  handleReload,
  deleteWork,
  cancelWork,
  approveStaff,
  rejectStaff,
  completeWorkClient,
}) {
  const [edit, setEdit] = useState({});
  const [clientId, setClientId] = useState("");
  const [workID, setWorkID] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isFormLoder, setFormLoder] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [cancelId, setCancelId] = useState("");
  const [rejecteddStaff,setRejectedstaff] = useState([]);
  const [rejectreason,setRejectreason] = useState({});
  const [rejectt, setReject] = useState([])
  localStorage.setItem("workID", workID);

  const fetchFileDownloadURLById = async (id, filename='none.png', type="image/png") => {
    setLoading(true);
    
    if(filename.split(".").length>1){
        filename = filename.split(".")[0];
    }

    const link = document.createElement("a");
    link.target = "_blank";
    link.download = filename


    // await
    // fileDownloadFromMDB({ 'id': id })
    await axios
        .get(`${getServiceUrl(documentDownloadFromMDB.create.baseURL)}${documentDownloadFromMDB.create.api}${id}/`, {
            responseType: "blob",
            headers : {
                Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
            },
            params:{
              agencyId:workAssign.agencyId
            }
        })
        .then((res) => {

                link.href = URL.createObjectURL(
                new Blob([res.data], { type: type })
                );
                link.click();
            
            setLoading(false);
        })
        .catch((e) => setLoading(false));
};

  const fetchWRDetails = () => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let id = userData.userId ? userData.userId : userData.id;
    if (!!id) {
      setClientId(id);
      // setEdit({...edit,clientName:userData.clientName})
    }
    if (!!workAssign.id) {
      fetchWorkById(workAssign.id);
      setWorkID(workAssign.id);
    }
  }

  useEffect(() => {
    fetchWRDetails();
  }, [workAssign]);

  const fetchWorkById = async (id) => {
    setLoading(true);
    const query = { id: id , agencyId:workAssign.agencyId };
    await getSingleWork(query)
      .then((data) => {
        setEdit(data);
        // setWorkList(modifyData.length ? modifyData : []);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  // set Default values
  const setDefault = () => {
    setEdit({});
    setWorkAssign(false);
  };

  const getrejectAccordiandata = (data)=>{
       setRejectreason([data]);    
  }

  useEffect(()=>{
    
      if(rejectreason.length>0){
      handleRejectStaff(rejectreason[0].id)}
  },[rejectreason])

  // const handleDelete = async () => {
  //   let query = {
  //     workId: workID,
  //   };
  //   setFormLoder(true);
  //   await deleteWork(query)
  //     .then((data) => {
  //       setFormLoder(false);
  //       setDefault();
  //       handleReload();
  //     })
  //     .catch(() => {
  //       setFormLoder(false);
  //     });
  // };
  // handle confirmation for cancel work
  const handleAction = (action) => {
    if (action) cancelWorkClient();
    else setOpenModal(!openModal);
  };
  //handle work cancel by client
  const cancelWorkClient = async (id) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let body = {
      workId: workID,
      clientId: clientId,
      agencyId: workAssign.agencyId,
      reason: "Client cancels the work request ",
    };
    setFormLoder(true);
    await cancelWork(body)
      .then((data) => {
        setFormLoder(false);
        setOpenModal(false);
        setDefault();
        handleReload();
      })
      .catch(() => {
        setFormLoder(false);
      });
  };

  const completeWorkByClient = async () => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let agencyID = userData.agencyId ? userData.agencyId : "";
    let body = {
      workId: workID,
      agencyId: workAssign.agencyId,
    };
    setFormLoder(true);
    await completeWorkClient(body)
      .then((data) => {
        setFormLoder(false);
        setDefault();
        handleReload();
      })
      .catch(() => {
        setFormLoder(false);
      });
  };

 
  // handle single staff approve by agency
  const handleApproveStaff = async (id) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let agencyID = userData.agencyId ? userData.agencyId : "";
    let body = {
      workId: workID,
      staffId: id,
      agencyId:workAssign.agencyId,
    };
    setFormLoder(true);
    await approveStaff(body)
      .then((data) => {
        setFormLoder(false);
        fetchWorkById(workAssign.id);
        // setDefault();
        // handleReload();
      })
      .catch(() => {
        setFormLoder(false);
      });
  };

  // handle single staff reject by agency
  const handleRejectStaff = async (id) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let agencyID = userData.agencyId ? userData.agencyId : "";
    let body = {
      workId: workID,
      staffId: id,
      agencyId:workAssign.agencyId,
      rejectReasons: rejectreason[0].newchecked,
      additionalComments: rejectreason[0].texarea,
      contactEnabled: rejectreason[0].contact,
    };
    // setFormLoder(true);
    await rejectStaff(body)
      .then((data) => {
        // setFormLoder(false);
        // setDefault();
        // handleReload();
        fetchWorkById(workAssign.id);
      })
      .catch(() => {
        setFormLoder(false);
      });
  };

  // handle approve all staff
  // const handleApproveAll = () => {
  //   setFormLoder(true);
  //   let { workStaffAssignment } = edit;
  //   if (workStaffAssignment.staffWorkData) {
  //     workStaffAssignment.staffWorkData.forEach((item) => {
  //       handleApproveStaff(item.staffId);
  //     });
  //   }
  //   setFormLoder(false);
  // };
  const handleApproveAll = () => {
    setFormLoder(true);
    let { workStaffAssignment } = edit;
    if (workStaffAssignment.staffWorkData) {
      workStaffAssignment.staffWorkData.forEach((item,index) => {
      setTimeout(() => {
          handleApproveStaff(item.staffId);
      }, 500*index);
    });
    }
    setWorkAssign(false);
    setFormLoder(false);
  };

  // handle approve all staff
  // const handleRejectAll = () => {
  //   setFormLoder(true);
  //   let { workStaffAssignment } = edit;

  //   if (workStaffAssignment.staffWorkData) {
  //     workStaffAssignment.staffWorkData.forEach((item) => {
  //       handleRejectStaff(item.staffId);
  //     });
  //   }
  //   setFormLoder(false);
  // };
  const handleRejectAll = () => {
    // setFormLoder(true);
    let { workStaffAssignment } = edit;


    if(workStaffAssignment.hasOwnProperty("rejectedStaff")){
       
      for(let i=0;i<workStaffAssignment.staffWorkData.length;i++){
        let count=0;;
        for(let a=0;a<workStaffAssignment.rejectedStaff.length;a++){
          if(workStaffAssignment.staffWorkData[i].staffId!==workStaffAssignment.rejectedStaff[a].staffId)
          count++;
        }
        if(count==workStaffAssignment.rejectedStaff.length){
          Toast({type: 'error', message: `The rejection reason is not filled for staff ${workStaffAssignment.staffWorkData[i].staffName}`});
        }
      }
    }
    else{
      for(let i=0;i<workStaffAssignment.staffWorkData.length;i++){
        Toast({type: 'error', message: `The rejection reason is not filled for staff ${workStaffAssignment.staffWorkData[i].staffName}`});
      }
    }

    // if (workStaffAssignment.staffWorkData) {
    //   workStaffAssignment.staffWorkData.forEach((item,index) => {
    //   setTimeout(() => {
    //       // handleRejectStaff(item.staffId);
    //   }, 500*index);
    // });
    // }
    // setFormLoder(false);
  };

  const isButtonDisabled = (formLoader, workStaffAssignment) => {
      return  !(workStaffAssignment &&  workStaffAssignment.staffWorkData &&  workStaffAssignment.staffWorkData.length > 0)

  };



  let { workStaffAssignment,id } = edit;
  
  useEffect(()=>{
    let rejectdata=[]
  for(let i=0;i<workStaffAssignment?.staffWorkData?.length;i++){
    let count=0;
    let rejectedreason = [];
    for(let a=0;a<workStaffAssignment?.rejectedStaff?.length;a++){
    if(workStaffAssignment?.staffWorkData[i].staffId===workStaffAssignment?.rejectedStaff[a].staffId){
      rejectedreason.push(workStaffAssignment.rejectedStaff[a])
      count++;
    }
    }
    if(count ==1){
      rejectdata.push({value:true, reject:rejectedreason[0]})
    }
    else {
      rejectdata.push({value:false,reject:""})
    }
  }
  setReject(rejectdata)
},[edit])
  
  return (
    <>
      <NormalModal
        toggle={setDefault}
        className={"modal-fill "}
        isOpen={workAssign.value}
        size="lg"
        onRefresh={fetchWRDetails}
        renderHeader={(e) => (
          <div className=" d-flex justify-content-between">
            <h6 className="font-weight-bold "> Work Request</h6>
            {/*<div className="d-flex ">
              {edit.status < 3 && edit.status !== 0 ? (
                <img
                  className=" ml-3 cursor-pointer"
                  src={editIcon}
                  onClick={() => {
                    setDefault();
                    setVisible({ value: true, id: workID });
                  }}
                  alt=""
                />
              ) : (
                ""
              )}
              <img
                className={`my-icons ml-3 ${
                  edit.status < 2 && edit.status !== 0
                    ? "cursor-pointer"
                    : "disabled"
                }`}
                src={`${
                  edit.status < 2 && edit.status !== 0
                    ? deleteIcon
                    : deleteIconDisable
                }`}
                onClick={() => {
                  handleDelete();
                }}
                alt=""
              />
            </div>*/}
          </div>
        )}
        bodyClass="add-staff-box"
        headerClass="border-0 pt-4 px-4 bg-violet"
      >
        {!isLoading ? (
          <>
            <div className="row align-items-start mb-3">
              <div className="col-9">
                <p className="font-weight-bold fs-16">
                  WR Id -{" "}
                  {workStaffAssignment
                    ? 
                      workStaffAssignment.workId.slice(
                        workStaffAssignment.workId.length - 6,
                        workStaffAssignment.workId.length
                      )
                    :  edit._id
                    ? edit._id &&
                      edit._id.slice(edit._id.length - 6, edit._id.length)
                    : ""}
                </p>
                </div>
                <div className="col-3 row">
                <div className="col-6">
                <NormalButton
                    onClick={(e) => {
                        if (edit.status !== 0)
                          setWorkAssign({ value: false, id: workStaffAssignment.workId });
                          setVisible({ value: true, id: workStaffAssignment.workId,agencyId:workAssign.agencyId });
                    }}
                    id="editWR"
                    label="EDIT"
                    disabled={
                      edit.status >= 3 || 
                      edit.status ===0 || 
                      (edit.status === 2 && edit.workStaffAssignment.staffWorkData.length !== 0)
                    }
                    outline={false}
                    // loader={isFormLoder}
                    className={
                      edit.status >= 3 || 
                      edit.status ===0 || 
                      (edit.status === 2 && edit.workStaffAssignment.staffWorkData.length !== 0)
                      ?"btn fs-12 text-primarybg bg-transparent font-weight-bold no-drop":"btn fs-12 text-primarybg bg-transparent font-weight-bold"}
                  />
                  </div>
                <div className="col-6">
                <NormalButton
                   onClick={() => {
                    setCancelId(id);
                    setOpenModal(true); 
                  }}
                    id="cancel"
                    label="CANCEL"
                    outline={false}
                   disabled={edit.status === 0 || edit.status > 4}
                    className={edit.status === 0 || edit.status > 4?"btn fs-12 text-primarybg bg-transparent font-weight-bold no-drop":" btn fs-12 text-primarybg bg-transparent font-weight-bold"}
                    />
                  </div>
                  </div>
              <div className="col-1">
                <div className="form-group">
                  <img className=" ml-3 " height="28px" width="20px" src={bagIcon} alt="" />
                </div>
              </div>
              <div className="col-11">
                <div className="form-group">
                  <p className="sub-heading mb-0">
                  Job Function -  {edit.jobCategory &&
                      edit.jobCategory.charAt(0).toUpperCase() +
                        edit.jobCategory.slice(1, edit.jobCategory
                      .length)} - {edit
                        .jobName &&
                      edit.jobName.charAt(0).toUpperCase() +
                        edit.jobName.slice(1, edit.jobName.length)}
                    {/*<span className="ml-2">
                      {edit.jobFunction &&
                        edit.jobFunction.charAt(0).toUpperCase() +
                          edit.jobFunction.slice(1, edit.jobFunction.length)}
                    </span>*/}
                  </p>
                  {/* <p className="sub-text fs-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p> */}
                </div>
                <div className="form-group">
                  <p className="sub-heading mb-0">
                  Work Request Priority -  {edit.priority}</p>
                </div>
               
              </div>
            </div>
            <div className="row align-items-center mb-2">
              <div className="col-1">
                <div className="form-group">
                  <img className=" ml-3 " src={markerIcon} alt="markerIcon"/>
                </div>
              </div>
              <div className="col-11">
                <div className="form-group">
                  <p className="sub-heading fs-12">{edit.location}</p>
                </div>
              </div>
            </div>
            <div className="row  justify-content-end">
              <div className="col-5">
                <div className="form-group">
                  <p className="sub-text fs-13">Work Requested Date/Time</p>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  {/* <p className="text-black fs-16 ">
                    {Moment(edit.startDate).format("DD/MM/YYYY")}
                    <span className="ml-3">
                      {Moment(edit.startDate).format("hh:mm a")} -{" "}
                      {Moment(edit.endDate).format("hh:mm a")}
                    </span>
                  </p> */}
                  <p className="text-black fs-12 ">
                    {" "}
                    Starts
                    <span className="ml-2">
                      {Moment(edit.startDate).format(`${localStorage.getItem('dateFormat')}`)}
                    </span>
                    {/* <span className="ml-3">
                            {Moment(edit.startDate).format('hh:mm a')} - {Moment(edit.startDate).format('hh:mm a')}
                            </span> */}
                    <span className="ml-3">
                      {Moment(edit.startDate).format("hh:mm:ss a")}
                    </span>
                  </p>
                  <p className="text-black fs-12 ">
                    {" "}
                    Ends
                    <span className="ml-2">
                      {Moment(edit.endDate).format(`${localStorage.getItem('dateFormat')}`)}
                    </span>
                    {/* <span className="ml-3">
                            {Moment(edit.endDate).format('hh:mm a')} - {Moment(edit.endDate).format('hh:mm a')}
                            </span> */}
                    <span className="ml-3">
                      {Moment(edit.endDate).format("hh:mm:ss a")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-end">
              <div className="col-5">
                <div className="form-group">
                  <p className="sub-text fs-13">Work Request Status</p>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <span
                    className="req-status text-black fs-13"
                    style={{"--variable-status-color":edit.status !==2 ?(colorByStatus?.[edit.status]?.color) : edit.status === 2 && edit.workStaffAssignment.staffWorkData.length !== 0 ?(colorByStatus?.[2.1]?.color):(colorByStatus?.[2]?.color)}}
                  >
                   {edit.status === 2 && edit.workStaffAssignment.staffWorkData.length !== 0 ? 'STAFF HAS BEEN ASSIGNED. PLEASE CONFIRM' : colorByStatus?.[edit.status]?.status}
                  </span>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-end">
              <div className="col-5">
                <div className="form-group">
                  <p className="sub-text fs-13">Number Of Staff</p>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <p className="text-black fs-13 "> {`${edit.staffAccepted ? edit.staffAccepted : 0}-Allocated (Out Of ${edit.staffNeeded} Requested)`} </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-end">
              <div className="col-5">
                <div className="form-group">
                  <p className="sub-text fs-13"> Work Request Title </p>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <p className="text-black fs-16 ">
                   {edit.workRequest?edit.workRequest:"-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-end">
              <div className="col-5">
                <div className="form-group">
                  <p className="sub-text fs-13">Engagement Type</p>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <p className="text-black fs-13 "> {edit.engagementType == 1 ? 'Contract' : edit.engagementType == 2 ? 'Regular' : ''} </p>
                </div>
              </div>
            </div>
            {/* <div className="row align-items-center justify-content-end">
              <div className="col-5">
                <div className="form-group">
                  <p className="sub-text fs-16">Work category</p>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <p className="text-black fs-16 "> {edit.jobFunction}</p>
                </div>
              </div>
            </div> */}
            {/* <div className="row align-items-center justify-content-end">
              <div className="col-5">
                <div className="form-group">
                  <p className="sub-text fs-13">Work Request Priority</p>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <p className="text-black fs-13 text-capitalize ">{edit.priority}</p>
                </div>
              </div>
            </div> */}
            <div className="row align-items-center justify-content-end">
              <div className="col-5">
                <div className="form-group">
                  <p className="sub-text fs-13">Work Request Type</p>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <p className="text-black fs-13 "> {
                      edit.wrType == 1 ? "Weekday" :
                      edit.wrType == 2 ? "Weekend" :
                      edit.wrType == 3 ? "Holiday" :
                      edit.wrType == 4 ? "Contract" : "-"
                  }</p>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-end">
                <div className="col-5">
                    <div className="form-group">
                        <p className="sub-text fs-13" >Attached Documents</p>
                    </div>    
                </div>
                <div className="col-6">
                    <div className="form-group">
                      {!edit.documents || edit.documents.length ===0 ? <label>No Attachments</label> : null}
                        {/* {edit.documents?.map((doc,i)=><a className="fs-16 document-links" href={doc.split("|").length>1 && doc.split("|")[1]} target="_blank"> {doc.split("|").length>1 && doc.split("|")[0]} ({i+1})</a> )} */}
                        {edit.documents?.length===0?"-":
                            edit.documents?.map((doc,i)=><a 
                                type='button'
                                className="fs-13 document-links" 
                                // href={doc.split("|").length>1 && doc.split("|")[0]} 
                                onClick={()=>{
                                        if(doc.split("|").length>1)
                                            fetchFileDownloadURLById(doc.split("|")[0], doc.split("|")[1], doc.split("|")[2]);
                                    } 
				 }>
                                  {doc.split("|").length>1 &&(
                                    doc.split("|")[1].length>35 ? '...'+doc.split("|")[1].substring(doc.split("|")[1].length-35) : doc.split("|")[1] 
                                  )} ({i+1})
                            </a> )}                       
                    </div>    
                </div>
            </div>
            <div className="row align-items-center justify-content-end">
                <div className="col-5">
                    <div className="form-group">
                        <p className="sub-text fs-13" >Special Notes</p>
                    </div>    
                </div>
                <div className="col-6">
                    <div className="form-group">
                      <p className="text-black fs-13 document-links "> {edit.notes}</p>                    
                    </div>    
                </div>
            </div>

            <div className="row align-items-start justify-content-end">
              <div className="col-5">
                {/* <div className="form-group">
                  <p className="sub-text fs-16">Work Assigned date &time</p>
                </div> */}
              </div>
              <div className="col-6">
                <div className="form-group">
                  <p className="text-black fs-12 ">
                    {" "}
                    {/* starts */}
                    {/* <span className="ml-2">
                      {Moment(edit.startDate).format("DD/MM/YYYY")}
                    </span> */}
                    {/* <span className="ml-3">
                            {Moment(edit.startDate).format('hh:mm a')} - {Moment(edit.startDate).format('hh:mm a')}
                            </span> */}
                    {/* <span className="ml-3">
                      {Moment(edit.startDate).format("hh:mm a")}
                    </span> */}
                  </p>
                  <p className="text-black fs-12 ">
                    {" "}
                    {/* ends */}
                    {/* <span className="ml-2">
                      {Moment(edit.endDate).format("DD/MM/YYYY")}
                    </span> */}
                    {/* <span className="ml-3">
                            {Moment(edit.endDate).format('hh:mm a')} - {Moment(edit.endDate).format('hh:mm a')}
                            </span> */}
                    {/* <span className="ml-3">
                      {Moment(edit.endDate).format("hh:mm a")}
                    </span> */}
                  </p>
                  {/* <p className="text-black fs-12 ">  Weekly on monday, Tuesday,wednesday,thursday,friday until Oct 14,2020
                        </p>                         */}
                </div>
              </div>
            </div>
            <hr className="px-2" />
            {/* {
                edit.status <3 ? */}
            <>
              <h6 className="text-center font-weight-bold mb-3 pb-3">Staff Assignment Details</h6>
              <table className="w-80 mb-3 col-12 center-table">
                <thead>
                  <th className="fs-13">Name</th>
                  {/* <td>ID</td> */}
                  {/* <td>Location</td> */}
                  <th className="fs-13 setstatusheader">Staff Status</th>
                  <th colSpan="2" className="fs-13 setaction">Action</th>
                </thead>
                <tbody>
                  {!!workStaffAssignment &&
                  // !!workStaffAssignment.staffWorkData ? (
                  workStaffAssignment.staffWorkData.length > 0 ?  (
                    <>
                      {workStaffAssignment.staffWorkData.map((item, index) => (
                        <tr className="settablerow">
                          <td className="fs-12 setstaffname">
                           <p className="">{item.staffName.length > 20 ? item.staffName.substring(0, 20) + "..." : item.staffName}</p>
                            </td>
                          {/* <td className="fs-12">{item.staffId}</td> */}
                          {/* <td className="fs-12">{item.location}</td> */}
                          <td className="fs-12 setstatus">
                            <p className="fs-12 parasetstatus" >
                              {" "}
                              {StaffStatusLabels(item.status,edit.status,item.clockIn, item.pauseResumeData, item.clockOut )}
                            </p>
                          </td>
                          {edit.status === 0 ? (
                            <td className="fs-12">
                             <p className="setstatus"> Work Canceled </p>
                            </td>
                          ) : (
                            <>
                              {item.status === 3 ? (
                                <>
                                  <td
                                    className="text-success text-decoration-underline fs-10 w-15 cursor-pointer approvestatus"
                                    onClick={() =>
                                      handleApproveStaff(item.staffId)
                                    }
                                  >
                                   <p className="setapprovedd"> APPROVE{" "} </p>
                                  </td>
                                </>
                              ) : (
                                <td className="text-success fs-10 w-15 no-drop approvestatuss">
                                  {/* Approved{" "} */}
                                 <p className="text-muted setapproved" > {item.status > 3 ? "APPROVED" : "APPROVE"} </p>
                                </td>
                              )}
                              {item.status === 3 ? (
                                <td
                                  className="text-danger fs-10 w-15 cursor-pointer"
                                  // className="text-danger fs-12"
                                  // onClick={() => handleRejectStaff(item.staffId)}
                                  // onClick={() =>
                                  //   handleRejectStaff(item.staffId)
                                  // }
                                  
                                >
                                  {/* REJECT{" "}
                                  {show && <p>My name</p>} */}
                                  <RejectAccordian key={item.id} id={item.staffId} onSubmit={getrejectAccordiandata} data={rejectt.length>0 && rejectt[index].value==true ? rejectt[index].reject:""} />
                                </td>
                              ) : (
                                <td
                                  // className="text-danger fs-12 cursor-pointer"
                                  className="text-danger fs-10 w-15 no-drop setreject"
                                  // onClick={() =>
                                  //   handleRejectStaff(item.staffId)
                                  // }
                                >
                                 <p className="setreject text-muted"> REJECT{" "} </p>
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="5" className="border-0">
                        <p className="text-center fs-12">
                          {edit.status === 0
                            ? "Work Canceled"
                            : edit.status === 1
                            ? "Need to accept by agency"
                            : "No staff assign"}
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
            {/* :''
            } */}
            <hr/>
            {/* Status >= 3 , After one staff complete work, the status will be 3 (yet to start) */}
            {/* Status = 2, Not valid here, bcz before accepting showing timeline will be irrelevant */}

            {edit.status >= 3  || (edit.status === 0 && edit.workStaffAssignment?.staffWorkData.filter(staffWD=>{
              if(!!staffWD.clockIn) return true;
              else return false;
            }).length>0) ? (
              <>
                 {edit.status != 10 ? 
                 <StaffDetails
                 data={edit.workStaffAssignment}
                 edit={edit}
                 status={edit.status}
                 locationData={edit.location}
                 startDate={edit.startDate}
                 endDate={edit.endDate}
               />
                : null}
                {/* <TimeSheetMark data={edit.workStaffAssignment} /> */}
                <div className="d-flex align-items-center justify-content-end flex-column text-center">
                  <div className="wrapper">
                    <p
                      className={`completeByClient fs-12 btn-gray-clr mb-1 ${
                        edit.status === 5 ? "cursor-pointer" : "disabled"
                      }`}
                      onClick={() => completeWorkByClient()}
                      id="clientApproval"
                    >
                      APPROVE ALL{" "}
                    </p>
                  </div>
                </div>
              </>
            ) : edit.status !== 0 ? (
              <div className="d-flex align-items-center justify-content-between">
                <div className="py-2">
                  <NormalButton
                    onClick={() => {
                      setOpenModal(true);
                    }}
                    id="cancelWR"
                    label="CANCEL WORK REQUEST"
                    outline={false}
                    // loader={isFormLoder}
                    className=" btn fs-12 text-primarybg bg-transparent font-weight-bold"
                  />
                  <p></p>
                </div>
                <div className="d-flex align-items-end mt-2 justify-content-end ">
                  <div className="p-3"></div>
                  <NormalButton
                    onClick={() => {
                      handleRejectAll();
                    }}
                    id="rejectAll"
                    label="REJECT ALL"
                    outline={false}
                    disabled={isButtonDisabled(isFormLoder,workStaffAssignment)}
                    // loader={isFormLoder}
                    className="mb-4 btn fs-12 btn-purple-outline mr-2 "
                  />
                  <NormalButton
                    onClick={() => {
                      handleApproveAll();
                    }}
                    id="approveAll"
                    label="APPROVE ALL"
                    outline={false}
                    disabled={isButtonDisabled(isFormLoder,workStaffAssignment)}

                    // loader={isFormLoder}
                    className="mb-4 btn fs-12 outline-dark-btn btn-block form-btn "
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          <CommonPageLoader />
        )}
      </NormalModal>
      <CancelWorkModel
        toggle={() => setOpenModal(!openModal)}
        isOpen={openModal}
        handleAction={(e) => handleAction(e)}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSingleWork,
      deleteWork,
      cancelWork,
      approveStaff,
      rejectStaff,
      completeWorkClient,
    },
    dispatch
  );
};

export default connect(
  mapDispatchToProps,
  mapDispatchToProps
)(withRouter(WorkAssignment));

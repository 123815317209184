import React, { useState} from "react";
import search from "assets/svg/search.svg";
import Union from "../../../assets/svg/Vector_8.svg";
import UnionLite from "../../../assets/svg/ListIcon.svg";
import optionLite from "../../../assets/svg/CalendarIcon.svg";
import option from "../../../assets/svg/Vector_9.svg";
import {  NormalDropdown } from "component/common/index";
import "./style.scss";
// import { propTypes } from "react-bootstrap/esm/Image";
import refreshIcon from "assets/svg/refreshGreen.svg"
import { MultiSelect } from "react-multi-select-component";
import ItemRenderer from "component/common/ItemRenderer/ItemRenderr.tsx";
import valueRenderer from "component/common/ItemRenderer/valueRenderer.tsx";
import AgencyfilterItemRenderer from "../AgencyfilterMultiselect/ItemRenderr.tsx";
import AgencyvalueRenderer from "../AgencyfilterMultiselect/valueRenderer.tsx";
import CustomArrowMultiSelect from "../CustomArrowMultiSelect";
import { useHistory } from 'react-router-dom';

import showArchivesGreen from '../../../assets/svg/show-archives-green.svg'
import showArchivesGrey from '../../../assets/svg/show-archives-grey.svg'
import {Box} from "@mui/material";
import {Container} from "@mui/material";
import { Grid } from "@mui/material";
export const TableFilter = ({
  action,
  view,
  setActiveView,
  setPaginationConfig,
  setPage,
  sort,
  query,
  setQuery,
  locations,
  viewByweek,
  setViewByweek,
  // fsWrap = true,
  // setFilter,
  // filterData,
  // isSort,
  handleReload,
  setFilterStatus,
  multiSelectOptionsList,
  selectedOption,
  setSelectedOption,
  agencyMultiOptions,
  agencySelectedOption,
  setAgencySelectedOption,
  showArchives,
  onShowArchivesClick
}) => {

  const history = useHistory()
  
  // const [filter, updateFilter] = useState("");
  const [forSearch, setForSearch] = useState("");
  // const [action, updateAction] = useState(action)
  // useEffect(()=>{
  //     console.log(locations)
  // },[locations])
  return (
    
      <Container maxWidth="1400px" sx={{display:"flex",paddingLeft:"16px !important", paddingRight:"0px !important"}}>
        <Grid item container spacing={2} sx={{display:"flex", justifyContent:"space-between", marginTop:"0px !important"}}>
        <div style={{ display: 'flex', alignItems:"center" }}>
          <Grid className="serch_box"item xs={12}>
            <form className="input-group  search-input-group" 
              onSubmit={(e) => {
                e.preventDefault();
                setQuery({ ...query, searchKey: forSearch });
              }}
              >
                <div className="search-bar mr-2" id={'wr-id-search-box'}>
                  <input
                    type="search"
                    id="searchValue"
                    name="searchValue"
                    value={forSearch}
                    onChange={e=>setForSearch(e.target.value)}
                    className="form-control border-right-0 searchInput"
                    placeholder="Search by WR Id"
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append ">
                    <span
                      className="input-group-text bg-white border-left-0 search-icon"
                      id="basic-addon2"
                    >
                      <button
                        type="submit"
                        className="hidden-button"
                      >
                        <img className="mailIcon" src={search} alt="Logo" />
                      </button>
                    </span>
                  </div>
                </div>
                
              </form>
          </Grid>
          <Grid item xs={12} className="ml-2 d-flex align-items-center">
                <button
                  id={'show-archive-btn'}
                  className={`show-archive-btn ${showArchives ? 'active':''}`}
                  onClick={() => {
                      onShowArchivesClick()
                  }}
                >
                  <img src={showArchives ? showArchivesGreen : showArchivesGrey } alt='show-archive' />
                  <span className='show-archive-text'>{showArchives ? 'Show Active' : 'Show Archives'}</span>
                </button> 
          </Grid>
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "flex-end", flexDirection: { xs: "column", sm: "row" } }}>
            
              {/* <p className="mb-0 pr-2">Site</p> */}
              {/* <NormalSelect
                                    placeholder="All"
                                    name="username"
                                    value={query.location}
                                    options={locations}
                                    className="form-control select-mini"
                                    handleChange={(e)=>{
                                        setQuery({...query,location:e.target.value})
                                    }}
                                /> */}
              {/* <NormalDropdown
                onClick={(e) => {
                  setQuery({
                    ...query,
                    location: e.target.value,
                    locationLabel: e.target.data.label,
                  });
                }}
                label={query.locationLabel? query.locationLabel.length < 20? query.locationLabel : query.locationLabel.substring(0,20) +"..." :  "All Location"}
                optionsList={locations}
                className={`custom-dropdown location border fs-12 rounded w-200 size`}
              /> */}
            <Grid item xs={12} className="d-flex align-items-center mr-2 sort_by" id={'agency-multi-select'}>
              {/* <p className="mb-0 mr-2 p-2">Show</p> */}
              {/* <NormalSelect
                                    placeholder="All "
                                    name="username"
                                    value={query.sortBy}
                                    options={sort}
                                    className="form-control select-mini"
                                    handleChange={(e)=>{
                                        setQuery({...query,sortBy:e.target.value})
                                    }}
                                /> */}
              {/* <NormalDropdown
                onClick={(e) => {
                  setFilterStatus(e.target.value);
                  setQuery({
                    ...query,
                    sortBy: e.target.value !== "2.1" ? e.target.value : 2,
                    sortLabel: e.target.data.label,
                  });
                }}
                label={query.sortLabel ? query.sortLabel.length < 20 ? query.sortLabel : query.sortLabel.substring(0,20) +"..." : "All Work Status"}
                optionsList={sort}
                className={`custom-dropdown border rounded fs-12 view_by_drop size`}
              /> */}

              <MultiSelect
              options = {agencyMultiOptions}
              value={agencySelectedOption}
              onChange={setAgencySelectedOption}
              labelledBy="Select"
              ItemRenderer={AgencyfilterItemRenderer}
              valueRenderer={AgencyvalueRenderer}
              disableSearch="true"
              className='dropdown-container agency-filter'
              ArrowRenderer={CustomArrowMultiSelect}
              />
            </Grid>

            <Grid item xs={12} className="d-flex align-items-center sort_by" id={'wr-status-multi-select'}>
             <MultiSelect 
             options = {multiSelectOptionsList}
             value={selectedOption}
             onChange={setSelectedOption}
             labelledBy="Select"
             ItemRenderer={ItemRenderer}
             valueRenderer={valueRenderer}
             ArrowRenderer={CustomArrowMultiSelect}
             disableSearch="true"
             className='dropdown-container wr-filter'
             />

            </Grid>
            <Grid item xs={12} className="d-flex align-items-center ml-2 view_by">
              {/* <p className="mb-0 mr-2 p-2">
                <span>View By</span>
              </p> */}
              <NormalDropdown
                onClick={(e) => {
                  // setQuery({
                  //   ...query,
                  //   // viewBy: e.target.value,
                  //   viewLabel: e.target.data.label,
                  // });
                  setViewByweek(e.target.value);
                }}
                // label={query.viewLabel ? query.viewLabel : "All"}
                label={<p className="view-by-week">{viewByweek}</p>}
                optionsList={action}
                className={`custom-dropdown flex-row rounded fs-12 view_by_drop size day-week-month-dropdown`}
                style={{width:"100px", height:"1.68rem", marginTop:"0.02rem"}}
              />
            </Grid>
            <Grid sx={{display:"flex", flexDirection: { xs: "row" }}}>
            <Grid item xs={4} className="d-flex align-items-center  mr-0">
                            <div onClick={() =>{ 
                                    setActiveView(false)
                                    setPaginationConfig(prevState => ({
                                      ...prevState,
                                      pageCount: 10,
                                  }));
                                    setPage(1)
                                    history.replace('list')
                                   }}  className="d-flex align-items-center cursor-pointer info-icon">
                                <img id={'list-view-btn'} className=" ml-3 mr-0 calenderLI_img" src={`${view ?  UnionLite : Union}`} alt="viewCalenderImg" />
                                <span className='extra-info' style={{marginTop:"3.5rem"}}> List View </span>
                            </div>
            
            </Grid>

            <Grid item xs={4} className="d-flex align-items-center  mr-1">
                            <div onClick={() => {setActiveView(true)
                            setPaginationConfig(prevState => ({
                              ...prevState,
                              pageCount: 50,
                          }));
                            setPage(1)
                            history.replace('calendar')}} className="d-flex align-items-center cursor-pointer info-icon" >
                                <img id={'calender-view-btn'} className=" ml-3 mr-1 calenderLI_img" src={`${view ?  option : optionLite}`} alt="viewCalenderImg"/>
                                <span className='extra-info' style={{marginTop:"3.5rem"}}> Calendar View </span>
                            </div>
            
            </Grid>
            <Grid item xs={4} className="d-flex align-items-center">
            <div onClick={handleReload} className="d-flex align-items-center  cursor-pointer info-icon">
                <img id={'refresh-btn'} className='refreshIcon ml-1' src={refreshIcon} alt="refreshIcon"/>
                <span className='extra-info' style={{marginTop:"3rem"}}> Refresh </span>
            </div>
            </Grid>
            </Grid>
            </Grid>
          </div>
          </Grid>
      </Container>
    
  );
};

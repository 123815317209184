import React, { useState, useEffect } from 'react'
import classes from './interactive_map.module.scss'
import TimelineMap from './timelineMap'
import Map from './map'


export default function InteractiveMap({ open, toggle, activePointsFromStaffDetails, staffName='', staffLatLong = { lat: '', lng: '' },clientLatLng,timeData = [], sitePicture }) {
    const [activePoint, setActivePoint] = useState('');
    const [showLines, setShowLines] = useState(true);
    const [warningModal, setWarningModal] = useState({
        show: false,
        msg:'! No Location Found'
    });

    useEffect(() => {
        if(activePointsFromStaffDetails || activePointsFromStaffDetails===0){
          setActivePoint(activePointsFromStaffDetails);
          setShowLines(false);
        }
      }, [activePointsFromStaffDetails])

    return !!open && (
        <div className={classes.interactive_map}>
            <div className={classes.container}>
                <div className={classes.close_button}>
                    <h2>{ !!staffName ? `Map View - ${staffName}`:'Map View' }</h2>
                    <button onClick={toggle}>×</button>
                </div>
                <div className={classes.map_action_wrapper}>
                    <div className={classes.map_wrapper}>
                        <Map
                            mapData={timeData}
                            staffLatLong={staffLatLong}
                            clientLocLatLong={clientLatLng}
                            activePoint={activePoint}
                            showLines={showLines}
                            setActivePoint={setActivePoint}
                            warningModal={warningModal}
                            sitePicture={sitePicture}
                            setWarningModal={setWarningModal}
                        />
                    </div>
                    <div className={classes.action_wrapper}>
                        <div> 
                            <p className={classes.shift_title}>My Shift Entry Details</p>
                            <label className={classes.show_lines}>
                                <input
                                    type="checkbox"
                                    checked={showLines}
                                    onChange={(e)=>setShowLines(e.target.checked)}
                                />
                                Show Lines
                            </label>
                            <hr/>
                            <div>
                                <TimelineMap
                                    timelineArray={timeData}
                                    activePoint={activePoint}
                                    setActivePoint={setActivePoint}
                                    mapPointHandler={(lat, lng) => {
                                        if (!lng || !lat) setWarningModal(prev => ({ ...prev, show: true }));
                                        // else console.log(`latitude : ${lat} , longitude : ${lng}`);
                                    }}
                                />
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


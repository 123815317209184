import React,{useState} from 'react'
import './style.scss';
import { useEffect } from 'react';
// import { TableWrapper } from '../TableWrapper';


const CustomDropdown = ({ options, value, onChange = ()=>{} , disabled = false, placeholder='select', onJobCategorySearch }) => {

    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState({lable:'', value:''});
    const [inputValue, setInputValue] = useState(value);

    useEffect(()=>{
     options?.map((item)=> {
      if(item.value === value){
        setSelectedOption(item)
      }
     })
    
     },[value,options])

    const handleOptionSelect = (option) => {
      setSelectedOption(option);
      setOpen(false)
      onChange(option);
      onJobCategorySearch(option.value) 
    };

    const handleInput = (e) => {
      if(e.key === 'Enter') {
        onJobCategorySearch(inputValue)
        setOpen(true)
      }
    }

    
  
    return (
      <div className={`custom-dropdown  ${disabled ? 'disabled-label' : ''}`}>
          <input className={`selected-label cursor-pointer ${disabled ? 'disabled-label' : ''} `} onClick={()=> {
            if(disabled){
                return;
            }
            setOpen(!open)
            }} 
            placeholder={placeholder}
            value={selectedOption?.value ? selectedOption?.label?.split('|_|').join(' - ') : inputValue}
            onKeyDown={handleInput}
            onChange={(e) => {
              if(selectedOption?.value) {
                setSelectedOption({label:'', value:''});
                onChange({label:'', value:''})
                setInputValue('')
                onJobCategorySearch('')
              }
              else{
                setInputValue(e.target.value)
              }
            }}
            disabled={disabled}
             />
                  <span className="icon-down down-icon custom-icon"></span>
      { open && <div className='d-flex flex-column position-absolute bg-white w-100  custom-dropdown-box' style={{top:'40px', left:'0px', zIndex:999, overflow:'auto', maxHeight:'30rem'}}>
      {/* pagination to be implemented after BE refinement */}
      {/* <div className='px-2' style={{width: '100%' }}>
            <TableWrapper
              page={page}
              paginationChange={(page) => setPage(page)}
              paginationConfig={paginationConfig}
              pageCountChange={(count) => setPaginationConfig({ ...paginationConfig, pageCount: Number(count) })}
            />
          </div> */}
        {options?.map((option, index) => (
          <div
            key={index}
            className={`dropdown-option ${selectedOption.value === option.value ? 'selected' : ''}`}
            onClick={() => handleOptionSelect(option)}
          >
            <div className='job-category-name-label'> {option.label.split('|_|')[0]}</div>
            <div className='job-category-purpose-label'> <span className='job-category-purpose'>{option.label.split('|_|')[1].split('').slice(0,50).join('')}</span>{option.label.split('|_|')[1].length > 50 ? '...' : ''} </div>
       
          </div>
        ))}
        </div>}


      </div>
    );
  };
  
  export default CustomDropdown;
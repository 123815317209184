import { combineReducers } from "redux";
import home from "./home"
import webSocketReducer from "./webSocketReducer"
import tourGuideReducer from "./tourGuide";
import warningReducer from "./warning";
import userDataReducer from "./userData";
import agencyDataReducer from "./agencyData"

export const reducers = combineReducers({
    home,
    webSocketReducer:webSocketReducer,
    tourGuideReducer,
    warningReducer,
    userDataReducer,
    agencyDataReducer
})
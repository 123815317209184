import { Agency } from "../service/apiVariables";
import { addQuery } from "../service/helperFunctions";


export const getAgencies = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, Agency.getAgencies);
      api({ ...Agency.getAgencies })
        .then(({ data,message }) => {
          resolve(data); 
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
  export const getAllAgencies = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, Agency.getAllAgencies);
      api({ ...Agency.getAllAgencies })
        .then(({ data,message }) => {
          resolve(data); 
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
  export const inviteAgencyByClient = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...Agency.inviteAgencyByClient, body})
        .then(({ data,message }) => {
          resolve(data); 
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };


  export const getAllJobCategories = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, Agency.getAllJobCategories);
      api({ ...Agency.getAllJobCategories })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const getConnectInvites = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, Agency.getConnectInvites);
      api({ ...Agency.getConnectInvites })
        .then(({ data,message }) => {
          resolve(data); 
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const cancelInvite = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, Agency.cancelInvite);
      api({ ...Agency.cancelInvite })
        .then(({ data,message }) => {
          resolve(data); 
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const getAgenciesWithPagination = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, Agency.getAgenciesWithPagination);
      api({ ...Agency.getAgenciesWithPagination })
        .then(({ data,message }) => {
          resolve(data); 
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
import React from "react";
import "./style.scss";

const valueRenderer = (selected, _options) => {
    return selected.length
      ? selected.map(({ label }) => "✔️ " + label)
      : <span className="publish-to-agency">Publish To Agency</span>;
  };


export default valueRenderer;
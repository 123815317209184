import React, { useEffect, useState } from 'react'
import './style.scss'
import loadingIcon from "assets/images/loading.png";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import dummyImage from 'assets/images/dummy-image.png'
import { getFileFromMDB } from 'action/fileHandler';

const DisplayCredentialsImage = React.memo(({getFileFromMDB, imageId, entity, clientId, agencyId , onSuccess, rounded, navbar}) => {
  const [onlineImage, setOnlineImage] = useState('')
  const [loading, setLoading] = useState(false)
  const [filtetype,setFileType] = useState("");

      const handleImageDownload = async (logo) => {
        setLoading(true)
        let imageData;
        let fileId = logo.split('|')[0]
        await getFileFromMDB({entity: entity ? entity:'', clientId: clientId ? clientId : '',agencyId: agencyId ? agencyId : ''},fileId)
          .then((response)=>{
            if (response.contentType === "application/pdf") {
               setFileType(response.contentType)
              imageData = `data:${response.contentType};base64,${response?.fileContent.data}`
            } else {
              imageData = `data:${response.contentType};base64,${response?.fileContent.data}`
            }
            setLoading(false)
            setOnlineImage(imageData.toString())
            if(onSuccess){
              onSuccess()
            }
            return imageData
          })
          .catch((error) => {
            setLoading(false);
            setOnlineImage(null);
            console.log('Error fetching image:', error);
          });
      };

      useEffect(()=>{
        if(imageId){
          handleImageDownload(imageId)
        }else{
          setOnlineImage(null)
        }
      },[imageId])

  return (<>
    {onlineImage !== null ? (
      <div style={{background:"white",width:"31rem", height:"28rem"}} className='wrboxdisplayimage'>
        {filtetype == "application/pdf"?
         <iframe
         title="PDF Document"
         src={onlineImage}
         width="100%"
         height="100%"
         frameBorder="0"
       />
        :
        <img
        className={`${
          navbar && 'profile-nav rounded-circle'
        } ${rounded && 'profile-icon rounded-circle'} ${
          loading ? 'loading-animation' : 'image-view'
        }`}
        src={loading ? loadingIcon : onlineImage}
        alt="User Profile"
      />
        }
      
      </div>
    ) : !navbar ? (
      <div className="d-flex justify-content-center align-items-center" style={{width:"31rem", height:"28rem",background:"white"}}>
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Image not found</Tooltip>}
          >
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <img src={dummyImage} alt="Dummy Profile" />
            </div>
        </OverlayTrigger>
      </div>
    ) : null}
</>)
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        getFileFromMDB
      },
      dispatch
    );
  };

  export default connect('', mapDispatchToProps)(DisplayCredentialsImage)


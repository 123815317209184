import React, { Component } from 'react';
import './pagination.scss';


export class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
    }
  }
  handlePagination = direction => {
    try {
      let { page = 1 } = this.props.pageMeta || {};
      let pageNumber = eval(`${page} ${direction} ${1}`);

      this.props.handleChange({ page: pageNumber });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    let { pageCount = 10, page = 1 } = this.props.pageMeta || {};
    if (pageCount <= 1) {
      return '';
    }
    // let { data } = this.props;
    // let { currentPage } = this.state;

    return (
      <div className="d-flex text-center w-100 justify-content-end pr-4 py-3">
        <p className="m-0 d-flex align-items-center">
          <button
            className="btn-effect table-left-arrow cursor-pointer"
            disabled={page === 1}
            onClick={e => page !== 1 && this.handlePagination('-')}
          >
            Previous
          </button>
          {/* {data.map((data, i) => (
            // <li className="page-item" key={i}>
              <span
              key={i}
                className={` ${i === currentPage ? 'active-page' : 'next-page'}`}
                href={`#${i + 1}`}
                onClick={() => this.handlePagination(data, i)}
              >

                {i + 1}
              </span>
            // </li>
          ))} */}
          {/* <span className="active-page">
            {page}
          </span>
          <span className="next-page">
            2
          </span> */}
          <button
            className="btn-effect table-right-arrow cursor-pointer"
            disabled={page === pageCount}
            onClick={e => page !== pageCount && this.handlePagination('+')}
          >
            Next
          </button>
        </p>
      </div>
    );
  }
}

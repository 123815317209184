import React, { Component } from "react";
import './NormalButton.scss';

export class NormalButton extends Component {
  render() {
    const {
      className = "",
      label = "",
      leftIcon="",
      onClick,
      id,
      disabled = false,
      outline = false,
      mainbg = false,
      link = false,
      normal = false,
      danger = false,
      rightIcon = "",
      rightIconTitle = "",
      rightIconStyle = "",
      rightIconAlt = "",
      loader = false
    } = this.props;

    return (
      <div className="" id={id == "rejectAll" || id == "approveAll" ? id : ""}>
        <button
          id={id}
          className={`btn cursor-pointer ${outline ? "outline-btn" : ""} ${
            mainbg ? "mainbg-btn" : ""
            } ${normal ? "normal-btn" : ""} ${danger ? "danger-btn" : ""} ${link ? "delete-btn" : ""} ${className}`}
          onClick={onClick}
          disabled={disabled || loader}
        >
          {leftIcon !== "" ? ( <img style={{marginRight: "5px",marginTop:"-0.25rem"}} src={leftIcon} alt="leftIcon"/>):('')}
          {label}
          {rightIcon !== "" ? ( 
          <>
            {rightIconTitle ?
              <span className={rightIconStyle} data-info-text={rightIconTitle}>
                <img className="ml-1" src={rightIcon} alt={rightIconAlt}/>
              </span> 
              :
                <img className="ml-1" src={rightIcon} alt={rightIconAlt}/>
            }
          </>):('')}
          {rightIcon !== "" ? (
            <span className={`btn-right-icon ${rightIcon}`}></span>
          ) : (
              ""
            )}
            {loader ?
            (
              <div className="spinner-border ml-2" style={{width:"15px", height:"15px"}}>
                <span className="sr-only">Loading...</span>
              </div>
            )
            : (
              ""
            )}
        </button>
      </div>
    );
  }
}

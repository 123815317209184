// import { authType } from "../service/actionType";

import { api } from "service/api";
import { authApi } from "../service/apiVariables";

// import { scrollTop } from "../service/helperFunctions";

// import { history } from "../service/helpers";

import { addQuery } from "../service/helperFunctions";

// common login
export const login =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.adminLogin, body })
        .then(({ message, token, data }) => {
          localStorage.setItem("AuthToken", data.token);
          localStorage.setItem("userId", data.userId);

          resolve(data);

          Toast({ type: "success", message, time: 5000 });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getUserLoginCount = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, authApi.getUserLoginCount);
        api({ ...authApi.getUserLoginCount }).then(({ data }) => {
          localStorage.setItem('productTour', data < 2 ? true : false)
          localStorage.setItem('OrientationCount',data < 2 ? true: false)
            resolve(data)
        }).catch(({ message }) => {
            reject(Toast({ type: "error", message }))
        })
    })
}

export const changePasswordOTP =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, authApi.changePassword);
      authApi.changePassword.query.emailId=encodeURIComponent(authApi.changePassword.query.emailId)
      api({ ...authApi.changePassword })
        .then(({ data, message }) => {
          Toast({ type: "success", message })
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const resetpassCode =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.resetPassword, body })
        .then(({ message, token, data }) => {
          resolve(true);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const updatePassword =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
    
      api({ ...authApi.updatePassword, body })
        .then(({ message, token, data }) => {
          resolve(true);
          Toast({ type: "success", message, time: 5000 });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const otpVerify =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, authApi.OTPVerify);
      authApi.OTPVerify.query.emailId=encodeURIComponent(authApi.OTPVerify.query.emailId)
      api({ ...authApi.OTPVerify })
        .then(({ message, data }) => {
          resolve(data);
          Toast({ type: "success", message, time: 5000 });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const otplogin =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.otpLogin, body })
        .then(({ message, data }) => {
          localStorage.setItem("AuthToken", data.token);
          localStorage.setItem("userData", JSON.stringify(data.data));

          resolve(data);

          Toast({ type: "success", message, time: 5000 });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };


  export const getPermissionMenu = () => {
    return new Promise((resolve, reject) =>  {
        api({  ...authApi.getPermissionMenu}).then((data) => {
            resolve(data)
        }).catch(({ message }) => {
            reject(message)
        })
    })
  }

  export const logOut = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, authApi.adminLogOut);
      api({ ...authApi.adminLogOut })
        .then(({ message, data }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch((data) => {
          reject(data);
        });
    });
  };
  
  export const sendOtpToNewEmail =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, authApi.sendOtpToNewEmail);
      authApi.sendOtpToNewEmail.query.emailId = encodeURIComponent(authApi.sendOtpToNewEmail.query.emailId)
      authApi.sendOtpToNewEmail.query.newEmailId = encodeURIComponent(authApi.sendOtpToNewEmail.query.newEmailId)
      api({ ...authApi.sendOtpToNewEmail })
        .then(({ data, message }) => {
          Toast({ type: "success", message })
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const otpVerifyNewEmail =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, authApi.otpVerifyNewEmail);
      authApi.otpVerifyNewEmail.query.emailId = encodeURIComponent(authApi.otpVerifyNewEmail.query.emailId)
      authApi.otpVerifyNewEmail.query.newEmailId = encodeURIComponent(authApi.otpVerifyNewEmail.query.newEmailId)
      api({ ...authApi.otpVerifyNewEmail })
        .then(({ data, message }) => {
          Toast({ type: "success", message })
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const workSchedulePageSteps = [
    // {
    //     target:'#work-schedule-title',
    //     content:'Work Schedule Module',
    //     placement:'auto',
    //     // disableScrolling: true
    // },
    {
        target:'#add-work-request-btn',
        content:'Click here to create a work request',
        placement:'auto',
        disableBeacon: true
        // disableScrolling: true
    },
    // {
    //     target:'#wr-status-legend',
    //     content:'Work request labels and their respective color codes to keep track of the work status.',
    //     placement:'auto',
    //     disableScrolling: true
    // },
    {
        target:'#wr-id-search-box',
        content:'Use the Work ID to search for a specific work request.',
        placement:'auto',
        disableScrolling: true
    },
    {
        target:'.agency-filter',
        content:'Use the agency multi-selector to view work records based on selected agencies.',
        placement:'auto',
        disableScrolling: true
    },
    {
        target:'.wr-filter',
        content:'Use the WR Status multi-selector to view work records based on their status.',
        placement:'auto',
        disableScrolling: true
    },
    {
        target:'.day-week-month-dropdown',
        content:'Use this dropdown to view work records based on certain timeline such as day, month and week.',
        placement:'auto',
        disableScrolling: true
    },
    {
        target:'#list-view-btn',
        content:'By default, the work records are displayed in the list view mode.',
        placement:'auto',
        disableScrolling: true,
        spotlightPadding: 5
    },
    {
        target:'#calender-view-btn',
        content:'By default, the work records are displayed in the list view mode. You can click on the calendar icon to switch it to calendar view mode.',
        placement:'auto',
        disableScrolling: true,
        spotlightPadding: 5
    },
    {
        target:'#refresh-btn',
        content:'Update the work records by refreshing. Although every few seconds the list auto refreshed.',
        placement:'auto',
        disableScrolling: true
    },
    {
        target:'#work-list-date-slider',
        content:'View work records based on the start and end date selection',
        placement:'auto',
        spotlightPadding: 5,
        disableScrolling: true
    },
    {
        target:'#work-list-item',
        content:'When a work request is created, it will be displayed here in the list view with all the necessary details.',
        placement:'auto',
        disableScrolling: true,
        spotlightPadding: false
    },
    {
        target:'#work-list-item-btns',
        content:'Click on "view" to check all the details related to the work request. You can also "edit" and "cancel" the work request.',
        disableScrolling: true,
    },
]

export const workScheduleCalendarSteps = [
    {
        target:'.e-work-cells:nth-child(4)',
        content:'Work records will appear inside these calendar cells. You can click on a particular record to view the details',
        disableBeacon: true,
        disableScrolling: true,
    },
]
import { settingsApi } from "../service/apiVariables";

import { addQuery } from "../service/helperFunctions";

export const getClientById =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, settingsApi.getClientById);
      api({ ...settingsApi.getClientById })
        .then(({ data }) => {
          dispatch({type: 'UPDATE_USER_DATA',payload: data,})
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
  
export const getTimeZoneList = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
      addQuery(query, settingsApi.getTimeZoneList);
      api({ ...settingsApi.getTimeZoneList })
          .then(({ data }) => {
              resolve(data);
          })
          .catch(({ message }) => {
              reject(Toast({ type: 'error', message }));
          });
  });
};

export const updateClient =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...settingsApi.postUpdateClient, body })
        .then(({ message, data, statusCode }) => {
          resolve(statusCode);

          Toast({ type: "success", message, time: 5000 });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const deleteClient = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, settingsApi.deleteClient);
      api({ ...settingsApi.deleteClient, query })
        .then(({ data,message }) => {
          resolve(data); 
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
  export const downloadData = (query,responseType) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, settingsApi.downloadData);
      api({ ...settingsApi.downloadData, responseType })
        .then((data,message) => {
          resolve(data); 
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
  export const checkLocationUsed = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, settingsApi.checkLocationUsed);
      api({ ...settingsApi.checkLocationUsed, query })
        .then(({ data,message }) => {
          resolve(data); 
          if(data === true)
          Toast({ type: "error", message:'This site location is used in work request(s) already. Deletion is not allowed.', time:8000 });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const getNewTasks = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, settingsApi.newTasks);
        api({ ...settingsApi.newTasks })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
  };
  
  export const getTaxSetting = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, settingsApi.getTaxSetting);
      api({ ...settingsApi.getTaxSetting })
        .then(({ data,message }) => {
          resolve(data); 
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const updateTaxSetting = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...settingsApi.updateTaxSetting, body })
        .then(({ data,message }) => {
          resolve(data); 
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const getTaxRegion = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, settingsApi.getTaxRegion);
      api({ ...settingsApi.getTaxRegion })
        .then(({ data,message }) => {
          resolve(data); 
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
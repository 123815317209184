import React from 'react';
// import { Modal } from 'react-bootstrap';
import { NormalButton, NormalModal } from 'component/common';
import alertIcon from 'assets/svg/alert.svg'

export function AlertModal(props) {
    return (
        <NormalModal
            isOpen={false}
            size="lg"
            renderHeader={e => <h6 className="font-weight-bold ">{props.title}</h6>}
            headerClass="border-0 pt-4 px-4"
            
        >
            <div className="text-center px-5 pt-3">
                <img src={alertIcon} alt="alertIcon"/>
                <p className="mt-3">{props.textContent}</p>
            </div>
            <div className="pb-3 px-5 pt-4 d-flex align-items-center justify-content-end">
                <NormalButton
                    // onClick={this.handleShow}
                    id="coAdmin"
                    label={props.button1}
                    outline={false}
                    // loader={isFormLoder}
                    className="mb-2 mr-4 btn fs-14 text-dark  bg-transparent btn-block"
                />
                <NormalButton
                    // onClick={this.handleShow}
                    id="confirm"
                    label={props.button2}
                    outline={false}
                    // loader={isFormLoder}
                    className="mb-2 btn fs-14 rounded-0 btn-grey btn-block"
                />
            </div>

        </NormalModal>
    )
}




import React, { useState } from "react";
// import { NormalButton, NormalSelect, NormalDate } from "component/common";
// import Moment from "moment";
import TimeSheetMark from "component/workShedule/timeSheetMark/index";

import "./style.scss";
import leftArrowIcon from "assets/svg/left_arrow.svg";
import rightArrowIcon from "assets/svg/right_arrow.svg";
// import deleteIcon from "assets/svg/delete.svg";
// import profile from "../../../assets/svg/Ellipse.png";
import useMapDataParser from 'component/common/interactiveMap/useMapDataParser';
import InteractiveMap from 'component/common/interactiveMap';

export function StaffDetails(props) {
  const [active, setActive] = useState(0);
  const { timeData: staffTimeData, staffName, staffLatLong, showMap, toggleMap } = useMapDataParser({ data: props?.data?.staffWorkData?.[active] });
  const [activePointsFromStaffDetails, setActivePointsFromStaffDetails] = useState('');
  const handleStaffDetais = (val) => {
    setActive(active + val);
  };
  let { staffWorkData} = props.data;    //, workId, edit 
  let { staffNeeded } = props.edit;
  // let{staffWorkData,workId}=props.locationData;
  // console.log(staffWorkData[active].clockOut);
  // console.log(
  //   Moment(staffWorkData[active].clockIn).format("hh:mm a") -
  //     Moment(staffWorkData[active].clockOut).format("hh:mm a")
  // );

  const getClientLoc = (latLng) => {
    let clientLoc = {lat: '', lng:''};
    if(!!latLng)
    {
      clientLoc.lng = latLng?.[0];
      clientLoc.lat = latLng?.[1]
    }
    return clientLoc;
  }
  return (
    <>
     {/* Map */}
     <InteractiveMap 
        timeData={staffTimeData} 
        activePointsFromStaffDetails={activePointsFromStaffDetails} 
        staffName={staffName} 
        staffLatLong={staffLatLong} 
        open={showMap} 
        toggle={toggleMap} 
        sitePicture={!!props?.edit?.sitePicture && props?.edit?.sitePicture.split("|").length>1 ? props?.edit?.sitePicture.split("|")[1] : ""} 
        clientLatLng={getClientLoc(props?.edit?.longLat)} />
           
      <div className="">
        <div className="row align-items-center justify-content-center ">
          {!!(staffNeeded >= 1) && (
            <div className="col-4 align-self-start">
              <div className="form-group">
                <img src={leftArrowIcon} alt="leftArrowIcon"/>
                <span
                  className={`navi-link sub-text green-href fs-10 ml-2 ${
                    active === 0 ? "disabled" : "cursor-pointer"
                  }`}
                  onClick={() => (active === 0 ? {} : handleStaffDetais(-1))}
                >
                  Show Previous
                </span>
              </div>
            </div>
          )}
          <div className="col-4">
            <div className="form-group text-center">
              <p className="text-black fs-13 font-weight-bold">
                {" "}
                Staff Shift Details
              </p>
            </div>
          </div>
          {!!(staffNeeded >= 1) && (
            <div className="col-4 align-self-end">
              <div className="form-group text-right">
                <span
                  className={`navi-link sub-text green-href fs-10 mr-2 ${
                    active + 1 === staffWorkData.length
                      ? "disabled"
                      : "cursor-pointer"
                  }`}
                  onClick={() =>
                    active + 1 === staffWorkData.length
                      ? {}
                      : handleStaffDetais(1)
                  }
                >
                  {" "}
                  Show next
                </span>
                <img src={rightArrowIcon} alt="rightArrowIcon" />
              </div>
            </div>
          )}
        </div>
        <div className="row align-items-center justify-content-center">
          {/*<div className="col-4">
            <div className="form-group">
              <p className="sub-text fs-16">Work Request ID</p>
            </div>
          </div>
          <div className="col-8">
            <div className="form-group">
              <p className="text-black fs-16 ">
                {" "}
                {`WR${workId.slice(workId.length - 6, workId.length)}`}
              </p>
            </div>
          </div>*/}

                      <div className='justify-content-center mb-3'>
                        <div className=" align-item-center ribbon highlight">
                         <span className="text-black fs-12 padding-1" > {staffWorkData[active].staffName} </span>
                        </div>    
                    </div>
          {/* <div className="col-4">
            <div className="form-group">
              <p className="sub-text fs-16">Staff Status/p>
            </div>
          </div>
          <div className="col-8">
            <div className="form-group">
              <p className="text-black fs-16 ">
                {" "}
                {staffWorkData[active].status === 1
                  ? "Yet to Accept"
                  : staffWorkData[active].status === 2
                  ? "Yet to approve by Agency"
                  : staffWorkData[active].status === 3
                  ? "Yet to approve by Client"
                  : staffWorkData[active].status === 4 &&
                    props.edit.status === 3
                  ? "Pending ClockIn"
                  : staffWorkData[active].status === 4 &&
                    props.edit.status === 3
                  ? "Yet to approve by Client"
                  : staffWorkData[active].status === 3 &&
                    props.edit.status === 4
                  ? "Pending ClockIn"
                  : staffWorkData[active].status === 4 &&
                    props.edit.status === 4 &&
                    staffWorkData[active].clockIn != null
                  ? "In Progress"
                  : staffWorkData[active].status === 4 &&
                    props.edit.status === 4 &&
                    staffWorkData[active].clockIn != null &&
                    staffWorkData[active].pauseResumeData > 0 &&
                    staffWorkData[active].pauseResumeData[
                      staffWorkData[active].pauseResumeData.length() - 1
                    ].key === "pause"
                  ? "Paused"
                  : staffWorkData[active].status === 5 &&
                    props.edit.status > 5 &&
                    staffWorkData[active].clockOut != null
                  ? "Completed By Staff"
                  : staffWorkData[active].status === 5 && props.edit.status > 3
                  ? "Completed By Staff"
                  : staffWorkData[active].status === 5 &&
                    props.edit.status === 4
                  ? "Completed By Staff"
                  : staffWorkData[active].status === 4
                  ? "In Progress"
                  : ""}
              </p>
            </div>
          </div> */}
          {/* <div className="col-4">
            <div className="form-group">
              <p className="sub-text fs-16">Work Hours</p>
            </div>
          </div>
          <div className="col-8">
            <div className="form-group">
              <p className="text-black fs-16 ">
                {" "}
                {staffWorkData[active].clockIn
                  ? Moment(staffWorkData[active].clockIn).format(
                      "MM/DD/YYYY hh:mm:ss a"
                    ) +
                    " - " +
                    Moment(staffWorkData[active].clockOut).format(
                      "MM/DD/YYYY hh:mm:ss a"
                    )
                  : "Not assigned"}
              </p>
            </div>
          </div> */}
          {/*<div className="col-4">
            <div className="form-group">
              <p className="sub-text fs-16">Location</p>
            </div>
          </div>
          <div className="col-8">
            <div className="form-group">
              <p className="text-black fs-16 mb-0">{props.locationData} </p>
	      <p className="btn-gray-clr fs-12 ">
                {" "}
                15km away from client’s location{" "}
              </p>
            </div>
          </div> */}
          
        </div> 
      </div>
      {staffWorkData?
            (staffWorkData[active].status >3 || (staffWorkData[active].status === 0 && staffWorkData[active].clockIn))?
            <TimeSheetMark status={props.status} data={staffWorkData[active]} startDate={props.startDate} endDate={props.endDate} edit={props.edit} toggleMap={toggleMap} sitePicture={props?.edit?.sitePicture} setActivePointsFromStaffDetails={setActivePointsFromStaffDetails} />
            :""
            :"" }
      </>
  );
}

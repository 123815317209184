import moment from "moment";
import { history } from "service/helpers";
export const addQuery = (dataObject, apiObject) => {
  if (!dataObject) {
    return "";
  }

  const keys = [
    "page",
    "limit",
    "size",
    "search",
    "clientId",
    "id",
    "status",
    "workId",
    "startDate",
    "endDate",
    "searchKey",
    "sortBy",
    "emailId",
    "agencyId",
    "otp",
    "oldPassword",
    "password",
    "jobCategory",
    "category",
    "entity",
    "fileId",
    "type",
    "email",
    "currency",
    "planName",
    "quantity",
    "unitAmount",
    "name",
    "purpose",
    "agencyId",
    "userType",
    "userMail",
    "userId",
    "auditId",
    "staffIds",
    "message",
    "deleteFlag",
    "flag",
    "location",
    'addressId', 
    'logType',
    "format",
    "module",
    "agencyName",
    "jobFunctionId",
    'paymentReq',
    "newEmailId",
    'functionName',
    'jobMasterId'

  ];

  keys.map((key) => {
    if (dataObject.hasOwnProperty(key) && typeof dataObject[key] != "object") {
      if (apiObject.query.hasOwnProperty(key)) {
        apiObject.addQuery = { key, payload: dataObject[key] };
      }
    }else if(Array.isArray(dataObject[key])){
      if (apiObject.query.hasOwnProperty(key)) {
        apiObject.addQuery = { key, payload: dataObject[key]};
      }
    } else {
      dataObject[key] &&
        Object.keys(dataObject[key]).map((keyName) => {
          if (apiObject.query.hasOwnProperty(keyName)) {
            apiObject.addQuery = {
              key: keyName,
              payload: dataObject[key][keyName],
            };
          }
        });
    }
  });
};

export const scrollTop = (id = "root", selector = "getElementById") => {
  var contentPageEle = document[selector](id);

  if (selector !== "getElementById") {
    contentPageEle = contentPageEle[0];
  }

  contentPageEle &&
    contentPageEle.scrollTo({
      top: 0,
      behavior: "smooth",
    });
};

export const generateUpdateRatingQuery = (query) => {
  let url = "";
  let isQuestionMarkAdded = false;

  if (query.hasOwnProperty("url_id")) {
    url = `/${query.url_id}`;
  }
  return (
    url +
    Object.keys(query).reduce((accumulator, key, index) => {
      if (
        query[key] === "" ||
        query[key] === null ||
        query[key] === undefined ||
        key === "url_id" ||
        (query[key] !== null && query[key].toString().trim() === "")
      ) {
        return accumulator;
      }
      const values = Array.isArray(query[key]) ? query[key] : [query[key]];
      const paramStrings = values.map(value => `${key}=${value}`);

      if (paramStrings.length > 0) {
        const separator = isQuestionMarkAdded ? "&" : "?";
        isQuestionMarkAdded = true;
        return accumulator + paramStrings.map(param => `${separator}${param}`).join("");
      } else {
        return accumulator;
      }
    }, "")
  );
};

export const generateQuery = (query) => {
  let url = "";
  let isQuestionMarkAdded = false;

  if (query.hasOwnProperty("url_id")) {
    url = `/${query.url_id}`;
  }
  return (
    url +
    Object.keys(query).reduce((accumulator, key, index) => {
      if (
        query[key] === "" ||
        query[key] === null ||
        query[key] === undefined ||
        key === "url_id" ||
        (query[key] !== null && query[key].toString().trim() === "")
      ) {
        return accumulator;
      } else {
        // return accumulator + `${index !== 0 ? "&" : "?"}${key}=${query[key]}`;
        let accumulatorStr = accumulator + `${isQuestionMarkAdded ? '&' : '?'}${key}=${query[key]}`;
        isQuestionMarkAdded = true;
        return accumulatorStr;
      }
    }, "")
  );
};

export const getFormatDate = (date) => {
  return moment(date).format("DD MMM,hh:mm:ss a");
};
export const getFormatDateOnly = (date) => {
  let newDate = moment(date).format("DD MM YYYY");

  // console.log(moment.unix(date).format("DD MM YYYY"));

  if (newDate === "Invalid date") {
    return moment.unix(date).format("DD MM YYYY");
  } else {
    return newDate;
  }
};

export const getSNo = ({ totalPages, page, chunk }, index) => {
  return parseInt((page - 1) * chunk) + index + 1;
};

export const displayImg = (file) => {
  if (typeof file === "object") {
    return URL.createObjectURL(file);
  } else {
    return file;
  }
};

export const getToken = () => {
  return localStorage.getItem("AuthToken")
    ? localStorage.getItem("AuthToken")
    : "";
};

export const removeFeatureDate = (current, paramDate, paramAction) => {
  return current.isBefore(moment(new Date()).subtract(paramDate, paramAction));
};

export const convertStringToObject = (searchQuery) => {
  try {
    if (!!searchQuery) {
      var search = searchQuery.substring(1);

      let obj = JSON.parse(
        '{"' +
          search
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );

      return {
        filter: "",
        search: "",
        page: 1,
        field: "",
        orderBy: "",
        ...obj,
      };
    }

    return {
      search: "",
      page: 1,
      field: "",
      orderBy: "",
      filter: "",
    };
  } catch (error) {
    console.log(error, "d4sds4d");
  }
};

export const appendQuery = (data) => {
  history.push({
    search: generateURLQuery(history.location.search, data),
  });
};
export const replaceQuery = (data) => {
  history.replace({
    search: generateURLQuery(history.location.search, data),
  });
};
const dataRequiredValidation = (value) =>
  value !== "" && value !== null && value !== undefined;

const updateQueryStringParameter = (uri, key, value) => {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
};

const removeQueryStringParameter = (url, parameter) => {
  var urlparts = url.split("?");
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + "=";
    var pars = urlparts[1].split(/[&;]/g);

    for (var i = pars.length; i-- > 0; ) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }
    return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
  }
  return url;
};

export const generateURLQuery = (url, data) => {
  return data.reduce((acc, { label, value }) => {
    if (dataRequiredValidation(value)) {
      return updateQueryStringParameter(acc, label, value);
    } else {
      return removeQueryStringParameter(acc, label);
    }
  }, url);
};

export const extractAddressFields = (components) => {
  var formatted = {
    state: "",
    street: "",
    pincode: "",
    city: "",
    country: "",
    region: "",
  };
  components.forEach((element) => {
    if (element.types.indexOf("administrative_area_level_1") !== -1) {
      formatted.state = element.long_name;
    }
    if (element.types.indexOf("country") !== -1) {
      formatted.country = element.short_name;
    }
    if (element.types.indexOf("country") !== -1) {
      formatted.region = element.long_name;
    }
    if (element.types.indexOf("locality") !== -1) {
      formatted.city = element.long_name;
    }
    if (element.types.indexOf("postal_code") !== -1) {
      formatted.pincode = element.long_name;
    }
    if (element.types.indexOf("street_number") !== -1) {
      formatted.street = element.long_name;
    }
    if (element.types.indexOf("route") !== -1) {
      formatted.street += `${formatted.street ? ", " : ""}${element.long_name}`;
    }
    if (
      element.types.indexOf("sublocality") !== -1 &&
      element.types.indexOf("sublocality_level_1") !== -1
    ) {
      formatted.street += `${formatted.street ? ", " : ""}${element.long_name}`;
    }
  });
  return formatted;
};

export const validatePassword = (password = '') =>{
  const validationsResult = {
    length: password.length >= 8 && password.length <= 15,
    specialCharacter: /[({!^$&*%#@~})]/.test(password),
    number: /[0-9]/.test(password),
    uppercase: /[A-Z]/.test(password),
  };
  return validationsResult
}

export const getTaxLabel = process.env.REACT_APP_BASE_SERVER === '1' ? 'Tax EIN Number' : process.env.REACT_APP_BASE_SERVER === '2' ? 'GSTIN Number' : null

export const handleFormatTaxLabel = (value) => {
  if(value){
     return   value.length > 2 && process.env.REACT_APP_BASE_SERVER === '1' ? value.replace(/[^0-9-]/g, '').slice(0, 10).replace(/^(\d{2})(\d{0,7})$/, '$1-$2') : value
  }else{
      return '';
  }
};

export const handleDeFormatTaxLabel = (value) =>{
  return process.env.REACT_APP_BASE_SERVER === '1' ? value.replace(/-/g, '').slice(0,9) : value
}

export const getTimeElapsedString = (dateString) => {
  const utcDate = dateString;
  const localDate = moment(utcDate);
  const date = new Date(localDate);
  const now = new Date();
  const elapsed = now - date;
  const elapsedSeconds = Math.floor(elapsed / 1000);

  if (elapsedSeconds < 60) {
    return `${elapsedSeconds} second${elapsedSeconds === 1 ? '' : 's'} ago`;
  } else if (elapsedSeconds < 3600) {
    const minutes = Math.floor(elapsedSeconds / 60);
    return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
  } else if (elapsedSeconds < 86400) {
    const hours = Math.floor(elapsedSeconds / 3600);
    return `${hours} hour${hours === 1 ? '' : 's'} ago`;
  } else if (elapsedSeconds < 2592000) { 
    const days = Math.floor(elapsedSeconds / 86400);
    return `${days} day${days === 1 ? '' : 's'} ago`;
  } else {
   let format= localStorage.getItem('dateFormat');
    return moment(localDate).format(format ? format : 'MM/DD/YYYY')
  }
}

export  const maskEmailAddress=(emailAddress) =>{
  function mask(str) {
      var strLen = str.length;
      if (strLen > 4) {
          return str.substr(0, 1) + str.substr(1, strLen - 1).replace(/\w/g, '*') + str.substr(-1,1);
      } 
      return str.replace(/\w/g, '*');
  }
  return emailAddress.replace(/([\w.]+)@([\w.]+)(\.[\w.]+)/g, function (m, p1, p2, p3) {      
      return mask(p1) + '@' + mask(p2) + p3;
  });
} 

import React from 'react';
import "./NormalInput.scss";
import infoIcon from "assets/svg/info.svg";


export const NormalInput = ({
    className = "form-control",
    placeholder = "",
    maxlength = "",
    minlength = "",
    label = "",
    onChange,
    onClick,
    value = "",
    name,
    disabled = false,
    type = 'text',
    isLink = false,
    info=false
}) => {

    const handleWheel = (e) => {
        e.currentTarget.blur();
    }

    const handleKeyDown = (e) => {
        if ((e.key === 'ArrowUp' || e.key === 'ArrowDown') && type === 'number') {
            e.preventDefault();
        }
    }

    return (
        <div className="normal-input">
            {label !== '' ?
                <div className="fs-12 text-black font-Gilroy pb-2 mb-1 line-height-19">
                    {label} {info?<img className="ml-4" src={infoIcon} alt="infoIcon"/>:''}
                </div>
                : ''}
            <input
                className={`${isLink ? `${className} islink` : className} ${placeholder==='Upload'?'cursor-pointer':''}`}
                name={name}
                type={type}
                disabled={disabled}
                value={value}
                min={0}
                placeholder={placeholder}
                maxLength={maxlength?maxlength : ''}
                minLength={minlength?minlength : ''}
                onChange={e => {

                    let body = {}

                    body = {
                        target: {
                            name: e.target.name,
                            value: e.target.value
                        }
                    }

                    onChange(body)

                }}
                onClick={e => {

                    let body = {}

                    body = {
                        target: {
                            name: e.target.name,
                            value: e.target.value
                        }
                    }
                        if(onClick){
                        onClick(body)
                        }
                    }
                 }
                 onWheel={handleWheel}
                 onKeyDown={handleKeyDown}
            />
            {isLink
                ?
                <span className="link-area">
                    <i className="fs-20 icon-link"></i>
                </span>
                : ''}
        </div>
    )
}
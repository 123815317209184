export const authType = {
    getUserLoginDetails: 'GET_USER_LOGIN_DETAILS',
}
export const UPDATE_CONNECTION_STATUS = 'UPDATE_CONNECTION_STATUS';
export const UPDATE_NOTIFICATION_COUNT = 'UPDATE_NOTIFICATION_COUNT';
export const UPDATE_NEW_WORK_REQUEST = 'UPDATE_NEW_WORK_REQUEST';

export const START_TOUR_GUIDE = 'START_TOUR_GUIDE';

export const SET_NO_REG_AGENCIES_WARNING = 'SET_NO_REG_AGENCIES_WARNING';

export const SET_DISTANCE_UNIT = "SET_DISTANCE_UNIT"
export const UPDATE_REGISTERED_AGENCY = "UPDATE_REGISTERED_AGENCY"
export const REQUESTED_AGENCY = "REQUESTED_AGENCY"

export const hasSubscriptionWarning = "hasSubscriptionWarning"
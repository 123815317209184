import React, { useState, useEffect, useLayoutEffect, Suspense, useRef } from "react";
import {
  NormalButton,
  // NormalCheckbox,
  // NormalSelect,
  Title,
  TableFilter,
} from "component/common";
import { TableWrapper } from "component/common";
import "./style.scss";
import { useLocation, withRouter } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { startTourGuide } from "reducer/tourGuide";
import moment from "moment";
import { CommonPageLoader } from "component/common";
// import WorkCalender from "component/workShedule/workCalender";
// import { pageNavigationByName } from "service/helpers";
// import AssignWorkSchedule from "./assignWorkSchedule";
// import AddWorkRequest from "component/workShedule/addWorkRequest";
// import HoverBox from "component/workShedule/hoverBox";
// import RepeatModel from "component/workShedule/repeatModel";
// import WorkAssignTimeSheet from "component/workShedule/workAssignTimeSheet";
// import WorkAssignment from "component/workShedule/workAssignment";
// import WorkAssignmentNew from "component/workShedule/workAssignment new";
import WorkScheduleList from "./workScheduleList";
import { getAllWork, getAllLocation ,getAgencies } from "action/workRequest";
// import viewActivity from "assets/svg/Viewactivity.svg";
import { useHistory } from 'react-router-dom';
import { colorByStatus } from "assets/WRColorSchema";
import { store } from "service/helpers";
import { useSelector } from "react-redux";
const WorkCalender = React.lazy(() => import("component/workShedule/workCalender"));
const WorkAssignmentNew = React.lazy(()=> import ("component/workShedule/workAssignment new")); 
const AddWorkRequest = React.lazy(() => import ("component/workShedule/addWorkRequest"));
// import moment from "moment";

// const OptionsList = [
//   { label: "All", value: "all", icon: viewIcon, click: "handleShow" },
//   { label: "In-progress", value: "4", icon: deleteIcon, click: "handleShow" },
//   { label: "Completed", value: "7", icon: editIcon, click: "handleShow" },
//   {
//     label: "Waiting for approval",
//     value: "5",
//     icon: viewActivity,
//     click: "handleShow",
//   },
// ];
export const StaffStatusLabels = (statusCode, status, clockIn, pauseResumeData, clockOut, cancelByWork, rejectedByStaff = false, rejectedByClient = false) =>{
  if(statusCode === 1 && !rejectedByStaff ) return (<div className="d-flex"><div style={{padding:'0.5px 6px', width:"10px", height:"12px", backgroundColor:'#FF8080'}}></div><span className="text-uppercase status-name-align" style={{color: "#3C4043"}}>&nbsp;Yet to Accept</span></div>)
  if(statusCode === 1 && rejectedByStaff) return (<div className="d-flex"><div style={{padding:'0.5px 6px', width:"10px", height:"12px", backgroundColor:'#FF8080'}}></div><span className="text-uppercase status-name-align" style={{color: "#3C4043"}}>&nbsp;Rejected by staff</span></div>)
  if(statusCode === 2 ) return (<div className="d-flex"><div style={{padding:'0.5px 6px', width:"10px", height:"12px", backgroundColor:'#FF8080'}}></div><span className="text-uppercase status-name-align" style={{color: "#3C4043"}}>&nbsp;Yet to approve by Agency</span></div>)
  if(statusCode === 3 && !rejectedByClient ) return (<div className="d-flex"><div style={{padding:'0.5px 6px', width:"10px", height:"12px", backgroundColor:'#FF8080'}}></div><span className="text-uppercase status-name-align" style={{color: "##3C4043"}}>&nbsp;Yet to approve by Client</span></div>)
  if(statusCode === 3 && rejectedByClient ) return (<div className="d-flex"><div style={{padding:'0.5px 6px', width:"10px", height:"12px", backgroundColor:'#FF8080'}}></div><span className="text-uppercase status-name-align" style={{color: "#3C4043"}}>&nbsp;Rejected by Client</span></div>)
  if(statusCode === 4 && status === 3 ) return (<div className="d-flex"><div style={{padding:'0.5px 6px', width:"10px", height:"12px", backgroundColor:'#F3993E'}}></div><span className="text-uppercase status-name-align" style={{color: "#3C4043"}}>&nbsp;Pending ClockIn</span></div>)
  if(statusCode === 4 && status === 4 && clockIn != null && pauseResumeData && pauseResumeData[pauseResumeData.length - 1].key == "pause" ) return( <div className="d-flex"><div style={{padding:'0.5px 6px', width:"10px", height:"12px", backgroundColor:'#34C7CA'}}></div><span className="text-uppercase status-name-align" style={{color: "#3C4043"}}>&nbsp;Paused</span></div>)
  if(statusCode === 4 && status === 4 && clockIn != null) return( <div className="d-flex"><div style={{padding:'0.5px 6px', width:"10px", height:"12px", backgroundColor:'#34C7CA'}}></div><span className="text-uppercase status-name-align" style={{color: "#3C4043"}}>&nbsp;In progress</span></div>)
  if(statusCode === 4 && status === 4 && !clockIn ) return (<div className="d-flex"><div style={{padding:'0.5px 6px', width:"10px", height:"12px", backgroundColor:'#F3993E'}}></div><span className="text-uppercase status-name-align" style={{color: "#3C4043"}}>&nbsp;Pending ClockIn</span></div>)
  if(statusCode === 4 && status === 4 && clockOut != null) return (<div className="d-flex"><div style={{padding:'0.5px 6px', width:"10px", height:"12px", backgroundColor:'#FFD700'}}></div><span className="text-uppercase status-name-align" style={{color: "#3C4043"}}>&nbsp;Completed By Staff</span></div>)
  if(statusCode === 5 && status >=  3)  return (<div className="d-flex"> <div style={{padding:'0.5px 6px', width:"10px", height:"12px",  backgroundColor:'#FFD700'}}></div><span className="text-uppercase status-name-align" style={{color: "#3C4043"}}>&nbsp;Completed By Staff</span> </div>)
  if(statusCode === 4 && cancelByWork) return (<div className="d-flex"><div style={{padding:'0.5px 6px', width:"10px", height:"12px", backgroundColor:'#F3993E'}}></div><span className="text-uppercase status-name-align" style={{color: "#3C4043"}}>&nbsp;Pending ClockIn</span></div>)
  if(statusCode === 4) return (<div className="d-flex"><div style={{padding:'0.5px 6px', width:"10px", height:"12px",  backgroundColor:'#34C7CA'}}></div><span className="text-uppercase status-name-align" style={{color: "#3C4043"}}>&nbsp;In progress</span></div>)
}
export const StaffStatusLabelsWithoutBox = (statusCode, status, clockIn, pauseResumeData, clockOut, cancelByWork ,rejectedByStaff = false, rejectedByClient = false) =>{
  if(statusCode === 1 && !rejectedByStaff ) return (<div className="d-flex"><span className="text-uppercase status-name-align-without-box cta-3-m" style={{color: "#FF8080"}}>&nbsp;Yet to Accept</span></div>)
  if(statusCode === 1 && rejectedByStaff) return (<div className="d-flex"><span className="text-uppercase status-name-align-without-box cta-3-m" style={{color: "red"}}>&nbsp;Rejected by staff</span></div>)
  if(statusCode === 2 ) return (<div className="d-flex"><span className="text-uppercase status-name-align-without-box cta-3-m" style={{color: "#FF8080"}}>&nbsp;Yet to approve by Agency</span></div>)
  if(statusCode === 3 && !rejectedByClient ) return (<div className="d-flex"><span className="text-uppercase status-name-align-without-box cta-3-m" style={{color: "#FF8080"}}>&nbsp;Yet to approve by Client</span></div>)
  if(statusCode === 3 && rejectedByClient ) return (<div className="d-flex"><span className="text-uppercase status-name-align-without-box cta-3-m" style={{color: "red"}}>&nbsp;Rejected by Client</span></div>)
  if(statusCode === 4 && status === 3 ) return (<div className="d-flex"><span className="text-uppercase status-name-align-without-box cta-3-m" style={{color: "#F3993E"}}>&nbsp;Pending ClockIn</span></div>)
  if(statusCode === 4 && status === 4 && clockIn != null && pauseResumeData && pauseResumeData[pauseResumeData.length - 1].key == "pause" ) return( <div className="d-flex"><span className="text-uppercase status-name-align-without-box cta-3-m" style={{color: "#34C7CA"}}>&nbsp;Paused</span></div>)
  if(statusCode === 4 && status === 4 && clockIn != null) return( <div className="d-flex"><span className="text-uppercase status-name-align-without-box cta-3-m" style={{color: "#34C7CA"}}>&nbsp;In progress</span></div>)
  if(statusCode === 4 && status === 4 && !clockIn ) return (<div className="d-flex"><span className="text-uppercase status-name-align-without-box cta-3-m" style={{color: "#F3993E"}}>&nbsp;Pending ClockIn</span></div>)
  if(statusCode === 4 && status === 4 && clockOut != null) return (<div className="d-flex"><span style={{padding:'4px', backgroundColor:'#FFD700'}}></span><span className="text-uppercase status-name-align-without-box cta-3-m" style={{color: "#FFD700"}}>&nbsp;Completed By Staff</span></div>)
  if(statusCode === 5 && status >=  3)  return (<div className="d-flex"> <span className="text-uppercase status-name-align-without-box cta-3-m" style={{color: "#FFD700"}}>&nbsp;Completed By Staff</span> </div>)
  if(statusCode === 4 && cancelByWork) return (<div className="d-flex"><span className="text-uppercase status-name-align-without-box cta-3-m" style={{color: "#F3993E"}}>&nbsp;Pending ClockIn</span></div>)
  if(statusCode === 4 && status === 5) return (<div className="d-flex" style={{width:"13rem"}}><span className="text-uppercase status-name-align-without-box cta-3-m" style={{color: "rgb(52, 199, 202)"}}>&nbsp;Pending Timesheet Submission</span></div>)
  if(statusCode === 4) return (<div className="d-flex"><span className="text-uppercase status-name-align-without-box cta-3-m" style={{color: "#34C7CA"}}>&nbsp;In progress</span></div>)
}
const OptionsList = [
  { 
    label: "All Work Status", 
    value: "all", 
    click: "handleShow" 
  },
  { 
    label: "NEW REQUEST", 
    isColorBox:true,
    value: 1, 
    click: "handleShow" 
  },
  { 
    label: "PENDING STAFF ASSIGNMENT", 
    isColorBox:true,
    value: 2, 
    click: "handleShow" 
  },
  { 
    label: "STAFF HAS BEEN ASSIGNED. PLEASE CONFIRM", 
    isColorBox:true,
    value: "2.1", 
    click: "handleShow" 
  },
  { 
    label: "PENDING STAFF CLOCK-IN", 
    isColorBox:true,
    value: 3, 
    click: "handleShow" 
  },
  { 
    label: "STAFF IS WORKING", 
    isColorBox:true,
    value: 4, 
    click: "handleShow" 
  },
  { 
    label: "PLEASE REVIEW STAFF TIMESHEET", 
    isColorBox:true,
    value: 5, 
    click: "handleShow" 
  },
  { 
    label: "PENDING TIMESHEET APPROVAL BY AGENCY", 
    isColorBox:true,
    value: 6, 
    click: "handleShow" 
  },
  { 
    label: "TIMESHEET APPROVED", 
    isColorBox:true,
    value: 7, 
    click: "handleShow" 
  },
  { 
    label: "PAYMENT DUE!!!", 
    isColorBox: true,
    value: 8, 
    click: "handleShow",
  },
  { 
    label: "INVOICE PAID", 
    isColorBox: true,
    value: 9, 
    click: "handleShow",
  },
  { 
    label: "NOT FILLED", 
    isColorBox: true,
    value: 10, 
    click: "handleShow",
  },
  { 
    label: "CANCELED", 
    isColorBox:true,
    value: 0, 
    click: "handleShow" 
  },
];
const actions = [
  {
    label: "Days",
    value: "Days",
    click: "handleShow",
  },
  {
    label: "Week",
    value: "Week",
    click: "handleShow",
  },
  {
    label: "Month",
    value: "Month",
    click: "handleShow",
  },
];

function WorkSchedule({ getAllWork, getAllLocation,getAgencies }) {
  // let jobLocation = [
  //   {
  //     label: "Work category",
  //     label: "Job function",
  //   },
  // ];
  // let action = [
  //   {
  //     label: "Add work Request",
  //     label: "Staff assignment",
  //   },
  // ];

  const [activeView, setActiveView] = useState(false);
  // const [calendarActiveView, setCalendarActiveView] = useState(true);
  const [visible, setVisible] = useState({ value: false, id: null });
  const [workAssign, setWorkAssign] = useState({ value: false, id: null,agencyId:null });
  const [clientId, setClientId] = useState("");
  const [refresh, doRefresh] = useState(0);
  const [workList, setWorkList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  const [initialStartTime, setInitialStartTime] = useState('');
  const [initialEndTime, setInitialEndTime] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [multiSelectOptionsList, setMultiSelectOptionsList] = useState([])
  const [selectedOption, setSelectedOption] = useState([])
  const [agencySelectedOption, setAgencySelectedOption] = useState([]);
  const [Allagencies,setAllagencies] = useState([]);
  const [agencyMultiOptions, setAgencyMultiOptions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [AgencyfilteredData,setAgencyFilteredData] = useState([]);
  const [combinedfilteredData,CombinedFilteredData] = useState([]);
  const [AgencyFilterMultiOptionslist,setAgencyfilterMutliOptionsList] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    TotalCount: 0,
    pageCount: 10,
    btnDisplay: 5,
    itemCount: 0,
  });

  let pageMeta = {}

  const [showArchives, setShowArchives] = useState(false)

  const [query, setQuery] = useState({
    startDate: moment().startOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS"),
    endDate: moment().endOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS"),
    sortBy: "all",
    viewBy: "all",
    searchKey: "",
    clientId: "",
    status: [],
    jobName: "all",
    location: "",
    page:1,
    size:10,
  });

  const [viewByweek, setViewByweek] = useState("Month");
  const [showAllStatus, setShowAllStatus] = useState(false);
  const [gmapsLoaded, setGmapsLoaded] = useState(false);
  const agencyData = useSelector((state)=>state.agencyDataReducer.agencyData)
  const initMap = () => {
    setGmapsLoaded(true);
  };

  // const handleNav = () => {
  //   pageNavigationByName({ name: "workRequest" });
  // };

  const history = useHistory()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const wrId = queryParams.get('workId');
  const agencyId = queryParams.get('agencyId');

  const getDataBasedOnAgencyStatus = (data) => {

    const updatedWorkList = data.filter((item) => {
      if(showArchives) {
          return (item.agencyStatus === 0 || item.agencyStatus === 2 || item.agencyStatus === 3)
      }
      else {
          return (item.agencyStatus === 1 || item.agencyStatus === undefined || item.agencyStatus === null)
      }
  })
  
  return updatedWorkList

  }

  useEffect(()=>{
  if(wrId && agencyId){
    setWorkAssign({value:true, id:wrId, agencyId:agencyId})
  }
  },[wrId])

  useEffect(()=>{
      if(!activeView){
        if(window.location.pathname === '/admin/workSchedule'){
          history.replace('list')
        }
      }
      return;
    },[])

  const handleUpdatePage = (value) =>{
    setQuery((prev)=>{
        return{
            ...prev,
            page:value
        }
    })
 }

    let productTour = localStorage.getItem('productTour')
    let workScheduleTourCompleted = localStorage.getItem('workScheduleTourCompleted')
    
    useLayoutEffect(()=>{
      if(productTour === 'true' && workScheduleTourCompleted !== 'true' && !loading && process.env.REACT_APP_HOSTMODE === "LIVE"){
              store.dispatch(startTourGuide({run: true}))
      }
    }, [loading, productTour, workScheduleTourCompleted])

  const getStatusName = (item) =>{
    if(item?.status === 2){
      if(item.workStaffAssignment && item.workStaffAssignment.staffWorkData.length !== 0){
          return 'STAFF HAS BEEN ASSIGNED. PLEASE CONFIRM' 
      }
    } 
  return colorByStatus?.[item.status]?.status
  }
  let workRequestData = useSelector((state)=> state.webSocketReducer.workRequest)

  useEffect(()=>{
      handleUpdateWorkRequest()
    },[workRequestData])

    const handleUpdateWorkRequest = () => {
      if(workRequestData){
          const isInRange =  workRequestData.startDate >= query.startDate && workRequestData.endDate <= query.endDate;
 
          if(isInRange){
              let workRequestId =  workRequestData?.id ? workRequestData?.id : workRequestData?._id
              workRequestData.id  = workRequestId;
              let targetIndex = workList.findIndex(obj => obj.id === workRequestId)
                 
              if(activeView){
                workRequestData["start"] = workRequestData.startDate;
                workRequestData["end"] = workRequestData.endDate;
                workRequestData["id"] = workRequestData.id;
                workRequestData["eventId"] = workRequestData.id;
                workRequestData["display"] = "block";
                workRequestData["editable"] = false;
                workRequestData["textEscape"] = false;
                workRequestData["html"] = true;
    
                workRequestData["StartTime"] = moment.utc(workRequestData.startDate).format();
                workRequestData["EndTime"] = moment.utc(workRequestData.endDate).format();
                workRequestData["Subject"] = `${dateFormaterFromHandleEventRendered(workRequestData.startDate)}-${dateFormaterFromHandleEventRendered(workRequestData.endDate)}&nbsp;${workRequestData.priority.toUpperCase()==="NORMAL"?"("+workRequestData.priority+")":"<b>("+workRequestData.priority+")</b>"}&nbsp;${workRequestData.jobName}`;
                workRequestData["Category"] = workRequestData.status;
              }
          
          let staffName="";
          workRequestData?.workStaffAssignment?.staffWorkData.map((staff) => {
          staffName = staffName !=="" ? (staffName + " , "+ staff.staffName) : staff.staffName;
                      })
         staffName=staffName==""? "No Staff Assigned" :staffName;
          let data ={
            ...workRequestData,
            staffName:staffName,
            "workAssignedTime":`${moment(workRequestData.startDate).format('hh:mm:ss a')} - ${moment(workRequestData.endDate).format('hh:mm:ss a')}`,
            "statusName" : getStatusName(workRequestData)
          }
              if(targetIndex === -1){
                  setWorkList([{...data},...workList])
                  return;
              }
         let existingWorkList = [...workList]
         existingWorkList[targetIndex] = data
         setWorkList(existingWorkList)
          }
  
       }
    }

  const getFilteredData = () => {

    let tempData = []
          
    if(selectedOption.length === 0 || selectedOption.length === 12){
       setFilteredData(workList)
       return;
    }
    
    selectedOption.map(x => {
      workList.map(a => {
          if(x.value == 2){
            if(a.status===2 && !!a.workStaffAssignment && a.workStaffAssignment.staffWorkData.length === 0){
              tempData.push(a)
            }
            return;
          }
          if(x.value == 2.1){
             if(a.status===2 && a.workStaffAssignment?.staffWorkData.length !== 0){
              tempData.push(a)
             }
            return;
          }
          if(x.value === a.status){
            tempData.push(a)
          }
        })
    })

    setFilteredData(tempData)
  }

  const getAgencyFilteredData = () => {
    let tempData = [];
    
    // If no agency option selected, return the entire workList
    if (agencySelectedOption.length === 0) {
      setAgencyFilteredData(workList);
      return;
    }
    
    // Array of the selected agency IDs
    const selectedAgencyIds = agencySelectedOption.map((selectedAgencyOption) => selectedAgencyOption.value);
    
    // Filter the workList based on the selected agency IDs
    tempData = workList.filter((work) => {
      return selectedAgencyIds.includes(work.agencyId);
    });
    
    setAgencyFilteredData(tempData);
  }

  useEffect(()=>{
    let combinetempdata= []

    if(selectedOption.length>0 && agencySelectedOption.length>0){
      
      
      filteredData.filter(function(item){
        return AgencyfilteredData.filter(function(elem){
          if(item.id===elem.id){
            combinetempdata.push(item);
          }
        })
      })
      CombinedFilteredData(combinetempdata)
    }
   

  },[AgencyfilteredData,filteredData])


  useEffect(()=>{
    getAgencyFilteredData()
  },[agencySelectedOption,workList])


  useEffect(() => {
    getFilteredData()
  }, [selectedOption, workList])
 
  // const fetchAgency = async()=>{

  //   let userData = JSON.parse(localStorage.getItem("userData"));
  //   let clientid = userData.userId ? userData.userId : userData.id;
  //   let query = {clientId: clientid}

  //   await getAgencies(query)
  //      .then((data)=>{
  //       setAllagencies(data) 
  //      })
  //      .catch((e)=> setLoading(false));
  // } 


  useEffect(()=>{

    let modifydata=[];
    let filteredAgencies = Allagencies.filter((item) => {
        if(showArchives) {
          return (item.status === 0 || item.status === 2 || item.status === 3)
      }
      else {
          return (item.status === 1 || item.status === undefined || item.status === null)
      }
    })
    for(let i=0;i<filteredAgencies.length;i++){
      modifydata.push({
       index:i, label: filteredAgencies[i].agencyName, value: filteredAgencies[i].agencyId }
      )
    }
    setAgencyMultiOptions(modifydata);

  },[Allagencies, showArchives])
  
  useEffect(()=>{
    // fetchAgency()
    setAllagencies(agencyData)
  },[agencyData])


useEffect(()=>{

  // fetchAgency()
 
  let countAdded = []
  let agencyfiltercount=[];
  OptionsList.map((x)=>{
    if(x.value != 'all'){
    let count = 0
    let key = x.value == 2.1 ? 21 : x.value

    workList.map((a)=>{
      if(x.value == 2){
        if(a.status===2 && !!a.workStaffAssignment && a.workStaffAssignment.staffWorkData.length === 0){
          count = count + 1
        }
        return;
      }
      if(x.value == 2.1){
         if(a.status===2 && a.workStaffAssignment?.staffWorkData.length !== 0){
          count = count + 1
         }
        return;
      }
      if(x.value === a.status){
        count = count + 1
      }
    })
    countAdded.push({...x, count:count, key : key})
     }
  })

  agencyMultiOptions.map((x)=>{

    let count=0;

    workList.map((a)=>{
      if(a.agencyId==x.value){
        count=count+1;
      }
    })
    agencyfiltercount.push({...x,count:count})
  })

   setAgencyfilterMutliOptionsList(agencyfiltercount)
      
   setMultiSelectOptionsList(countAdded)

},[workList, agencyMultiOptions])

      // For handling duplicate initaial call, when switching to calendar view.
      const previousActiveViewRef = useRef({view:activeView, initial:true});
      useEffect(()=>{
        if (previousActiveViewRef.current.view === false && activeView === true) {
            previousActiveViewRef.current = {view:activeView, initail:false};
           return;
        }
        if(previousActiveViewRef.current.initial === true){
            previousActiveViewRef.current = {view:activeView, initail:false};
            return;
        }
        fetchAllWork(query);
        previousActiveViewRef.current = {view:activeView, initail:false};
      },[activeView , paginationConfig.pageCount])

  useEffect(()=>{
    window.initMap = initMap();
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
      let userData = JSON.parse(localStorage.getItem("userData"));
      let id = userData.userId ? userData.userId : userData.id;
      setClientId(id);
      // Initially tried below code for auto refresh but it didn't work. It was sending the API calls but with the wrong payloads 
      // Start date and end date was correct but not the location but in case of manuall refresh it was working fine and we are
      // using the same function for both. My observation is updating the state is not triggered by intervals properly.
      // code :
      // setInterval(()=>afterLoadCalendar(query),30000);
  },[]);

  // useEffect(()=>{
  //   afterLoadCalendar(query);
  //   setTimeout(()=>{  
  //     setRefreshTrigger(!refreshTrigger);
  //   },60000)
  // },[refreshTrigger])

  useEffect(() => {
    afterLoadCalendar(query);
    // setTimeout(()=>{  
    //    setRefreshTrigger(!refreshTrigger);
    //      },60000)
    // if (locations.length === 0) fetchAllLocations();
  }, [
    query.searchKey,
    query.sortBy,
    query.location,
    query.jobName,
    query.page,
    // visible,
    // workAssign,
    refreshTrigger,
    showArchives
  ]);

  // useEffect(()=>{
  //   if (activeView){
  //     var tempView = viewByweek;
  //     if (tempView==="Week"){
  //       setViewByweek("Month");
  //     }else{
  //       setViewByweek("Week");
  //     }
  //     setTimeout(()=>{
  //       setViewByweek(tempView);
  //     },1);
  //   }
  // },[activeView])

  const afterLoadCalendar = (query) => {
    fetchAllWork(query);
  };
  // fetch all location for filter
  const fetchAllLocations = async () => {
    setLoading(true);
    let userData = JSON.parse(localStorage.getItem("userData"));
    let agencyID = userData.agencyId ? userData.agencyId : "";

    let query = {
      agencyId:agencyID[0],
    }
     
    await getAllLocation(query)
      .then((data) => {
        let modifyData = [
          {
            label: "All Location",
            value: "",
          },
        ];
        data[0].data.forEach((item) => {
          modifyData.push({
            label: item,
            value: item,
          });
        });
        setLocations(modifyData.length ? modifyData : []);

        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  const dateFormaterFromHandleEventRendered = (dateStr) => {
    let dateFormat = 'h:mm';
    let amPmStr = 'a'
    if(parseFloat(moment(dateStr).format('m'))<=0){
        dateFormat = 'h';
    }
    if(moment(dateStr).format('a')==='pm'){
        amPmStr = 'p';
    }

    return `${moment(dateStr).format(dateFormat)}${amPmStr}`;
}
  // fetch all work data method
  const fetchAllWork = async (queryAsParam) => {
    let query = queryAsParam;
    setLoading(true);
    if (viewByweek === "Days") {
      query.startDate = moment(query.startDate).startOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
      query.endDate = moment(query.startDate).endOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    } else if (viewByweek === "Week") {
      query.startDate = moment(query.startDate).startOf("week").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
      query.endDate = moment(query.startDate).endOf("week").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    } else if (viewByweek === "Month") {
      query.startDate = moment(query.startDate).startOf("month").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
      query.endDate = moment(query.startDate).endOf("month").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    }
    let userData = JSON.parse(localStorage.getItem("userData"));
    let id = userData.userId ? userData.userId : userData.id;
    let agencyID = userData.agencyId ? userData.agencyId : "";
    setClientId(id);
    query.clientId = id;
    query.agencyId = ["all"];
    let body={...query}
    body.size = paginationConfig.pageCount
    body.startDate = moment(body.startDate).utc().milliseconds(0).format('YYYY-MM-DD[T]HH:mm:ss.SSSS')
    body.endDate = moment(body.endDate).utc().milliseconds(0).format('YYYY-MM-DD[T]HH:mm:ss.SSSS')
    await getAllWork(body)
      .then(({data,page}) => {
        let dataBasedOnAgencyStatus = getDataBasedOnAgencyStatus(data)
        let modifyData = [];
        let count = page * paginationConfig.pageCount;
        setPaginationConfig({ ...paginationConfig, TotalCount: count, itemCount:data.length})
     
        if (activeView) {
          dataBasedOnAgencyStatus.forEach((item) => {
            // item["start"]= moment(item.startDate).format("DD MMM,hh:mm a");
            item["start"] = item.startDate;
            item["end"] = item.endDate;
            item["id"] = item.id;
            item["eventId"] = item.id;
            item["display"] = "block";
            item["editable"] = false;
            item["textEscape"] = false;
            item["html"] = true;

            item["StartTime"] = moment.utc(item.startDate).format();
            item["EndTime"] = moment.utc(item.endDate).format();
            // item["Subject"] = item.workRequest;
            item["Subject"] = `${dateFormaterFromHandleEventRendered(item.startDate)}-${dateFormaterFromHandleEventRendered(item.endDate)}&nbsp;${item.priority.toUpperCase()==="NORMAL"?"("+item.priority+")":"<b>("+item.priority+")</b>"}&nbsp;${item.jobName}`;
            item["Category"] = item.status;
            
            if (item.isArchived !== 1) modifyData.push(item);
          });
          setWorkList(modifyData.length ? modifyData : []);
        } else {
          setWorkList(
            dataBasedOnAgencyStatus.map(item=>{
              return {
                ...item,
                "statusName" : item.status === 2 && item.workStaffAssignment.staffWorkData.length !== 0 ? 'STAFF HAS BEEN ASSIGNED. PLEASE CONFIRM': colorByStatus?.[item.status]?.status,
              }
            })
          );
        }
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  //handle reload calender events
  const handleReload = () => {
    doRefresh((prev) => prev + 1);
  };

  const onShowArchivesClick = () => {
    setShowArchives(!showArchives)
 }


  return (
    <div className="p-4 h-100 ">
       {/* This is for POC of WebSocket, it will be revisited later */}
      {/* <WebSocket workList={workList} setWorkList={setWorkList}/> */}
      <div className=" d-flex justify-content-between mb-3 flex-wrap">
        <div id={'work-schedule-title'}>
          <Title label="Work Schedule" className=""/>
        </div>
        <div className="labels-container">
          <div className="">
            {/* <Link to={`/admin/workSchedule/workScheduleList`}> */}
            <NormalButton
              id={'add-work-request-btn'}
              label="+ Work request"
              outline={false}
              className="btn bg-c5 text-white px-3 py-2 text-uppercase fw-600 fs-12 font-Montserrat"
              onClick={() => {
                

                // let cDate = moment.utc().format("YYYY-MM-DD[T]HH:mm:ss.SSSS");

                setInitialStartTime("");
                setInitialEndTime("");

                setVisible({ value: true });

              }}
            />
            {/* </Link> */}
          </div>
        </div>
      </div>
      {/* <div className="d-flex justify-content-end">
        {showAllStatus ? <>
          <ul className="work-status-list" id={'wr-status-legend'}>
                <li style={{"--dynamic-color-value": colorByStatus?.[1].color}}>{colorByStatus?.[1].status}</li>
                <li style={{"--dynamic-color-value": colorByStatus?.[2].color}}>{colorByStatus?.[2].status}</li>
                <li style={{"--dynamic-color-value": colorByStatus?.["2.1"].color}}>{colorByStatus?.["2.1"].status}</li>
                <li style={{"--dynamic-color-value": colorByStatus?.[3].color}}>{colorByStatus?.[3].status}</li>
                <li style={{"--dynamic-color-value": colorByStatus?.[4].color}}>{colorByStatus?.[4].status}</li>
                <li style={{"--dynamic-color-value": colorByStatus?.[5].color}}>{colorByStatus?.[5].status}</li>
                <li style={{"--dynamic-color-value": colorByStatus?.[6].color}}>{colorByStatus?.[6].status}</li>
                <li style={{"--dynamic-color-value": colorByStatus?.[7].color}}>{colorByStatus?.[7].status}</li>
                <li style={{"--dynamic-color-value": colorByStatus?.[8].color}}>{colorByStatus?.[8].status}</li>
                <li style={{"--dynamic-color-value": colorByStatus?.[9].color}}>{colorByStatus?.[9].status}</li>
                <li style={{"--dynamic-color-value": colorByStatus?.[10].color}}>{colorByStatus?.[10].status}</li>
                <li style={{"--dynamic-color-value": colorByStatus?.[0].color}}>{colorByStatus?.[0].status}</li>

            <li className="cursor-pointer showStatusButton" onClick={()=>setShowAllStatus(false)}>
                - SHOW LESS
            </li>
        </ul>
        </>:<>
          <ul className="work-status-list d-md-flex flex-wrap" id={'wr-status-legend'}>
          // <ul className="work-status-list">
          //       <li style={{"--dynamic-color-value": colorByStatus?.[1].color}}>{colorByStatus?.[1].status}</li>
          //       <li style={{"--dynamic-color-value": colorByStatus?.[2].color}}>{colorByStatus?.[2].status}</li>
          //       <li style={{"--dynamic-color-value": colorByStatus?.[4].color}}>{colorByStatus?.[4].status}</li>
          //       <li style={{"--dynamic-color-value": colorByStatus?.[7].color}}>{colorByStatus?.[7].status}</li>
          //       <li style={{"--dynamic-color-value": colorByStatus?.[8].color}}>{colorByStatus?.[8].status}</li>
          //       <li style={{"--dynamic-color-value": colorByStatus?.[0].color}}>{colorByStatus?.[0].status}</li>
          //   </ul>
            <li className="cursor-pointer showStatusButton" onClick={()=>setShowAllStatus(true)}>
                + SHOW ALL
            </li>
          </ul>
        </>}
      </div> */}
      <div className="bg-white p-0 border-radius-5">
        <TableFilter
          query={query}
          locations={locations}
          setQuery={setQuery}
          setPaginationConfig= {setPaginationConfig}
          view={activeView}
          setActiveView={setActiveView}
          setPage={handleUpdatePage}
          sort={OptionsList}
          action={actions}
          viewByweek={viewByweek}
          setViewByweek={setViewByweek}
          handleReload={()=>afterLoadCalendar(query)}
          setFilterStatus={setFilterStatus}
          multiSelectOptionsList = {multiSelectOptionsList}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          agencySelectedOption={agencySelectedOption}
          setAgencySelectedOption={setAgencySelectedOption}
          agencyMultiOptions={AgencyFilterMultiOptionslist}
          showArchives={showArchives}
          onShowArchivesClick={onShowArchivesClick}
        />

        <div style={{marginTop:"1rem", marginBottom:"-1.5rem"}}>
        <TableWrapper
          page={query.page}
          pageMeta={pageMeta}
          paginationChange={(page) => handleUpdatePage(page)}
          paginationConfig={paginationConfig}
          pageCountChange={(count) => setPaginationConfig({ ...paginationConfig, pageCount: Number(count) })}
        />
        </div>
        <div className="row mt-3 ">
          <div className="col-12">
            {activeView ? (
            <Suspense fallback={<div><CommonPageLoader/></div>}>
            <WorkCalender
              afterLoadCalendar={afterLoadCalendar}
              setPage={handleUpdatePage}
              workList={selectedOption.length==0 && agencySelectedOption.length==0 ? workList : selectedOption.length>0 && agencySelectedOption.length==0? (filteredData.filter(wRequest=>{
                if(filterStatus==="2.1") return (wRequest.status===2 && wRequest.workStaffAssignment?.staffWorkData.length !== 0 );
                else if (filterStatus===2) return (wRequest.status===2 && !!wRequest.workStaffAssignment && wRequest.workStaffAssignment?.staffWorkData.length === 0 );
                else return true;
              })) :AgencyfilteredData.length>0 && selectedOption.length==0? AgencyfilteredData: agencyMultiOptions.length>0 && selectedOption.length>0 ? combinedfilteredData:"" }
              query={query}
              setQuery={setQuery}
              refresh={refresh}
              clientId={clientId}
              setVisible={setVisible}
              setWorkAssign={setWorkAssign}
              viewByweek={viewByweek}
              setInitialStartTime={setInitialStartTime}
              setInitialEndTime={setInitialEndTime}
              StaffStatusLabels={StaffStatusLabels}
            />
            </Suspense>
            ): (
              <WorkScheduleList
                refresh={refresh}
                setPage={handleUpdatePage}
                filterStatus={filterStatus}
                handleReload={handleReload}
                loading={loading}
                setWorkList={setWorkList}
                afterLoadCalendar={afterLoadCalendar}
                // workList={workList}
                workList={selectedOption.length==0 && agencySelectedOption.length==0 ? workList : selectedOption.length>0 && agencySelectedOption.length==0? filteredData :AgencyfilteredData.length>0 && selectedOption.length==0? AgencyfilteredData: agencySelectedOption.length>0 && selectedOption.length>0 ? combinedfilteredData:"" } 
                query={query}
                setQuery={setQuery}
                setVisible={setVisible}
                clientId={clientId}
                setWorkAssign={setWorkAssign}
                viewByweek={viewByweek}
                StaffStatusLabels={StaffStatusLabels}
                showArchives={showArchives}
              />
            )}
          </div>
        </div>
      </div>
      <Suspense fallback={<div><CommonPageLoader/></div>}>
      <WorkAssignmentNew
        // visible={visible}
        setVisible={setVisible}
        workAssign={workAssign}
        setWorkAssign={setWorkAssign}
        handleReload={handleReload}
        showArchives={showArchives}
      />
      </Suspense>
      <Suspense fallback={<div><CommonPageLoader/></div>}>
      <AddWorkRequest
      allAgencies={Allagencies}
        visible={visible}
        setVisible={setVisible}
        handleReload={handleReload}
        initialStartTime={initialStartTime}
        initialEndTime={initialEndTime}
        setInitialStartTime={setInitialStartTime}
        setInitialEndTime={setInitialEndTime}
      />
      </Suspense>
      {/* <HoverBox/> */}
      {/* <RepeatModel/> */}
      {/* <AddWorkRequest></AddWorkRequest> */}
      {/* <HoverBox></HoverBox> */}
      {/* <RepeatModel></RepeatModel> */}
      {/* <WorkAssignment
             visible={visible}
             setVisible={setVisible}/> */}
      {/* <WorkAssignment/> */}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllWork,
      getAllLocation,
      getAgencies,
    },
    dispatch
  );
};

export default connect(
  mapDispatchToProps,
  mapDispatchToProps
)(withRouter(WorkSchedule));

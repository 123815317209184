import React, { useEffect, useRef, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { extractAddressFields } from "../../../service/helperFunctions";

// import { Map, GoogleApiWrapper } from "google-maps-react";
export function LocationAutoComplete({ placeholder = "", className }) {
  // constructor(props) {
  //   super(props);
  //   this.state = { address: '' };
  // }
  const googleMapRef = useRef();
  // let googleMap;
  const [gmapsLoaded, setGmapsLoaded] = useState(false);
  const [address, setAddress] = useState("");
  const initMap = () => {
    setGmapsLoaded(true);
  };

  useEffect(() => {
    // const googleMapScript = document.createElement('script');
    // googleMapScript.src=`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places&callback=initMap`;
    // googleMapScript.async = true;
    // window.document.body.appendChild(googleMapScript);
    // googleMapScript.addEventListener('load', () => {
    //                     getLatLng();
    //                 });
    // })

    window.initMap = initMap();
    // const gmapScriptEl = document.createElement(`script`)
    // gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places`
    // document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl);
    // initMap();
  }, []);

  const handleChange = (address) => {
    this.setState({ address });
    setAddress(address);
  };

  // const handleSelect = address => {
  //   geocodeByAddress(address)
  //     .then(results => getLatLng(results[0]))
  //     .then(latLng => console.log('Success', latLng))
  //     .catch(error => console.error('Error', error));
  // };
  // const createGoogleMap = (coordinates) => {
  //   googleMap = new window.google.maps.Map(googleMapRef.current, {
  //     zoom: 16,
  //     center: {
  //       lat: coordinates.lat(),
  //       lng: coordinates.lng(),
  //     },
  //     disableDefaultUI: true,
  //   })
  // };
  const handleSelect = (address) => {
    this.setState({ address });
    this.props.handleSelect(address);
    geocodeByAddress(address)
      .then((results) => {
        let data = extractAddressFields(results[0].address_components);
        if (this.props.handleAddress) {
          this.props.handleAddress(data);
        }
        getLatLng(results[0]).then((latDetails) =>
          this.props.handleLatLng(latDetails)
        );
      })
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };

  return (
    <>
      {gmapsLoaded ? (
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: placeholder,
                  className: className,
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      ) : (
        ""
      )}
      {/* <div
      id="google-map"
      ref={googleMapRef}
     style={{ width: '400px', height: '300px' }}/> */}
    </>
  );
}

// export default GoogleApiWrapper({
//     apiKey: 'process.env.REACT_APP_API_KEY',
//     libraries: ["places"]
//   })(LocationAutoComplete);

import React, {  useEffect, useState } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import { getAgencies } from 'action/Agency';
import { getClientById } from 'action/settingsAct';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './sidebar.scss';
import atom_green from 'assets/svg/atom_green.svg';
import atom_white from 'assets/svg/atom_white.svg';
import feedbackSolid from 'assets/svg/feedback_solid.svg';
import feedbackTransparent from 'assets/svg/feedback transparent.svg';
// import groupSolid from 'assets/svg/group solid.svg';
// import groupTransparent from 'assets/svg/group transparent.svg';
import settingsSolid from 'assets/svg/settings_solid.svg';
import settingsTransparent from 'assets/svg/settings transparent.svg';
import { SET_DISTANCE_UNIT, SET_NO_REG_AGENCIES_WARNING } from "service/actionType"
import settingsWithWarning from "assets/svg/settingsWithWarning.svg"
import settingsWithWarningWhite from "assets/svg/settingsWithWarningWhite.svg"
import subscriptionSolidIcon from "assets/svg/Subscription-solid-filled.svg"
import subscriptionWhiteIcon from "assets/svg/Subscription-white.svg";
import subscriptionSolidWarningIcon from 'assets/svg/subscription-solid-warning.svg'
import subscriptionWhiteWarningIcon from 'assets/svg/subscription-white-warning.svg'
import agencySettingsSolid from "assets/svg/agencySettingsSolid.svg"
import agencySettingsTransparent from "assets/svg/agencySettingsTransparent.svg"
import agencySettingsSolidWithWarning from "assets/svg/agencySettingsSolidWithWarning.svg"
import agencySettingsTransparentWithWarning from "assets/svg/agencySettingsTransparentWithWarning.svg"
import { startTourGuide } from 'reducer/tourGuide';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import agencyData from 'reducer/agencyData';
import moment from "moment";
import { store } from "service/helpers"
import dashboardSolid from "assets/svg/dashboard.svg"
import dashboardTransparent from "assets/svg/dashboard_transparent.svg"
// export default class Sidebar extends Component {
//   constructor(props) {
//     super(props);
//     this.activeTab = this.activeTab.bind(this);
//     this.state = {
//       navLinks: [
//         {
//           to: '/something',
//           label: '',
//           iconName: '',
//           iconTransparent: '',
//           classNames:"",
//           id:0
//         },
//         {
//           to: '/admin/WorkSchedule',
//           label: 'Work Schedule',
//           iconName: atom_green,
//           iconTransparent: atom_white,
//           classNames:"WSSizePlus",
//           id:1
//         },
//         {
//           to: '/admin/settings',
//           label: 'Settings',
//           iconName: settingsSolid,
//           iconTransparent: settingsTransparent,
//           classNames:"sideBarIcon",
//           id:2
//         },
       
//         {
//           to: '/admin/feedback',
//           label: 'Feedback',
//           iconName: feedbackSolid,
//           iconTransparent: feedbackTransparent,
//           classNames:"sideBarIcon",
//           id:3
//         },
        
//         {
//           to: '/something',
//           label: '',
//           iconName: '',
//           iconTransparent: '',
//           id:4,
//         },
//       ],
//       prev: '',
//       next: '',
//       active: ''
//     };
//   }

//   activeTab = (index) => {   
//     console.log(index,"INDEX")
//     let{location}=history;
  
//     // console.log("histroy",location.pathname.split('/'))
//     let matchData=location.pathname.split('/')
//     if(matchData[matchData.length+1]!=='notification'){
//       this.setState({
//         next: index+1,
//         prev:index-1,
//         active:index
//       },()=>{
//         console.log(this.state.prev,this.state.next,"PREV NEXT")
//       })
//     }
//   }


//   componentDidMount(){
//     let{location}=history;
//     let {navLinks}=this.state;
//     navLinks.forEach((item,index)=>{
//       if( (location.pathname.search( item.to)!==-1) || (item.to  === location.pathname) ){
//         this.setState({
//           next: index+1,
//           prev:index-1,
//           active:index
//          });
//       }
//     })
//   }

//   render() {
//     let { navLinks,prev,next,active } = this.state;
//     let { menuOpen, handleSidenav } = this.props;
//     let {location}=history
//     return (
//       <>
//         <div className={`left-menu bg-back-green  ${menuOpen ? 'open' : ''}`}>
//           <div className="sidebar-logo">
//             {/* <img src={logo} alt="logo" /> */}
//           </div>
//           <PerfectScrollbar>
//             <ul>
//               {/* {
//               navLinks.map(({ to, label, iconName,iconTransparent }, index) => (
//                 <li key={index} >
//                     <NavLink to={to} onClick={(to!='/something'?()=>this.activeTab(index):'')} className={`nav-link  ${to=='/something'?'disabled':''} ${prev == index ? 'prev-li' : ''} ${next == index ? 'next-li' : ''}`}>
//                       <div className="sidebar-menu">
//                         <div className="menu-icon text-left">
//                         {active==index&&location.pathname!=='/admin/notification'?<img src={iconName} className=''/>:<img src={iconTransparent} className=''/>}
//                         </div>
//                         {menuOpen&&<span className="sidebar-menu-desc fs-16">{label}</span>}
//                       </div>
//                   </NavLink>
//                 </li>
//               ))
//               } */}
              
//               {
//                  navLinks.map(({ to, label, iconName,iconTransparent, classNames }, index) => (
//                   <li key={index}>
//                     {/*<NavLink onClick={(to!='/something'?()=>this.activeTab(index):'')} to={to} className={`nav-link  ${to=='/something'?'disabled':''} ${prev==index?'prev-li':''} ${next==index?'next-li':''}`}>*/}
//                     <NavLink onClick={(to!=='/something'?()=>this.activeTab(index):()=>{})} to={to} tabIndex={to==='/something'?'-1':''} className={`nav-link  ${to==='/something'?'disabled':''} ${prev===index&&location.pathname!=='/admin/notification'?'prev-li':''} ${next===index&&location.pathname!=='/admin/notification'?'next-li':''}`}>
//                       <div className="sidebar-menu">
//                         <div className="menu-icon text-left">
//                           {/*active==index?<img src={iconName} className=''/>:<img src={iconTransparent} className=''/>*/}
//                           {active===index&&location.pathname!=='/admin/notification'?<img src={iconName} className={classNames}/>:<img src={iconTransparent} className={classNames} />}
//                         </div>
//                         {menuOpen?<span className="sidebar-menu-desc fs-16">{label}</span>:<div className='hoverTitle'><span>{label}</span></div>}
//                       </div>
//                     </NavLink>
//                   </li>
//                   // loadTab(to, label, iconName,iconTransparent,index)
//                 ))
//                }
//             </ul>
//           </PerfectScrollbar>
//         </div>
//         <div className={`sidemenu-overlay ${menuOpen ? 'open' : ''}`} onClick={() => handleSidenav()}></div>
//       </>
//     );
//   }
// }
const initialNavLinks = [
  {
    to: '/something',
    label: '',
    iconName: '',
    iconTransparent: '',
    classNames:"",
    id:0
  },
  {
    to: '/admin/WorkSchedule',
    label: 'Work Schedule',
    iconName: atom_green,
    iconTransparent: atom_white,
    classNames:"WSSizePlus",
    id:1
  },
 
  {
    to: '/admin/feedback',
    label: 'Feedback',
    iconName: feedbackSolid,
    iconTransparent: feedbackTransparent,
    classNames:"sideBarIcon",
    id:3
  },

  {
    to: '/admin/AgencyManagement',
    label: 'Agency Management',
    iconName: agencySettingsSolid,
    iconTransparent: agencySettingsTransparent,
    classNames:"sideBarIcon",
    id:6
  },

  {
    to: '/admin/subscription',
    label: 'Subscription',
    iconName: subscriptionSolidIcon ,
    iconTransparent: subscriptionWhiteIcon,
    classNames:"sideBarIcon",
    id:4
  },
  
  {
    to: '/admin/settings',
    label: 'Settings',
    iconName: settingsSolid,
    iconTransparent: settingsTransparent,
    classNames:"sideBarIcon",
    id:2
  },
  {
    to: '/admin/dashboard',
    label: 'Dashboard',
    iconName: dashboardSolid,
    iconTransparent: dashboardTransparent,
    classNames:"sideBarIcon",
    id:7
  },
  {
    to: '/something',
    label: '',
    iconName: '',
    iconTransparent: '',
    id:5,
  },
]

 const Sidebar = (props) => {
  
  const [navLinks, setNavLinks] = useState(initialNavLinks);
  const [sideLinks, setSideLinks] = useState([]);
  const [prev, setPrev] = useState('');
  const [next, setNext] = useState('');
  const [active, setActive] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [registeredAgencies, setRegisteredAgencies] = useState([]);
  const [distanceUnit, setdistanceUnit] = useState([]);

  const dispatch = useDispatch()
  const history = useHistory();

  const userData = JSON.parse(localStorage.getItem('userData')) 

  const hasSubscriptionWarning = useSelector((state)=> state.warningReducer.hasSubscriptionWarning);

  const loadRegisteredAgencies = async () => {
    try {
      const data = await props.getAgencies({
        clientId: userData.id,
        agencyName: '',
        category: '',
      });
      dispatch({type : "UPDATE_AGENCY_DATA" , payload :data})
      setRegisteredAgencies(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadDistanceUnit = async () => {
    try{
     const data = await props.getClientById({
      id: userData.id,
     })  

     localStorage.setItem('userData', JSON.stringify(data))
     if(moment(data.subscriptionValidity)<moment(new Date())){
      store.dispatch({type:'hasSubscriptionWarning', payload:true});
    }else{
      store.dispatch({type:'hasSubscriptionWarning', payload:false});
    }
     setdistanceUnit(data.distanceUnit)

    }
    catch (error){

    }
  }
  const firstLogin = localStorage.getItem('firstLogin');

  useEffect(()=>{
    loadRegisteredAgencies()
    loadDistanceUnit()
  },[]) 

  const agencyData = useSelector((state)=>state.agencyDataReducer.agencyData) 


  const noRegAgenciesWarning = useSelector(
    (state) => state.warningReducer.noRegAgenciesWarning
  );

  const actualDistanceUnit = useSelector(
    (state) => state.warningReducer.distanceUnit
  ) 

  useEffect(()=>{
    if(agencyData.length === 0){
      dispatch({ type: SET_NO_REG_AGENCIES_WARNING, payload: true })
    } 
    else {
      dispatch({ type: SET_NO_REG_AGENCIES_WARNING, payload: false })
    }
  },[agencyData])

  useEffect(() => {
    if (isLoading) {
      return;
    }
  
    if ((!registeredAgencies || registeredAgencies.length === 0 || registeredAgencies === null)) {
      // dispatch({ type: SET_NO_REG_AGENCIES_WARNING, payload: true });
        if(firstLogin === "true") {
          let userStatus = JSON.parse(localStorage.getItem('userData')).status
          userStatus === 3 ?  history.push('/admin/subscription')  : history.push('/admin/AgencyManagement');
        }
      localStorage.setItem('firstLogin', 'false');
    } else {
      // dispatch({ type: SET_NO_REG_AGENCIES_WARNING, payload: false });
      if (firstLogin === 'true') {
        localStorage.setItem('firstLogin', 'false');
      }
    }
  }, [isLoading, registeredAgencies, history]);

  useEffect(()=>{
    if(isLoading){
      return
    }
    if (distanceUnit!==0){
      dispatch({ type: SET_DISTANCE_UNIT, payload: distanceUnit});
    }
    else{
      dispatch({type: SET_DISTANCE_UNIT, payload: distanceUnit});
    }
  },[isLoading,distanceUnit, history])

  useEffect(() => {
    let updatedNavLinks = navLinks.map((link) => {
      if (link.label === 'Agency Management') {
        if (!noRegAgenciesWarning) {
          return {
            ...link,
            iconName: agencySettingsSolid,
            iconTransparent: agencySettingsTransparent,
          };
        } else {
          return {
            ...link,
            iconName: agencySettingsSolidWithWarning,
            iconTransparent: agencySettingsTransparentWithWarning,
          };
        }
      }
      if(link.label === 'Settings') {
        if(distanceUnit==0){
          return {
            ...link,
            iconName: settingsWithWarning,
            iconTransparent: settingsWithWarningWhite
          }
        }
        else{
          return{
            ...link,
           iconName: settingsSolid,
           iconTransparent: settingsTransparent,
          }
        }
      }  
      if (link.label === 'Subscription') {
        return { 
          ...link, 
          iconName: hasSubscriptionWarning? subscriptionSolidWarningIcon : subscriptionSolidIcon,
          iconTransparent: hasSubscriptionWarning? subscriptionWhiteWarningIcon : subscriptionWhiteIcon,
        }

      } 
      return link;
    });
    setSideLinks(updatedNavLinks);
    setNavLinks(updatedNavLinks);
  }, [noRegAgenciesWarning, registeredAgencies,distanceUnit,actualDistanceUnit]);

  const activeTab = (index) => {  
    let{location}=history;
    let matchData=location.pathname.split('/')
    if(matchData[matchData.length+1]!=='notification'){
        setNext(index+1)
        setPrev(index-1)
        setActive(index)
    }
  }
  function getNavLinks(permisionsList, allLinks) {
    const labels1 = new Set(permisionsList.map(obj => obj.label));
    const navObjects = [];
    for (let obj of allLinks) {
      if (labels1.has(obj.label)) {
        navObjects.push(obj);
      }
    }
    return navObjects;
  }
  useEffect(()=>{
    let updatedNavLinks = getNavLinks(props.permissionList, navLinks)
    if(updatedNavLinks.length > 0)
    setNavLinks([{
    to: '/something',
    label: '',
    iconName: '',
    iconTransparent: '',
    classNames:"",
    id:0
    },
    ...updatedNavLinks,
    {
      to: '/something',
    label: '',
    iconName: '',
    iconTransparent: '',
    id:4,
    },])
  },[props.permissionList,sideLinks])

  let userStatus = JSON.parse(localStorage.getItem('userData')).status
  useEffect(()=>{
    if(userStatus ===3)
    setNavLinks(prevLinks=>prevLinks.filter((item)=>item.to !== '/admin/settings'))
    if(window.location.pathname === '/admin/settings'){
        history.push('/admin/subscription')  
    }
  },[userStatus])
  
useEffect(()=>{
  let{location}=history;
  navLinks.forEach((item,index)=>{
    if( (location.pathname.search( item.to)!==-1) || (item.to  === location.pathname) ){
        setNext(index+1)
        setPrev(index-1)
        setActive(index)
    }
  })
},[history.location, navLinks])

    const handleTour = () =>{
      dispatch(startTourGuide({run: false, stepIndex: 0}))
    }
  
    let { menuOpen, handleSidenav } = props;
    let {location}=history
    return (
      <>
        <div className={`left-menu bg-back-green  ${menuOpen ? 'open' : ''}`}>
          <div className="sidebar-logo">
          </div>
          {/* <div style={{height:'40px'}}>

          </div> */}
          <PerfectScrollbar>
            <ul>
              {
                 navLinks.map(({ to, label, iconName,iconTransparent, classNames }, index) => (
                  <li key={index} onClick={handleTour}>
                    <NavLink onClick={(to!=='/something'?()=>activeTab(index):()=>{})} to={to} tabIndex={to==='/something'?'-1':''} className={`nav-link  ${to==='/something'?'disabled':''} ${prev===index&&location.pathname!=='/admin/notification'?'prev-li':''} ${next===index&&location.pathname!=='/admin/notification'?'next-li':''}`}>
                      <div className="sidebar-menu">
                        <div className="menu-icon text-left">
                        {
                          !menuOpen ? (
                            <OverlayTrigger
                              placement="right"
                              overlay={<Tooltip id={`tooltip-${index}`}>{label}</Tooltip>}
                            >
                              <img
                                src={active === index && location.pathname !== "/admin/notification" ? iconName : iconTransparent}
                                className={classNames}
                              />
                            </OverlayTrigger>
                          ) : (
                            <img
                              src={active === index && location.pathname !== "/admin/notification" ? iconName : iconTransparent}
                              className={classNames}
                            />
                          )
                        }
                        </div>
                        {/* {menuOpen?<span className="sidebar-menu-desc fs-16">{label}</span>:<div className='hoverTitle'><span>{label}</span></div>} */}
                        {menuOpen && <span className="sidebar-menu-desc fs-16">{label}</span>}
                      </div>
                    </NavLink>
                  </li>
                ))
               }
            </ul>
          </PerfectScrollbar>
        </div>
        <div className={`sidemenu-overlay ${menuOpen ? 'open' : ''}`} onClick={() => handleSidenav()}></div>
      </>
    );
  
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getAgencies,
    getClientById,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Sidebar);
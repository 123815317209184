
export const dashboardSteps = [
    {
        target:'#agency-metrics',
        content: 'Displays information about total agencies that you have connected with, total expenditure and work request related metrics.',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'#top-3-agencies',
        content:'Here you can check information about top 3 paid agencies.',
        placement:'auto',
    },
    {
        target:'#top-3-agencies-arrow',
        content:'Click here to check details about top 3 using and corping agencies.',
        placement:'auto',
    },
    {
        target:'#expenditure-breakup',
        content:'You can check visual representation of expenditure breakup of all your agencies here.',
        placement:'auto',
    },
    {
        target:'#dashboard-job-categories',
        content:'Displays information about all the job categories.',
        placement:'auto',
    },
    {
        target:'#agency-worker-ratings',
        content:'You can check top 3 workers based on ratings here.',
        placement:'auto',
    },
    {
        target:'#performance-filter',
        content:'Search by agency name and select a specific date range to view agencies related metrics within that time frame.',
        placement:'auto',
    },
    {
        target:'#dashboard-invoice',
        content:'You can check visual representation of paid, in review and pending invoices here.',
        placement:'auto',
    },
    {
        target:'#work-metrics',
        content:'Displays information about contracted, non contracted, weekend works and complaints',
        placement:'auto',
    },
    {
        target:'#dashboard-approvals',
        content:'Displays information about your pending approvals and approval history.',
        placement:'auto',
    }
]
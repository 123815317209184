
export const agencymanagementSteps = [ 
    {
        target:'#staffingAgencies',
        content:'All your connected agencies will be displayed here.',
        disableBeacon: true,
        placement: 'auto',
        disableScrolling: false
    },
    {
        target:'#staffingAgencyExplore',
        content:'Various listed agency categories will be displayed here. You can search for all the listed agencies from different categories. You can make an invite request to the agency of your choice.',
        disableBeacon: false,
        disableScrolling: false
    },
    // {
    //     target:'.search-bar-agencies',
    //     content:'Search for all the listed agencies from different categories. You can make an invite request to the agency of your choice.',
    //     disableBeacon: true,
    //     placement: 'top',
    //     disableScrolling: true
    // },
    // {
    //     target:'#categories-cont',
    //     content:'From the available list of categories, select any and listed agencies of that category will be displayed in the search box',
    //     disableBeacon: true,
    //     placement: 'bottom',
    //     disableScrolling: true
    // },
    
]
import React, { Component } from "react";
// import { Pagination } from "../index"
// import {
//   scrollTop,
//   appendQuery,
//   convertStringToObject,
// } from "service/helperFunctions";
// import { history } from "service/helpers";
import "./table.scss";
import sortIcon from "assets/svg/sort.svg";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";

export class TableWrapper extends Component {
  state = {
    currentSortKeyDetails: null,
    paginationactive: 1,
    options: [
      {value:10,label:'10'},
      {value:50,label:'50'},
      {value:100,label:'100'},
      {value:500,label:'500'},
      {value:1000,label:'1000'},
    ],
    keyName: "label",
    valueName: "value",
    placeholder: "Select",
  };

  // componentWillMount() {
  //     let { field = '', orderBy = '' } = this.props;
  //     if (field && orderBy) {
  //         this.setState({
  //             currentSortKeyDetails: { sortKey: field, orderBy },
  //         });
  //     }
  // }

  componentDidUpdate(){
    if(this.state.paginationactive!==this.props.page){
        this.setState({ paginationactive: this.props.page });
    }
}

  getPlaceHolder = () => {
    //   let { placeHolder = 'No data found' } = this.props;
    //   if (typeof placeHolder === 'function') {
    //     return placeHolder();
    //   }
    //   return <p className="text-center">{placeHolder}</p>;
  };

  // handlePagination = page => {
  //     let { queryHandler, scrollProps = '' } = this.props;
  //     queryHandler({ page })
  //         .then(() => {
  //             scrollTop(...scrollProps);
  //         })
  //         .catch(err => {
  //             console.log('Error:::' + err);
  //         });
  // };
  handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
    this.setState({ paginationactive: pageNumber });
    this.props.paginationChange(pageNumber);
  }

  render() {
    let {
      handleSort,
      pagination = true,
      headerDetails,
      paginationConfig,
      pageCountChange,
      // paginationLength,
      children,
      // pageMeta,
      isEmpty = false,
      className = "",
      overFlow = true,
      // pageNumber,
    } = this.props;
    let { paginationactive, options, keyName, valueName, placeholder } =
      this.state;
    // let { sortKey: currentSortKey, orderBy = "" } =
    //   this.state.currentSortKeyDetails || {};
    // console.log(paginationConfig, "SDA");

    return (
      <div className="maintable table-container">
        {pagination && (
          <div className="div-pagination">
            <Pagination
              activePage={paginationactive}
              itemsCountPerPage={
                !!paginationConfig ? paginationConfig.pageCount : 0
              }
              totalItemsCount={
                !!paginationConfig ? paginationConfig.TotalCount : 0
              }
              pageRangeDisplayed={
                !!paginationConfig ? paginationConfig.btnDisplay : 0
              }
              itemClass="page-item"
              linkClass="page-link"
              innerClass=" custom-pagination pagination"
              nextPageText="Next"
              prevPageText="Previous"
              hideFirstLastPages={true}
              linkClassPrev="custom-prev"
              linkClassNext="custom-next"
              disabledClass="custom-disable"
              onChange={this.handlePageChange.bind(this)}
            />
            <div className="total-entries">
              <span>Show</span>
              {/* <span className="coount">{!!paginationConfig?paginationConfig.itemCount:0}</span> */}
              <select
                className="custom-hide-option mx-2"
                value={!!paginationConfig ? paginationConfig.pageCount : 0}
                // disabled={disabled}
                onChange={(e) => {
                  pageCountChange(e.target.value);
                  this.setState({paginationactive : 1});
                  this.props.paginationChange(1)
                }}
              >
                <option value="" defaultValue>
                  {placeholder}
                </option>
                {options.length !== 0 && paginationConfig ? (
                  options.map((option, index) => (
                    <option
                      value={option[valueName]}
                      disabled={
                        option.value > paginationConfig.TotalCount
                          ? true
                          : false
                      }
                      key={index}
                    >
                      {option[keyName]}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No Options
                  </option>
                )}
              </select>
              <span>Entries</span>
            </div>
          </div>
        )}
        <div
          className={`maintable-content px-2 ${
            overFlow ? "table-responsive" : ""
          } ${className}`}
        >
          <table className="table">
            <thead>
              <tr style={{background:"white"}}>
                {headerDetails?.map(
                  (
                    {
                      label,
                      className,
                      divClass = "",
                      sortKey = "",
                      element,
                      isSort,
                      ascKey,
                    },
                    index
                  ) => {
                    return (
                      <th className={className} key={index}>
                        <div
                          style={{
                            position: "relative",
                            bottom: "7px"
                          }}
                          className={`d-flex align-items-center justify-content-start text-center fs-14 ${
                            sortKey && "cursor-pointer"
                          } ${divClass}`}
                        >
                          {label}
                          {element && element()}
                          {isSort ? (
                            <div
                                className={`d-flex table-filter align-items-center cursor-pointer ml-2
                                'active-filter'}`}
                            >
                            <img src={sortIcon} onClick={()=>handleSort(ascKey)} alt="sortIcon"  width={'11'} height={'11'} />
                            </div>
                          ) : (
                                ''
                            )}
                        </div>
                      </th>
                    );
                  }
                )}
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </table>
          {isEmpty ? this.getPlaceHolder() : ""}
        </div>
        {/* {pageMeta.buyerCount != 0 && */}
        {/* // } */}
      </div>
    );
  }
}

TableWrapper.propTypes = {
  placeHolder: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isEmpty: PropTypes.bool,
  headerDetails: PropTypes.array.isRequired,
  pageMeta: PropTypes.object,
};

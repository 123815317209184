import React from "react";
import { NormalModal } from "component/common";
import "./style.scss";
import GoogleMaps from "../googleMaps";

export default function GoogleMapsModel({ isOpen, toggle, data }) {
  return (
    <NormalModal
      className={"modal-fill shadow"}
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      size="lg"
      bodyClass="add-staff-box"
      headerClass="border-0"
      centered={true}
    >
      <>
        {!!data.longLat && (
          <GoogleMaps
            lan1={data.longLat[0]}
            lat1={data.longLat[1]}
            lat2={
              data.workStaffAssignment &&
              data.workStaffAssignment.staffWorkData &&
              data.workStaffAssignment?.staffWorkData[0].longLat[1]
            }
            lan2={
              data.workStaffAssignment &&
              data.workStaffAssignment.staffWorkData &&
              data.workStaffAssignment?.staffWorkData[0].longLat[0]
            }
          />
        )}
      </>
    </NormalModal>
  );
}

import {  fileUploadOnMDB , fileDownloadFromMDB } from '../service/apiVariables';
import {  addQuery } from '../service/helperFunctions';



  export const uploadFileToMDB = (body, query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, fileUploadOnMDB.create);
      api({ ...fileUploadOnMDB.create, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const getFileFromMDB = (query,path) => (dispatch, getState, { api, Toast }) => {
    
    fileDownloadFromMDB.getFile.url = path ? `storage/mdb/file/${path}` : 'storage/mdb/file'
    return new Promise((resolve, reject) => {
      addQuery(query, fileDownloadFromMDB.getFile);
      api({ ...fileDownloadFromMDB.getFile })
        .then(({data}) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
};

export const deleteFileFromMDB = (query) => (dispatch, getState, { api, Toast }) => {
    
  return new Promise((resolve, reject) => {
    addQuery(query, fileDownloadFromMDB.deleteFile);
    api({ ...fileDownloadFromMDB.deleteFile })
      .then(({data, message}) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
import React, { useEffect, useState,useRef } from "react";
import { withRouter } from "react-router-dom";
import { NormalModal } from "component/common";
import "./style.scss";
import { NormalButton, CommonPageLoader } from "component/common";
import CancelWorkModel from "component/workShedule/cancelWorkModel";
import CustomLoader from "component/common/CustomLoader";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  getSingleWork,
  deleteWork,
  cancelWork,
  approveStaff,
  rejectStaff,
  updateRatings,
  completeWorkClient,
  addWorkRatings
} from "action/workRequest";
import { getFileFromMDB } from "action/fileHandler";
import Moment from "moment";
// import { TimeSheetMark } from "component/workShedule/timeSheetMark/index";
// import { StaffDetails } from "component/workShedule/staffDetails/index";
import { StaffDetails } from "../staffDetails new";
import DisplayCredentialsImage from "./DisplayCredentialsImage";
// import editIcon from "assets/svg/white-edit.svg";
// import deleteIcon from "assets/svg/white-delete.svg";
// import deleteIconDisable from "assets/svg/bin.svg";
import editIcon from "assets/svg/new-edit-icon.svg";
import expandfeedback from "assets/svg/expand-feedback.svg";
import disableeditIcon from "assets/svg/Disableedit.svg";
import greyTriangle from "assets/svg/credentials_tooltip.svg";
import closeIcon from "assets/svg/closesquare.svg"
import closeRedbg from "assets/svg/closeRedbg.svg";
import disablecloseIcon from "assets/svg/disableCloseicon.svg";
import disablecloseIconbg from "assets/svg/closeDisablebg.svg";
import GreenflagIcon from "assets/svg/Green-flagicon.svg";
import RedflagIcon from "assets/svg/Red-flagicon.svg";
import CalendarBlack from "assets/svg/Calendar-black.svg";
import TimeIcon from "assets/svg/Time-icon.svg";
import upArrowIcon from "assets/svg/arrow-up-bold.svg";
import downArrowIcon from "assets/svg/arrow-down-bold.svg";
import arrowDown2 from "assets/svg/arrowdown2.svg";
import arrowUp2 from "assets/svg/arrowup2.svg";
import dummyFrame from "assets/svg/dummyFrame 2497.svg";
import warningIcon from "assets/svg/warning.svg";
import approvedthumsupdull from "assets/svg/thumsup approved.svg"
import approvedthumsupsolid from "assets/svg/thumsup approvedsolid.svg";
import arrowDownScroll from "assets/svg/arrow-down-scroll.svg";
import recurrenceIcon from 'assets/svg/recurrenceIcon.svg'
import TimeIconGreen from "assets/svg/timeIcongreen.svg"
import Thumbsdowndull from "assets/svg/thumsup_rejected.svg"
import TimeIconRed from "assets/svg/timeIconred.svg";
// import tickIcon from "assets/svg/tick.svg";
import { colorByStatus } from "assets/WRColorSchema";
import axios from "axios";
import { getServiceUrl } from "service/api";
import RecurringModel from "../RecurringModel";
import { documentDownloadFromMDB } from "service/apiVariables";
import { StaffStatusLabelsWithoutBox } from "pages/Admin/workSchedule";
import RejectAccordian from "component/common/RejectAccordian/RejectAccordian";
import DisplayAgencyImage from "./DisplayAgencyImage";
import {Toast} from 'service/toast';
// import StarRating from "component/common/StarRating";
import StarIcon from "assets/svg/Star.svg";
import ReactStars from "react-rating-stars-component";
import closeButton from "assets/svg/closeButton.svg"
import ratingEditIcon from "assets/svg/rating-edit-icon.svg"
import checkIcon from "assets/svg/rating-tick.svg"
import ViewCredentials from "./ViewCredentials";

function WorkAssignment({
  setVisible,
  workAssign,
  setWorkAssign,
  getSingleWork,
  handleReload,
  deleteWork,
  cancelWork,
  approveStaff,
  rejectStaff,
  updateRatings,
  completeWorkClient,
  getFileFromMDB,
  showArchives,
  addWorkRatings
}) {
  const [edit, setEdit] = useState({});
  const [clientId, setClientId] = useState("");
  const [workID, setWorkID] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isFormLoder, setFormLoder] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [cancelId, setCancelId] = useState("");
  const [rejectreason,setRejectreason] = useState({});
  const [rejectt, setReject] = useState([]);
  const [recurrencePopup, setRecurrencePopup] = useState({status:false});
  const [credentialsPopup, setCredentialsPopup] = useState([]);
  const  [staffworkDataLength,setStaffWorkDataLength] = useState(0);
  const popupRef = useRef(null);
  const [clientNotesBox, setClientNotesBox] = useState(false)
  const [staffShiftDetails, setStaffShiftDetails] = useState(false);
  const RatingCount = 4;
  const Rating = Array(RatingCount).fill(null);
  const dropdownRef = useRef(null);
  const topRef = useRef(null);
  const bottomRef = useRef(null);
  const [feedbackRatingShow, setFeedbackRatingShow] = useState(false)
  const [feedbackData, setFeedbackData] = useState({});
  const [ratingData, setRatingData] = useState({});
  const [overallRatingData, setOverallRatingData] = useState(null);
  const [overallFeedbackData, setOverallFeedbackData] = useState("")
  const [loadingKey, setLoadingKey] = useState('')
  const [imageVisibility, setImageVisibility] = useState({});
  const [credentialsdownload, setCredentialsDownloads] = useState([])
  const [staffids, setStaffIds] = useState([]);
  const [workcompleted,setWorkCompleted] = useState(false);
  const [scrollToTop,setScrollToTop] = useState(false);

  const [editOverallRating, setEditOverallRating] = useState(false)
  const [editStaffRatingId, setEditStaffRatingId] = useState('');

  // Initial Staff Rating and Feedback
  useEffect(() => {
    const newFeedbackData = {};
    const newRatingData = {};
    edit?.workStaffAssignment?.staffWorkData?.forEach((staff) => {
      newFeedbackData[staff.staffId] = { feedback: staff?.staffReviews?.feedback };
      newRatingData[staff.staffId] = staff?.staffReviews?.rating;
    });
    setFeedbackData(newFeedbackData ? newFeedbackData : {});
    setRatingData(newRatingData ? newRatingData : {});
  }, [edit]);

  // Initial Overall Rating and Feedback
  useEffect(() => {
    setOverallRatingData(edit?.workStaffAssignment?.starRating ? edit?.workStaffAssignment?.starRating : null)
    setOverallFeedbackData(edit?.workStaffAssignment?.feedBack ? edit?.workStaffAssignment?.feedBack : "")
  }, [edit])

  // Update the feedbackData state when the client enters feedback in a textarea
  const handleFeedbackChange = (staffId, feedback) => {
    setFeedbackData((prevData) => ({
      ...prevData,
      [staffId]: { feedback },
    }));
  };

  const handleupdateRatings = async () => {

    let body = {
      agencyId: workAssign.agencyId,
      staffIds: staffids,
      clientId: clientId,
      jobFunctionId:edit?.jobFunctionId
    }

    await updateRatings(body)
      .then((data) => {
        setFormLoder(false);
        setWorkCompleted(false);
        setDefault();
        handleReload();
      })
      .catch(() => {
        setFormLoder(false);
      });
  }

  useEffect(()=>{
    if(staffids.length>0 && workcompleted){
    handleupdateRatings()}
  },[staffids,workcompleted])


  // Update the ratingData state when the client enters a rating
  const handleRatingChange = (staffId, rating) => {
    
    if (!isNaN(rating) && rating >= 0 && rating <= 5) {
      setRatingData((prevData) => ({
        ...prevData,
        [staffId]: parseFloat(rating),
      }));
    } else {
      setRatingData({});
    }
  };

  localStorage.setItem("workID", workID);

   // websocket implementation

 let workRequestData = useSelector((state)=> state.webSocketReducer.workRequest)

 useEffect(()=>{
  if(!!workRequestData){
    if (!!workAssign.id) {
      let workRequestId =  workRequestData?.id ? workRequestData?.id : workRequestData?._id
      if(workAssign.id === workRequestId){
        handleUpdateWorkRequest()
      }
    }
  }
  
 },[workRequestData])

 const handleUpdateWorkRequest = () => {
  setEdit(workRequestData)
 }

  const fetchFileDownloadURLById = async (id, filename='none.png', type="image/png") => {
    setLoading(true);
    
    if(filename.split(".").length>1){
        filename = filename.split(".")[0];
    }

    const link = document.createElement("a");
    link.target = "_blank";
    link.download = filename


    // await
    // fileDownloadFromMDB({ 'id': id })
    await axios
        .get(`${getServiceUrl(documentDownloadFromMDB.create.baseURL)}${documentDownloadFromMDB.create.api}${id}/`, {
            responseType: "blob",
            headers : {
                Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
            },
            params:{
              agencyId:workAssign.agencyId
            }
        })
        .then((res) => {

                link.href = URL.createObjectURL(
                new Blob([res.data], { type: type })
                );
                link.click();
            
            setLoading(false);
        })
        .catch((e) => setLoading(false));
};

  const fetchWRDetails = () => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let id = userData.userId ? userData.userId : userData.id;
    if (!!id) {
      setClientId(id);
      // setEdit({...edit,clientName:userData.clientName})
    }
    if (!!workAssign.id) {
      fetchWorkById(workAssign.id);
      setWorkID(workAssign.id);
    }
  }

  useEffect(() => {
    if(workAssign.value)
    fetchWRDetails();
  }, [workAssign]);

  const fetchWorkById = async (id) => {
    setLoading(true);
    const query = { id: id , agencyId:workAssign.agencyId };
    await getSingleWork(query)
      .then((data) => {
        setEdit(data);
        // setWorkList(modifyData.length ? modifyData : []);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  // set Default values
  const setDefault = () => {
    setEdit({});
    setWorkAssign(false);
    setFeedbackRatingShow(false)
    setEditOverallRating(false);
    setEditStaffRatingId('');
  };

  const getrejectAccordiandata = (data)=>{
       setRejectreason([data]);    
  }

  useEffect(()=>{
    
      if(rejectreason.length>0){
      handleRejectStaff(rejectreason[0].id)}
  },[rejectreason])



  // const handleDelete = async () => {
  //   let query = {
  //     workId: workID,
  //   };
  //   setFormLoder(true);
  //   await deleteWork(query)
  //     .then((data) => {
  //       setFormLoder(false);
  //       setDefault();
  //       handleReload();
  //     })
  //     .catch(() => {
  //       setFormLoder(false);
  //     });
  // };
  // handle confirmation for cancel work
  const handleAction = (action) => {
    if (action) cancelWorkClient();
    else setOpenModal(!openModal);
  };
  //handle work cancel by client
  const cancelWorkClient = async (id) => {
    setLoadingKey('cancel-work')
    let body = {
      workId: workID,
      clientId: clientId,
      agencyId: workAssign.agencyId,
      reason: "Client cancels the work request ",
    };
    setFormLoder(true);
    await cancelWork(body)
      .then((data) => {
        setFormLoder(false);
        setOpenModal(false);
        setDefault();
        setLoadingKey('')
        handleReload();
      })
      .catch(() => {
        setFormLoder(false);
        setLoadingKey('')
      });
  };

  const completeWorkByClient = async () => {

    setLoadingKey('approve-all-invoicing')
  
    let staffRatingData = edit?.workStaffAssignment?.staffWorkData?.map((staff) => ({
      feedback: feedbackData[staff.staffId]?.feedback || "",
      rating: ratingData[staff.staffId] || null,
      staffId: staff.staffId,
    }));
  
    staffRatingData.map((item) => {
      Object.keys(item).forEach(key => {
        if (item[key] === null) {
            delete item[key];
        }
      })
    })

    let Staffids= [];
    for(let i=0; i<edit?.workStaffAssignment?.staffWorkData?.length ; i++) {
      let indexid ;
      if (Object.keys(ratingData).includes(edit?.workStaffAssignment?.staffWorkData[i].staffId)){
         indexid = edit?.workStaffAssignment?.staffWorkData[i].staffId;
         if(ratingData[indexid] !== undefined){
        Staffids.push(edit?.workStaffAssignment?.staffWorkData[i].staffId)}
      }
    }
    setStaffIds(Staffids)

    await new Promise(resolve => setTimeout(resolve, 0));
    
    let body = {
      workId: workID,
      agencyId: workAssign.agencyId,
      staffRating: staffRatingData,
      starRating: overallRatingData,
      feedBack: overallFeedbackData
    };
    setFormLoder(true);
    await completeWorkClient(body)
      .then((data) => {
        setFormLoder(false);
        setWorkCompleted(true)
        setDefault();
        handleReload();
        setLoadingKey('')
      })
      .catch(() => {
        setFormLoder(false);
        setLoadingKey('')
      });
  };

 
  // handle single staff approve by agency
  const handleApproveStaff = async (id) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let agencyID = userData.agencyId ? userData.agencyId : "";
    setLoadingKey(`approve-staff-${id}`)
    let body = {
      workId: workID,
      staffId: id,
      agencyId:workAssign.agencyId,
    };
    setFormLoder(true);
    await approveStaff(body)
      .then((data) => {
        setFormLoder(false);
        fetchWorkById(workAssign.id);
        setLoadingKey('')
        // setDefault();
        // handleReload();
      })
      .catch(() => {
        setFormLoder(false);
        setLoadingKey('')
      });
  };

  // handle single staff reject by agency
  const handleRejectStaff = async (id) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let agencyID = userData.agencyId ? userData.agencyId : "";
    setLoadingKey(`reject-staff-${id}`);
    let body = {
      workId: workID,
      staffId: id,
      agencyId:workAssign.agencyId,
      rejectReasons: rejectreason[0].newchecked,
      additionalComments: rejectreason[0].texarea,
      contactEnabled: rejectreason[0].contact,
    };
    // setFormLoder(true);
    await rejectStaff(body)
      .then((data) => {
        // setFormLoder(false);
        // setDefault();
        // handleReload();
        fetchWorkById(workAssign.id);
        setLoadingKey('');
      })
      .catch(() => {
        setFormLoder(false);
        setLoadingKey('');
      });
  };

  // handle approve all staff
  // const handleApproveAll = () => {
  //   setFormLoder(true);
  //   let { workStaffAssignment } = edit;
  //   if (workStaffAssignment.staffWorkData) {
  //     workStaffAssignment.staffWorkData.forEach((item) => {
  //       handleApproveStaff(item.staffId);
  //     });
  //   }
  //   setFormLoder(false);
  // };
  const handleApproveAll = () => {
    setLoadingKey('approveall-work')
    setFormLoder(true);
    let loaderflag= 0;
    let { workStaffAssignment } = edit;
    if (workStaffAssignment.staffWorkData) {
      workStaffAssignment.staffWorkData.forEach((item,index) => {
      setTimeout(() => {
          handleApproveStaff(item.staffId);
          loaderflag=loaderflag+1;
      }, 500*index);
    });
    }
    if(loaderflag!==0){
       setLoadingKey('')
    }
    setWorkAssign(false);
    setFormLoder(false);
  };

  // handle approve all staff
  // const handleRejectAll = () => {
  //   setFormLoder(true);
  //   let { workStaffAssignment } = edit;

  //   if (workStaffAssignment.staffWorkData) {
  //     workStaffAssignment.staffWorkData.forEach((item) => {
  //       handleRejectStaff(item.staffId);
  //     });
  //   }
  //   setFormLoder(false);
  // };
  const handleRejectAll = () => {
    // setFormLoder(true);
    setLoadingKey('rejectall-work')
    let { workStaffAssignment } = edit;


    if(workStaffAssignment.hasOwnProperty("rejectedStaff")){
       
      for(let i=0;i<workStaffAssignment.staffWorkData.length;i++){
        let count=0;;
        for(let a=0;a<workStaffAssignment.rejectedStaff.length;a++){
          if(workStaffAssignment.staffWorkData[i].staffId!==workStaffAssignment.rejectedStaff[a].staffId)
          count++;
        }
        if(count==workStaffAssignment.rejectedStaff.length){
          Toast({type: 'error', message: `The rejection reason is not filled for staff ${workStaffAssignment.staffWorkData[i].staffName}`});
        }
      }
    }
    else{
      for(let i=0;i<workStaffAssignment.staffWorkData.length;i++){
        Toast({type: 'error', message: `The rejection reason is not filled for staff ${workStaffAssignment.staffWorkData[i].staffName}`});
      }
    }
    setLoadingKey('rejectall-work')
    // if (workStaffAssignment.staffWorkData) {
    //   workStaffAssignment.staffWorkData.forEach((item,index) => {
    //   setTimeout(() => {
    //       // handleRejectStaff(item.staffId);
    //   }, 500*index);
    // });
    // }
    // setFormLoder(false);
  };

  const isButtonDisabled = (formLoader, workStaffAssignment) => {
      return  !(workStaffAssignment &&  workStaffAssignment.staffWorkData &&  workStaffAssignment.staffWorkData.length > 0)

  };



  let { workStaffAssignment,id } = edit;
  
  useEffect(()=>{
    let rejectdata=[]
  for(let i=0;i<workStaffAssignment?.staffWorkData?.length;i++){
    let count=0;
    let rejectedreason = [];
    for(let a=0;a<workStaffAssignment?.rejectedStaff?.length;a++){
    if(workStaffAssignment?.staffWorkData[i].staffId===workStaffAssignment?.rejectedStaff[a].staffId){
      rejectedreason.push(workStaffAssignment.rejectedStaff[a])
      count++;
    }
    }
    if(count ==1){
      rejectdata.push({value:true, reject:rejectedreason[0]})
    }
    else {
      rejectdata.push({value:false,reject:""})
    }
  }
  setReject(rejectdata)
},[edit])


const handleCloseRecurring = () => {
  setRecurrencePopup((prev)=>{
    return{
      ...prev,
       status:false
    }
   })
}

const handleDropdownChange = () => {
  setStaffShiftDetails(!staffShiftDetails)

  dropdownRef.current.scrollIntoView({
    behavior: 'smooth', // Optional: Add smooth scrolling effect
    block: 'start', // Optional: Scroll to the start of the container
  });

}
    
     const Scroll = useRef(null);

      const handleKeyupandDown = (event) => {

        const scrollableElement = Scroll.current;

        if (event.keyCode === 38) {
          scrollableElement.scrollBy(0, -40)
        } 
        else if (event.keyCode === 40) {
          scrollableElement.scrollBy(0, 40)
        }
      };

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    setScrollToTop(true);
};

  const onclickScrollToTop = () => {
    topRef.current.scrollIntoView({behavior :"smooth"});
    setScrollToTop(false);
  }

const handleViewClick = (docId) => {
  setImageVisibility((prevState) => ({
    ...prevState,
    [docId]: !prevState[docId] || false,
  }));
};

const downloadCredentials = (credentialsdownload) => {

  const handleImageDownload = async (logo,agencyId) => {
    setLoading(true)
    let imageData;
    let fileId = logo.split('|')[0]
    await getFileFromMDB({agencyId: agencyId ? agencyId : ''},fileId)
      .then((response)=>{
        imageData = `data:${response.contentType};base64,${response?.fileContent.data}`
        setLoading(false)
        const anchor = document.createElement('a');
        anchor.href = imageData;
        anchor.download = response.title;
        anchor.style.display = 'none';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      })
      .catch((error) => {
        setLoading(false);
        console.log('Error fetching image:', error);
      });
  }

  if(credentialsdownload.length>0){
  for(let i=0;i<credentialsdownload.length;i++){
    handleImageDownload(credentialsdownload[i][0],credentialsdownload[i][1])
    if(i==credentialsdownload.length-1){
      setCredentialsDownloads([]);
    }
  }}
  else{
    Toast({type: 'error', message: `No document selected for download..`});
  }
 

};

const storingdownloadfile = (uploadDocLink,agencyId)=>{
  
  setCredentialsDownloads((prevCredentials) => {
    const isAlreadyPresent = prevCredentials.some(
      (item) =>
        Array.isArray(item) &&
        item.length === 2 &&
        item[0] === uploadDocLink &&
        item[1] === agencyId
    );

    if (isAlreadyPresent) {
      // If both values are already present and the checkbox is unchecked,
      return prevCredentials.filter(
        (item) =>
          !(
            Array.isArray(item) &&
            item.length === 2 &&
            item[0] === uploadDocLink &&
            item[1] === agencyId
          )
      );
    } else {
      // If both values are not already present or the checkbox is checked,
      const newCredentials = [...prevCredentials];
      newCredentials.push([uploadDocLink, agencyId]);
      return newCredentials;
    }
  });

}

const handleStaffNameClick = (index) => {
  const updatedPopups = credentialsPopup.map((popup, i) => {
    return i === index ? !popup : false;
  });
  setCredentialsPopup(updatedPopups);
};

const handleStaffNameHover = (index, isEntering) => {
  const updatedPopups = [...credentialsPopup];
  updatedPopups[index] = isEntering; // Set to true on mouseenter, false on mouseleave
  setCredentialsPopup(updatedPopups);
};

useEffect(()=>{
   if(!! workStaffAssignment &&  workStaffAssignment.staffWorkData.length>0){
    setCredentialsPopup(Array(workStaffAssignment.staffWorkData.length).fill(false));
    setStaffWorkDataLength(workStaffAssignment.staffWorkData.length);
   }
},[workStaffAssignment])

const handleClosePopups = () => {
  setCredentialsPopup(Array(staffworkDataLength).fill(false));
};

const saveRatings = async() => {

  setEditOverallRating(false);

  let staffRatingData = edit?.workStaffAssignment?.staffWorkData?.map((staff) => ({
    feedback: feedbackData[staff.staffId]?.feedback || "",
    rating: ratingData[staff.staffId] || null,
    staffId: staff.staffId,
  }))
  
  staffRatingData.map((item) => {
    Object.keys(item).forEach(key => {
      if (item[key] === null) {
          delete item[key];
      }
    })
  })
  
  let body = {
    workId: workID,
    agencyId: workAssign.agencyId,
    staffRating: staffRatingData,
    starRating: overallRatingData,
    feedBack: overallFeedbackData
  };

  await addWorkRatings(body)
      .then((data) => {
        setTimeout(() => fetchWRDetails(),200)
      })
      .catch((err) => {
        console.log(err)
      });
}

  
  return (
    <>
      <NormalModal
        toggle={setDefault}
        className={"modal-fill "}
        isOpen={workAssign.value}
        size="lg"
        onRefresh={fetchWRDetails}
        renderHeader={(e) => (
          <div className=" d-flex justify-content-between">
            <h6 className="font-weight-bold "> Work Request</h6>
            {/*<div className="d-flex ">
              {edit.status < 3 && edit.status !== 0 ? (
                <img
                  className=" ml-3 cursor-pointer"
                  src={editIcon}
                  onClick={() => {
                    setDefault();
                    setVisible({ value: true, id: workID });
                  }}
                  alt=""
                />
              ) : (
                ""
              )}
              <img
                className={`my-icons ml-3 ${
                  edit.status < 2 && edit.status !== 0
                    ? "cursor-pointer"
                    : "disabled"
                }`}
                src={`${
                  edit.status < 2 && edit.status !== 0
                    ? deleteIcon
                    : deleteIconDisable
                }`}
                onClick={() => {
                  handleDelete();
                }}
                alt=""
              />
            </div>*/}
          </div>
        )}
        bodyClass={`add-staff-box scrollable-content ${showArchives ? 'pointer-events-none':''}`}
        headerClass="border-0 pt-4 px-4 bg-violet"
      >
        {!isLoading ? 
          <>
            <div id="bottom" ref={topRef}></div>
            <div className='d-flex justify-content-end align-items-center sticky-header'>
           {/* <div className='wr-id-box'>
                    <span>WR Id -{" "}
                  {workStaffAssignment
                    ? 
                      workStaffAssignment.workId.slice(
                        workStaffAssignment.workId.length - 6,
                        workStaffAssignment.workId.length
                      )
                    :  edit._id
                    ? edit._id &&
                      edit._id.slice(edit._id.length - 6, edit._id.length)
                    : ""} </span>
           </div> */}
            <div class="frame-24366">
              <div class="frame-24944">
               <div class="wr h2-sb">WR</div>
              </div>
              <div class="c-60693 num-sb"> {workStaffAssignment
                    ? 
                      workStaffAssignment.workId.slice(
                        workStaffAssignment.workId.length - 6,
                        workStaffAssignment.workId.length
                      )
                    :  edit._id
                    ? edit._id &&
                      edit._id.slice(edit._id.length - 6, edit._id.length)
                    : ""}</div>
            </div>


           <div className='d-flex justify-content-center align-items-center wr-status-name'>
                    <div className={"wr-color-status-box"} style={{"background":edit.status !==2 ?(colorByStatus?.[edit.status]?.color) : edit.status === 2 && edit.workStaffAssignment.staffWorkData.length !== 0 ?(colorByStatus?.[2.1]?.color):(colorByStatus?.[2]?.color)}}> </div>
                    <div className='wr-color-status-name h3-r' style={{"color":edit.status !==2 ?(colorByStatus?.[edit.status]?.color) : edit.status === 2 && edit.workStaffAssignment.staffWorkData.length !== 0 ?(colorByStatus?.[2.1]?.color):(colorByStatus?.[2]?.color)}}> {edit.status === 2 && edit.workStaffAssignment.staffWorkData.length !== 0 ? 'STAFF HAS BEEN ASSIGNED. PLEASE CONFIRM' : colorByStatus?.[edit.status]?.status}</div>
           </div>

           <div class="frame-2317">
           { edit.status >= 3 || edit.status ===0 || (edit.status === 2 && edit.workStaffAssignment.staffWorkData.length !== 0)?
           <div className="no-drop r wr-edit-icon" message='Edit Work Request'>
           <img src={disableeditIcon} id="newWrEditCancel"
            className='iconsax-linear-messageedit' />
           </div>

           :
           <div className="no-drop r wr-edit-icon" message='Edit Work Request'>
           <img src={editIcon} id={ edit.status >= 3 || 
                      edit.status ===0 || 
                      (edit.status === 2 && edit.workStaffAssignment.staffWorkData.length !== 0)?"newWrEditCancel":"newWrEdit"}
                    className='iconsax-linear-messageedit' 
                    onClick={(e) => {
                        if (edit.status !== 0)
                          setWorkAssign({ value: false, id: workStaffAssignment.workId });
                          setVisible({ value: true, id: workStaffAssignment.workId,agencyId:workAssign.agencyId });
                    }}/>
            </div>
                  }

        {/* {edit.status === 0 || edit.status > 4 ?
        <div class="frame-2419" message='Cancel Work Request' id="newWrEditCancel">
        <img src={disablecloseIconbg} className='vector4'/>
        <img src={disablecloseIcon} className='iconsax-linear-closesquare'/> 
        </div>
        
        :<div class="frame-2419" message='Cancel Work Request' id={edit.status === 0 || edit.status > 4 ? "newWrEditCancel":"newWrEdit"} 
                 onClick={() => {
                    setCancelId(id);
                    setOpenModal(true); 
                  }}>
         <img src={closeRedbg} className='vector4'/>
         <img src={closeIcon} className='iconsax-linear-closesquare'/> 
        </div>
       } */}
      </div>

           {/* WR Details end */}
      <div className='sticky-scroll-btn cursor-pointer' tabIndex={0} id='scroll-down-button' onClick={scrollToTop == true? onclickScrollToTop :  scrollToBottom}>
      <img tabIndex={1} src={arrowDownScroll} alt="arrow-down" style={{ transform: `rotate(${scrollToTop ? 180 : 0}deg)` }} />
      </div>
     </div>
     
     <div className='wr-details-container' tabIndex={0} ref={Scroll} onKeyDown={handleKeyupandDown}>

      {/* Sticky Scroll to Bottom Button*/}
      
      {/*Start and end details here...*/}
     {/* <div class="frame-1833">
  <div class="frame-1831">
    <div class="frame-1089">
      <div class="ellipse-74"></div>
         <img src={GreenflagIcon} className='material-symbols-location-onStart' />
    </div>

    <div class="frame-1829">
      <div class="starts">Starts</div>

      <div class="frame-1828">
        <div class="frame-1827">
        <img src={CalendarBlack} className='uil-calender' />

          <div class="_27-02-2023">{Moment(edit.startDate).format(localStorage.getItem('dateFormat'))} </div>
        </div>

        <div class="frame-1826">
        <img src={TimeIcon} className='ic-sharp-access-time' />

          <div class="_03-15-00-pm">{Moment(edit.startDate).format('hh:mm:ss a')}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="frame-18332">
    <div class="frame-1090">
      <div class="ellipse-742"></div>
      <img src={RedflagIcon} className='frame-2439' />
      
    </div>

    <div class="frame-1829">
      <div class="ends">Ends</div>

      <div class="frame-1828">
        <div class="frame-1827">
        <img src={CalendarBlack} className='uil-calender2' />
         

          <div class="_27-02-2023">{Moment(edit.endDate).format(localStorage.getItem('dateFormat'))} </div>
        </div>

        <div class="frame-1826">
        <img src={TimeIcon} className='ic-sharp-access-time2' />
          <div class="_03-15-00-pm">{Moment(edit.endDate).format('hh:mm:ss a')}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="line-77"></div>
</div> */}

  {/* Agency Details */}

  <div class="frame-24755">
  <div class="frame-24911">
  <div class="frame-24199">
    <div class="frame-24799">
      <div class="frame-24811">
        <div class="frame-23277">
          {edit.agencyLogo ? 
        <DisplayAgencyImage agencyId={edit.agencyId} imageId={edit.agencyLogo}/>
        : <img class="image-11" src={dummyFrame} />
        }
        </div>
        <div class="frame-23266">
          <div class="frame-22000">
            <div class="agency-name">Agency Name</div>
          </div>
          <div class="frame-21855 wr-agency-name" message={edit.agencyName}>
            <div class="willow-staffing-agency h2-sb">{edit.agencyName}</div>
          </div>
        </div>
      </div>
    </div>
    <div className= {edit?.agencyRating !== undefined && edit?.agencyRating !==null ? "frame-24822":"frame-no-rating"}>
      {edit?.agencyRating !== undefined && edit?.agencyRating !== null ? 
      <div class="frame-22455">
      <svg
        class="vector"
        width="18"
        height="16"
        viewBox="0 0 34 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.34827 30.5655C7.69154 30.9617 6.88132 30.3738 7.05417 29.6266L9.14682 20.5799C9.21928 20.2666 9.11266 19.939 8.86972 19.7284L1.85024 13.6427C1.27079 13.1403 1.57977 12.1883 2.3438 12.122L11.6219 11.3166C11.9424 11.2888 12.2212 11.086 12.3465 10.7897L15.9486 2.26685C16.2473 1.56002 17.249 1.56002 17.5477 2.26685L21.1498 10.7897C21.2751 11.086 21.5539 11.2888 21.8744 11.3166L31.1525 12.122C31.9165 12.1883 32.2255 13.1403 31.6461 13.6427L24.6266 19.7284C24.3836 19.939 24.277 20.2666 24.3495 20.5799L26.4421 29.6266C26.615 30.3738 25.8048 30.9617 25.148 30.5655L17.1965 25.7688C16.9208 25.6025 16.5755 25.6025 16.2998 25.7688L8.34827 30.5655Z"
          fill="#FFD700"
        />
      </svg>
    </div>
    : 
      <div class="frame-22455">
      <svg
        class="vector"
        width="18"
        height="16"
        viewBox="0 0 34 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.34827 30.5655C7.69154 30.9617 6.88132 30.3738 7.05417 29.6266L9.14682 20.5799C9.21928 20.2666 9.11266 19.939 8.86972 19.7284L1.85024 13.6427C1.27079 13.1403 1.57977 12.1883 2.3438 12.122L11.6219 11.3166C11.9424 11.2888 12.2212 11.086 12.3465 10.7897L15.9486 2.26685C16.2473 1.56002 17.249 1.56002 17.5477 2.26685L21.1498 10.7897C21.2751 11.086 21.5539 11.2888 21.8744 11.3166L31.1525 12.122C31.9165 12.1883 32.2255 13.1403 31.6461 13.6427L24.6266 19.7284C24.3836 19.939 24.277 20.2666 24.3495 20.5799L26.4421 29.6266C26.615 30.3738 25.8048 30.9617 25.148 30.5655L17.1965 25.7688C16.9208 25.6025 16.5755 25.6025 16.2998 25.7688L8.34827 30.5655Z"
          fill="#d0d0d0"
        />
      </svg>
      </div>
    }
      <div class="_4-3">{edit?.agencyRating !== undefined && edit?.agencyRating !== null ? (edit.agencyRating).toFixed(1) : 'No Rating'}</div>
    </div>
  </div>
  <div class="frame-24900">
    <div class="frame-10800">
      <div class="frame-24966">
        <div class="category">Category</div>
      </div>
      <div class="frame-21711">
        <div class="nurse">{edit.jobCategory}</div>
      </div>
    </div>
    <div class="frame-10799">
      <div class="frame-24955">
        <div class="job-name">Job Name</div>
      </div>
      <div class="frame-217122">
        <div className={!edit.hasOwnProperty('jobName') || edit.jobName == null ? "select-jobname": "certified-nursing-assistant-cna"}>
        {!edit.hasOwnProperty('jobName') || edit.jobName == null ? <> Select job name <img style={{width:"23px"}} src={warningIcon}/></> : edit.jobName}
        </div>
      </div>
    </div>
  </div>
</div>

{/* recurrence*/}

<div class="frame-24200">
  <div class="frame-24899">
    <div class="frame-18311">
      <div class="frame-10899">
        <div class="frame-18300">
          <div class="frame-18288">
            <div class="frame-18277">
              <div class="starts">Starts</div>
            </div>
          </div>
        </div>
        <div class="frame-108922">
          <div class="ellipse-744"></div>
           <img src={GreenflagIcon} className='material-symbols-location-onStart' />
        </div>
      </div>
      <div class="frame-18299">
        <div class="frame-18288">
          <div class="frame-18277">
          <img src={CalendarBlack} className='uil-calender' />

            <div class="_27-02-20233">{Moment(edit.startDate).format(localStorage.getItem('dateFormat'))}</div>
          </div>
          <div class="frame-18266">
          <img src={TimeIconGreen} className='uil-calender' />

            <div class="_03-15-00-pm">{Moment(edit.startDate).format('hh:mm:ss a')}</div>
          </div>
        </div>
      </div>
      <div class="line-777"></div>
    </div>
    <div class="frame-18811">
      <div class="frame-108933">
        <div class="frame-18300">
          <div class="frame-182822">
            <div class="frame-182722">
              <div class="ends">Ends</div>
            </div>
          </div>
        </div>
        <div class="frame-10900">
          <div class="ellipse-7422"></div>
          <img src={RedflagIcon} className='frame-2439' />
        </div>
      </div>
      <div class="frame-182922">
        <div class="frame-18288">
          <div class="frame-18277">
          <img src={CalendarBlack} className='uil-calender' />
            <div class="_27-02-20233">{Moment(edit.endDate).format(localStorage.getItem('dateFormat'))}</div>
          </div>
          <div class="frame-18266">
          <img src={TimeIconRed} className='' />
            <div class="_03-15-00-pm2">{Moment(edit.endDate).format('hh:mm:ss a')}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className='rate-frequency-container'>
    <div className='rate-frequency-text'>
      {(edit.rateFrequency == 1) ? "Per Hour" :
        edit.rateFrequency == 2 ? "Per Day" :
        edit.rateFrequency == 3 ? "Per Week" :
        "Per Month"}
    </div>
  </div>
  <div class="frame-18799">
    <div class="normall">{edit.priority=="normal"?"Normal":edit.priority}</div>
  </div>
  <div class="frame-18800">
    <div class="weekday">{
                            edit.wrType == 1 ? "Weekday" :
                            edit.wrType == 2 ? "Weekend" :
                            edit.wrType == 3 ? "Holiday" :
                            edit.wrType == 4 ? "Contract" : "-"
                        }</div>
  </div>
  {edit?.repeat === true ?
  <div  onClick={()=> setRecurrencePopup((prev)=>{
                return{
                  ...prev,
                  status:true,
                }
              })}class="frame-11566">
    <div class="recurrence">Recurrence</div>
    <img src={recurrenceIcon} width={20} height={20}/>
  </div>
   : null
   }
         {!!workAssign.id && edit.masterWorkId ?
        <div className="position-absolute" style={{minWidth:'100px', top:'0.5125rem', right:'2px'}} > 
        <a href={`${window.location.pathname}?workId=${edit.masterWorkId}&agencyId=${edit.agencyId}`} target="_blank">
          <div className="wr-parent-id">Master WR {edit.masterWorkId.substr(edit.masterWorkId.length - 6)} </div>
          </a>
        </div>
      : null}
</div>
</div>
  {/* Recurrence  */}
  <RecurringModel
      show={recurrencePopup.status}
      handleClose={handleCloseRecurring}
      repeat={edit?.repeat}
      repeatData={edit?.repeatData}
      disabled={true}
      viewWr={true}
      /> 
  {/* <div class="frame-2362">
  <div class="frame-2327">
    <img class="image-1" src={dummyFrame} />
  </div>

  <div class="frame-2326">
    <div class="frame-2200">
      <div class="agency-name">Agency Name</div>
    </div>

    <div class="frame-2185">
      <div class="willow-staffing-agency">{edit.agencyName}</div>
    </div>
  </div>

  <div class="frame-2245">
    <div class="frame-2244">
      <div class="_4-3-5">
        <span
          ><span class="_4-3-5-span">4.3 </span
          ><span class="_4-3-5-span2">/ 5</span></span
        >
      </div>
    </div>

    <div class="frame-22452">
      {Rating.map((_,index)=>(
      <svg
        key={index}
        class="vector"
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.24746 18L5.78694 11.3447L0.623779 6.86842L7.44483 6.27632L10.0975 0L12.7501 6.27632L19.5711 6.86842L14.408 11.3447L15.9475 18L10.0975 14.4711L4.24746 18Z"
          fill="#FFD700"
        />
      </svg>
       ))}
       <svg
        class="vector"
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
         d="M4.7507 17.0162L6.03793 11.4515L6.1022 11.1736L5.88672 10.9868L1.56908 7.24356L7.27529 6.74823L7.55956 6.72355L7.67064 6.46072L9.88695 1.21676L12.1033 6.46072L12.2144 6.72355L12.4986 6.74823L18.2048 7.24356L13.8872 10.9868L13.6717 11.1736L13.736 11.4515L15.0232 17.0162L10.1316 14.0655L9.88695 13.9179L9.64228 14.0655L4.7507 17.0162Z"
         stroke="#033C28"
         stroke-width="0.947368"
        />
      </svg>
      
    </div>
  </div>
</div> */}

{/* WR TYPE Details */}

{/* <div class="frame-2417">
  <div class="frame-2418">
  
    <div class="frame-2177">
      <div class="frame-1080">
        <div class="category">Category</div>

        <div class="frame-2171">
          <div class="nurse">{edit.jobCategory}</div>
        </div>
      </div>

      <div class="frame-1079">
        <div class="job-name">Job Name</div>

        <div class="frame-2171">
          <div class="certified-nursing-assistant-cna">
          {edit.jobName}
          </div>
        </div>
      </div>
    </div>

    <div class="frame-2176">
      <div class="frame-1081">
        <div class="engagement">Engagement</div>

        <div class="frame-21712">
          <div class="contract">
          {
           edit.engagementType == 1 ? "Contract":
            edit.engagementType == 2 ? "Regular": "-"
           }
          </div>
        </div>
      </div>

      <div class="frame-1079">
        <div class="priority">Priority</div>

        <div class="frame-2172">
          <div class="normal">{edit.priority}</div>
        </div>
      </div>

      <div class="frame-1080">
        <div class="type">Type</div>

        <div class="frame-21712">
          <div class="weekday">
                     {
                            edit.wrType == 1 ? "Weekday" :
                            edit.wrType == 2 ? "Weekend" :
                            edit.wrType == 3 ? "Holiday" :
                            edit.wrType == 4 ? "Contract" : "-"
                        }
          </div>
        </div>
      </div>
    </div>
  </div>
</div> */}

   {/* Staff Details*/}
   {!!workStaffAssignment && !!workStaffAssignment.staffWorkData&&            
         <div class="frame-2479">
  <div class="frame-2324">
    {/* <div class="frame-2200">
      <div class="staff-assignment-details">Staff Assignment Details</div>
    </div>

    <div class="frame-2201">
      <div class="frame-1878">
        <div class="group-1148">
          <div class="ellipse-75"></div>

          <div class="_1">{edit.staffNeeded}</div>
        </div>

        <div class="requested">Requested</div>
      </div>

      <div class="frame-1879">
        <div class="assigned">Assigned</div>

        <div class="group-1148">
          <div class="ellipse-752"></div>

          <div class="_12">{edit.staffAccepted ? edit.staffAccepted : 0}</div>
        </div>
      </div>
    </div> */}
    <div class="frame-24855">
  <div class="frame-22000">
    <div class="staff-assignment">Staff Assignment</div>
  </div>
  <div class="frame-22022">
    <div class="frame-18788">
      <div class="group-11488">
        <div class="ellipse-75"></div>
        <div class="_1">{edit.staffNeeded}</div>
      </div>
      <div class="requested s2-r">Requested</div>
    </div>
    <div class="frame-187999">
      <div class="assigned s2-r">Assigned</div>
      <div class="group-11488">
        <div class="ellipse-752"></div>
        <div class="_12">{edit.staffAccepted ? edit.staffAccepted : 0}</div>
      </div>
    </div>
  </div>
</div>
    <div class="frame-24877">
  <div class="engagement-type s3-r ">Engagement Type:</div>
  <div class="frame-24866">
    <div class="contract s3-r">{
           edit.engagementType == 1 ? "Contract":
            edit.engagementType == 2 ? "Regular": "-"
           }</div>
  </div>
</div>

  </div>

   {!!workStaffAssignment && !!workStaffAssignment.staffWorkData&&
      <>
        <table className="w-80 mb-3 col-12 center-table" ref={dropdownRef}>
                <thead>
                  <th className="fs-13 s2-m">Name</th>
                  {/* <td>ID</td> */}
                  {/* <td>Location</td> */}
                  {/* <th className="fs-13 setRating s2-m">Rating</th> */}
                  <th className="fs-13 setstatusheader s2-m">Staff Status</th>
                  <th colSpan="2" className="fs-13 setaction s2-m">Action</th>
                </thead>
                <tbody>
                  {!!workStaffAssignment &&
                  // !!workStaffAssignment.staffWorkData ? (
                  workStaffAssignment.staffWorkData.length > 0 ?  (
                    <>
                      {workStaffAssignment.staffWorkData.map((item, index) => (
                        <tr className="settablerow">
                          <td className="fs-12 setstaffname" onMouseLeave={() => handleStaffNameHover(index, false)}>
                           <p onMouseEnter={()=> handleStaffNameHover(index,true)} onClick={() => { handleStaffNameClick(index)}} className="staffnamelink">{item.staffName.length > 20 ? item.staffName.substring(0, 20) + "..." : item.staffName}</p>
                                {/* credentials starts here..  */}
      {credentialsPopup[index] &&
      <div class="frame-23611">
        <div
           style={{marginLeft:"17rem", marginTop:"-0.2rem"}}
            onClick={handleClosePopups}
            aria-label="close-button"
            className="cursor-pointer"
          >
            <img src={closeButton} alt="close-buton" />
          </div>
      <div class="frame-23555">
        <div class="frame-23488">
          <div class="connor-james-credentials">{item.staffName.length > 12 ? item.staffName.substring(0, 12) + "..." : item.staffName} Credentials</div>
        </div>
        <div class="frame-23544">
          <div class="frame-23522">
            <div class="frame-23499">
              <div class="documents">Documents</div>
            </div>
            {item.credentials?.map((x)=>{

              return(
              <div class="frame-23511">
              <input onChange={()=>storingdownloadfile(x.uploadDocLink,edit.agencyId)} style={{cursor:"pointer"}} type="checkbox" id="downloadCheckbox"/>
    
              <div class="health-doc">{x.docName.length > 10 ? x.docName.substring(0, 10) + "..." : x.docName}</div>
              {/* <ViewCredentials key={x.docId} docId={x.docId} imageId={x.uploadDocLink} agencyId={edit.agencyId? edit.agencyId:""} /> */}
              <div key={x.docId}>
          <ViewCredentials
            docId={x.docId}
            imageId={x.uploadDocLink}
            agencyId={edit.agencyId}
            isVisible={imageVisibility[x.docId] || false}
            onViewClick={() => handleViewClick(x.docId)}
          />
          {imageVisibility[x.docId] && (
            <div style={{position:"absolute", marginTop:"-18rem",marginLeft:"-4.5rem",zIndex:"5"}}>
              <div
            onClick={()=>handleViewClick(x.docId)}
            aria-label="close-button"
            className="cursor-pointer view-close-button"
          >
            <img src={closeButton} alt="close-buton" />
          </div>
            <DisplayCredentialsImage
              docId={x.docId}
              imageId={x.uploadDocLink}
              agencyId={edit.agencyId}
            />
            </div>
          )}
        </div>
            </div>
            )
            })}
            
          </div>
          <div class="frame-235422">
            <div class="frame-23499">
              <div class="expiry-date">Expiry Date</div>
            </div>
            {item.credentials?.map((x)=>{
              return (
                <div class="frame-235222">
                <div class="_02-09-2023">{Moment(x.expireDate).format(localStorage.getItem('dateFormat'))}</div>
              </div>
              )
            })}
          </div>
        </div>
        {item.credentials?.length>0?"":<p class="health-doc">No Documents Found</p>}
        <div onClick={()=>downloadCredentials(credentialsdownload)} class="frame-248666">
          <div class="download">Download</div>
        </div>
      </div>
      <img style={{position:"absolute",top:"-1rem",marginLeft:"-17rem"}} src={greyTriangle}/>
    </div>
    
      
      }

     {/* credentials ends here.. */}
                            </td>
                          {/* <td className="fs-12">{item.staffId}</td> */}
                          {/* <td className="fs-12">{item.location}</td> */}
                          <td className="fs-12 setratingdata">
                          <p>
                            {item.rating !== null && item.rating !== undefined ? (
                              <>
                                <img src={StarIcon} style={{ verticalAlign: 'baseline' }} />
                                <span style={{ verticalAlign: 'baseline' }}>
                                  {item.rating.toFixed(1)}
                                </span>
                              </>
                            ) : (
                              "No Rating"
                            )}
                          </p>
                          </td>
                          <td className="fs-12 setstatus">
                            <p className="fs-12 parasetstatus" >
                              {" "}
                              {StaffStatusLabelsWithoutBox(item.status,edit.status,item.clockIn, item.pauseResumeData, item.clockOut ,edit?.cancelByWork
                                )}
                            </p>
                          </td>
                          {(edit.status === 0 || edit.cancelByWork)? (
                            <td className="fs-12">
                             <p className="setcanceledstatus"> Work Canceled </p>
                            </td>
                          ) : (
                            <>
                              {item.status === 3 ? (
                                <>
                                  <td
                                    className="text-success text-decoration-underline fs-10 w-15 cursor-pointer approvestatus"
                                    onClick={() =>
                                      handleApproveStaff(item.staffId)
                                    }
                                  >
                                   <p className="setapprovedd"> APPROVE{" "} <img style={{verticalAlign:"text-bottom"}} src={approvedthumsupdull}/> { loadingKey === `approve-staff-${item.staffId}` ? <CustomLoader/> : null} </p>
                                  </td>
                                </>
                              ) : (
                                <td className="text-success fs-10 w-15 no-drop approvestatuss">
                                  {/* Approved{" "} */}
                                 <p className="text-muted setapproved" > {item.status > 3 ? <>APPROVED <img style={{verticalAlign:"text-bottom"}} src={approvedthumsupsolid}/></> : <div> <span>APPROVE </span>  <img style={{verticalAlign:"text-bottom"}} src={approvedthumsupdull}/></div>  } </p>
                                </td>
                              )}
                              {item.status === 3 ? (
                                <td
                                  className="text-danger fs-10 w-15 cursor-pointer"
                                  // className="text-danger fs-12"
                                  // onClick={() => handleRejectStaff(item.staffId)}
                                  // onClick={() =>
                                  //   handleRejectStaff(item.staffId)
                                  // }
                                  
                                >
                                  {/* REJECT{" "}
                                  {show && <p>My name</p>} */}
                                  <RejectAccordian key={item.id} id={item.staffId} onSubmit={getrejectAccordiandata} data={rejectt.length>0 && rejectt[index].value==true ? rejectt[index].reject:""} />
                                </td>
                              ) : (
                                <td
                                  // className="text-danger fs-12 cursor-pointer"
                                  className="text-danger fs-10 w-15 no-drop setreject"
                                  // onClick={() =>
                                  //   handleRejectStaff(item.staffId)
                                  // }
                                >
                                 <p className="setreject text-muted" style={{marginTop:"0.3rem"}}> REJECT{" "} <img style={{verticalAlign:"text-bottom" , marginBottom:"-5px"}} src={Thumbsdowndull}/> </p>
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="5" className="border-0">
                      <p style={{position: "relative", right: edit.status === 1 ? "32px" : edit.status === 0 ? "50px" : "42px"}} className="text-center fs-12">
                          {edit.status === 0
                            ? "Work Canceled"
                            : edit.status === 1
                            ? "Need to accept by agency"
                            : "No staff assigned"}
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
         </>
       }
      {/*Staff details end here...*/}

    </div>}

      {/* Staff shift details */}

      <div class="frame-2325">
  {!staffShiftDetails&& <>
    {edit.status >= 3  || (edit.status === 0 && edit.workStaffAssignment?.staffWorkData.filter(staffWD=>{
              if(!!staffWD.clockIn) return true;
              else return false;
            }).length>0) ? (
              <>
                 {edit.status != 10 ? 
                 <div class="staff-shift-details">Staff Timesheet Details</div>
                : null
                }
              </>
                ):(
                  ""
                )}
     {!staffShiftDetails&& <>
        {edit.status >= 3  || (edit.status === 0 && edit.workStaffAssignment?.staffWorkData.filter(staffWD=>{
              if(!!staffWD.clockIn) return true;
              else return false;
            }).length>0) ? (
              <>
                 {edit.status != 10 ? 
                <div class="frame-2314">
                <div class="line-76"></div>
        
                 <div class="ellipse-202"></div>
                 <img class="iconsax-linear-arrowdown-2" style={{cursor:"pointer"}} src={arrowDown2} onClick={()=> {
                  setStaffShiftDetails(!staffShiftDetails);
                  setFeedbackRatingShow(false);
                }}/>
               </div>
                : null
                }
              </>
                ):(
                  ""
                )}
                </>
        }
       </>
       }
  { staffShiftDetails &&
    <>
  {edit.status >= 3  || (edit.status === 0 && edit.workStaffAssignment?.staffWorkData.filter(staffWD=>{
              if(!!staffWD.clockIn) return true;
              else return false;
            }).length>0) ? (
              <>
                 {edit.status != 10 ? 
                 <StaffDetails
                 data={edit.workStaffAssignment}
                 edit={edit}
                 status={edit.status}
                 locationData={edit.location}
                 startDate={edit.startDate}
                 endDate={edit.endDate}
                 onFeedbackChange={handleFeedbackChange}
                 onRatingChange={handleRatingChange}
                 ratingData={ratingData}
                 feedbackData={feedbackData}
                 wrData={edit}
                 editStaffRatingId={editStaffRatingId}
                 setEditStaffRatingId={setEditStaffRatingId}
                 saveRatings={saveRatings}
               />
                : null
                }
              </>
                ):(
                  ""
                )}
                {/* Individual Staff Rating and Feedback goes here */}
        </>
      }
   {staffShiftDetails&& <>
    {edit.status >= 3  || (edit.status === 0 && edit.workStaffAssignment?.staffWorkData.filter(staffWD=>{
              if(!!staffWD.clockIn) return true;
              else return false;
            }).length>0) ? (
              <>
                 {edit.status != 10 ? 
                 <div class="frame-2314-2" >
                 <div class="line-76"></div>
         
                  <div class="ellipse-202"></div>
                  <img class="iconsax-linear-arrowdown-2" style={{cursor:"pointer"}} src={arrowUp2} onClick={()=>handleDropdownChange()}/>
                </div>
                : null
                }
              </>
                ):(
                  ""
                )}
       </>
       
       }
</div>




            {/* staff shift details ends here.*/}


            {/* client Notes */}
            <div className='client-notes-container mb-5'> 
                             
              <div className="rectangle-325">
  
     <div class="frame-2344">
    <div class="frame-2343">
    <div class="frame-2333">
      <div style={{width:"1rem", height:"1rem", marginLeft:"0.4rem"}}>
      <svg
        class="material-symbols-location-on"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 7.5C7.84375 7.5 8.13813 7.3775 8.38313 7.1325C8.62771 6.88792 8.75 6.59375 8.75 6.25C8.75 5.90625 8.62771 5.61187 8.38313 5.36687C8.13813 5.12229 7.84375 5 7.5 5C7.15625 5 6.86208 5.12229 6.6175 5.36687C6.3725 5.61187 6.25 5.90625 6.25 6.25C6.25 6.59375 6.3725 6.88792 6.6175 7.1325C6.86208 7.3775 7.15625 7.5 7.5 7.5ZM7.5 13.75C5.82292 12.3229 4.57042 10.9973 3.7425 9.77313C2.91417 8.54938 2.5 7.41667 2.5 6.375C2.5 4.8125 3.00271 3.56771 4.00813 2.64062C5.01313 1.71354 6.17708 1.25 7.5 1.25C8.82292 1.25 9.98687 1.71354 10.9919 2.64062C11.9973 3.56771 12.5 4.8125 12.5 6.375C12.5 7.41667 12.086 8.54938 11.2581 9.77313C10.4298 10.9973 9.17708 12.3229 7.5 13.75Z"
          fill="#B4B9B7"
        />
      </svg>
      </div>
      <div
        class="willow-staffing-agency-old-route-119-highway-north-indiana-pa-usa"
      >
        {edit.location}
      </div>
    </div>
      <div class="frame-2342">
        <div class="frame-2340">
          <div class="frame-2339">
            <div class="client-notes">Client Notes</div>
          </div>
        </div>
  
        <div class="frame-2341">
          <div class="frame-23402">
            <div class="please-refer-the-staff-with-proper-experience">
            {edit.notes ? edit.notes : "N/A"}
            </div>
          </div>
        </div>
      </div>
       
  
       { clientNotesBox && <>
  
  <div class="frame-2342">
  <div class="frame-2340">
    <div class="frame-2339">
      <div class="client-notes">Attached Documents</div>
    </div>
  </div>
  
  <div class="frame-2341">
    <div class="frame-23402">
      <div class="please-refer-the-staff-with-proper-experience">
      {!edit.documents || edit.documents.length ===0 ? <label>  No Attachments</label> : null}
                        {/* {edit.documents?.map((doc,i)=><a className="fs-16 document-links" href={doc.split("|").length>1 && doc.split("|")[1]} target="_blank"> {doc.split("|").length>1 && doc.split("|")[0]} ({i+1})</a> )} */}
                        {edit.documents?.length===0?" ":
                            edit.documents?.map((doc,i)=><a 
                                type='button'
                                className="fs-13 document-links" 
                                // href={doc.split("|").length>1 && doc.split("|")[0]} 
                                onClick={()=>{
                                        if(doc.split("|").length>1)
                                            fetchFileDownloadURLById(doc.split("|")[0], doc.split("|")[1], doc.split("|")[2]);
                                    } 
				 }>
                                  {doc.split("|").length>1 &&(
                                    doc.split("|")[1].length>35 ? '...'+doc.split("|")[1].substring(doc.split("|")[1].length-35) : doc.split("|")[1] 
                                  )} ({i+1})
                            </a> )}                       
      </div>
    </div>
  </div>
  </div>
   
  <div class="frame-2342">
  <div class="frame-2340">
    <div class="frame-2339">
      <div class="client-notes">Work Request Title</div>
    </div>
  </div>
  
  <div class="frame-2341">
    <div class="frame-23402">
      <div class="please-refer-the-staff-with-proper-experience">
      {edit.workRequest?edit.workRequest:"N/A"}
      </div>
    </div>
  </div>
  </div>

  <div class="frame-2342">
  <div class="frame-2340">
    <div class="frame-2339">
      <div class="client-notes">Created Date</div>
    </div>
  </div>
  
  <div class="frame-2341">
    <div class="frame-23402">
      <div class="please-refer-the-staff-with-proper-experience">
      {edit.createdAt?(Moment(edit.createdAt).format(`${localStorage.getItem('dateFormat')}`))+' - '+ (Moment(edit.createdAt).format("hh:mm:ss a")):"N/A"}
      </div>
    </div>
  </div>
  </div>
  
  </>}
  
  
  
    </div>
  </div>
  
  
                             <div className='d-flex justify-content-between align-items-center' style={{minHeight:'2rem'}}>
                              <div className='additional-notes-title ml-3'>Additional Details </div>
                              <div className='shrink-expand-title cursor-pointer' onClick={()=> setClientNotesBox(!clientNotesBox)}>         
                               {clientNotesBox && <>Shrink <img src={upArrowIcon}/> </>}  
                               {!clientNotesBox && <>More <img src={downArrowIcon}/></>}  
                              </div>
                              </div>
                             </div>
                  </div>

        {/* Staff Rating and Feedback Feedback - Edit Mode */}

        {edit?.status ===5 &&
          <div style={{position: "relative", bottom: "30px"}} class="frame-2325">
          {!feedbackRatingShow && 
            <>
              <>
                <div style={{position: "relative", top: !feedbackRatingShow && "15px"}} class="rating-details">Staff Rating</div>
              </>
              {!feedbackRatingShow&& <>
              <>
                <div class="frame-2314">
                <div class="line-76"></div>
        
                 <div class="ellipse-202"></div>
                 <img class="iconsax-linear-arrowdown-2" style={{cursor:"pointer"}} src={arrowDown2} onClick={()=> {
                  setFeedbackRatingShow(!feedbackRatingShow);
                  setStaffShiftDetails(false);
                }}/>
               </div>
              </>
            </>
            }
            </>
       }
      { feedbackRatingShow &&
        <>
        <div class="provide-feedback">Provide your valuable feedback</div>
        <div class="line-74"></div>
            <div className="frame-2204-22">
                  <div style={{margin: "15px 0"}} className="text-center rating-details"> Staff Rating </div>
                  {edit?.workStaffAssignment?.staffWorkData?.map((staff, index) => (
                    <div class="frame-2248">
                    <div style={{width: "150px"}} class="frame-2245-2">
                      <div class="frame-2244-2">
                        <div class="connor-james-2">{staff.staffName}</div>
                      </div>
                      <div style={{position: "relative", top: "5px"}} class="_4-3-5">
                        <span
                          ><span class="_4-3-5-span">{ratingData[staff.staffId]}</span
                          ><span class="_4-3-5-span2">/ 5</span>
                        </span>
                      </div>
                      {/* <input
                        className="rating-input"
                        style={{border: "none", width: "135px"}}
                        type="number"
                        step="0.1"
                        placeholder="Add Rating (out of 5)"
                        value={ratingData[staff.staffId] || ""}
                        onChange={(e) => handleRatingChange(staff.staffId, e.target.value)}
                      /> */}
                      <ReactStars
                        // key={ratingData[staff.staffId]}
                        size={25}
                        count={5}
                        color={"#E3E3E3"}
                        activeColor={"#FABB05"}
                        value={ratingData[staff.staffId] || 0}
                        a11y={true}
                        isHalf={true}
                        onChange={(newRating) => handleRatingChange(staff.staffId, newRating)}
                      />

                        <div class="frame-22452">
                      </div>
                    </div>
                    <textarea
                      className="feedback-input"
                      style={{border: "none",  outline: "none", resize: "none"}}
                      name="feedback" 
                      id="" 
                      cols="30" 
                      rows="2" 
                      placeholder="Add Feedback here"
                      value={feedbackData[staff.staffId]?.feedback || ""}
                      onChange={(e) => handleFeedbackChange(staff.staffId, e.target.value)}
                    ></textarea>
                  </div>
                  ))}
              </div>
            </>
          }
          {feedbackRatingShow && 
            <>
              <div class="frame-2314" >
                <div class="line-76"></div>
                  <div class="ellipse-202"></div>
                    <img class="iconsax-linear-arrowdown-2" style={{cursor:"pointer"}} src={arrowUp2} onClick={()=>setFeedbackRatingShow(!feedbackRatingShow)}/>
                  </div>
              </>
              
            }
        </div>
        }

        {/* Staff Rating and Feedback - View Mode */}
        {edit?.status >5 &&
          <div style={{position: "relative", bottom: "30px"}} class="frame-2325">
          {!feedbackRatingShow && 
            <>
              <>
                <div style={{position: "relative", top: !feedbackRatingShow && "15px"}} class="rating-details">Staff Rating</div>
              </>
              {!feedbackRatingShow&& <>
              <>
                <div class="frame-2314">
                <div class="line-76"></div>
        
                 <div class="ellipse-202"></div>
                 <img class="iconsax-linear-arrowdown-2" style={{cursor:"pointer"}} src={arrowDown2} onClick={()=> setFeedbackRatingShow(!feedbackRatingShow)}/>
               </div>
              </>
            </>
            }
            </>
       }
      { feedbackRatingShow &&
        <>
        <div class="line-74"></div>
            <div className="frame-2204-22">
                  <div style={{margin: "15px 0"}} className="text-center rating-details"> Staff Rating </div>
                  {edit?.workStaffAssignment?.staffWorkData?.map((staff, index) => (
                    editStaffRatingId !== staff?.staffId ? <div class="frame-2248-view">
                    <div style={{width: "150px"}} class="frame-2245-view">
                      <div class="frame-2244-view">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                {staff?.staffName}
                              </Tooltip>
                            }
                          >
                        <div class="connor-james-2-view">{staff?.staffName?.length > 20 ? staff?.staffName?.substring(0, 20) + "..." : staff?.staffName}</div>
                            </OverlayTrigger>
                      </div>
                      {ratingData[staff.staffId] > 0 ?
                        <div style={{position: "relative", top: "5px"}} class="_4-3-5">
                          <span>
                            <span class="_4-3-5-span">{ratingData[staff.staffId]}</span>
                            <span class="_4-3-5-span2">/ 5</span>
                          </span>
                        </div>
                    :<span style={{position: "relative", top: "3px"}} class="_4-3-5-span fs-10">Not Rated</span> }
            
                      <div className="d-flex">
                        {/* <StarRating starsValue={staff.staffReviews?.rating}/> */}
                        <ReactStars
                          size={25}
                          count={5}
                          color={"#E3E3E3"}
                          value={ratingData[staff.staffId] || 0}
                          a11y={true}
                          isHalf={true}
                          edit={false}
                          activeColor={"#FABB05"}
                        />
                      </div>
                    </div>
                  
                    <div class="frame-2246-view">
                      <div class="view-feedback s3-r">
                        {staff.staffReviews?.feedback 
                        ? staff.staffReviews?.feedback.length > 110 
                        ? `${staff.staffReviews?.feedback.slice(0, 110)}...`
                        : staff.staffReviews?.feedback
                        : "No feedback"}  
                      </div>
                      { staff.staffReviews?.feedback 
                         ? staff.staffReviews?.feedback.length > 110 ? 
                         <>
                        <img src={expandfeedback} style={{marginTop:"6px"}}/>
                        <div class="hover-feedback s3-r">
                        {staff.staffReviews?.feedback ? staff.staffReviews?.feedback : "No feedback"}
                      </div>
                      </>
                      :"":"" }
                    </div>
                    {edit.status !== 9 && <div className="rating-edit-btn">
                            <img
                              className='cursor-pointer'
                              src={ratingEditIcon}
                              alt="rating-edit-icon"
                              onClick={() => setEditStaffRatingId(staff.staffId)}
                            />
                        </div>}
                  </div>
                  :
                  <div class="frame-2248">
                    <div style={{width: "150px"}} class="frame-2245-2">
                      <div class="frame-2244-2">
                      <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                {staff?.staffName}
                              </Tooltip>
                            }
                          >
                        <div class="connor-james-2">{staff?.staffName?.length > 20 ? staff?.staffName?.substring(0, 20) + "..." : staff?.staffName}</div>
                      </OverlayTrigger>
                      </div>
                      <div style={{position: "relative", top: "5px"}} class="_4-3-5">
                        <span
                          ><span class="_4-3-5-span">{ratingData[staff.staffId]}</span
                          ><span class="_4-3-5-span2">/ 5</span>
                        </span>
                      </div>
                      <ReactStars
                        key={ratingData[staff.staffId]}
                        size={25}
                        count={5}
                        color={"#E3E3E3"}
                        activeColor={"#FABB05"}
                        value={ratingData[staff.staffId] || 0}
                        a11y={true}
                        isHalf={true}
                        onChange={(newRating) => handleRatingChange(staff.staffId, newRating)}
                      />

                        <div class="frame-22452">
                      </div>
                    </div>
                    <textarea
                      className="feedback-input"
                      style={{border: "none",  outline: "none", resize: "none"}}
                      name="feedback" 
                      id="" 
                      cols="30" 
                      rows="2" 
                      placeholder="Add Feedback here"
                      value={feedbackData[staff.staffId]?.feedback || ""}
                      onChange={(e) => handleFeedbackChange(staff.staffId, e.target.value)}
                    ></textarea>
                        {edit.status !== 9 && <div className='rating-check-btn'>
                          {editStaffRatingId === staff.staffId
                            &&
                            <img
                              className='cursor-pointer'
                              src={checkIcon}
                              alt="check-icon"
                              onClick={() => {setEditStaffRatingId('');saveRatings(false)}}
                            />
                          } 
                        </div>}
                  </div>                  
                  ))}
              </div>
            </>
          }
          {feedbackRatingShow && 
            <>
              <div class="frame-2314" >
                <div class="line-76"></div>
                  <div class="ellipse-202"></div>
                    <img class="iconsax-linear-arrowdown-2" style={{cursor:"pointer"}} src={arrowUp2} onClick={()=>setFeedbackRatingShow(!feedbackRatingShow)}/>
                  </div>
              </>
              
            }
        </div>
        }

      {/* Overall Rating - Edit Mode */}
      {edit.status === 5 &&
        <div style={{width: "445px", justifyContent: "space-between"}} class="frame-2247">
        <div class="frame-2245">
          <div class="frame-22244">
            <div class="overall-wr">Overall WR</div>
          </div>
          <div style={{position: "relative", top: "5px"}} class="_4-3-5">
            <span>
              <span class="_4-3-5-span">{overallRatingData}</span>
                <span class="_4-3-5-span2">/ 5</span>
              </span>
          </div>
          {/* <input
            className="rating-input"
            style={{border: "none", width: "135px"}}
            type="number"
            step="0.1"
            placeholder="Add Rating (out of 5)"
            value={overallRatingData}
            onChange={(e) => setOverallRatingData(e.target.value)}
          /> */}
          <ReactStars
              size={25}
              count={5}
              color={"#E3E3E3"}
              activeColor={"#FABB05"}
              value={overallRatingData}
              a11y={true}
              isHalf={true}
              onChange={(newRating) => setOverallRatingData(newRating)}
            />
        </div>
        <textarea
          className="feedback-input"
          style={{border: "none",  outline: "none", resize: "none"}}
          name="feedback" 
          id="" 
          cols="30" 
          rows="2" 
          placeholder="Add Feedback here"
          value={overallFeedbackData}
          onChange={(e) => setOverallFeedbackData(e.target.value)}
        ></textarea>
      </div>
      }

      {/* Overall Rating - View Mode */}
      {edit.status > 5 && !editOverallRating &&
        <div class="frame-2247">
        <div class="frame-2245">
          <div class="frame-22244">
            <div class="overall-wr">Overall WR</div>
          </div>
          {overallRatingData > 0 ? 
            <div style={{position: "relative", top: "5px"}} class="_4-3-5">
            <span>
              <span class="_4-3-5-span">{overallRatingData}</span>
              <span class="_4-3-5-span2">/ 5</span>
            </span>
          </div>
          : <span style={{position: "relative", top: "3px"}} class="_4-3-5-span fs-10">Not Rated</span>}
            <div className="d-flex">
              <div className="d-flex">
                {/* <StarRating starsValue={edit?.workStaffAssignment?.starRating} /> */}
                <ReactStars
                  size={25}
                  count={5}
                  color={"#E3E3E3"}
                  activeColor={"#FABB05"}
                  value={overallRatingData}
                  a11y={true}
                  isHalf={true}
                  edit={false}
                  onChange={(newRating) => setOverallRatingData(newRating)}
                />
              </div>
            </div>
        </div>
        <div style={{background: "#e1e1e1"}} class="frame-2246-view">
          <div class="view-feedback s3-r">
            {edit?.workStaffAssignment?.feedBack
               ? edit?.workStaffAssignment?.feedBack.length> 110 ? `${edit?.workStaffAssignment?.feedBack.slice(0, 110)}...` : edit?.workStaffAssignment?.feedBack 
               : "No feedback"}
          </div>
          {edit?.workStaffAssignment?.feedBack
            ? edit?.workStaffAssignment?.feedBack.length> 110 ?
            <>
            <img src={expandfeedback} style={{marginTop:"6px"}}/>
            <div className="hover-feedback s3-r">
              {edit?.workStaffAssignment?.feedBack ? edit?.workStaffAssignment?.feedBack : "No feedback"}
            </div>

            </>
            :"" : ""
          }
        </div>
        {edit.status !== 9 && <div className='rating-edit-btn'>
             <img 
             className='cursor-pointer' 
             src={ratingEditIcon} 
             alt="rating-edit-icon" 
             onClick={() => setEditOverallRating(true)}
             />
        </div>}
      </div>
      }

      {edit.status > 5 && editOverallRating &&
        <div style={{width: "34.375rem", justifyContent: "space-between"}} class="frame-2247">
        <div class="frame-2245">
          <div class="frame-22244">
            <div class="overall-wr">Overall WR</div>
          </div>
          <div style={{position: "relative", top: "5px"}} class="_4-3-5">
            <span>
              <span class="_4-3-5-span">{overallRatingData}</span>
                <span class="_4-3-5-span2">/ 5</span>
              </span>
          </div>
          <ReactStars
              size={25}
              count={5}
              color={"#E3E3E3"}
              activeColor={"#FABB05"}
              value={overallRatingData}
              a11y={true}
              isHalf={true}
              onChange={(newRating) => setOverallRatingData(newRating)}
            />
        </div>
        <textarea
          className="feedback-input"
          style={{border: "none",  outline: "none", resize: "none"}}
          name="feedback" 
          id="" 
          cols="30" 
          rows="2" 
          placeholder="Add Feedback here"
          value={overallFeedbackData}
          onChange={(e) => setOverallFeedbackData(e.target.value)}
        ></textarea>
        {edit.status !== 9 && 
                    <div className='rating-check-btn'>
                      <img
                        className='cursor-pointer'
                        src={checkIcon}
                        alt="check-icon"
                        onClick={() => saveRatings()}
                      />
                    </div>}
      </div>}
  
              {/* end Div */}
              </div>
              {/* Dummy div for scrollToBottom */}
              {/* <div id="bottom" ref={bottomRef}></div> */}
              <hr/>
            {/* Status >= 3 , After one staff complete work, the status will be 3 (yet to start) */}
            {/* Status = 2, Not valid here, bcz before accepting showing timeline will be irrelevant */}

            {edit.status >= 3  || (edit.status === 0 && edit.workStaffAssignment?.staffWorkData.filter(staffWD=>{
              if(!!staffWD.clockIn) return true;
              else return false;
            }).length>0) ? (
              <>
                 {edit.status != 10 ? 
              //    <StaffDetails
              //    data={edit.workStaffAssignment}
              //    edit={edit}
              //    status={edit.status}
              //    locationData={edit.location}
              //    startDate={edit.startDate}
              //    endDate={edit.endDate}
              //  />

                "" : null}
                {/* <TimeSheetMark data={edit.workStaffAssignment} /> */}
                <div className="d-flex align-items-center justify-content-end flex-column text-center sticky-footer">
                {edit.status !== 0 && edit.status < 4 &&    
                     <div class="frame-25311" style={{cursor:"pointer"}} onClick={() => {
                    setCancelId(id); 
                    setOpenModal(true); 
                  }}>
                     <div class="btn cursor-pointer fs-16 text-primarybg op  bg-transparent font-weight-bold">Cancel Work</div>
                     </div>
                       }
                  <div className="wrapper"> 
                    <p
                      className={`completeByClient fs-12 btn-gray-clr mb-1 ${
                        edit.status === 5 ? "cursor-pointer" : "disabled"
                      }`}
                      onClick={() => completeWorkByClient()}
                      id="clientApproval"
                    >
                      APPROVE ALL{" "}{ loadingKey === `approve-all-invoicing` ? <CustomLoader/> : null}
                    </p>
                  </div>
                </div>
              </>
            ) : edit.status !== 0 ? (
              <div className="d-flex align-items-center justify-content-between sticky-footer flex-row" >
                <div className="py-2 " style={{marginTop:"-0.7rem", height:"30.4px"}}>
                  <NormalButton
                    onClick={() => {
                      setOpenModal(true);
                    }}
                    id="cancelWR"
                    label="CANCEL WORK REQUEST"
                    outline={false}
                    // loader={isFormLoder}
                    className=" btn fs-12 text-primarybg bg-transparent font-weight-bold"
                  />
                  <p></p>
                </div>
                <div className="d-flex align-items-end justify-content-end" style={{height: '30.4px'}}>
                  <div className="p-3"> </div>
                  <NormalButton
                    onClick={() => {
                      handleRejectAll();
                    }}
                    id="rejectAll"
                    label="REJECT ALL"
                    outline={false}
                    disabled={isButtonDisabled(isFormLoder,workStaffAssignment)}
                    // loader={isFormLoder}
                    className="mb-4 btn fs-12 btn-purple-outline mr-2"
                  />
                  <NormalButton
                    onClick={() => {
                      handleApproveAll();
                    }}
                    id="approveAll"
                    label="APPROVE ALL"
                    outline={false}
                    disabled={isButtonDisabled(isFormLoder,workStaffAssignment)}
                    loader={loadingKey === 'approveall-work'}
                    // loader={isFormLoder}
                    className="mb-4 btn fs-12 outline-dark-btn btn-block form-btn "
                  />
                </div>
              </div>
            ) : (
              ""
            )}

          </>
         : (
          <CommonPageLoader />
        )}
        <div id="bottom" ref={bottomRef}></div>
      </NormalModal>
      <CancelWorkModel
        toggle={() => setOpenModal(!openModal)}
        isOpen={openModal}
        handleAction={(e) => handleAction(e)}
        loadingKey={loadingKey} 
        loadingCheckKey="cancel-work"
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSingleWork,
      deleteWork,
      cancelWork,
      approveStaff,
      rejectStaff,
      updateRatings,
      completeWorkClient,
      getFileFromMDB,
      addWorkRatings
    },
    dispatch
  );
};

export default connect(
  mapDispatchToProps,
  mapDispatchToProps
)(withRouter(WorkAssignment));

import moment from 'moment';
import React from 'react'
import classes from './interactive_map.module.scss'
import clockInCursor from '../../../assets/svg/map_cursors/clockIn.svg'
import clockOutCursor from '../../../assets/svg/map_cursors/clockOut.svg'
import pauseCursor from '../../../assets/svg/map_cursors/pause.svg'
import resumeCursor from '../../../assets/svg/map_cursors/resume.svg'
import { entryType } from './constants'

function TimeLineMapPoint({ label, date, pointType, pointClickHandler, active }) {

    let line = '';
    switch (pointType) {
        case entryType.PAUSED:
            line += classes.dashed;
            break;
        case entryType.CLOCK_OUT:
            line += classes.no_line;
            break;
        case entryType.CLOCK_IN:
        case entryType.RESUMED:
        default: 
            line += classes.solid;
            break;
    }

    return (
        <div className={classes.timeline_point} onClick={pointClickHandler}>
            <div className={classes.line_cursor_wrapper}>
                <img
                    className={classes.cursor}
                    src={
                        label === entryType.CLOCK_IN ? clockInCursor :
                            label === entryType.CLOCK_OUT ? clockOutCursor :
                                label === entryType.PAUSED ? pauseCursor : resumeCursor
                    }
                    alt="cursor"
                />
                <div className={`${classes.line} ${line}`} />
            </div>
            <div className={classes.content_wrapper}>
                <p className={classes.label}>{label}</p>
                <p className={classes.time}>{`${moment(date).format(`${localStorage.getItem('dateFormat')}`)},  ${moment(date).format('LTS')}`}</p>
            </div>
        </div>
    )
}

export default function TimelineMap({ timelineArray = [], mapPointHandler = () => { }, activePoint, setActivePoint }) {

    const onClickHandler = (lat, lng, index) => {
        mapPointHandler(lat, lng);
        if (!!lng && !!lat) setActivePoint(index);
    }

    return (
        <div className={classes.timeline_wrapper}>
            {
                !!timelineArray.length && timelineArray.map((item, index) => (
                    <TimeLineMapPoint
                        key={index}
                        active={activePoint === index}
                        label={item?.label}
                        date={item?.date}
                        pointType={item?.label}
                        pointClickHandler={() => onClickHandler(item?.lat, item?.lng, index)}
                    />
                ))
            }
        </div>
    )
}

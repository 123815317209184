import React from 'react';
import GoogleMapReact from 'google-map-react';
import { DistanceMatrix } from 'component/common/DistanceMatrix/distanceMatrix';
// import staffLocation from '../../../assets/images/staff-location.png';
import clientLocation from '../../../assets/images/client-location.png';
import letterC from "../../../assets/images/letter-c.png";
function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  var dis = d *  0.621371; //Distance in miles.
  //return d;
  return dis;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export default function GoogleMaps({lat1, lan1, lat2, lan2, sitePicture}) {
  /*const handleGoogleMapApi = (google) => {

   
    var flightPath = new google.maps.Polyline({
      path: [{lat: lan1, lng: lat1}, {lat: lat2, lng: lan2}],
      geodesic: false,
      strokeColor: '#2646EC',
      strokeOpacity: 1,
      strokeWeight: 2,
    });

    //flightPath.setMap(google.map);
  };*/
  const AnyReactComponent = ({text, sitePicture}) => (
    <div>
      <div id="custom-marker-staff">
        <div id="inner-circle" style={{width: 200}}>
          <img src={text === 'Staff' ? clientLocation : (sitePicture?sitePicture:letterC)} 
            style={{
              width:"50px",
              height:"50px",
              // borderRadius:"50%",
            }} 
            alt="staff"
          />
          <br />
          {text === 'Client' ? (
            <b className="text-primary font-weight-bold">
              {lat2 !== undefined && lan2 !== undefined && <DistanceMatrix destination={lat1} destination2={lan1} origin={lat2} origin2={lan2}/>}
            </b>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
  return (
    <div className="row align-items-start mb-3">
      <div className="col-12">
        <div style={{height: '40vh', width: '100%'}}>
          <GoogleMapReact
            defaultCenter={{lng: lat1, lat: lan1}}
            defaultZoom={15}
            // onGoogleApiLoaded={({ map, maps }) =>
            //   renderMarkers(map, maps)
            // }
            //onGoogleApiLoaded={handleGoogleMapApi}
            >
            <AnyReactComponent lng={lat1} lat={lan1} text="Client" sitePicture={sitePicture} />
            {lat2 !== undefined && lan2 !== undefined && <AnyReactComponent lng={lan2} lat={lat2} text="Staff" />}
          </GoogleMapReact>
        </div>
      </div>
    </div>
  );
}

import { NotificationManager } from 'react-notifications';

export const Toast = ({ type = 'success', message, time = null }) => {
   let fetch_Msg = "Data fetched successfully";
   if (Array.isArray(message)) {
      let item;

      for (item of message) {
         NotificationManager[type](item.message, '', time ? time : type === 'success' ? 3000 : 5000)
      }
   } else {
      if (message?.localeCompare(fetch_Msg)) {
         NotificationManager[type](message, '', time ? time : type === 'success' ? 3000 : 5000)
      }
   }
}

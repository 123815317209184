import React from 'react';
import "./style.scss";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';   //Button
import {colorByStatus} from "../../../assets/WRColorSchema";
import ArrowDown from "assets/svg/arrow-down-multi-select.svg"

export class NormalDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          dropdownId: Math.floor(100000 + Math.random() * 900000),
          isOpen: false
        }
    
      }
      handleDropdown = () => {
        // console.log("this is:", mennuId);
        // let submenuElem = document.getElementById(mennuId);
        // submenuElem.classList.toggle("show");
        this.setState({ isOpen: !this.state.isOpen })
      };
    
      render() {
        const {
          className = "",
          style = "",
          label = "",
          onClick,
          disabled = false,
          caret = true,
          // alinement = "",
          outline =false,
          color='',
          optionsList = [ 
            { icon: "edit", label: "Edit", iconClass: "material-icons" },
            { icon: "content_copy", label: "Copy", iconClass: "material-icons" },
            { icon: "delete", label: "Delete", iconClass: "material-icons" },
        ],
        } = this.props;
    let {isOpen}=this.state;

    return (
        <ButtonDropdown disabled={disabled} isOpen={isOpen}  toggle={this.handleDropdown}  direction="down">
         {/* <Button id="caret" >{label ? label : ''}  {isOpen ? <i className="fas fa-chevron-up ml-2"></i> : <i className="fas fa-chevron-down ml-2"></i>}</Button> */}
        <DropdownToggle disabled={disabled} outline={outline} color={color}  className={className} style={style} >
          
         
        {label?label:<i className={className}></i>} <i className="fas fa-chevron-down ml-4"></i><img src={ArrowDown} style={{width:"7px"}} alt='arrow-down'></img>
        </DropdownToggle>

        <DropdownMenu >
          {optionsList.map((data, index) =>
            <DropdownItem  onClick={e => {
              let body = {};
              body = {
                target: {
                  value: data.value,
                  data:data
                }
              }
              // this.handleDropdown(id)
              onClick(body);
            }}
             key={index}
            >
              <div className='d-flex fs-12'>
                {data.isColorBox?<div className="color-box" style={{"--dynamic-color-value": colorByStatus?.[data.value].color}}></div>: data.icon ? <img className="mx-2" src={data.icon} alt=''/> : ""}  {data.label}
              </div>
              </DropdownItem>
          )}
          {/* <DropdownItem onClick={this.signOut}>Sign Out</DropdownItem> */}

        </DropdownMenu>
      </ButtonDropdown>


    );

}

}
import React, {Component} from "react"
import {NavLink} from 'react-router-dom';
import settingsSolid from 'assets/svg/settings solid.svg';
import settingsTransparent from 'assets/svg/settings transparent.svg';

import atom_green from 'assets/svg/atom_green.svg';
import atom_white from 'assets/svg/atom_white.svg';
import feedbackSolid from 'assets/svg/feedback_solid.svg';
import feedbackTransparent from 'assets/svg/feedback transparent.svg';
import agencySettingsSolid from "assets/svg/agencySettingsSolid.svg"
import agencySettingsTransparent from "assets/svg/agencySettingsTransparent.svg"
import subscriptionSolidIcon from "assets/svg/Subscription-solid-filled.svg"
import subscriptionWhiteIcon from "assets/svg/Subscription-white.svg";
import dashboardSolid from "assets/svg/dashboard.svg"
import dashboardTransparent from "assets/svg/dashboard_transparent.svg"
import {history} from '../../../service/helpers';
import "./newsidebar.scss"



export default class NewSidebarMobile extends Component {
    constructor(props) {
      super(props);
      this.activeTab = this.activeTab.bind(this);
      this.state = {
        navLinks: [
          {
            to: '/admin/WorkSchedule',
            label: 'Work Schedule',
            iconName: atom_green,
            iconTransparent: atom_white,
            classNames:"WSSizePlus1",
            id:"WSSizePlus2",
          },
         
          {
            to: '/admin/feedback',
            label: 'Feedback',
            iconName: feedbackSolid,
            iconTransparent: feedbackTransparent,
            classNames:"sideBarIcon1",
            id:"sideBarIcon",
          },
        
          {
            to: '/admin/AgencyManagement',
            label: 'Agency Management',
            iconName: agencySettingsSolid,
            iconTransparent: agencySettingsTransparent,
            classNames:"sideBarIcon1",
            id:"sideBarIcon",
          },
        
          {
            to: '/admin/subscription',
            label: 'Subscription',
            iconName: subscriptionSolidIcon ,
            iconTransparent: subscriptionWhiteIcon,
            classNames:"sideBarIcon1",
            id:"sideBarIcon"
          },
          
          {
            to: '/admin/settings',
            label: 'Settings',
            iconName: settingsSolid,
            iconTransparent: settingsTransparent,
            classNames:"sideBarIcon1",
            id:"sideBarIcon",
          },
          {
            to: '/admin/dashboard',
            label: 'Dashboard',
            iconName: dashboardSolid,
            iconTransparent: dashboardTransparent,
            classNames:"sideBarIcon1",
            id:"sideBarIcon",
          },
        ],
        prev: '',
        next: '',
        active: '',
      };
    }
  
    activeTab = (index) => {
      this.setState(
        {
          next: index + 1,
          prev: index - 1,
          active: index,
        },
        () => {
          // callback function logic goes here
        },
      );
    };

    UNSAFE_componentWillMount() {
        let {location} = history;
        let {navLinks} = this.state;
        navLinks.forEach((item, index) => {
          if (item.to === location.pathname) {
            this.setState({
              next: index + 1,
              prev: index - 1,
              active: index,
            });
          }
        });
      }
      

    render() {
        
        // let {navLinks, prev, next, active} = this.state;
        let {navLinks, active} = this.state;
        let {menuOpen, handleSidenav} = this.props;
        let {location} = history;
        const tab = (to,index) =>{
            if(to !== "/something"){
                this.activeTab(index)
            }
        }
        const closeMenu = () => {
            handleSidenav(false)
          }
    
        return (
            <>
               {/* <button>{menuOpen ? "false" : "true"}</button> */}
            <div className="left=menu">
               <ul className={`menuNav ${menuOpen ? " showMenu" : "hidemenu"}`}>
               {navLinks.map(({to, label, classNames, id}, index) => (
                <li key={index}>
                    <NavLink
                       onClick={()=>{tab(to,index); closeMenu()}}
                       tabIndex={to === "/something" ? "-1" :""}
                       to={to}
                       className="nav-link"
                       exact
                    >
                    <div className="sidebar-menu">
                     {(active === index && location.pathname.indexOf(to)>-1) || 
                        (active!==undefined && location.pathname.indexOf(to)>-1) ? (
                          <span className={classNames}>{label}</span>
                        ) : (
                          <span id={id}>{label}</span>
                        )}
                     
                    </div>
                    </NavLink>
                
                </li>
               ))}
               </ul>
            </div>
            </>
        )
    }
}


const initialState = {
    agencyData:[
    ]
  };

  export function updateAgencyData(data){
    return {
        type: 'UPDATE_AGENCY_DATA',
        payload: data,
    }
}

  export default  (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_AGENCY_DATA':
        return {
          ...state,
          agencyData:[
          ...action.payload
          ]
        }; 
      case 'ADD_REGISTERED_AGENCY_DATA': 
      return {
        ...state,
        agencyData:[
          ...state.agencyData,action.payload
        ]
      }
      default:
        return state;
    }
  };
  
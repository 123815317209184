import React, { memo } from 'react'
import "./style.scss";
import moment from "moment";
import notifi_clock from "assets/svg/noti_clock.svg"
import atom_white from 'assets/svg/atom_white.svg';
import settingsTransparent from "assets/svg/settings transparent.svg"
import feedbackTransparent from 'assets/svg/feedback transparent.svg';
import invoiceTransparent from 'assets/svg/stack_white.svg';
import AgencyTransparent from 'assets/svg/agencySettingsTransparent.svg';
import subscriptionTransparent from 'assets/svg/Subscription-white.svg'

import { useHistory } from 'react-router-dom';
import No_results_found from "assets/svg/no_data_found_illus.svg";
import { startTourGuide } from 'reducer/tourGuide';
import { useDispatch } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const NotificationMessages = ({data,setWorkAssign}) => {
  let notificationData = [...data]
  let history = useHistory()
  const dispatch = useDispatch();
  let OrientationCount = localStorage.getItem('OrientationCount')

  const handleTour = ({target:{value}}) => {
    dispatch(startTourGuide({run: true}))
  }
  const userData = JSON.parse(localStorage.getItem("userData"))
  const accountExpired = userData?.subscriptionValidity ? moment(userData.subscriptionValidity)<moment(new Date()) : false
  return (
    <>
    <div className="down">
      {notificationData.length ? (
                                   
      notificationData.map(({ date, notifications }, index) => {

          return (
            <div className="ml-3 mr-3" key={index}>
                <h6 className="notifi-date">{moment(date).format(`${localStorage.getItem('dateFormat')}`)}</h6>
                {notifications.map((item) => (
                  <div className="container notification-bar cursor-pointer"
                  onClick={() => {
                    if(accountExpired) return;
                    if (item.path.split('/')[1] === "workSchedule"){
                      setWorkAssign({
                                      value: true, 
                                      id: item.path.split('/')[2],
                                      agencyId:item.path.split('/')[3]
                                    });
                    } else if (item.path.split('/')[1] === "editTimeSheet"){
                      setWorkAssign({ value: true, 
                                      id: item.path.split('/')[2],
                                      // Although there is one more ID in this path - 
                                      // item.path.split('/')[3], the WR pop-up is working anyway without it (Confirmed in UT). 
                                      agencyId:item.path.split('/')[4]
                                    });
                    }
                    else if (item.path.split('/')[1] === "settings"){
                      history.push('/admin/settings')
                    }
                    else if (item.path.split('/')[1] === "jobMaster"){
                      history.push('/admin/AgencyManagement')
                    }else if(item.path.split('/')[1] === "agency"){
                      history.push(`/admin/AgencyManagement?invitesmanagement=true&agencyId=${item.path.split('/')[2]}`)
                    }
                    else if(item.path.split('/')[2] === "tax"){
                      history.push('/admin/settings')
                    }
                    // else{
                    //   alert("NOT IMPLEMENTED YET.")
                    // }
                  }}>
                    <div className="row align-items-start p-3 align-items-stretch">
                      {/* <div className="col-1 d-flex justify-content-start">
                                                </div> */}
                      <div className="col-9 pl-0 d-flex justify-content-start align-items-center" style={{paddingRight:"0"}}>
                        <div style={{
                          backgroundColor: item.status === 0 && item.message.includes("canceled")?"#B7B7B7" : 
                                          item.status === 1 && item.message.includes("canceled") ? "#B7B7B7" :
                                           item.status === 2 && item.message.includes("canceled") ? "#B7B7B7" :
                                           item.status === 1 ? "#4F81BD" :
                                           item.status === 2 ? "#FB6873" :
                                           item.status === 3 ? "#F3993E" : 
                                           item.status === 4 ? "#6DFAFD" :
                                           item.status === 5 ? "#FDF851" : 
                                           item.status === 6 ? "#EA58F9" : 
                                           item.status === 7 ? "#9C4EF7" :  
                                           item.status === (8 || 9) ? "#73F340":
                                           ""
                        }} className="checkBox col-1">
                          <img className='notif-icon' style={{width: "20px"}} src={
                            item.path.split('/')[1] === "workSchedule" ? atom_white : 
                            item.path.split('/')[1] === "settings" ? settingsTransparent : 
                            item.path.split('/')[1] === "feedback" ? feedbackTransparent : 
                            item.path.split('/')[1] === "agency" || item.path.split("/")[1] === 'agencies' ? AgencyTransparent:
                            item.path.split('/')[1] === 'client'?
            (item.message.includes('archived') && settingsTransparent || item.message.includes('subscription') && subscriptionTransparent ):  
                            item.path.split('/')[1] === "editTimeSheet" ? atom_white : 
                            item.path.split('/')[1] === "invoice" ? invoiceTransparent : 
                            item.path.split('/')[1] === "jobMaster" ? AgencyTransparent : 
                            item.path.split('/')[2] === "tax" ? settingsTransparent : 
                            ""
                            } alt="" />
                        </div>
                        <div className="col-11">
                          {/* <p className=" pl-3 fs-12 font-weight-bold mb-1 text-grey-42">
                            {item.status}
                          </p> */}
                          <p className="fs-15 pl-3 wordwrap fixed-height mb-0 text-grey-6c notifi-message" style={{paddingRight:"0"}}>
                            {item.message}
                          </p>
                        </div>
                      </div>
                      <div className="col-2 text-right pr-32 d-flex flex-column justify-content-between cursor-pointer text-center">
                        <div>
                        {item.path ? (
                          <>
                          {!accountExpired ?
                            <p
                              className="text-grey-6c mb-0 text-view fs-15 notifi-view" 
                            >
                              View
                            </p>
                             :
                             <OverlayTrigger
                             placement="left"
                             overlay={
                                 <Tooltip>
                                     Your subscription is expired. View notification is blocked
                                 </Tooltip>
                             }>
                             <p className="text-grey-6c mb-0 no-drop" >
                              View
                            </p>
                             </OverlayTrigger>
                             }
                          </>
                         ) : (
                         ""
                        )}
                        </div>
                        <div class="frame-4430">
                           <img class="notifi_clock" src={notifi_clock} />
                           <div class="_notifi-time">{moment(item.createdAt).format('hh:mm a')}</div>
                           </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
          );
        })
      ) : (
        <>
        <div className='d-flex justify-content-center align-items-center' style={{width:"100%", flexDirection:"column"}}>
        <div>
        <img className="preload-image no_result_found_notifi" src={No_results_found}
                        onLoad={() => {
                          document.querySelector('.preload-image').style.display = 'block';
                        }}
        />
        </div>
          <div>
          {OrientationCount === "true" ? <p style={{fontSize:"13px", margin:"auto", textAlign:"center"}}>Not Sure Where To Start? <u style={{color: "#3FB1FB",cursor:"pointer"}} onClick={handleTour}>Click Product Tour</u> to know more</p> :""}
          </div>
        </div>
      </>
      )}
      </div>
    </>
  )
}

export default memo(NotificationMessages)

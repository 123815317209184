import React, { Component } from "react";
import Navbar  from "component/common/navbar";
import Sidebar from "component/common/Sidebar";
import {logout} from './../service/utilities'
import "assets/scss/layouts/AdminLayout.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import NotificationSettings from 'pages/Admin/Notification'
import TourGuide from "component/common/TourGuide";
import { getPermissionMenu } from "action/AuthAct";
import connectToWebSocket from "service/webSocket";
import { getSingleSubscription } from 'action/SubscriptionAct';
import {store} from 'service/helpers'


import { history } from "service/helpers"
import { CommonPageLoader } from "component/common";
import { getClientById } from "action/settingsAct";
import NewSidebarMobile from "component/common/newSidebarMobile";
import moment from "moment";

let paths = {
   'Work Schedule': '/admin/WorkSchedule',
   'Agency Management':'/admin/AgencyManagement',
   'Feedback':'/admin/feedback',
   'Subscription': '/admin/subscription',
   'Settings': '/admin/settings',
   'Dashboard':'/admin/dashboard'
 } 

 let featuresList =['Agency Management','Dashboard']
 
export class AdminLayout extends Component {
   state = {
      loading:true,
      profilemenu: false,
      menuOpenClass: false,
      authToken: '',
      isSideBarClicked: false,
      permissionList:[],
      permittedPaths:[],
      features:[],
      isClosed:true
   };

   componentDidMount = async() => {
      try{
      console.log("mount")
      await this.fetchClientData()
      let authToken = localStorage.getItem('AuthToken')
      // console.log(localStorage.getItem('userData'))
      let userData = JSON.parse(localStorage.getItem('userData'))
      if (!authToken) {
         logout()
      } else {
         this.setState({ authToken })
         userData.subscriptionPlanId?
         await this.fetchAllPermissionsAndFeatures()
         :this.fetchAllPermissions();
         connectToWebSocket()
         window.addEventListener('online', ()=>{
            connectToWebSocket()
             })
      }

      // Check if user has logged in for the first time
      const firstLogin = localStorage.getItem('firstLogin');

      if (firstLogin === null) {
         // User has logged in for the first time
         localStorage.setItem('firstLogin', 'true');
       }
      } 
      catch (err){
         console.log(err)
      }
   }
   componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
         this.hasPermission(window.location.pathname, this.state.permittedPaths)
      }
    }

    hasPermission(route, permissionList) {
      const parts = route.split("/");
      let pathName = `/${parts[1]}/${parts[2]}`
      let permit = permissionList.includes(pathName.toLowerCase());
   if(!permit){
      let userData = JSON.parse(localStorage.getItem('userData'));
      const givenDate = moment(userData?.subscriptionValidity);
      const currentDate = moment();
      const remainingDays = Math.max(0, givenDate.diff(currentDate, 'days'));
      const accountValidity = userData?.accountValidity
      if(remainingDays === 0 && !accountValidity){
         history.push('/admin/subscription/manageplans')
      }
      else{
         history.push('/admin/settings')
      }
   // history.push('/admin/settings')
   }
    }

      //get all permissions
  fetchAllPermissions =()=>{
   this.setState({ loading: true })
   getPermissionMenu()
   .then((data)=>{
      let permissionPaths = []
      data.data?.forEach(item => {
         if(paths[item.label])
         permissionPaths.push(paths[item.label].toLowerCase())
      });
      this.hasPermission(window.location.pathname, permissionPaths)
      this.setState({loading: false, permissionList:data.data, permittedPaths:permissionPaths})
   })
   .catch((err)=>{
      console.log(err)
      this.setState({ loading: false, permissionList:[{label: "Settings"}]})
   })
}  

   fetchClientData = async()=>{
      let user = JSON.parse(localStorage.getItem('userData'))
      try{
      const data = await store.dispatch(getClientById({id:user.id}))
      // console.log(data)
      localStorage.setItem('userData', JSON.stringify(data))
      } 
      catch (err){
         console.log(err)
      }
   }

   fetchAllPermissionsAndFeatures = async()=>{
      this.setState({loading:true}) 
      const  userData = JSON.parse(localStorage.getItem('userData'));
      // console.log(userData) 
      try{
      const [permissionsData,planData] = await Promise.all([getPermissionMenu(),store.dispatch(getSingleSubscription({id:userData.subscriptionPlanId},true))]) 
      let permissionPaths=[]
      let permissionList=[] 
      permissionsData.data?.forEach( item => {
         if(featuresList.includes(item.label)){
            if(planData.features.some(feature =>feature.value === item.label)){
               paths[item.label] && permissionPaths.push(paths[item.label].toLowerCase())
               permissionList.push(item)
            }
         } 
         else { 
            permissionPaths.push(paths[item.label].toLowerCase())
            permissionList.push(item)
         }
      } 
      ) 
      // permissionList = permissionsData.data.filter(item => {
      //    if(featuresList.includes(item.label)){
      //       if(planData.features.some(feature =>feature.value === item.label)){
      //          return true
      //       }
      //    } 
      //    else { return true}
      // } )
      this.hasPermission(window.location.pathname, permissionPaths)
      this.setState({loading:false,permissionList:permissionList,permittedPaths:permissionPaths})} 
      catch(err){
         let paths = ["/admin/settings","/admin/subscription"]
         this.setState({loading:false, permissionList:[{label: 'Settings'},{label: 'Subscription'}], permittedPaths:paths}) 
         history.push('/admin/settings')
         console.log(err)
      }
   }

   
   handleProfileMenu = (event, active) => {
      event.stopPropagation();
      this.setState({
         profilemenu: active
      });
   };

   handleSidenav = () => {
      let { menuOpenClass } = this.state;

      this.setState({
         menuOpenClass: !menuOpenClass
      },()=>{
         // console.log(this.state.menuOpenClass,"MENUCLASS")
      });
   };

   handleSideBar = () => {
      let { isSideBarClicked } = this.state;

      this.setState({
         isSideBarClicked: !isSideBarClicked
      }); 

      isSideBarClicked ? setTimeout(()=>this.setState({isClosed:isSideBarClicked}),500) : this.setState({isClosed:isSideBarClicked})
   };

   render() {
      let { children } = this.props;

      let {loading, menuOpenClass, permissionList } = this.state;

      return (
         <>
         <div id="main-content" onClick={e => this.handleProfileMenu(e, false)}>
         {
               !loading ?
               <>
            <div className="content-wrapper">
               <div className="d-block d-sm-none">
               <NewSidebarMobile
                   menuOpen={menuOpenClass}
                   handleSidenav={this.handleSidenav}
                   permissionList={permissionList}
                   pathname={history.location.pathname}
                />
               </div>
               <div className="d-none d-sm-block">
               <Sidebar
                  menuOpen={menuOpenClass}
                  handleSidenav={this.handleSidenav}
                  permissionList={permissionList}
                  pathname={history.location.pathname}
               />
               </div>
               <Navbar
               // changeProfileState={(e, param) => this.handleProfileMenu(e, param)}
               // active={this.state.profilemenu}
               handleNav={this.handleSidenav}
               handleSideBar= {this.handleSideBar}
               sideBarClickStatus={this.state.isSideBarClicked}
               />
               <div className="content">{children}</div>
            </div>
            </>
            :
            <div style={{position: "relative", top: "310px", left:"25px"}}>
               <CommonPageLoader />
            </div>
         }
         </div>
         <div className={`sidebarNavigation open ${this.state.isSideBarClicked ? 'in ' : 'out'} ${this.state.isClosed && 'hide'}`}>
               <NotificationSettings handleSideBar={this.handleSideBar} sideBarClickStatus={this.state.isSideBarClicked} />
            </div>
            <TourGuide/>
         </>
      );
   }
}

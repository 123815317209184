import React, { useState, useEffect } from "react";
// import { NormalInput, NormalButton, Title } from "component/common";
import "./style.scss";
import { CommonPageLoader } from "component/common";
import WorkAssignment from 'component/workShedule/workAssignment';
import AddWorkRequest from "component/workShedule/addWorkRequest";
import WorkAssignmentNew from "component/workShedule/workAssignment new";
import { getNotifications } from "../../../action/notification";
import { getAgencies } from "action/workRequest";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import refreshIcon from "../../../assets/svg/mdi_refresh_white.svg";
import closeIcon from "../../../assets/svg/close.svg";
import NotificationMessages from "./NotificationMessages";


function NotificationSettings(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Allagencies,setAllagencies] = useState([]);
  const [workAssign, setWorkAssign] = useState({value: false, id: null});
  const [visible, setVisible] = useState({ value: false, id: null });
  const [refresh, setRefresh] = useState(false)

  const agencyData = useSelector((state)=>state.agencyDataReducer.agencyData) 


  // const fetchAgency = async()=>{

  //   let userData = JSON.parse(localStorage.getItem("userData"));
  //   let clientid = userData.userId ? userData.userId : userData.id;
  //   let query = {clientId: clientid}

  //   await props.getAgencies(query)
  //      .then((data)=>{
  //       setAllagencies(data);
  //      })
  //      .catch((e)=> setLoading(false));
  // }
  // useEffect(() => {
  //   if(props.sideBarClickStatus)
  //   fetchAll();
  // }, [props.sideBarClickStatus]);


  let notificationCount = useSelector((state)=> state.webSocketReducer.notificationCount)
  useEffect(() => {
    if(props.sideBarClickStatus)
    fetchNotifications()
  }, [props.sideBarClickStatus]);

  useEffect(() => {
    if(props.sideBarClickStatus && notificationCount > 0)
    fetchNotifications()
  }, [notificationCount]);

  useEffect(()=>{
    setAllagencies(agencyData)
    // fetchAgency()
  },[agencyData])

const fetchNotifications = async() =>{
  setLoading(true);
    await props.getNotifications().then((d) => {
      setLoading(false)
      if(d.length)
      d.sort((a,b)=>{return moment(b.date) - moment(a.date);})
      if(d[0]?.notifications.length !== data[0]?.notifications.length){
            setData(d);
        }
    })
    .catch((err)=>{
      setLoading(false)
    })
}
  const fetchAll = async () => {
    const query = {};
    
        setLoading(true);
    await props
      .getNotifications(query)
      .then((data) => {
        if(data.length)
        data.sort((a,b)=>{return moment(b.date) - moment(a.date);})
        setData(data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  let listData = [
    {
      date: "Today",
      notifications: [
        {
          status: "New Agency Onboarded",
          message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        },
        {
          status: "New Plan",
          message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        },
        {
          status: "Pending",
          message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        },
        {
          status: "Pending",
          message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        },
      ],
    },
    {
      date: "06/10/2020",
      notifications: [
        {
          status: "New Agency Onboarded",
          message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        },
        {
          status: "New Plan",
          message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        },
        {
          status: "Pending",
          message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        },
        {
          status: "Pending",
          message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        },
      ],
    },
    {
      date: "05/10/2020",
      notifications: [
        {
          status: "New Agency Onboarded",
          message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        },
        {
          status: "New Plan",
          message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        },
        {
          status: "Pending",
          message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        },
        {
          status: "Pending",
          message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        },
      ],
    },
  ];
  
  return (
    <>
        {/* <Title label="Notification" /> */}
        <div className='d-flex modal-headerNotification'>
                <div className='col-10 '>
                    <h6 className="position-fixed mt-2">Notification</h6>
                </div>
                <div className='col-2 d-flex justify-content-end'>
                    <img className='refreshIconInModel  mt-2' width="19" height="16" src={refreshIcon} onClick={fetchAll} alt="refreshIcon"/>
                    <img className='refreshIconInModel ml-2  mt-2' width="19" height="16"  src={closeIcon} onClick={props.handleSideBar} alt="closeIcon"/>
                </div>
            </div>
      {!loading ? (
        <NotificationMessages data={data} setWorkAssign={setWorkAssign} />
        // <>
        // <div className="down">
        //   {data.length ? (
                                       
        //   data.sort((a,b)=>{return moment(b.date) - moment(a.date);}).map(({ date, notifications }, index) => {

        //       return (
        //         <div className="ml-3 mr-3" key={index}>
        //             <h6 className="font-weight-bold">{moment(date).format(`${localStorage.getItem('dateFormat')}`)}</h6>
        //             {notifications.map((item) => (
        //               <div className="container notification-bar cursor-pointer"
        //               onClick={() => {
        //                 if(item.path.split('/')[1]==="workSchedule"){
        //                   setWorkAssign({value: true, id: item.path.split('/')[2],agencyId:item.path.split('/')[3]});
        //                 }
        //                 // else{
        //                 //   alert("NOT IMPLEMENTED YET.")
        //                 // }
        //               }}>
        //                 <div className="row align-items-start p-3 align-items-center">
        //                   {/* <div className="col-1 d-flex justify-content-start">
        //                                             </div> */}
        //                   <div className="col-10 pl-0 d-flex justify-content-start align-items-center">
        //                     <div className="checkBox col-1"></div>
        //                     <div className="col-11">
        //                       <p className=" pl-3 fs-12 font-weight-bold mb-1 text-grey-42">
        //                         {item.status}
        //                       </p>
        //                       <p className="fs-10 pl-3 wordwrap fixed-height mb-0 text-grey-6c">
        //                         {item.message}
        //                       </p>
        //                     </div>
        //                   </div>
        //                   <div className="col-2 text-right pr-32 ">
        //                     {item.path ? (
        //                       <>
        //                         <p
        //                           className="text-grey-6c mb-0 text-view fs-12" 
        //                         >
        //                           View
        //                         </p>
        //                       </>
        //                      ) : (
        //                      ""
        //                     )}
        //                   </div>
        //                 </div>
        //               </div>
        //             ))}
        //           </div>
        //       );
        //     })
        //   ) : (
        //     <h6 className="text-center">No results Found</h6>
        //   )}
        //   </div>
        // </>
      ) : (
        <div colSpan="12" className="border-0 reports-loading-icon">
          {props.sideBarClickStatus && <CommonPageLoader />}
        </div>
      )}

        <WorkAssignmentNew
          workAssign={workAssign}
          setVisible={setVisible}
          setWorkAssign={setWorkAssign}
          handleReload={() => console.log('reload')}
        />
        <AddWorkRequest
        allAgencies={Allagencies}
        visible={visible}
        setVisible={setVisible}
        handleReload={()=> console.log("reload")}
      /> 
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getNotifications,
      getAgencies,
    },
    dispatch
  );
};

export default connect(mapDispatchToProps, mapDispatchToProps)(NotificationSettings);

import { workRequest } from "../service/apiVariables";
import { addQuery } from "../service/helperFunctions";

// export const getAllWork = (query) => (dispatch, getState, { api, Toast }) => {
//     return new Promise((resolve, reject) => {
//        addQuery(query, workRequest.getAllWork);
//        api({ ...workRequest.getAllWork })
//           .then(( data ) => {
//              resolve(data);
//           })
//           .catch(({ message }) => {
//              reject(Toast({ type: 'error', message }));
//           });
//     });
// };

export const getAllWork =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...workRequest.getAllWork, body })
        .then(({ data, page, message }) => {
          resolve({data,page});
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const getAllLocation =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...workRequest.getAllLocation,query })
        .then(({ data, message }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
  export const getAllRegions = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, workRequest.getRegions);
        api({ ...workRequest.getRegions })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};
export const getAllJobFunctions =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, workRequest.getAllJobFunctions);
      api({ ...workRequest.getAllJobFunctions, query })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
  export const getAllJobCategoriesByClient = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, workRequest.getJobCategoriesBYClient);
      api({ ...workRequest.getJobCategoriesBYClient, query })
        .then(({ data,message }) => {
          resolve(data); 
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
  export const getAgencies = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, workRequest.getAgencies);
      api({ ...workRequest.getAgencies, query })
        .then(({ data,message }) => {
          dispatch({type:"UPDATE_AGENCY_DATA", payload :data})
          resolve(data); 
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
  export const getAllJobNamensByClient = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, workRequest.getJobNamesBYClient);
      api({ ...workRequest.getJobNamesBYClient, query })
        .then(({ data,message }) => {
          resolve(data); 
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const addWork =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...workRequest.create, body })
        .then(({ data, message }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const cancelWork =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...workRequest.cancelWork, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const updateRatings = 
  (body) => 
  (dispatch, getState, { api, Toast}) => {
    return new Promise((resolve, reject) => {
      addQuery(body, workRequest.updateRatings);
      api({ ...workRequest.updateRatings, body })
        .then(({ data, message }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          console.log("Error (Rating update failed..)")
        });
    });
  }

export const completeWorkClient =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...workRequest.completeWork, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const approveStaff =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...workRequest.approveStaff, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const rejectStaff =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...workRequest.rejectStaff, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const completedWork =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...workRequest.completedWork, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const updateWork =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...workRequest.update, body })
        .then(({ data, message }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getSingleWork =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, workRequest.getById);
      api({ ...workRequest.getById, query })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const deleteWork =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      //  addQuery(query, workRequest.delete);
      api({ ...workRequest.delete, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const getAllLocationsByClient =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, workRequest.getAllLocationByClient);
      api({ ...workRequest.getAllLocationByClient, query })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const disApproveTimeSheet =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...workRequest.disApproveTimeSheet, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const addWorkRatings = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...workRequest.addWorkRatings, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

const { START_TOUR_GUIDE } = require("service/actionType");

const initialState = {
    tourGuideData:{
        run: false
    }
  };

  export function startTourGuide(data){
    return {
        type: START_TOUR_GUIDE,
        payload: data,
    }
}

  export default  (state = initialState, action) => {
    switch (action.type) {
      case START_TOUR_GUIDE:
        return {
          ...state,
         tourGuideData:{
          ...state.tourGuideData,
          ...action.payload
         }
        };
      default:
        return state;
    }
  };
import React, { useState, useEffect } from "react";
import "./style.scss";
import {
  TableWrapper,
  CommonPageLoader,
  // NormalDropdown,
} from "component/common";
import Moment from "moment";
// import WorkAssignTimeSheet from "component/workShedule/workAssignTimeSheet";
import {
  // getAllWork,
  cancelWork,
  approveStaff,
  rejectStaff,
} from "action/workRequest";
import moment from "moment";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import interactionPlugin from "@fullcalendar/interaction";
// import timeGridPlugin from "@fullcalendar/timegrid";
import CancelWorkModel from "component/workShedule/cancelWorkModel";
import { startTourGuide } from "reducer/tourGuide";
import { bindActionCreators } from "redux";
import { connect,useDispatch } from "react-redux";

// import verticalDots from "assets/svg/three-dots.svg";
import No_results_found from "assets/svg/no_data_found_illus.svg";
import calendarIcon from "assets/svg/calendar-icon.svg";
import backIcon from "assets/svg/less-than.svg";
import frwdIcon from "assets/svg/greater-than.svg";
import warningIcon from "assets/svg/warning.svg";
// import {ReactComponent as InfoIcon} from "assets/svg/info-icon.svg"
import {ReactComponent as OneStaff} from "assets/svg/oneStaff.svg"
import {ReactComponent as TwoStaff} from "assets/svg/twoStaff.svg"
import { colorByStatus } from "assets/WRColorSchema";
import { NormalDate } from "component/common";
import { Grid } from "@mui/material";
import {useMediaQuery} from "@mui/material";
import RecurringIcon from 'assets/svg/recurrenceIcon.svg'

// const OptionsList = [
//   {
//     label: "View Details",
//     value: "view",
//     icon: viewIcon,
//   },
//   {
//     label: "Edit",
//     value: "edit",
//     icon: editIcon,
//   },
// ];

// const OptionsList1 = [
//   {
//     label: "View Details",
//     value: "view",
//     icon: viewIcon,
//   },
// ];
const headerDetailsTemp = [
  {
    label: 'WR Id',
    isSort: true,
    ascKey:"id",
    key: "WR ID",
  },
  {
    label: "Job Name",
    isSort: true,
    ascKey: "jobName",
    key: "JobName",
  },
  {
    label: "Agency Name",
    isSort: true,
    ascKey: "agencyName",
    key: "Agency Name"
  },
  {
    label: "Shift Date",
    isSort: true,
    ascKey: "startDate",
    key: "shiftDate"
  },
  {
    label: "Shift Time",
    isSort: true,
    ascKey: "startDate",
    key: "ShiftTime"
  },
  {
    label: "Current Status",
    isSort: true,
    ascKey: "statusName",
    key: "currentStatus",
  },
  {
    label: "Actions",
  },
];
// const calendarRef = React.createRef();
function WorkScheduleList({
  refresh,
  handleReload,
  clientId,
  loading,
  setWorkList,
  setPage,
  visible,
  workList,
  query,
  setQuery,
  setVisible,
  setWorkAssign,
  afterLoadCalendar,
  cancelWork,
  approveStaff,
  rejectStaff,
  viewByweek,
  filterStatus,
  StaffStatusLabels,
  showArchives
}) {
  // const [loading, setLoading] = useState(false);
  // const [calendarApi, setCalendarApi] = useState({});
  // const [calendarTitel, setCalendarTitel] = useState("");
  const [headerDetails, setHeaderDetails] = useState(headerDetailsTemp);
  const [isFormLoder, setFormLoder] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [cancelId, setCancelId] = useState("");
  const [agencyId,setAgencyId] = useState("");
  const [showDateInput, setshowDateInput] = useState(false);
  const [ascKey, setascKey] = useState("startDate");
  const [isAscending, setisAscending] = useState(false);
   const [onHover, setOnHover] = useState({status:false, id:''});
   const[loadingKey, setLoadingKey] = useState('')
   const [run, setRun] = useState(false);
   const dispatch = useDispatch();
   let userData = JSON.parse(localStorage.getItem("userData"));
   let agencyID = userData.agencyId ? userData.agencyId : "";
   let OrientationCount = localStorage.getItem('OrientationCount')
   const isMediumScreen = useMediaQuery('(max-width:960px)');
   const isXsOrSmScreen = useMediaQuery('(max-width:960px)');
  // useEffect(() => {
  //   let api = calendarRef.current.getApi();
  //   query.startDate = moment
  //     .utc(api.currentDataManager.state.dateProfile.activeRange.start)
  //     .format("YYYY-MM-DD[T]HH:mm:ss.SSSZZ");

  //   query.endDate = moment
  //     .utc(api.currentDataManager.state.dateProfile.activeRange.end)
  //     .format("YYYY-MM-DD[T]HH:mm:ss.SSSZZ");

  //   let userData = JSON.parse(localStorage.getItem("userData"));
  //   let id = userData.userId ? userData.userId : userData.id;
  //   query.clientId = id;
  //   setQuery(query);
  //   setCalendarTitel(api.currentDataManager.data.viewTitle);
  //   setCalendarApi(api);
  //   afterLoadCalendar(query);
  // }, [query.searchKey, query.sortBy, refresh, query.location, query.viewBy]);

  // useEffect(() => {
  //   // if (Number(calendarGrid) === 0) {
  
  //   let api = calendarRef.current.getApi();
    
  //   query.startDate = moment
  //     .utc(api.currentDataManager.state.dateProfile.activeRange.start)
  //     .format();
  //   query.endDate = moment
  //     .utc(api.currentDataManager.state.dateProfile.activeRange.end)
  //     .format();
  //   setQuery(query);
  //   setCalendarTitel(api.currentDataManager.data.viewTitle);
  //   setCalendarApi(api);
  //   console.log("viewByWeek is trigered now and working properly ::: " + viewByweek);
  // }, [
  //   viewByweek,
  // ]);

  // const fetchAll = async () => {
  //     setLoading(true);
  //     await getAllWork(query).then((data) => {
  //         // setData(data.data);
  //         setLoading(false);
  //     })
  //         .catch((e) => {
  //             setLoading(false);
  //         });
  // };

  const handleTour = ({target:{value}}) => {
    dispatch(startTourGuide({run: true}))
  }

  useEffect(() => {
    let localQuery = {...query}
    if (viewByweek === "Days") {
      localQuery.startDate = moment(localQuery.startDate).startOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
      localQuery.endDate = moment(localQuery.startDate).endOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    } else if (viewByweek === "Week") {
      localQuery.startDate = moment(localQuery.startDate).startOf("week").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
      localQuery.endDate = moment(localQuery.startDate).endOf("week").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    } else if (viewByweek === "Month") {
      localQuery.startDate = moment(localQuery.startDate).startOf("month").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
      localQuery.endDate = moment(localQuery.startDate).endOf("month").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    }
    if(run){
      setQuery(localQuery);
    }
    setRun(true)
    // afterLoadCalendar(query);
  }, [viewByweek]);

  // const handleToggle = () => {
  //   setVisible(!visible);
  // };
  //calender next click handle
  const calendarNext = () => {
    // calendarApi.next();
    if (viewByweek === "Week") {
      query.startDate = moment(query.startDate).add(1, "week").startOf("week").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
      query.endDate = moment(query.startDate).endOf("week").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    } else if (viewByweek === "Days") {
      query.startDate = moment(query.startDate).add(1, "day").startOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
      query.endDate = moment(query.startDate).endOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    } else if (viewByweek === "Month") {
      query.startDate = moment(query.startDate).add(1, "month").startOf("month").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
      query.endDate = moment(query.startDate).endOf("month").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    }
    setQuery(query);
    setPage(1);
    afterLoadCalendar(query);
  };

  //calender prev click handle
  const calendarPrev = () => {
    // calendarApi.prev();
    if (viewByweek === "Week") {
      query.startDate = moment(query.startDate)
        .subtract(1, "week")
        .startOf("week")
        .format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
      query.endDate = moment(query.startDate).endOf("week").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    } else if (viewByweek === "Days") {
      query.startDate = moment(query.startDate).subtract(1, "day").startOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
      query.endDate = moment(query.startDate).endOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    } else if (viewByweek === "Month") {
      query.startDate = moment(query.startDate)
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
      query.endDate = moment(query.startDate).endOf("month").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    }
    setQuery(query);
    setPage(1);
    afterLoadCalendar(query);
  };

  //calender prev click handle
  const calendarToday = () => {
    // calendarApi.today();
    query.startDate = moment(new Date()).startOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    query.endDate = moment(new Date()).endOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    setQuery(query);
    afterLoadCalendar(query);
  };

  // manual sorting
  const handleSort = (type, isAsc, key, index) => {
    let tempData = workList;
    if (type === "string") {
      tempData.sort((a, b) => {
        if (a[key] < b[key]) {
          return isAsc === 1 ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return isAsc === 1 ? 1 : -1;
        }
        return 0;
      });
    } else if (type === "date") {
      if (isAsc === 1) {
        tempData.sort((a, b) => new Date(a[key]) - new Date(b[key]));
      } else {
        tempData.sort((a, b) => new Date(b[key]) - new Date(a[key]));
      }
    }
    let tempHeader = [...headerDetails];
    tempHeader[index].isAsc = headerDetails[index].isAsc === 1 ? 0 : 1;
    setHeaderDetails(tempHeader);
    setWorkList(tempData);
  };
  useEffect(()=>{
    // handleSort('string', -1, 'startDate', 2);
  },[workList]);
  // handle single staff approve by Client
  const handleApproveStaff = async (id) => {
    let body = {
      // workId: workID,
      staffId: id,
      agencyId:agencyID[0],
    };
    setFormLoder(true);
    await approveStaff(body)
      .then((data) => {
        setFormLoder(false);
        // setDefault();
        // handleReload();
      })
      .catch(() => {
        setFormLoder(false);
      });
  };

  // handle single staff approve by Client
  const handleRejectStaff = async (id) => {
    let body = {
      // workId: workID,
      staffId: id,
    };
    setFormLoder(true);
    await rejectStaff(body)
      .then((data) => {
        setFormLoder(false);
        // setDefault();
        // handleReload();
      })
      .catch(() => {
        setFormLoder(false);
      });
  };
  // handle confirmation for cancel work
  const handleAction = (action) => {
    if (action) cancelWorkClient();
    else setOpenModal(!openModal);
  };
  //handle work cancel by client
  const cancelWorkClient = async (id) => {
    setLoadingKey('cancel-work')
    let body = {
      workId: cancelId,
      clientId: clientId,
      agencyId: agencyId,
      reason: "Client cancels the work request",
    };
    setFormLoder(true);
    await cancelWork(body)
      .then((data) => {
        setFormLoder(false);
        setOpenModal(false);
        // setDefault();
        setLoadingKey('')
        handleReload();
      })
      .catch(() => {
        setFormLoder(false);
        setLoadingKey('')
      });
  };
  const calendarChooseDay = (date) => {
    // calendarApi.today();
    if (viewByweek === "Days") {
      query.startDate = moment(new Date(date)).startOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
      query.endDate = moment(query.startDate).endOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    } else if (viewByweek === "Week") {
      query.startDate = moment(new Date(date)).startOf("week").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
      query.endDate = moment(query.startDate).endOf("week").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    } else if (viewByweek === "Month") {
      query.startDate = moment(new Date(date)).startOf("month").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
      query.endDate = moment(query.startDate).endOf("month").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    }
    setQuery(query);
    afterLoadCalendar(query);

    setQuery(query);
    afterLoadCalendar(query);
  };
  return (
    <>
      <div className="p-0 bg-white h-100">
        <div className="border-radius-5">
          <div id="workList" className="">
            <div className=" custom-header">
              <div className="row">
                <div className="col-md-5">
                  <div className="nav-btns">
                    <button className="today-btn" onClick={calendarToday}>
                      Today
                    </button>
                  </div>
                </div>
                <div className="col-md-7 d-flex align-items-center">
                  <div className="d-flex align-items-center" id={'work-list-date-slider'}>
                    <span
                      className=" mr-4 cursor-pointer"
                      onClick={calendarPrev}
                    >
                      <img src={backIcon} alt="backIcon" width={'9'} height={'16'} />
                    </span>
                    <span className="cal-icc mb-1"
                    onClick={() => {
                      setshowDateInput(!showDateInput);
                    }}
                    >
                      <img src={calendarIcon} alt="calendarIcon" width={'20'} height={'22'} />
                    </span>
                    <span className="calend-title ml-4 mr-4 mt-1 fs-14">
                        {viewByweek === "Days"
                          ? moment(
                              query.startDate ? query.startDate : new Date()
                            )
                              .startOf("day")
                              .format("MMM DD, YYYY")
                          : ""}
                        {viewByweek === "Week"
                          ? moment(
                              query.startDate ? query.startDate : new Date()
                            )
                              .startOf("week")
                              .format("MMM DD") +
                            " - " +
                            moment(
                              query.startDate ? query.startDate : new Date()
                            )
                              .endOf("week")
                              .format("MMM DD,YYYY")
                          : ""}
                        {viewByweek === "Month"
                          ? moment(
                              query.startDate ? query.startDate : new Date()
                            )
                              .startOf("month")
                              .format("MMM DD") +
                            " - " +
                            moment(
                              query.startDate ? query.startDate : new Date()
                            )
                              .endOf("month")
                              .format("MMM DD,YYYY")
                          : ""}
                    </span>
                    <span className="cursor-pointer" onClick={calendarNext}>
                      <img src={frwdIcon} alt="frwdIcon" width={'9'} height={'16'} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="work-schedule-list">
              <FullCalendar
                headerToolbar={false}
                ref={calendarRef}
                plugins={[timeGridPlugin]}
                initialView=""
                allDaySlot={false}
                events={[]}
                eventClick={(e) => {}}
                dateClick={(e) => {}}
                dayMaxEvents={true}
                editable={true}
                selectable={true}
                selectMirror={true}
              />
            </div> */}
            {/* <div className="work-schedule-list">
              <FullCalendar
                headerToolbar={false}
                ref={calendarRef}
                plugins={[timeGridPlugin]}
                initialView=""
                allDaySlot={false}
                events={[]}
                eventClick={(e) => {}}
                dateClick={(e) => {}}
              />
            </div> */}
              {showDateInput && (
                <NormalDate
                  placeholder="MM/DD/YYY"
                  name="clockIn"
                  value={query.startDate ? new Date(query.startDate) : ""}
                  className="form-control rounded-0 "
                  onChange={(event) => {
                    calendarChooseDay(event.target.value);
                  }}
                  label={""}
                  icon={false}
                  mindate="false"
                  timeInput="false"
                />
              )}

            <TableWrapper
              headerDetails={headerDetails}
              className="workScheduleList"
              pagination={false}
              handleSort={(ascKey)=>{
                setascKey(ascKey);
                setisAscending(!isAscending);
            }}
            >
              {!loading ? (
                <>
                  {workList.length ? (
                    workList.filter(wRequest=>{
                      if(filterStatus==="2.1") return (wRequest.status===2 && wRequest.workStaffAssignment.staffWorkData.length !== 0 );
                      else if (filterStatus===2) return (wRequest.status===2 && !!wRequest.workStaffAssignment && wRequest.workStaffAssignment.staffWorkData.length === 0 );
                      else return true;
                    })
                    .sort((a, b) => {
                      let key1 = a[ascKey];
                      let key2 = b[ascKey];
                      if (typeof key1 === "string") {
                        key1 = key1.toLocaleLowerCase();
                      }
                      if (typeof key2 === "string") {
                        key2 = key2.toLocaleLowerCase();
                      }
                      if (isAscending) {
                        if (key1 > key2) {
                          return -1;
                        } else if (key2 > key1) {
                          return 1;
                        }
                      } else {
                        if (key1 < key2) {
                          return -1;
                        } else if (key2 < key1) {
                          return 1;
                        }
                      }
                      return 0;
                    })
                    .map((item, index) => {
                      let {
                        // workRequest,
                        agencyId,
                        agencyName,
                        endDate,
                        priority,
                        jobName,
                        startDate,
                        status,
                        id,
                        isArchived,
                        statusName,
                        repeat
                      } = item;
                      let staffNames = []
                      let noOfWorkers = item.workStaffAssignment? item.workStaffAssignment?.staffWorkData?.length : 0 
                       staffNames = item.workStaffAssignment? item.workStaffAssignment?.staffWorkData.map(x => {
                        let statusCode = x.status
                        let tempRejectedStaff = item.workStaffAssignment? item.workStaffAssignment?.rejectedStaff ?item.workStaffAssignment?.rejectedStaff: [] : []
                        let rejectedByStaff = tempRejectedStaff.some((rStaff)=> rStaff.staffId === x.staffId && rStaff.userType == 6)
                        let rejectedByClient = tempRejectedStaff.some((rStaff)=> rStaff.staffId === x.staffId && rStaff.userType == 7)
                         return {name:x.staffName,
                           status:{
                            name:StaffStatusLabels(statusCode, status, x.clockIn, x.pauseResumeData, x.clockOut, x?.cancelByWork, rejectedByStaff, rejectedByClient)
                            
                          }}
                       }) : []
                   
                      return (
                        
                          <tr className="workschedule-tr my-2" key={index} id='work-list-item'>
                            {/* <td>
                              <div className="d-flex align-items-center h-100">
                              <p className="mb-0">{workRequest}</p>
                              </div>
                            </td> */}
                            <td>
                              <div className="d-flex align-items-center h-100 fs-13">
                                <p className='mb-0'>{`${!!id && id.substr(id.length - 6)}`}</p>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center h-100 text-capitalize fs-13" id={!item.hasOwnProperty('jobName') || jobName == null ? "list-job-name":""}>
                                {!item.hasOwnProperty('jobName') || jobName == null ? <> Select job name  <img style={{width:"20px",marginLeft:"5px"}} src={warningIcon}/></> : jobName}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center h-100 text-capitalize fs-13">
                                <p className="mb-0">{agencyName}</p>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center h-100 fs-13">
                                {Moment(startDate).format(`${localStorage.getItem('dateFormat')}`)}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center h-100 fs-13">
                                {Moment(startDate).format("hh:mm A")} - {Moment(endDate).format("hh:mm A")}
                              </div>
                            </td>
                            <td 
                            onMouseEnter={()=> setOnHover({status:true, id:id})}
                              onMouseLeave={()=> setOnHover({status:false, id:''})}
                            >
                              <div 
                              className="d-flex  align-items-center h-100 fs-13"
                              >
                                <div className="status-color-box" style={{"--dynamic-color-value": ((status=== 2) && item.workStaffAssignment?.staffWorkData?.length !== 0) ? colorByStatus?.["2.1"]?.color :colorByStatus?.[status]?.color}}></div>
                                {isArchived === 1 ? (
                                  <span className="required">CANCELED</span>
                                ) : (
                                 <div 
                                 className="d-flex gap-5 justify-content-between  mt-1 align-items-center status-name-pos">
                                <span  className={status===0?"required ":""}>{`${statusName} `}</span>
                                <div 
                                style={{cursor:'pointer', margin:'10px' }}>
                            { onHover.status & onHover.id == id ?
                                <div
                                style={{zIndex: "2"}}
                                className=" work-schedule-list-popup bg-white rounded   position-absolute d-flex flex-column align-items-center "
                                >
                                <div className="table-multiple-staff">
                                {noOfWorkers > 0 ? <div className="container ">
                                          <div className="row pt-2">
                                                 <div className="col"> Name</div>
                                                  <div className="col"> Status</div>
                                          </div>
                                          </div> : <span className="pt-4 px-2">STAFF NOT ASSIGNED</span>}
                               {staffNames?.length ? staffNames.map(x => <div className="w-100 px-2 my-2  h-100 d-flex"><span style={{textAlign:'left',width:'35%', overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>{x.name}</span><span style={{textAlign:'left'}} className="mr-2"></span><span style={{textAlign:'left',width:'60%'}}>{x.status.name}</span></div>) : null}

                               </div>
                               </div> : null}
                                {/* <InfoIcon fill="green"  style={{width:'30px', height:'30px',  borderRadius:"100%"}} /> */}
                                </div>
                         <div>
                          { noOfWorkers > 0 ? null : <OneStaff fill="white" stroke="white" strokeWidth="20"  style={{width:'25px', height:'25px', backgroundColor:'grey', borderRadius:"100%"}} /> }
                          { noOfWorkers === 1 ? <OneStaff fill="white" stroke="white" strokeWidth="20"  style={{width:'25px', height:'25px', backgroundColor:'#6666FF', borderRadius:"100%"}} /> : null}
                          { noOfWorkers > 1 ? <TwoStaff fill="blue"  style={{width:'30px', height:'30px',  borderRadius:"100%"}} /> : null} 
                        </div> 
                     {noOfWorkers > 0 ? 
                      <p  className='px-2 rounded-circle bg-warning d-flex justify-content-center align-items-center'  style={{fontSize:'11px', height:'21px', minWidth:'21px', alignItems:'center' }}>{` ${noOfWorkers}`}</p>
                     : <p  className= 'px-2 rounded-circle d-flex justify-content-center align-items-center'  style={{fontSize:'11px', height:"21px", minWidth:"21px" ,backgroundColor:'grey', color:'white' }}>{` ${noOfWorkers}`}</p>
                     }
                               </div>
                               )}
                               {repeat && <img src={RecurringIcon} title='Recurrence WR' style={{marginLeft:'6px'}} width={'25'} height={'20'} />}
                              </div>
                            </td>

                            <td>
                                <Grid sx={{
                                          display: 'flex',
                                          flexDirection: isXsOrSmScreen ? 'column' : 'row',
                                        }} id={'work-list-item-btns'} > 
                                 <Grid item xs={12} sm={6} md={4} lg={3}>
                                   <span
                                      className="action-span cursor-pointer text-view btn btn-sm fs-13 font-Montserrat"
                                      onClick={(e) => {
                                        
                                        setWorkAssign({ value: true, id: id, agencyId:agencyId});
                                      }}
                                    >
                                      View
                                    </span>
                                   </Grid>
                                   <Grid item xs={12} sm={6} md={4} lg={3}>
                                    {status >= 3 || status ===0 || ((status=== 2) && item.workStaffAssignment?.staffWorkData?.length !== 0) ?(
                                      <span className={`action-span no-drop btn btn-sm btn-muted fs-13 font-Montserrat ${isMediumScreen ? "":"ml-3"}  ${showArchives ? 'visibility-hidden':''}`}>
                                      Edit
                                    </span>
                                  ) :(
                                <span
                                      className={`action-span cursor-pointer text-edit btn btn-sm fs-13 font-Montserrat ${isMediumScreen ? "":"ml-3"} ${showArchives ? 'visibility-hidden':''}`}
                                      onClick={(e) => {
                                        setVisible({ value: true, id: id,agencyId:agencyId });
                                      }}
                                    
                                    >
                                      Edit
                                    </span>
                                  )}
                                  </Grid>
                                  {/* <span className='action-span'>   Approve  </span>
                                                  <span className={`action-span ml-3`} onClick={()=>{
                                                      console.log("click")
                                                  }}>Reject   </span> */}
                                  <Grid item xs={12} sm={6} md={4} lg={3}> 
                                  {status === 0 || status > 4 ? (
                                    <span className={`action-span no-drop btn btn-sm btn-muted fs-13 font-Montserrat ${isMediumScreen ? "":"ml-3"} ${showArchives ? 'visibility-hidden':''}`}>
                                      Cancel
                                    </span>
                                  ) : (
                                    <span
                                      className={`action-span text-white cursor-pointer btn btn-sm btn-danger fs-13 ${isMediumScreen ? "":"ml-3"} ${showArchives ? 'visibility-hidden':''}`}
                                      onClick={() => {
                                        setAgencyId(agencyId)
                                        setCancelId(id);
                                        setOpenModal(true);
                                      }}
                                    >
                                      Cancel
                                    </span>
                                  )}
                                 </Grid>
                                </Grid>
                                
                                  
                                {/* <span className="action-span ml-4">
                                  <NormalDropdown
                                    onClick={(e) => {
                                      console.log(
                                        e.target.value,
                                        "e.target.value"
                                      );
                                      if (e.target.value === "view") {
                                        setWorkAssign({ value: true, id: id });
                                      } else if (e.target.value === "edit") {
                                        if (status !== 0 && status < 3)
                                          setVisible({ value: true, id: id });
                                      }
                                      // } else if (e.target.value === "edit") {
                                      // props.history.push(
                                      //     `/admin/userManagement/${item.id}`
                                      // );
                                      // }
                                    }}
                                    label={<img src={verticalDots} />}
                                    optionsList={
                                      // status >= 3 || status === 0
                                      status > 3 || status === 0
                                        ? OptionsList1
                                        : OptionsList
                                    }
                                    className="subscribe-dots"
                                    // showModal={this.showModal}
                                  />
                                </span> */}
                              
                            </td>
                            {/* <td>
                                            <div className="horizontal-menu"></div>
                                            <NormalDropdown
                                            onClick={(e) => {
                                                console.log(e.target.value, "e.target.value");
                                                // if (e.target.value === "view") {
                                                // setUserId(item.id);
                                                // setVisible(!visible);
                                                // } else if (e.target.value === "archive") {
                                                // setArchive(!archive);
                                                // setArchiveData({
                                                //     userId: item.id,
                                                //     status: 0,
                                                // });
                                                // } else if (e.target.value === "edit") {
                                                // props.history.push(
                                                //     `/admin/userManagement/${item.id}`
                                                // );
                                                // }
                                            }}
                                            label={
                                                <img src={verticalDots}/>
                                            }
                                            optionsList={OptionsList}
                                            className="subscribe-dots"
                                            // showModal={this.showModal}
                                            /> 
                                        </td> */}
                          </tr>
                      );
                    })
                  ) : (
                    <td colSpan="12" className="border-0 pt-4">
                    <img className="preload-image no_result_found_ws" src={No_results_found}
                      onLoad={() => {
                        document.querySelector('.preload-image').style.display = 'block';
                      }}
                    />
                   { OrientationCount ==="true"? <p style={{fontSize:"13px", margin:"auto", textAlign:"center"}}>Not Sure Where To Start? <u style={{color: "#3FB1FB",cursor:"pointer"}} onClick={handleTour}>Click Product Tour</u> to know more</p>:"" }
                  </td>
                  )}
                </>
              ) : (
                <tr>
                  <td colSpan="12" className="border-0">
                  <div className="common-loader"><CommonPageLoader /></div>
                </td>
                </tr>
              )}
            </TableWrapper>
          </div>
        </div>
        {/* <div className="d-flex">
                    <NormalButton
                        onClick={this.handleToggle}
                        label="Assignment Details"
                        className="text-white bg-c2"
                    />
                </div> */}
        {/* {visible ? <WorkAssignTimeSheet></WorkAssignTimeSheet> : ''} */}
        <CancelWorkModel
          toggle={() => setOpenModal(!openModal)}
          isOpen={openModal}
          handleAction={(e) => handleAction(e)}
          loadingCheckKey='cancel-work'
          loadingKey={loadingKey}
        />
      </div>
    </>
  );
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      cancelWork,
      approveStaff,
      rejectStaff,
    },
    dispatch
  );
};
export default connect(
  mapDispatchToProps,
  mapDispatchToProps
)(WorkScheduleList);

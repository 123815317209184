import React, { useState, useEffect, Component } from "react";
// import { NavLink } from "react-router-dom";
import { logout } from "../../../service/utilities";
import "./navbar.scss";
import nav_bell from "../../../assets/svg/mdi_notifications .svg";
import nav_bell_grey from '../../../assets/svg/notificationGrey.svg';
import nav_user from "../../../assets/svg/user.svg";
import logoutIcon from "../../../assets/svg/logout.svg";
import menu from "../../../assets/svg/menu.svg";
// import logo from "../../../assets/svg/agency_logo_base.png";
import { getNotificationsCout } from "action/notification";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { NormalSwitch } from '../NormalSwitch';
import { TourSwitch } from '../TourGuide/TourSwitch';
import { startTourGuide } from 'reducer/tourGuide';
import { useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Navbar = (props) => {
  const [search, setSearch] = useState("none");
  const [count, setCount] = useState(0);

  const dispatch = useDispatch()

  let notificationCount = useSelector((state)=> state.webSocketReducer.notificationCount)
  let tourGuideData = useSelector((state) => state.tourGuideReducer.tourGuideData);
   const handleTour = ({target:{value}}) => {
    dispatch(startTourGuide({run:value}))
    // close the notification box if tour guide is turned on
    if(props.sideBarClickStatus) {
      props.handleSideBar()
        return;
      }
  }

  useEffect(()=>{
    if(tourGuideData.run && props.sideBarClickStatus) {
      props.handleSideBar()
    }
    }, [tourGuideData.run])

    useEffect(()=>{
      setCount(notificationCount)
     },[notificationCount]);
     
     useEffect(()=>{
      getNotifyCount()
     },[]);

  const getNotifyCount = async () => {
    try {
      const data = await props.getNotificationsCout({});
   if (data) {
        setCount(data.count || 0);
    }
    } catch (error) {
     console.log("Failed to load data.");
   }
 };
  
  if (!localStorage.getItem("userData")){
    logout();
  }
  let userData = JSON.parse(localStorage.getItem("userData"));
  let clientName = userData.clientName ? userData.clientName : "User Name";
  let clientContacts = userData.clientContacts ? userData.clientContacts : "Client Name";
    //  let sitePicture = userData.clientAddress[0].sitePicture
    //    ? userData.sitePicture
    //    : { nav_user };
    // console.log(userData.clientAddress[0].sitePicture);
    let isConnected = useSelector((state) => state.webSocketReducer.isConnected);

  return (
    <>
      <div className="navbar fixed-top p-2">
        <div className="d-flex justify-content-end w-100 align-items-center">
          {/* <div className='search-field'>
                     <input
                        className='form-control '
                        type='text'
                        placeholder='search...'
                     />
                  </div> */}
          <div className="mr-4 d-flex align-items-center pr-3 nav-logo">
            <img
              width="20"
              height="14"
              src={menu}
              onClick={props.handleNav}
              className="mb-3 mr-1 cursor-pointer menuIcon"
              alt="menu"
            />
            <img src={""} className=""/>
            {/* <p className="ml-3 text-violet">powered by Staff IQ</p> */}
          </div>
          <div className="col-12 position-absolute d-flex justify-content-center alpha">
          <p className="pr-5 font_size mv-left">{clientName}
            </p>
          </div>
          <div className={`mr-4 mb-2 ${(userData.status === 2 || userData.status === 3) && 'disable-click'}`} >
            <TourSwitch
              checked={tourGuideData.run}
              onChange={handleTour}
              className=" p-2 fs-13 px-5 btn btn-dark-primary border btn-block"
            />
            </div>
          <div className={`mr-1 pr-3 bell position-relative ${(userData.status === 2 || userData.status === 3) && 'disable-click'}`}>
            {/* {"/admin/notification"} target="_blank"> */}
            <img
              src={`${props.sideBarClickStatus?nav_bell : nav_bell_grey}`}
              width="20"
              height="20"
              className="mb-2 cursor-pointer"
              alt="navbell"
              onClick={() => {

                // localStorage.setItem('notifications', 0);
                props.handleSideBar();
              }}
            />
            <span className="badge my-badge">{!!count ? count : ""}</span>
          </div>

          <div className="d-flex align-items-center pr-3 user_client hover-img">
          <OverlayTrigger placement="left" overlay={<Tooltip >{userData.status === 2? "Please restore your account to get instant updates" : isConnected ? 'You are now connected to get updates instantly': 'You are now disconnected to get updates instantly, Please refresh'}</Tooltip>}>
          <div className='d-flex align-items-center mr-3 mb-2 position-absolute' style={{bottom:'5px', left:'-5px', zIndex:1}}>
        <div className={`${userData.status === 2 ? 'bg-danger' : isConnected ? 'connected': 'bg-danger'}`} style={{borderRadius:'50%', minWidth:'15px', minHeight:'15px', border:'2px solid white'}}></div>
        </div>
        </OverlayTrigger>
            <img src={nav_user} className="mb-3 mt-1" alt="nav_user" width="26" height="26" />
            <span className="extra-hover mx-2">
              {clientContacts[0].contactPerson}
            </span>
          </div>
          <div className="d-flex align-items-start mr-4">
            <div className="vert-line mr-3"></div>
            <div
              className="d-flex align-items-center cursor-pointer logout_icon position-relative"
              onClick={() => logout(true)}
            >
              <img className="" src={logoutIcon} alt="user" width="14" height="14" />
              <p className="ml-3 mb-1 logout-text text-light-violet fs-13">Logout</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


// class Navbar extends Component {
//   constructor() {
//     super();
//     this.state = {
//       search: "none",
//       count: 0,
//     };
//   }

//   // Fix for blank screen issue
//   async getNotifyCount() {

//     await this.props.getNotificationsCout({})
//       .then((data) => {
//         if (data) {
//           this.setState((prevState) => ({
//             ...prevState,
//             count: data.count || 0,
//           }));
//         }
//       })
//       .catch((e) => console.log('failed to load data.'));
//   }

//   // Before Blank screen issue
//   // async getNotifyCount(){
//   //   //console.log("getNotificationCount is not working yet");

//   //   await  this.props.getNotificationsCout({})
//   //   .then((data) => {
//   //     this.setState(prevState => {
//   //       return {
//   //         ...prevState, count: data.count
//   //       };
//   //     });
      
//   //   })
//   //   .catch((e) => console.log('failed to load data.'));
//   // }

//   componentDidMount(){
//     const intervalId = setInterval(() => {
//       this.getNotifyCount();
//     }, 5000);
//   }

//   componentWillUnmount(){
//     clearInterval(this.intervalId);
//   }

//   render() {
//     if(!!!localStorage.getItem("userData")){
//       logout();
//     }
//     let userData = JSON.parse(localStorage.getItem("userData"));
//     let clientName = userData.clientName ? userData.clientName : "User Name";
//     let clientContacts = userData.clientContacts ? userData.clientContacts : "Client Name";
//     //  let sitePicture = userData.clientAddress[0].sitePicture
//     //    ? userData.sitePicture
//     //    : { nav_user };
//     // console.log(userData.clientAddress[0].sitePicture);

//     return (
//       <>
//         <div className="navbar fixed-top p-2">
//           <div className="d-flex justify-content-end w-100 align-items-center">
//             {/* <div className='search-field'>
//                      <input
//                         className='form-control '
//                         type='text'
//                         placeholder='search...'
//                      />
//                   </div> */}
//             <div className="mr-4 d-flex align-items-center pr-3 nav-logo">
//               <img
//                 width="20" 
//                 height="14" 
//                 src={menu}
//                 onClick={this.props.handleNav}
//                 className="mb-3 mr-1 cursor-pointer menuIcon"
//                 alt="menu"
//               />
//               <img src={""} className=""/>
//               {/* <p className="ml-3 text-violet">powered by Staff IQ</p> */}
//             </div>
//             <div className="col-12 position-absolute d-flex justify-content-center alpha">
//             <p className="pr-5 font_size mv-left">
//             {clientName}
//             </p>
//             </div>
//             <div className="mr-3 pr-3 bell position-relative">
              
//                {/* {"/admin/notification"} target="_blank"> */}
               
//                 <img 
//                 src={`${this.props.sideBarClickStatus?nav_bell:nav_bell_grey}`}
//                 width="20" 
//                 height="20" 
//                 className="mb-3 cursor-pointer"
//                 alt="navbell"
//                 onClick={() => {
                  
//                   // localStorage.setItem('notifications', 0);
//                   this.props.handleSideBar() }}
                  
//                   />
//                 <span className="badge my-badge">{!!this.state.count ?this.state.count:''}</span>
              
//             </div>
//             <div className="d-flex align-items-center pr-3 user_client hover-img">
//               <img src={nav_user} className="mb-3" alt="nav_user" width="26" height="26" />
//               <span className="extra-hover mx-2">
//               {clientContacts[0].contactPerson}
//               </span>
//             </div>
//             <div className="d-flex align-items-start mr-4">
//               <div className="vert-line mr-3"></div>
//               <div
//                 className="d-flex align-items-center cursor-pointer logout_icon position-relative"
//                 onClick={() => logout()}
//               >
//                 <img className="" src={logoutIcon} alt="user" width="14" height="14" />
//                 <p className="ml-3 mb-1 text-light-violet fs-13">Logout</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getNotificationsCout,
    },
    dispatch
  );
};

export default connect(mapDispatchToProps, mapDispatchToProps)(Navbar);

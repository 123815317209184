import React, { useEffect, useState } from 'react'
import './style.scss'

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { getFileFromMDB } from 'action/fileHandler';
import AttachmentIcon from 'assets/svg/attachment.svg'
const DisplayFile = ({getFileFromMDB,  file, agencyId}) => {
  const [onlineImage, setOnlineImage] = useState('')
  const [attatchment, setAttatchment] = useState({status:false, type:''})
  const [title, setTitle] = useState('')

    const handleImageDownload = async (fileId) => {
        let imageData;
         await getFileFromMDB({agencyId, entity:''},fileId)
         .then((response)=>{
            handleContentType(response.contentType)
            setTitle(response.title)
          imageData = `data:${response.contentType};base64,${response.fileContent.data}`
          setOnlineImage(imageData.toString())
          return imageData
         })
      }

      const handleContentType = (type) =>{
          if(type.split('/')[0] === 'image'){
            setAttatchment({status:false})
          }else{
            setAttatchment({status:true, type:type.split('/')[1]})
          }
      }

      useEffect(()=>{
        if(file && file[0]?.split('|')[0]){
          handleImageDownload(file[0]?.split('|')[0])
        }else{
          setOnlineImage(null)
        }
      },[file])

  return (<>
  {attatchment.status ?(<div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
           <img src={AttachmentIcon}/>
           <label className='font-weight-bold mb-0 mt-2'><span className='upload-filename'>{title.split('-')[1]}</span> Attatched</label> 
        </div>): null }

  {!attatchment.status ?(<> {onlineImage  ? 
      <img className="w-100 h-100"
         src={onlineImage} /> 
           
         :
       <div className="w-100 h-100 d-flex justify-content-center align-items-center">
           <span className='font-weight-bold mb-0'>NA</span> 
        </div> }  </>): null}
</>)
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        getFileFromMDB
      },
      dispatch
    );
  };

  export default connect('', mapDispatchToProps)(DisplayFile)
import React from "react";
import clockIcon from "assets/svg/mdi_blue-clock-outline.svg"
import moment from "moment";
// import { msToTime } from "service/utilities";
import flag from 'assets/svg/flag.svg'
import "../../../assets/scss/index.scss"


export default function TotalHours({ staffAvailable, work, data }) {
  return (
    <div className="row container-fluid mb-3">
      <div className="col-md-4 d-flex " style={{width:"13rem"}}>
        <img src={clockIcon}  height="25px" width="16px" alt="clockIcon"/>
        <span className="fs-14 ml-3 s2-r">Work Hours</span>
      </div>
      <div className="col-md-5">
        <span className="fs-13 s2-m">
          {staffAvailable && !!data.clockIn
            ? moment(
                staffAvailable &&
                  data.clockIn
              ).format("hh:mm:ss a")
            : "Pending"}{" "}
          -{" "}
          {staffAvailable && !!data.clockOut
            ? moment(
                staffAvailable &&
                  data.clockOut
              ).format("hh:mm:ss a")
            : "Pending"}
        </span>
      </div>
      <div className="col-md-3 row no-padding" style={{width:"15rem",padding:"0"}}>
        <span className="sub-text d-flex justify-content-center align-items-center fs-13 col-md-9 no-padding s2-m">
          {/* TODO: Need to enable once the backend api is working fine. */}
          {staffAvailable &&
          data.staffWorkedHrs !== undefined
            ? data.staffWorkedHrs.toFixed(
                4
              )
            : "Pending"}{" "}
          hours
          <div className="flag-icon col-md-3 row no-padding ">
                                                        { (parseFloat(data.staffWorkedHrs).toFixed(4)) > ((moment(work.endDate) - moment(work.startDate)) / (60*1000*60)) ? 
                                                      <> 
                                                       <img className=" ml-2 cursor-pointer mt-1 " src={flag} alt="flag"/>
                                                        <span className='flagicon-msg mb-1 s2-m'> Staff has worked more than expected time </span>
                                                        </>
                                                        : ""}
                                                        </div>
          {/* {staffAvailable &&
          work.workStaffAssignment.staffWorkData.filter(
            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
          )[0].staffWorkedHrs != undefined ? work.workStaffAssignment.staffWorkData
            .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
            .staffWorkedHrs.split(".")[0] >= 0 ? (
            work.workStaffAssignment.staffWorkData
              .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
              .staffWorkedHrs.split(".")[0] +
            (work.workStaffAssignment.staffWorkData
              .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
              .staffWorkedHrs.split(".")[0] > 1
              ? " hrs "
              : " hr")
          ) : "" +
            +work.workStaffAssignment.staffWorkData
              .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
              .staffWorkedHrs.split(".")[1] >=
          0 ? (
            work.workStaffAssignment.staffWorkData
              .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
              .staffWorkedHrs.split(".")[1] +
            (work.workStaffAssignment.staffWorkData
              .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
              .staffWorkedHrs.split(".")[1] > 1
              ? " mins"
              : "min")
          ) : (
            ""
          ) : (
            ""
          )} */}
          {/* {msToTime(
            new Date(
              work.workStaffAssignment.staffWorkData.length > 0 &&
                work.workStaffAssignment.staffWorkData.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                ).length > 0 &&
                work.workStaffAssignment.staffWorkData.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                )[0].clockOut,
            ).getTime() -
              new Date(
                work.workStaffAssignment.staffWorkData.length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  )[0].clockIn,
              ).getTime(),
          ).hours >= 0 ? (
            msToTime(
              new Date(
                work.workStaffAssignment.staffWorkData.length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  )[0].clockOut,
              ).getTime() -
                new Date(
                  work.workStaffAssignment.staffWorkData.length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    ).length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    )[0].clockIn,
                ).getTime(),
            ).hours +
            (msToTime(
              new Date(
                work.workStaffAssignment.staffWorkData.length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  )[0].clockOut,
              ).getTime() -
                new Date(
                  work.workStaffAssignment.staffWorkData.length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    ).length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    )[0].clockIn,
                ).getTime(),
            ).hours > 1
              ? " hrs"
              : " hr")
          ) : (
            ""
          )}{" "}
          {msToTime(
            new Date(
              work.workStaffAssignment.staffWorkData.length > 0 &&
                work.workStaffAssignment.staffWorkData.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                ).length > 0 &&
                work.workStaffAssignment.staffWorkData.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                )[0].clockOut,
            ).getTime() -
              new Date(
                work.workStaffAssignment.staffWorkData.length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  )[0].clockIn,
              ).getTime(),
          ).minutes >= 0 ? (
            msToTime(
              new Date(
                work.workStaffAssignment.staffWorkData.length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  )[0].clockOut,
              ).getTime() -
                new Date(
                  work.workStaffAssignment.staffWorkData.length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    ).length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    )[0].clockIn,
                ).getTime(),
            ).minutes +
            (msToTime(
              new Date(
                work.workStaffAssignment.staffWorkData.length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  )[0].clockOut,
              ).getTime() -
                new Date(
                  work.workStaffAssignment.staffWorkData.length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    ).length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    )[0].clockIn,
                ).getTime(),
            ).minutes > 1
              ? " mins"
              : " min")
          ) : (
            ""
          )} */}
        </span>
      </div>
    </div>
  );
}

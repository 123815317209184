import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "./date.scss";
// import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export class NormalDate extends Component {
  filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  render() {
    let {
      id='',
      className = "form-control w-100",
      placeholder = `${localStorage.getItem('dateFormat')}`,
      onChange,
      value = "",
      name,
      disabled = false,
      isReadOnly = false,
      showYearPicker = false,
      showFullMonthYearPickerStatus = false,
      showMonthYearPickerStatus = false,
      showYearDropdown = false,
      showMonthDropdown = false,
      maxdate = false,
      mindate = false,
      timeInput = false,
      label = "",
      showTimeSelect = false,
      timeIntervals = 30,
    } = this.props;

    const filterPassedTime = (time) => {
      const currentDate = new Date();
      const selectedDate = new Date(time);

      return currentDate.getTime() < selectedDate.getTime();
    };
    return (
      <>
        {!showTimeSelect ? (
          <div className="normal-date-input">
            {label !== "" ? (
              <div className="fs-12 text-black font-Gilroy pb-2 mb-1 line-height-20">
                {label}
              </div>
            ) : (
              ""
            )}
            {!isReadOnly ? (
              <DatePicker
              id={id}
                timeInputLabel={timeInput && "Time:"}
                showTimeInput={timeInput}
                className={className}
                disabled={disabled}
                placeholderText={placeholder}
                selected={value}
                showYearPicker={showYearPicker}
                showMonthYearPicker={showMonthYearPickerStatus}
                showFullMonthYearPicker={showFullMonthYearPickerStatus}
                showMonthDropdown={showMonthDropdown}
                showYearDropdown={showYearDropdown}
                dateFormat={`${localStorage.getItem('dateFormat')==="DD/MM/YYYY"?"dd/MM/yyyy":"MM/dd/yyyy"}`}
                maxDate={maxdate && new Date()}
                minDate={mindate && new Date()}
                autoComplete="off"
                onChange={(date, e) => {
                  let body = {};

                  body = {
                    target: {
                      name: name,
                      value: date,
                    },
                  };
                  if(e?.target.value) { 
                    const isValid = moment(e.target.value,localStorage.getItem('dateFormat'),true).isValid() 
                    isValid && onChange(body);
                  } 
                  else {
                    onChange(body);
                  }
                }}
                name={name}
              />
            ) : (
              <p>{value}</p>
            )}
          </div>
        ) : (
          <div className="normal-date-input">
            {label !== "" ? (
              <div className="fs-12 text-black font-Gilroy pb-2 mb-1 line-height-20">
                {label}
              </div>
            ) : (
              ""
            )}
            {!isReadOnly ? (
              <DatePicker
                filterTime={this.filterPassedTime}
                showTimeSelect={showTimeSelect}
                timeIntervals={timeIntervals}
                showTimeSelectOnly
                showPopperArrow={false}
                // timeInputLabel={timeInput && "Time:"}
                timeCaption="HH:MM"
                // showTimeInput={timeInput}
                className={className}
                disabled={disabled}
                placeholderText={placeholder}
                selected={value}
                // showYearPicker={showYearPicker}
                // showMonthYearPicker={showMonthYearPickerStatus}
                // showFullMonthYearPicker={showFullMonthYearPickerStatus}
                // showMonthDropdown={showMonthDropdown}
                // showYearDropdown={showYearDropdown}
                dateFormat="h:mm a"
                // maxDate={maxdate && new Date()}
                // minDate={mindate && new Date()}
                autoComplete="off"
                onChange={(date) => {
                  let body = {};

                  body = {
                    target: {
                      name: name,
                      value: date,
                    },
                  };

                  onChange(body);
                }}
                name={name}
              />
            ) : (
              <p>{value}</p>
            )}
          </div>
        )}
      </>
    );
  }
}

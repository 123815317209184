import React,{useEffect, useState, useRef} from "react";
import CustomLoader from "../CustomLoader";
import peachTriangle from "assets/svg/Polygon 13.svg";
import greyTriangle from "assets/svg/Polygon grey.svg";
import rejectedThumbsdown from "assets/svg/thumsdown_rejected.svg";
import rejectedThumbsup from "assets/svg/thumsup_rejected.svg"
import "./RejectAccordian.scss"


const RejectAccordian = (props)=>{

    const [show, setShow] = useState(false);
    const [newchecked,setnewChecked] = useState([]);
    const [texarea,setTextarea] = useState("");
    const [data,setData] = useState(props.data);
    const [inputone,setInputone] = useState(false);
    const [inputtwo,setInputtwo] = useState(false);
    const [inputthree,setInputthree] = useState(false);
    const [inputfour,setInputfour] = useState(false);
    const [inputzero,setInputzero] = useState(false);
    const [contact,setContact] = useState(false);
    const [open,setOpeninput]= useState(false);
    const [loadingKey, setLoadingKey] = useState('')
    const containerRef = useRef(null);
    
    const data1 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Dislikes his/her past history"){
            return true
        }
    })
    

    const data2 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Unreliable/Attendance Concerns"){
            return true
        }
    })
    
    const data3 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Communication Problems"){
            return true
        }
    })

    const data4 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "His/Her hiring price is too expensive"){
            return true
        }
    })

    const data0 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Others"){
            return true
        }
    })

    useEffect(()=>{
       if(data0?.length>0){
        setOpeninput(true)
       }
       else if(data0?.length==0){
        setOpeninput(false)
       }
    },[data0])

    const handleCheckChange = (e)=>{

        const {value,checked}=e.target;

        if(checked){
            setnewChecked(pre => [...pre,value])
        }
        else{
            setnewChecked(pre =>{
                return [...pre.filter(elem => elem!==value)]
            })
        }
    }
    
    const handleinputChange = (e)=>{
        
        const {value,checked}=e.target;

        if(value=="Dislikes his/her past history"){
            if(checked==true){
                setInputone(true)
            }
            else{
                setInputone(false)
            }
        }
        if(value=="Unreliable/Attendance Concerns"){
            if(checked==true){
                setInputtwo(true)
            }
            else{
                setInputtwo(false)
            }
        }
        if(value=="Communication Problems"){
            if(checked==true){
                setInputthree(true)
            }
            else{
                setInputthree(false)
            }
        }
        if(value=="His/Her hiring price is too expensive"){
            if(checked==true){
                setInputfour(true)
            }
            else{
                setInputfour(false)
            }
        }
        if(value=="Others"){
            if(checked==true){
                setInputzero(true)
                setOpeninput(true)
            }
            else{
                setInputzero(false)
                setOpeninput(false)
            }
        }
    }

    const handleContactChange = (e) =>{
        if(contact == false){
            setContact(true)
        }
        else{
            setContact(false);
        }
    }
    
    const handletextarea = (e) =>{
        setTextarea(e.target.value)
    }
    


    
    const handleSubmit = (e)=>{
        let id= props.id
         e.preventDefault()
         setLoadingKey('reject-staff')
         props.onSubmit({newchecked,texarea,id,contact})
    }

    const handleClick = ()=>{
        setShow(!show) 
    }

    useEffect(() => {
        if (show) {
          scrollToContainer();
        }
      }, [show, containerRef]);
    const scrollToContainer = () => {
        if (containerRef.current) {
            const containerTop = containerRef.current.getBoundingClientRect().top;
            const offset = 150; 

            if (containerTop < offset) {
              containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else {
              containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
      };
      
    return (
        <>
        <div className={show==true?"outerdiv":""}>
           <div>
            <p className={show==true?"setReject":"setnotReject"} onClick={handleClick}>{data?
            <>REJECTED <img src={rejectedThumbsdown}/></>:
            <>REJECT <img style={{verticalAlign:"text-top"}} src={rejectedThumbsup}/></>}</p>
           </div>
           {show && 

            <div className={data?"frame-236111":"frame-2361"} ref={containerRef}>

                <form onSubmit={handleSubmit}>
                  
                  <img src={data?greyTriangle: peachTriangle} className="white-triangle-reject" />
                    <div class="frame-2355">
                          <div class="frame-2348">
                               <div class="select-reason-s-for-rejecting-work-request" style={{color:"black", fontSize: "0.8125rem",marginTop:"-1.8rem",fontWeight:"600"}}>
                               Select reason(s) for rejecting staff:
                                </div>
                           </div>
                    </div>
                    <div className="d-flex flex-row" style={{gap:'20px'}}>
                    <div>
                    <input onChange={(e)=>handleCheckChange(e)} onClick={(e)=> data?.rejectReasons?.length>0?"":handleinputChange(e)} className="setinput1" type="checkbox" checked={data1?.length>0?true:inputone} id="dislikes-past-history" name="Dislikes his/her past history" value="Dislikes his/her past history" /> <label for="dislikes-past-history" style={{color:"black", fontFamily:"Montserrat", fontSize: "0.8125rem"}}>Dislikes his/her past Work history </label> 
                    <br></br>
                    <input onChange={(e)=>handleCheckChange(e)} onClick={(e)=> data?.rejectReasons?.length>0?"":handleinputChange(e)} className="setinput2" type="checkbox"  checked={data2?.length>0?true:inputtwo} id="unreliable-attendance-concerns" name="Unreliable/Attendance Concerns" value="Unreliable/Attendance Concerns" /> <label for="unreliable-attendance-concerns" style={{color:"black", fontFamily:"Montserrat", fontSize: "0.8125rem"}}> Unreliable/Attendance Concerns</label> 
                    <br></br>
                    <input onChange={(e)=>handleCheckChange(e)} onClick={(e)=> data?.rejectReasons?.length>0?"":handleinputChange(e)} className="setinput2" type="checkbox"  checked={data3?.length>0?true:inputthree} id="communication-problems" name="Communication Problems" value="Communication Problems" /> <label for="communication-problems" style={{color:"black", fontFamily:"Montserrat", fontSize: "0.8125rem"}}> Communication Problems </label> 
                    <br></br>
                    <input onChange={(e)=>handleCheckChange(e)} onClick={(e)=> data?.rejectReasons?.length>0?"":handleinputChange(e)} className="setinput2" type="checkbox"  checked={data4?.length>0?true:inputfour} id="hiring-price-expensive" name="His/Her hiring price is too expensive" value="His/Her hiring price is too expensive" /> <label for="hiring-price-expensive" style={{color:"black", fontFamily:"Montserrat", fontSize: "0.8125rem"}}> His/Her hiring price is too expensive </label> 
                    <br></br>
                    </div>

                    <div className="d-flex flex-column" style={{gap:'4px'}}>
                    <div className="">
                    <input onChange={(e)=>handleCheckChange(e)} onClick={(e)=> data?.rejectReasons?.length>0?"":handleinputChange(e)} className="setinput" type="checkbox"  checked={data0?.length>0?true:inputzero} id="others" name="Others" value="Others" />  <label for="others" style={{color:"black", fontFamily:"Montserrat", fontSize: "0.8125rem"}}> Others </label> 
                    </div>
                    
                    <input disabled={data?true:false} id="othercomments" onChange={handletextarea} className={!open?" hidetextarea":""} rows="3" cols="40" placeholder={data? data.additionalComments:"Enter other reasons..."}></input>
                    <div className="contactdiv">
                        <input onChange={(e)=> data?.rejectReasons?.length>0?"":handleContactChange(e)} id="contact" className="" type="checkbox" checked={data?.contactEnabled == true?true:contact} /> 
                        <label for='contact' id="contact" className="setcontactlabel" style={{color:"black", fontFamily:"Montserrat", fontSize: "0.8125rem"}}>Please Contact to Discuss</label></div>
                    </div>
                    </div>
                    <div className="setsubmitbutton">
                      <button id="submit" className={data?"btn-muted submitmuted setSubmit":"setsubmit submitunmuted"} disabled={data?true:false}>
                      <div class="button-container">
                        Submit 
                        { loadingKey === 'reject-staff' ? <CustomLoader/> : null}
                      </div>
                      </button>
                    </div>
    
                </form>
                
                
            </div>
            
           
           }
           </div>
        </>
    )
}

export default RejectAccordian;
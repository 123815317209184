import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes/index";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "service/helpers";
// import "./assets/scss/index.scss";
// import "react-notifications/lib/notifications.css";
// import "../node_modules/@syncfusion/ej2-base/styles/material.css";
// import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
// import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
// import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
// import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
// import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
// import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
// import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
// import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
// import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";


const loadCssFiles = async () => {
  try {
    await Promise.all([
  import ("./assets/scss/index.scss"),
  import ("react-notifications/lib/notifications.css"),
  import ("../node_modules/@syncfusion/ej2-base/styles/material.css"),
  import ("../node_modules/@syncfusion/ej2-buttons/styles/material.css"),
  import ("../node_modules/@syncfusion/ej2-calendars/styles/material.css"),
  import ("../node_modules/@syncfusion/ej2-dropdowns/styles/material.css"),
  import ("../node_modules/@syncfusion/ej2-inputs/styles/material.css"),
  import ("../node_modules/@syncfusion/ej2-lists/styles/material.css"),
  import ("../node_modules/@syncfusion/ej2-navigations/styles/material.css"),
  import ("../node_modules/@syncfusion/ej2-popups/styles/material.css"),
  import ("../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css"),
  import ("../node_modules/@syncfusion/ej2-react-schedule/styles/material.css"),
  ]) 
  }
  catch (error) {
    console.error("Error loading CSS files:", error);
  }
};

const renderApp = () => {

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById("root")
);
}

loadCssFiles().then(renderApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

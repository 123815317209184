import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./style.scss";
import staffatomLoadinggifNew from "assets/images/Loading_gif/staffatom-loading-third.gif"
export class CommonPageLoaderClass extends Component {

    state = {
        isImageLoaded: false 
      };
    
      componentDidMount() {
        
        const img = new Image();
        
        img.src = staffatomLoadinggifNew;
        img.onload = () => {
          this.setState({ isImageLoaded: true });
        };
      }

    render() {
        let { isSpinningLoader = false } = this.props
        return (
            <>
                {!isSpinningLoader ?
                    this.state.isImageLoaded ?
                    <div className="loader">
                        {/* <div className="loader-circle">
                        </div> */}
                        {/* <div style={{zIndex:"1"}}>Loading...</div> */}
                        <img src={staffatomLoadinggifNew} style={{width:"110px", height:"100px", background:"none" ,marginLeft:"-5.7rem"}}/>
                    </div> : ""
                    :
                    <div class="lds-spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                }

            </>
        )
    }

}
let component = CommonPageLoaderClass;

export const CommonPageLoader = connect(null, null)(component)



export const colorByStatus = {
    0:{
        status: "CANCELED",
        color: "#B7B7B7",
        statusCode: "0",
    },
    1:{
        status: "NEW REQUEST",
        color: "#4F81BD",
        statusCode: "1",
    },
    2:{
        status: "PENDING STAFF ASSIGNMENT",
        color: "#E06666",
        statusCode: "2",

    },
    "2.1":{
        status: "STAFF HAS BEEN ASSIGNED. PLEASE CONFIRM",
        color: "#FFCCCB",
        statusCode: "2.1",

    },
    3:{
        status: "PENDING STAFF CLOCK-IN",
        color: "#F3993E",
        statusCode: "3",

        
    },
    4:{
        status: "STAFF IS WORKING",
        color: "#6DFBFB",
        statusCode: "4",

    },
    5:{
        status: "PLEASE REVIEW STAFF TIMESHEET",
        color: "#FEF851",
        statusCode: "5",

    },
    6:{
        status: "PENDING TIMESHEET APPROVAL BY AGENCY",
        color: "#EB58F9",
        statusCode: "6",

    },
    7:{
        status: "TIMESHEET APPROVED",
        color: "#9D4EF7",
        statusCode: "7",

    },
    8:{
        status: "PAYMENT DUE!!!",
        color: "#73F440",
        statusCode: "8",

    },
    9:{
        status: "INVOICE PAID",
        color: "#1bba18",
        statusCode: "9",

    },
    10:{
        status: "NOT FILLED",
        color: "#757171",
        statusCode: "9",

    },
}
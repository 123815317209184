import React from "react";
import { Option } from "react-multi-select-component";
import "./style.scss"
import StarIcon from '../../../assets/svg/Star.svg';
import StarNoRating from "../../../assets/svg/Star_no_rating.svg";

interface IDefaultItemRendererProps {
  checked: boolean;
  option: Option;
  disabled?: boolean;
  onClick;
}

const ItemRenderer = ({
  checked,
  option,
  onClick,
  disabled,
}: IDefaultItemRendererProps) => (
  <div className={`item-renderer all-agencies-renderer ${disabled ? "disabled" : ""}`}  >
    <div className="check-text">
    <input
      className={`checkbox${option.key}`}
      type="checkbox"
      onChange={onClick}
      checked={checked}
      tabIndex={-1}
      disabled={disabled}
    />
    <span id="label" className="setoption option-select">{`${option.label}`} </span>
    </div>
    {option.index>=0?  <div  className={ option.rating!==null && option.rating!== undefined ? 'agency-rating' : "no-rating" }><img src={option.rating!==null && option.rating!== undefined ? StarIcon: StarNoRating} alt="star-icon"/> <span id="rating" className='ml-1 fs-14'>{`${option.rating!==null && option.rating!== undefined ? option.rating.toFixed(1): "No Rating"}`}</span> </div>:""}
  </div>
);

export default ItemRenderer;
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { store } from './helpers';

 function connectToWebSocket(){
  const wsUrls = JSON.parse(process.env.REACT_APP_WEBSOCKET);
  const token = localStorage.getItem('AuthToken')
    wsUrls.forEach((url) => {
    const ws = new SockJS(url);
    const stompClient = Stomp.over(ws);
    stompClient.debug = null
    stompClient.connect({ Authorization: "Bearer " + token }, function(frame) {
      const portString = url.match(/:(\d+)/)[1];
      const portNumber = parseInt(portString.slice(-2));
      
      
      if (portNumber) {
        if(portNumber === 99){
          stompClient.subscribe("/users/topic/notify/count",function(data) {
            let NFCData = JSON.parse(data.body)
               store.dispatch({type:'UPDATE_NOTIFICATION_COUNT', payload:NFCData.message})
          });
          }else if(portNumber === 90 || portNumber === 95){
                stompClient.subscribe("/users/topic/client/workrequest",function(data) {
                let wrData = JSON.parse(data.body)
                 store.dispatch({type:'UPDATE_NEW_WORK_REQUEST', payload:wrData.message})
               });
        }else if(portNumber === 85 || portNumber === 87){
                stompClient.subscribe("/users/topic/client/invite",function(data) {
                 let inviteData = JSON.parse(data.body)
        
                    // Agency Accepted or Declined
                    if(inviteData.message[0] && inviteData.message[0].hasOwnProperty('inviteStatus')){   

                      if(inviteData.message[0].inviteStatus === 1){
                    store.dispatch({type : 'ADD_REGISTERED_AGENCY_DATA' , payload :inviteData.message[0]})}
                     store.dispatch({type:'REQUESTED_AGENCY', payload:inviteData.message[0]})
                     return;
                    }
                    // Agency unsubscribed
                  store.dispatch({type:'UPDATE_REGISTERED_AGENCY', payload:inviteData.message}) 
                  store.dispatch({type:'UPDATE_AGENCY_DATA',payload:inviteData.message})
                   });
         }

      }
            store.dispatch({type:'UPDATE_CONNECTION_STATUS', payload:true})
    })
         ws.onclose = function() {
         store.dispatch({type:'UPDATE_CONNECTION_STATUS', payload:false})
          };
         window.addEventListener('offline', ()=>{
            ws.close()
            })
  })
 
}
  
export default connectToWebSocket
import { generateQuery } from "./helperFunctions";
import { generateUpdateRatingQuery } from "./helperFunctions";

export const authApi = {
  adminLogin: {
    api: "user/auth/admin/login",
    method: "post",
    baseURL: "auth",
  },
  adminLogOut: {
    url: 'v1/logOut',
    method: 'put',
    baseURL: 'auth',
    query: {
      auditId:'',
    },
    get api() {
       return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
       this.query[key] = payload;
    },
 },
  forgetPassword: {
    api: "v1/sendOtp",
    method: "put",
    baseURL: "auth",
  },
  resetPassword: {
    api: "v1/resetPassword",
    method: "post",
    baseURL: "auth",
  },

  otpLogin: {
    api: "v1/otpLogin",
    method: "post",
    baseURL: "auth",
  },
  updatePassword: {
    api: "v1/resetPassword",
    method: "post",
    baseURL: "auth",
  },
  OTPVerify: {
    url: "v1/otpVerify",
    method: "put",
    baseURL: "auth",
    query: {
      emailId: null,
      otp: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getUserLoginCount: {
    url: "v1/getLoggedUserCount",
    method: "get",
    baseURL: "job",
    query: {
       userMail: null
    },
    get api() {
       return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
       this.query[key] = payload;
    },
 },

  changePassword: {
    url: "v1/sendOtp",
    method: "put",
    baseURL: "auth",
    query: {
      emailId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getPermissionMenu: {
    api: 'subscription/getPermissionsMenu',
    method: 'get',
    baseURL: 'subscription',
 },
 sendOtpToNewEmail: {
  url: "migrate/sendOtp",
  method: "put",
  baseURL: "auth",
  query: {
    emailId: null,
    newEmailId: null,
  },
  get api() {
    return this.url + generateQuery(this.query);
  },
  set addQuery({ key, payload }) {
    this.query[key] = payload;
  }
},
otpVerifyNewEmail: {
  url: "migrate/otpVerify",
  method: "put",
  baseURL: "auth",
  query: {
    emailId: null,
    newEmailId: null,
    otp: null
  },
  get api() {
    return this.url + generateQuery(this.query);
  },
  set addQuery({ key, payload }) {
    this.query[key] = payload;
  }
}
};

export const workRequest = {
  create: {
    api: "client/addWorkRequest",
    method: "post",
    baseURL: "work",
  },
  update: {
    api: "client/updateWorkRequest",
    method: "put",
    baseURL: "work",
  },
  getAllWork: {
    api: "client/getAllWorkByClient",
    method: "post",
    baseURL: "work",
  },
  // getAllLocation: {
  //    url: '/v1/workStatus/getAllWorkLocation',
  //    method: 'get',
  //    baseURL: 'workStatus',
  //    query: {
  //    },
  //    get api() {
  //       return this.url + generateQuery(this.query);
  //    },
  //    set addQuery({ key, payload }) {
  //       this.query[key] = payload;
  //    },
  // },
  getRegions: {
    url: "subscription/getAllCountry",
    method: "get",
    baseURL: "subscription",
    query: {
       
    },
    get api() {
       return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
       this.query[key] = payload;
    },
 },
  getById: {
    url: "client/getWorkRequestById",
    method: "get",
    baseURL: "work",
    query: {
      id: null,
      agencyId:null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  delete: {
    url: "client/deleteWorkById",
    method: "put",
    baseURL: "work",
    query: {
      workId: null,
      //  reason: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAllLocationByClient: {
    url: "client/getAllLocationByClient",
    method: "get",
    baseURL: "client",
    query: {
      clientId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  cancelWork: {
    api: "v1/workStatus/cancelWorkByClient",
    method: "put",
    baseURL: "workStatus",

  },
  completeWork: {
    api: "v1/workStatus/completeWorkByClient",
    method: "put",
    baseURL: "workStatus",
  },
  updateRatings: {
    url: "v1/workStatus/updateRatings",
    method: "put",
    baseURL: "workStatus",
    query:{
      agencyId:null,
      staffIds:null,
      clientId:null,
      jobFunctionId:null
    },
    get api() {
      return this.url + generateUpdateRatingQuery(this.query);
    },
    set addQuery({ key, payload }){
      this.query[key] = payload;
    },
  },
  approveStaff: {
    api: "v1/workStatus/approveStaffByClient",
    method: "put",
    baseURL: "workStatus",
  },
  rejectStaff: {
    api: "v1/workStatus/rejectStaffByClient",
    method: "put",
    baseURL: "workStatus",
  },
  // rejectStaff: {
  //    api: "v1/workStatus/completeWorkByClient",
  //    method: "put",
  //    baseURL: "workStatus"
  // },
  getAllLocation: {
    api: "v1/workStatus/getAllWorkLocation",
    method: "get",
    baseURL: "workStatus",
    query: {
      agencyId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getJobFunctions: {
    url: "client/getAllJobFunctionByClient",
    method: "get",
    baseURL: "client",
    query: {
      clientId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAllJobFunctions: {
    url: "agency/getJobFunction",
    method: "get",
    baseURL: "agency",
    query: {
      agencyId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAgencies: {
    url: "agency/getAgencies",
    method: "get",
    baseURL: "agency",
    query:{
      clientId:null,
      agencyId:null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }){
      this.query[key] = payload;
    },
  },
  getJobCategoriesBYClient: {
    url: "client/getAllJobCategoriesByClient",
    method: "get",
    baseURL: "client",
    query: {
       clientId: null,
       agencyId: null,
       agencySubscribedJob: true
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getJobNamesBYClient: {
    url: "client/getAllJobNamesByClient",
    method: "get",
    baseURL: "client",
    query: {
       agencyId: null,
       clientId: null,
       jobCategory: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
       this.query[key] = payload;
    },
  },

  disApproveTimeSheet: {
    api: "v1/timesheet/disApproveTimeSheetByClient",
    method: "put",
    baseURL: "workStatus",
  },
  addWorkRatings: {
    api: "v1/workStatus/addWorkRatings",
    method: "put",
    baseURL: "workStatus",
  },
};

export const documentUpload = {
  create: {
    api: "storage/aws/uploadFile",
    method: "post",
    baseURL: "fileUpload",
  },
};
export const documentUploadOnMDB = {
  create: {
     api: 'storage/mdb/uploadFile',
     method: 'post',
     baseURL: 'fileUpload',
  },
};

export const documentDownloadFromMDB = {
  create: {
     api: 'storage/mdb/download/file/',
     method: 'get',
     baseURL: 'fileUpload',
     query: {
        'id': null,
     },
  },
};

export const FeedbackApi = {
  getAllFeedback: {
    url: "client/getAllFeedback",
    method: "get",
    baseURL: "client",
    query: {
      page: null,
      size: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  postAddFeedback: {
    api: "client/addFeedback",
    method: "post",
    baseURL: "client",
  },
  postInvite: {
    api: "client/inviteClient",
    method: "post",
    baseURL: "client",
  },
  getClientById: {
    url: "client/getProfile",
    method: "get",
    baseURL: "client",
    query: {
      id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getClientByName: {
    url: "client/searchClientByName",
    method: "get",
    baseURL: "client",
    query: {
      name: "abc",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  putDeleteClient: {
    url: "invoice/delete",
    method: "put",
    baseURL: "client",
    query: {
      id: null,
      status: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  postUpdateClient: {
    api: "client/updateProfile",
    method: "put",
    baseURL: "client",
  },
};
export const settingsApi = {
  getClientById: {
    url: "client/getProfile",
    method: "get",
    baseURL: "client",
    query: {
      id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getTimeZoneList: {
    url: "subscription/getTimeZoneList",
    method: "get",
    baseURL: "subscription",
    query: {
       timezone: ["America", "Asia", "US", "Pacific"],
    },
    get api() {
       return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
       this.query[key] = payload;
    },
  },
  getSubscriptions: {
    url: "subscription/getBillingType",
    method: "get",
    baseURL: "subscription",
    query: {
      type:1
    },
    get api() {
       return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
       this.query[key] = payload;
    },
 },
  getClientByName: {
    url: "client/searchClientByName",
    method: "get",
    baseURL: "client",
    query: {
      name: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  putDeleteClient: {
    url: "invoice/delete",
    method: "put",
    baseURL: "client",
    query: {
      id: null,
      status: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  postUpdateClient: {
    api: "client/updateProfile",
    method: "put",
    baseURL: "client",
  },
  postUpdatePayrate: {
    api: "client/updatePayRate",
    method: "put",
    baseURL: "client",
  },
  deleteClient: {
    url: "client/updateDeleteFlag",
    method: "delete",
    baseURL: "client",
    query: {
      clientId: null,
      deleteFlag: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  downloadData: {
    url: "account/backup",
    method: "get",
    baseURL: "job",
    query: {
      format: null,
      module: null,
      endDate: null,
      startDate: null,
    },
    responseType:'',
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  checkLocationUsed: {
    url: "client/checkClientLocationExists",
    method: "get",
    baseURL: "client",
    query: {
      clientId: null,
      addressId : null,
      location: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  newTasks: {
    url: "dashboard/newTasks",
    method: "get",
    baseURL: "work",
    query: {
       
    },
    get api() {
       return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
       this.query[key] = payload;
    },
  },

  getTaxSetting: {
    url: "tax/taxSetting",
    method: "get",
    baseURL: "job",
    query: {
      clientId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateTaxSetting: {
    url: "tax/taxSetting",
    method: "put",
    baseURL: "job",
    query: {
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getTaxRegion: {
    url: "tax/region",
    method: "get",
    baseURL: "job",
    query: {
      code:''
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const notification = {
  get: {
    url: "notification/get",
    method: "get",
    baseURL: "notification",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  count: {
    url: "notification/notificationCount",
    method: "get",
    baseURL: "notification",
    query: {
    },
    get api() {
       return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
       this.query[key] = payload;
    },
 },
};

export const Agency ={
  getAgencies: {
    url: "agency/getAgencies",
    method: "get",
    baseURL: "agency",
    query: {
      clientId:'',
      agencyName:'',
      category:'',
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAllAgencies: {
    url: "agency/getAllAgencies",
    method: "get",
    baseURL: "agency",
    query: {
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  inviteAgencyByClient: {
    api: "connect/inviteAgencyByClient",
    method: "post",
    baseURL: "client",
  },
  getAllJobCategories: {
    url: "v1/getAllJobCategories",
    method: "get",
    baseURL: "job",
    query: {
      status:'',
      jobFunctionStatus:'active',
      searchKey:'',
      page:1,
      size:10
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getConnectInvites: {
    url: "connect/getConnectInvites",
    method: "get",
    baseURL: "client",
    query: {
      
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  cancelInvite: {
    url: "connect/cancelInvite",
    method: "put",
    baseURL: "client",
    query: {
      agencyId:'',
      message:'',
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAgenciesWithPagination: {
    url: "agency/getAgenciesWithPagination",
    method: "get",
    baseURL: "agency",
    query:{
      clientId:'',
      agencyName:'',
      category:'',
      page: 1,
      size: 10
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }){
      this.query[key] = payload;
    },
  },
}

export const jobCategoriesApi ={
  addJobCategory: {
    url: "v1/addJobCategory",
    method: "post",
    baseURL: "job",
    get api() {
      return this.url;
    },
    
  },
  
  addJobFunctionById: {
    url: "v1/addJobFunctionById",
    method: "post",
    baseURL: "job",
    query: {
      id:'',
      name:'',
      purpose:'',
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  jobFunctionNotifyUsers: {
    url: "v1/notifyUsers",
    method: "post",
    baseURL: "job",
    query: {
      category:'',
      functionName:'',
      jobMasterId:'',
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  }
}

export const fileUploadOnMDB = {
  create: {
     url: 'storage/mdb/uploadFile',
     method: 'post',
     baseURL: 'fileUpload',
     query: {
      agencyId:'',
      entity:null
     },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const fileDownloadFromMDB = {
  getFile: {
     url: 'v1/storage/mdb/file',
     method: 'get',
     baseURL: 'fileUpload',
     query: {
        agencyId:'',
        entity:'',
     },
     get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteFile: {
    url: 'storage/mdb/deleteFile',
    method: 'delete',
    baseURL: 'fileUpload',
    query: {
       fileId:'',
       entity:'',
       agencyId: ''
    },
    get api() {
     return this.url + generateQuery(this.query);
   },
   set addQuery({ key, payload }) {
     this.query[key] = payload;
   },
 },
};

export const stripeSubscriptionApi = {
 
  checkout: {
    url: "stripe/subscription/checkoutSession",
    method: "post",
    baseURL: "subscription",
    query: {
      email:'',
      currency:'',
      planName:'',
      quantity :0,
      unitAmount:0
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  }
}

export const razorPaySubscriptionApi = {
  checkout: {
    url: "razorpay/subscription/checkoutSession",
    method: "post",
    baseURL: "subscription",
    query: {
      currency:'',
      unitAmount:0
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
}

export const subscriptionApi = {

  paymentCheckOutSession: {
    url: "payment/checkoutSession",
    method: "post",
    baseURL: "subscription",
    query: {
       paymentReq:''
     },
     get api() {
       return this.url + generateQuery(this.query);
     },
     set addQuery({ key, payload }) {
       this.query[key] = payload;
     },
 },
paymentTransactions: {
  url: "payment/getAllTransactions",
  method: "get",
  baseURL: "subscription",
  query: {
    startDate:'',
    endDate:'',
    paymentStatus:'',
    userType:null,
    page:1,
    size:10,

  },
  get api() {
    return this.url + generateQuery(this.query);
  },
  set addQuery({ key, payload }) {
    this.query[key] = payload;
  },
},
singleSubscription: {
  url: "subscription/getById",
  method: "get",
  baseURL: "subscription",
  query: {
    id:null,
  },
  get api() {
    return this.url + generateQuery(this.query);
  },
  set addQuery({ key, payload }) {
    this.query[key] = payload;
  },
},
gettransactionLogsCSV: {
  url: "payment/transactionLogsCSV",
  method: "get",
  baseURL: "subscription",
  query: {
     userId: null
   },
   get api() {
     return this.url + generateQuery(this.query);
   },
   set addQuery({ key, payload }) {
     this.query[key] = payload;
   },
},
pauseResumePlan: {
  url: "client/pauseResumePlan",
  method: "put",
  baseURL: "client",
  query: {
    flag:null,
    id: null
  },
  get api() {
    return this.url + generateQuery(this.query);
  },
  set addQuery({ key, payload }) {
    this.query[key] = payload;
  },
}, 
getLogs : {
  url: "getLogs",
  method:'get',
  baseURL:"Logs",
  query:{ 
    logType:null,
    page:null,
    size:null
  }, 
  get api(){ 
    return this.url + generateQuery(this.query); 

  } , 
  set addQuery({key,payload}){ 
    this.query[key] = payload
  }

}
}

export const dashboardApi ={
  getWrTracker: {
    url: "dashboard/wrTracker",
    method: "get",
    baseURL: "work",
    query: {
      endDate:null,
      search:'',
      startDate:null
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getRegisteredAgenciesByCategory: {
    url: "dashboard/registeredAgenciesByCategory",
    method: "get",
    baseURL: "work",
    query: {
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getInvoiceDetails: {
    url: "dashboard/invoiceDetails",
    method: "get",
    baseURL: "work",
    query: {
      agencyName:'',
      startDate:null,
      endDate:null
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getRegisteredCategories: {
    url: "dashboard/registeredCategories",
    method: "get",
    baseURL: "work",
    query: {
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getStaffRating: {
    url: "dashboard/staffRating",
    method: "get",
    baseURL: "work",
    query: {
      agencyId:'',
      category:''
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  
}
import React, { useEffect, useState } from 'react'
import './style.scss'
import loadingIcon from "assets/images/loading.png";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import dummyImage from "assets/images/dummy-image-2.png"
import { getFileFromMDB } from 'action/fileHandler';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const DisplayAgencyImage = ({getFileFromMDB,  imageId, entityClient, agencyId}) => {
  const [onlineImage, setOnlineImage] = useState('')
  const [loading, setLoading] = useState(false)

    const handleImageDownload = async (logo) => {
      setLoading(true)
        let imageData;
         let fileId = logo
         await getFileFromMDB({ entity: `${entityClient ? 'client' : ''}`, agencyId }, fileId)
         .then((response)=>{
          imageData = `data:${response.contentType};base64,${response?.fileContent.data}`
          setLoading(false)
          setOnlineImage(imageData.toString())
          return imageData
         }).catch((error) => {
          setLoading(false);
          setOnlineImage(null);
          console.log('Error fetching image:', error);
        });
      }

      useEffect(()=>{
        if(imageId){
          handleImageDownload(imageId)
        }else{
          setOnlineImage(null)
        }
      },[imageId])

  return (<>
    {onlineImage !== null ?
      <img className={loading ? 'loading-animation': 'image-view-agency'}
         src={loading ? loadingIcon :onlineImage} /> 
           :
       <div className="w-100 h-100 d-flex justify-content-center align-items-center">
           <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Image not found</Tooltip>}
            >
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <img src={dummyImage} alt="dummy image" />
              </div>
            </OverlayTrigger>
        </div> }
</>)
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        getFileFromMDB
      },
      dispatch
    );
  };

  export default connect('', mapDispatchToProps)(DisplayAgencyImage)


const { SET_NO_REG_AGENCIES_WARNING } = require("service/actionType");
const { SET_DISTANCE_UNIT } = require("service/actionType");
const {hasSubscriptionWarning} = require("service/actionType")

const initialState = {
    noRegAgenciesWarning: false,
    distanceUnit: 0,
    hasSubscriptionWarning:false
  };

  export default  (state = initialState, action) => {
    switch (action.type) {
      case SET_NO_REG_AGENCIES_WARNING:
        return {
          ...state,
          noRegAgenciesWarning: action.payload,
        }
        case SET_DISTANCE_UNIT:
         return {
          ...state,
          distanceUnit: action.payload,
         } 
         case hasSubscriptionWarning: 
         return{ 
          ...state, 
          hasSubscriptionWarning : action.payload
         }
      default:
        return state;
    }
  };
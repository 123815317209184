
export const feedbackPageSteps = [
    // {
    //     target:'#feedback-title',
    //     content:'Feedback Module'
    // },
    {
        target:'#add-feedback-btn',
        content:'Click here to create a feedback',
        disableBeacon: true,
    },
    {
        target:'#feedback-item',
        content:'All your feedbacks will be displayed here in the list. You can view the feedback details and resend the same feedback if you wish to',
        spotlightPadding: 0,
        disableScrolling: true
    },
]

export const addFeedbackSteps = [
    {
        target:'.add-feedback-1',
        content:'Select an agency you want to send the feedback for',
        disableBeacon: true,
    },
    {
        target:'.add-feedback-2',
        content:'Enter your name',
        spotlightPadding: 0,
        disableScrolling: true
    },
    {
        target:'.add-feedback-3',
        content:'Enter your company name',
        spotlightPadding: 0,
        disableScrolling: true
    },
    {
        target:'.add-feedback-4',
        content:'Write your feedback in here',
        spotlightPadding: 0,
        disableScrolling: true
    },
]


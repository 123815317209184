import React, { useState } from "react";
import { NormalButton } from "component/common";
import Moment from "moment";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import "./style.scss";
// import editIcon from "assets/svg/edit.svg";
import clockIcon from "assets/svg/mdi_access_time.svg";
import markerIconCin from "assets/svg/map_cursors/clockIn.svg";
import markerIconCout from "assets/svg/map_cursors/clockOut.svg";
import markerIconPause from "assets/svg/map_cursors/pause.svg";
import markerIconResume from "assets/svg/map_cursors/resume.svg";
import expandfeedback from "assets/svg/expand-feedback.svg";
import warningIcon from "assets/svg/Warning-yellow.svg";
import disableMap from "assets/svg/DisableMap.svg"
import GoogleMapsModel from "component/workShedule/googlemapsModel";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { disApproveTimeSheet } from "action/workRequest";
import BreakHours from "component/common/breakHours";
import TotalHours from "component/common/totalHours";
import OverHours from "component/common/overtimeHours";

import ratingEditIcon from "assets/svg/rating-edit-icon.svg"
import checkIcon from "assets/svg/rating-tick.svg"

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// import profile from "../../../assets/svg/Ellipse.png";

function TimeSheetMark(props) {

  const {setActivePointsFromStaffDetails, toggleMap, editStaffRatingId, setEditStaffRatingId, saveRatings} = props;

  const [openGoogleMaps, setOpenGoogleMaps] = useState(false);
  let workID = localStorage.getItem("workID");

  // let isVisible = false;
  // const calculateHours = (date1, date2) => {
  //   let diff = (new Date(date1).getTime() - new Date(date2).getTime()) / 1000;
  //   diff /= 60 * 60;
  //   return Math.abs(Math.round(diff));
  // };

  // const calculateBreakHours = (date1, date2, data) => {
  //   let diff = (new Date(date2).getTime() - new Date(date1).getTime()) / 1000;
  //   diff /= 60 * 60;
  //   let diffBreak =
  //     (new Date(data[data.length - 1].time).getTime() -
  //       new Date(data[0].time).getTime()) /
  //     1000;
  //   diffBreak /= 60 * 60;
  //   let final = diff - diffBreak;
  //   // let hours= Math.abs(Math.round(diff));

  //   return Math.abs(Math.round(final));
  // };
  let {
    pauseResumeData,
    clockIn,
    clockOut,
    // status,
    // edit,
    // staffWorkedHrs,
    // pausedHours,
  } = props.data;

  let {status} = props


  const handleDisApproveTimeSheet = async (id, agencyid, workID) => {
    let body = {
      agencyId: agencyid,
      workId: workID,
      staffId: id,
    };

    await props
      .disApproveTimeSheet(body)
      .then((data) => {})
      .catch(() => {
        // setFormLoder(false);
      });
  };

  // let currentIndex = -1;
  const { workStaffAssignment } = props.edit;
  //console.log(workStaffAssignment);
  let workAvailable =
    workStaffAssignment !== undefined &&
    workStaffAssignment.staffWorkData &&
    workStaffAssignment.staffWorkData.length > 0;
  let staffAvailable =
    workAvailable && workStaffAssignment.staffWorkData.length > 0;
  // console.log(
  //   workStaffAssignment.staffWorkData.filter(
  //     (staff) =>
  //       staff.staffId === JSON.parse(localStorage.getItem("userData")).id
  //   ).length > 0,
  //   "staffAvailable"
  // );

  // Staff Rating and Feedback Handle Functions
  const handleFeedbackInputChange = (event) => {
    const { value } = event.target;
    props.onFeedbackChange(props.data.staffId, value);
  };

  const handleRatingInputChange = (rating) => {
    // const { value } = event.target;
    props.onRatingChange(props.data.staffId, parseFloat(rating));
  };

  return (
    <>
      {/* <div className="d-flex align-items-start mb-3"> */}
      {(status >= 3 || status===0) && clockIn !== null ? (
        <>
          <div className=" d-flex justify-content-between ml-5 mt-5 mb-3" style={{width:"37rem"}}>
            <h6 className="font-weight-bold mt-2">Time Sheet</h6>
            <NormalButton
              onClick={() => {
                handleDisApproveTimeSheet(props.data.staffId, props.edit.agencyId, workID);
              }}
              label={props.data.disagreeTimeSheet == true ? "DISAGREED (NOTIFY AGAIN)":"DISAGREE TIME (NOTIFY)"}
              outline={false}
              leftIcon = {warningIcon}
              rightIconAlt = "warning-icon"
              rightIconTitle = "Please exercise caution when utilizing this feature to express any disagreements regarding the submitted timesheet."
              rightIconStyle = "disagree-time-warn-icon"
              disabled={
                status !== 5
                // isFormLoder ||
                // (workStaffAssignment &&
                //   workStaffAssignment.staffWorkData == 0)
              }
              // loader={isFormLoder}
              className="mb-2 btn fs-12 outline-dark-btn btn-secondary form-btn ml-18 Disagree-time"
            />
	    {/*<p
              className={`Disapprove mb-2 btn fs-16 outline-dark-btn btn-lg form-btn ml-18 ${
                status === 5 ? "cursor-pointer" : "disabled"
              }`}
              onClick={() =>
                handleDisApproveTimeSheet(props.data.staffId, workID)
              }
            >
              DISAGREE TIME{" "}
            </p>*/}
          </div>
          <div class="d-flex flex-column align-items-start mb-3" style={{ width:"36rem", marginLeft:"2.5rem"}}>
            <div class="step completed green">
              <div class="v-stepper">
                <div class="circle"></div>
                <div class="line"></div>
              </div>

              <div class="content-stepper">
                <div className="row">
                  <div className="col-5">
                    <span class="fs-13 ml-0 s2-r">
                      {!!clockIn
                        ? Moment(new Date(clockIn)).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`)
                        : "Pending"}
                    </span>
                  </div>
                  <div className="col-2" style={{marginLeft:"-2rem"}}>
                    <span class="fs-11 s1-r">Clock-in</span>
                  </div>
                  {!!clockIn?(
                  <div
                    className="col-2 cursor-pointer"
                    onClick={()=>{
                        setActivePointsFromStaffDetails(0); 
                        toggleMap();
                    }}  >
                    <img src={markerIconCin} alt="markerIconCin"/>
                  </div>):(
                    <div
                    className="col-2 no-drop">
                    <img src={disableMap} alt="disableMap"/> </div>
                  )}
                  <div className="newtimesheetwidth">
                      {moment(clockIn)<moment(props.startDate) && !!clockIn? (
                          <small className="fs-11 text-success no-wrap s2-m">Clocked-In Early</small>
                      ) : (
                          ""
                      )}
                      {moment(clockIn)>moment(props.startDate) && !!clockIn? (
                          <small className="fs-11 text-danger no-wrap s2-m">Clocked-In Late (Tardy)</small>
                      ) : (
                          ""
                      )}
                  </div>
                </div>
              </div>
            </div>
            {pauseResumeData
              ? pauseResumeData.map((item, index) => {
                  return (
                    <div
                      class={`step ${item.key === "pause" ? "active":"completed"}`}
                    >
                      <div class="v-stepper">
                        <div class="circle"></div>
                        <div class="line"></div>
                      </div>

                      <div class="content-stepper">
                        <div className="row">
                          <div className="col-5">
                            <span class="fs-13 ml-0 s2-r">
                              {Moment(item.time).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`)}
                            </span>
                          </div>
                          <div className="col-2" style={{marginLeft:"-2rem"}}>
                            <span class="fs-11">
                              {item.key === "pause" ? "Pause" : "Resume"}
                            </span>
                          </div>
                          <div
                            className="col-2 cursor-pointer"
                            onClick={()=>{
                              setActivePointsFromStaffDetails(index+1); 
                              toggleMap();
                          }}
                          >
                            <img src={item.key === "pause" ? markerIconPause : markerIconResume} alt="markerIcon"/>
                          </div>
                          <div className="col-3">
                            <span className="red-tag fs-11"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
            <div class="step completed red">
              <div class="v-stepper">
                <div class="circle"></div>
                <div class="line"></div>
              </div>

              <div class="content-stepper">
                <div className="row">
                  <div className="col-5">
                    <span class="fs-13 ml-0 s2-r">
                      {!!clockOut
                        ? Moment(new Date(clockOut)).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`)
                        : 
                        !!clockIn?'In Progress':
                        'Pending'}
                    </span>
                  </div>
                  <div className="col-2" style={{marginLeft:"-2rem"}}>
                    <span class="fs-11 s1-r">Clock-out</span>
                  </div>
                  {!!clockOut?(
                  <div
                    className="col-2 cursor-pointer"
                    onClick={()=>{
                      if(!!clockOut){
                        setActivePointsFromStaffDetails(pauseResumeData?pauseResumeData.length+1:1); 
                    }
                      toggleMap();
                  }}
                          
                  >
                    <img src={markerIconCout} alt="markerIconCout"/>
                  </div>):(
                     <div
                     className="col-2 no-drop">
                    <img src={disableMap} alt="disableMap"/>
                    </div>
                  )}
                  <div className="newtimesheetwidth">
                      {moment(props.endDate) < moment(clockOut) && !!clockOut ? (
                        <small className="fs-11 text-danger no-wrap s2-m">Clocked-Out Late</small>
                      ) : (
                          ""
                      )}
                      {moment(props.endDate) > moment(clockOut) && !!clockOut? (
                        <small className="fs-11 text-danger no-wrap s2-m">Clocked-Out Early</small>
                      ) : (
                          ""
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" d-flex flex-column justify-content-between mb-3" style={{marginLeft:"5.5rem",marginTop:"-2rem",width:"39rem"}}>
            <TotalHours staffAvailable={staffAvailable} work={props.edit} data={props.data}/>
            <BreakHours staffAvailable={staffAvailable} work={props.edit} data={props.data} />
            <div className="row container-fluid mb-3">
              <div className="col-md-4 d-flex ">
                <img src={clockIcon} height="25px" width="16px" alt="clockIcon"/>
                <span class="s2-r fs-14 ml-3">Break Hours</span>
              </div>
              <div className="col-md-5">
                <span class="fs-14">
                  {/* {!!clockIn ? Moment(clockIn).format("hh:mm a") : "Pending"} -{" "}
                  {!!clockOut ? Moment(clockOut).format("hh:mm a") : "Pending"} */}
                  
                </span>
              </div>
              <div className="col-md-3">
                <span className="sub-text fs-12 s2-m">
                  {/* {!!clockOut ? calculateHours(clockIn, clockOut) : "Pending"}{" "} */}
                  {/* {!!clockOut ? [staffWorkedHrs] : "Pending"} hours{" "} */}
                  {staffAvailable &&
                  props.data.staffWorkedHrs !== undefined
                    ? props.data.pausedHours.toFixed( 4 ): "0.0000"}{" "}
                  hours
                </span>
              </div>
            </div>
            <OverHours staffAvailable={staffAvailable} work={props.edit} data={props.data} />
            {props.data.claimHazardPay ? 
                       <div class="frame-2403">
                            <div class="frame-2399">
                            <div class="hazard-reason">Hazard Reason</div>
                             </div>
                            
                            <div class="frame-2400">
                            <div class="at-emergency-ward-got-hurt-due-to-human-error">
                             {props.data.hazardPayReason}
                            </div>
                            </div>
                        </div>
                        : null}
            <div className="row container-fluid mb-3">
              {/* {pauseResumeData !== undefined &&
                pauseResumeData &&
                pauseResumeData.length > 0 &&
                pauseResumeData[0].clockOut > props.edit.endDate && (
                  <>
                    <div className="col-md-5 d-flex ">
                      <img src={clockIcon} />
                      <span class="fs-16 ml-3">Over time work hours</span>
                    </div>
                    <div className="col-md-4">
                      <span class="fs-16">
                        {" "}
                        {Moment(props.edit.endDate).format("hh:mm a")} -{" "}
                        {Moment(pauseResumeData[0].clockOut).format("hh:mm a")}
                      </span>
                    </div>
                    <div className="col-md-3">
                      <span className="sub-text fs-14">
                        {" "}
                        <span className="sub-text fs-14">
                          {Moment(pauseResumeData[0].clockOut).diff(
                            Moment(props.edit.endDate),
                            "hours"
                          )}{" "}
                          hours
                        </span>
                      </span>
                    </div>
                  </>
                )} */}
            </div>
          </div>
        </>
      ) : (
        <div className=" d-flex justify-content-center mb-3">
          {status >= 4 ? (
            <h6 className="font-weight-bold ">Staff Need to Start Work</h6>
          ) : (
            <h6 className="font-weight-bold ">Need to Approve</h6>
          )}
        </div>
      )}

      {/* Staff Rating and Feedback - Individual Staff Edit Mode*/}
      {props.wrData.status === 5 &&
        <div class="frame-2248">
        <div style={{width: "150px"}} class="frame-2245-2">
          <div class="frame-2244-2">
            <div class="connor-james-2">{props.data.staffName}</div>
          </div>
          <div style={{position: "relative", top: "5px"}} class="_4-3-5">
            <span>
              <span class="_4-3-5-span">{props.ratingData[props.data.staffId]}</span>
                <span class="_4-3-5-span2">/ 5</span>
            </span>
          </div>
          {/* <input
            className="rating-input"
            style={{border: "none", width: "135px"}}
            type="number"
            step="0.1"
            placeholder="Add Rating (out of 5)"
            value={ratingData[staff.staffId] || ""}
            onChange={(e) => handleRatingChange(staff.staffId, e.target.value)}
          /> */}
          <ReactStars
            size={25}
            count={5}
            color="#E3E3E3"
            activeColor={"#FABB05"}
            value={props.ratingData[props.data.staffId] || 0}
            a11y={true}
            isHalf={true}
            onChange={handleRatingInputChange}
          />

          <div class="frame-22452">
        </div>
      </div>
      <textarea
        className="feedback-input"
        style={{border: "none",  outline: "none", resize: "none"}}
        name="feedback" 
        id="" 
        cols="30" 
        rows="2" 
        placeholder="Add Feedback here"
        value={props.feedbackData[props.data.staffId]?.feedback || ""}
        onChange={handleFeedbackInputChange}
      ></textarea>
    </div>
      }

      {/* Staff Rating and Feedback - Individual Staff View Mode*/}
      {props.wrData.status > 5 && editStaffRatingId !== props?.data?.staffId &&
        <div class="frame-2248-view">
        <div style={{width: "150px"}} class="frame-2245-view">
          <div class="frame-2244-view">
          <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    {props?.data?.staffName}
                  </Tooltip>
                }
              >
            <div class="connor-james-view-2">{props?.data?.staffName?.length > 20 ? props?.data?.staffName?.substring(0, 20) + "..." : props?.data?.staffName}</div>
          </OverlayTrigger>
          </div>
          {props.ratingData[props.data.staffId] > 0 ?
            <div style={{position: "relative", top: "5px"}} class="_4-3-5">
              <span>
                <span class="_4-3-5-span">{props.ratingData[props.data.staffId]}</span>
                  <span class="_4-3-5-span2">/ 5</span>
              </span>
           </div>
          : <span style={{position: "relative", top: "3px"}} class="_4-3-5-span fs-10">Not Rated</span>}
          <div className="d-flex">
            {/* <StarRating starsValue={staff.staffReviews?.rating}/> */}
            <ReactStars
              size={25}
              count={5}
              color={"#E3E3E3"}
              value={props.ratingData[props.data.staffId] || 0}
              a11y={true}
              isHalf={true}
              edit={false}
              activeColor={"#FABB05"}
            />
          </div>
        </div>
                  
        <div class="frame-2246-view">
          <div className="view-feedback s3-r">
            {props.feedbackData[props.data.staffId]?.feedback
              ? props.feedbackData[props.data.staffId]?.feedback.length > 110
              ? `${props.feedbackData[props.data.staffId]?.feedback.slice(0, 110)}...`
              : props.feedbackData[props.data.staffId]?.feedback
              : "No feedback"}
          </div>
          {props.feedbackData[props.data.staffId]?.feedback
              ? props.feedbackData[props.data.staffId]?.feedback.length > 110 ?
          <>
          <img src={expandfeedback} style={{marginTop:"6px"}}/>
          <div class="hover-feedback s3-r">
            {props.feedbackData[props.data.staffId]?.feedback ? props.feedbackData[props.data.staffId]?.feedback : "No feedback"}
          </div>
          </>
          :"":"" }
          </div>
          {props.wrData?.status !== 9 && <div className="rating-edit-btn">
            <img
              className='cursor-pointer'
              src={ratingEditIcon}
              alt="rating-edit-icon"
              onClick={() => setEditStaffRatingId(props.data?.staffId)}
            />
          </div>}
        </div>
      }
      {props.wrData.status > 5 && editStaffRatingId === props?.data?.staffId && 
      <div class="frame-2248">
        <div style={{width: "150px"}} class="frame-2245-2">
          <div class="frame-2244-2">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    {props?.data?.staffName}
                  </Tooltip>
                }
              >
            <div class="connor-james-2">{props?.data?.staffName?.length > 20 ? props?.data?.staffName?.substring(0, 20) + "..." : props?.data?.staffName}</div>
          </OverlayTrigger>
          </div>
          <div style={{position: "relative", top: "5px"}} class="_4-3-5">
            <span>
              <span class="_4-3-5-span">{props.ratingData[props.data.staffId]}</span>
                <span class="_4-3-5-span2">/ 5</span>
            </span>
          </div>
          <ReactStars
            size={25}
            count={5}
            color="#E3E3E3"
            activeColor={"#FABB05"}
            value={props.ratingData[props.data.staffId] || 0}
            a11y={true}
            isHalf={true}
            onChange={handleRatingInputChange}
            key={props.ratingData[props.data.staffId]}
          />
          <div class="frame-22452">
        </div>
      </div>
      <textarea
        className="feedback-input"
        style={{border: "none",  outline: "none", resize: "none"}}
        name="feedback" 
        id="" 
        cols="30" 
        rows="2" 
        placeholder="Add Feedback here"
        value={props.feedbackData[props.data.staffId]?.feedback || ""}
        onChange={handleFeedbackInputChange}
      ></textarea>
      {props.wrData?.status !== 9 && <div className="rating-check-btn">
            <img
              className='cursor-pointer'
              src={checkIcon}
              alt="check-icon"
              onClick={() => { setEditStaffRatingId(''); saveRatings(false) }}
            />
          </div>}
    </div>}       

      <GoogleMapsModel
        data={props.data}
        isOpen={openGoogleMaps}
        toggle={() => setOpenGoogleMaps(!openGoogleMaps)}
      />
      {/* </div> */}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      disApproveTimeSheet,
    },
    dispatch
  );
};

export default connect(
  mapDispatchToProps,
  mapDispatchToProps
)(withRouter(TimeSheetMark));

import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import Tooltip from 'react-bootstrap/Tooltip'; 
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import ItemRenderer from "component/common/MultiSelectRenderer/ItemRenderr.tsx";
import valueRenderer from "component/common/MultiSelectRenderer/valueRenderer.tsx";
import {
  NormalModal,
  NormalInput,
  NormalSelect,
  NormalDate,
  // NormalCheckbox,
  NormalTextarea,
  DragFileUpload,
  CommonPageLoader,
  // LocationAutoComplete,
} from "component/common";
import "./style.scss";
import Moment from "moment";
import infoIcon from 'assets/svg/info.svg'
import SimpleReactValidator from "simple-react-validator";
import { NormalButton } from "component/common";
// import { useCustomReducer } from "service/helpers/hooks";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect , useSelector } from "react-redux";
import {
  addWork,
  deleteWork,
  getSingleWork,
  updateWork,
  getAllJobFunctions,
  getAllJobCategoriesByClient,
  getAllLocationsByClient,
  getAllJobNamensByClient,
  getAgencies,
} from "action/workRequest";
import { getAllJobCategories } from "action/Agency";
import { getClientById } from "action/settingsAct";
import { fileUploadOnMDB } from "action/fileUpload";
import { uploadFileToMDB, deleteFileFromMDB} from "action/fileHandler";
// import LocationSearchInput from "component/common/locationSearchAutocomplete";
// import editIcon from "assets/svg/mdi_edit.svg";
// import deleteIcon from "assets/svg/bin.svg";
import fileUploadIcon from "assets/svg/Vector.svg";
// import profile from "../../../assets/svg/Ellipse.png";
import { Toast } from "service/toast";
import DisplayFile from "./DisplayFile";
import CustomDropdown from "component/common/CustomDropDown";
import DeleteFileConfirmationModal from "component/DeleteFileConfirmationModal"
import deleteFileIcon from "assets/svg/delete-icon.svg";
import RecurringModel from "../RecurringModel";
import recurrenceIcon from 'assets/svg/recurrenceIcon.svg'
import ConfirmModal from '../confirmModal';

import { intersectionWith, isEqual } from "lodash";

let initState = {
  agencyName:"",
  clientName: "",
  location: "",
  longLat: [13.11212, 80.121212],
  zipCode: "",
  workRequest: "",
  startDate: "",
  endDate: "",
  isRepeat: false,
  priority: "",
  engagementType : "",
  wrType: "",
  staffNeeded: "",
  jobCategory: "",
  jobName: "",
  notes: "",
  documents: [],
  clientSiteTimeZone : "",
  holidays: [],
  repeat: false,
  repeatData:{
    repeatFrequency:'',
    endsOn:'',
  },
  rateFrequency:1
};

const billingTypeOptions = [
  { 
      label: "Per Hour",
      value: 1
  }, 
   {  label: "Per Day",
      value: 2
  },
    { label: "Per Week",
      value: 3
  },
    { label: "Per Month",
      value: 4
  }
]

function AddWorkRequest({
  visible,
  setVisible,
  addWork,
  fileUploadOnMDB,
  uploadFileToMDB,
  deleteFileFromMDB,
  deleteWork,
  getSingleWork,
  handleReload,
  updateWork,
  getAllJobFunctions,
  getClientById,
  getAllLocationsByClient,
  initialStartTime,
  initialEndTime,
  setInitialStartTime,
  setInitialEndTime,
  getAllJobCategoriesByClient,
  getAllJobNamensByClient,
  getAgencies,
  allAgencies,
  getAllJobCategories
}) {
  const [edit, setEdit] = useState(initState);
  const [enableWR, setEnableWR] = useState([]);
  const [clientId, setClientId] = useState("");
  const [agencyId, setAgencyId] = useState("");
  const [updeteID, setUpdeteID] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [isFormLoader, setFormLoader] = useState(false);
  // const [isLoading, setLoading] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isValidateupdate, setIsValidateupdate] = useState(false);
  const [file, setFile] = useState([]);
  const [startTime, setstartTime] = useState("");
  const [endTime, setendTime] = useState("");
  const [document, setDocument] = useState([]);
  const [jobFunctionNames, setJobFunctionNames] = useState([]);
  const [jobFunctionCategories, setJobFunctionCategories] = useState([]);
  const [locations, setlocations] = useState([]);
  const [allLocations, setallLocations] = useState([]);
  // const [allAgencies, setAllagencies] = useState([]);
  const [allAgenciesoptions, setAllagencyoptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [jobCategories, setJobCategories] = useState([])
  const [disabledJobCategories, setDisabledJobCategories] = useState([])
  const [filteredJobCategories, setFilteredJobCategories] = useState([])
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [recurring, setRecurring] = useState({status:false});

  const [autoSplitWR, setAutoSplitWR] = useState(false)
  const [showAutoSplitWRConfirmation, setShowAutoSplitWRConfirmation] = useState(false)
  const [startDateWRType,setStartDateWRType] = useState(null)
  const [endDateWRType,setEndDateWRType] = useState(null)
  const [loadingKey, setLoadingKey] = useState('')

  // const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  //Pagination to be implemented after BE refinement
  // const [paginationConfig, setPaginationConfig] = useState({
  //     TotalCount: 0,
  //     pageCount: 10,
  //     btnDisplay: 5,
  //     itemCount: 0,
  //   });

  const userInfo = useSelector((state)=>state.userDataReducer.userData);
  
  // set Validator
  const validator = useRef(
    new SimpleReactValidator({
      validators: {
        element: (message) => (
          <span className="error-message font-md">{message}</span>
        ),
      },
    })
  );

  const loadJobCategories = async (query) => {
    await getAllJobCategoriesByClient(query).then((data) => {
      let jobCategoriesLabels=[];
      let categories = data?.map((item)=> {
        jobCategoriesLabels.push({
          label:`${item.category} - ${item.purpose}`,
          value: item.category
        })
        return {
          label:`${item.category} |_| ${item.purpose}`,
          value: item.category
        }
      })
      setJobCategories(categories || []);
      setFilteredJobCategories(categories || [])
      setDisabledJobCategories(jobCategoriesLabels || [])
    })
    .catch((err) =>{
      console.log(err)
    })
  };
  useEffect(()=>{
    if (clientId) {
      loadJobCategories({
        clientId: clientId
      })
    }
    return ;
  },[clientId])

  const handleSelectJobCategory = (e) => {

    const temp = {...edit};
    if(jobCategory !== "" && selected.length == 1 && allAgenciesoptions.length >= 1 && e.value !== jobCategory && edit.agencyName !== "" && edit.jobName !== ""){
      setSelected([]);
      setAllagencyoptions([]);
      temp.agencyName = ''
      temp.jobName= ''
    }
    if(jobCategory!== "" && e.value !== jobCategory && location!=="" && edit.addressId!==""){
      temp.location= ''
      temp.addressId= ''
    }
    if(jobCategory !== "" && e.value !== jobCategory) {
      setSelected([]);
    }
    setEdit({...temp, jobCategory:e.value })

  }

  useEffect(()=>{
    if (initialStartTime){
      setEdit({ ...edit,startDate : initialStartTime});
    };
    if(initialEndTime){
      setEdit({ ...edit, endDate : initialEndTime});
    };
    return(()=>{
      setInitialStartTime("");
      setInitialEndTime("");
    })
  },[initialStartTime, initialEndTime])

  const validatorUpdate = () => {
    setIsValidateupdate(!isValidateupdate);
  };

  
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let id = userData.userId ? userData.userId : userData.id;
    let agencyID = !!visible.agencyId?visible.agencyId:selected[0]?.value;

    // if (visible) {
    // }

    if (!!id) {
      setClientId(id);
      setAgencyId(agencyID);
      // fetchId(id);

      if (jobFunctionNames.length === 0) {
        setLoading(false)
        // fetchAllJonFunctionCategories(agencyID, id);
        // fetchAllJobFunction(agencyID);
        // fetchAllLocations(id);
        fetchingAllLocation()
      }
      else{
        setLoading(false)
        // fetchAllJonFunctionCategories(agencyID, id);
      }
    }

    if (!!visible.id) {
      // console.log(visible, "this is visable");
      fetchWorkById(visible.id);
      setUpdeteID(visible.id);
    } else {
      // fetchId(id);
      setEdit({ ...edit, clientName: userInfo.clientName });    }

    // setFile("");
  }, [visible.value , userInfo]); 




  useEffect(()=>{
    let userData = JSON.parse(localStorage.getItem("userData"));
    let id = userData.userId ? userData.userId : userData.id;
    if(selected.length>0){
      let name= "agencyName"
      let agencyid= !!visible.agencyId?visible.agencyId: selected[0].value
      let value= selected[0].label;
      // fetchAllJonFunctionCategories(agencyid, id);
      if(edit.jobCategory){
      fetchAllJobFunction(edit.jobCategory, selected?.[selected?.length - 1]?.value)
      }
      handleInput({target:{name,value}})
    }else{
      setJobFunctionNames([])
    }
  },[selected])

  const fetchId = async (id) => {
    let obj = {
      id: id,
    };
   
    await getClientById(obj)
      .then((data) => {
        setEdit({ ...edit, clientName: data.clientName,});
      })
      .catch((e) => setLoading(false));

    // let userData = JSON.parse(localStorage.getItem("userData"));
    // let clientid = userData.userId ? userData.userId : userData.id;
    // let query = {clientId: clientid}

    // await getAgencies(query)
    //    .then((data)=>{
    //     setAllagencies(data);
    //    })
    //    .catch((e)=> setLoading(false));
  };

  useEffect(()=>{
    let modifydata=[];
    for(let i=0;i<allAgencies.length;i++){
      //Checking for Active Agencies only
      allAgencies[i].status == 1 && moment(allAgencies[i].subscriptionValidity).isAfter(moment()) && allAgencies[i].job.map((item) => {
              if(item.category === edit.jobCategory){
                modifydata.push({
                  index:i, label: allAgencies[i].agencyName, value: allAgencies[i].agencyId, rating: allAgencies[i].rating }
                 )
              }
      })
     
    }
    setAllagencyoptions(modifydata);
  },[allAgencies, edit.jobCategory])
 
  const fetchWorkById = async (id) => {
    setLoading(true);
    let userData = JSON.parse(localStorage.getItem("userData"));
    let agencyID = userData.agencyId ? userData.agencyId : "";
    const query = { id: id , agencyId:visible.agencyId };
    // console.log(query);
    await getSingleWork(query)
      .then((data) => {
        setEdit(data);
        setEnableWR(data);
        fetchAllJobFunction(data.jobCategory, visible?.agencyId);
        // console.log('sdgsdgdsg',modifyData);
        // setWorkList(modifyData.length ? modifyData : []);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  // // fetch all job functions
  // const fetchAllJonFunctionOld = async (id) => {
  //   setLoading(true);

  //   let query = {
  //     agencyId: !!id ? id : agencyId,
  //   };
  //   await getAllJobFunctions(query)
  //     .then((data) => {
  //       // let modifyData=[{
  //       //     label:'All',
  //       //     value:''
  //       // }];
  //       let modifyData = [];
  //       data[0].job.forEach((item) => {
  //         modifyData.push({
  //           label: item,
  //           value: item,
  //         });
  //       });
  //       setJobFunctionNames(modifyData.length ? modifyData : []);

  //       setLoading(false);
  //     })
  //     .catch((e) => setLoading(false));
  // };

  // fetch all job functions 
  const fetchAllJobFunction=async(jobCategory, agencyId)=>{
    setFormLoader(true);

    let idagency=!!visible.agencyId?visible.agencyId: agencyId ? agencyId : selected[0]?.value;
    let query={
        agencyId: idagency,
        clientId: clientId,
        jobCategory: jobCategory,
    }
    await getAllJobNamensByClient(query)
        .then((data) => {
        let uniqueJobNames = [...new Set(data)];
        let modifyData=[];
            uniqueJobNames.forEach((item)=>{
                modifyData.push(
                    {
                        label:item,
                        value:item
                    }
                );
            })
            modifyData = jobFunctionNames?.length > 0 && selected?.length !== 1 ? intersectionWith(jobFunctionNames, modifyData, isEqual) : modifyData;
            if(uniqueJobNames.length > 0){
            modifyData.push({
              label: 'Any of the above',
              value: 'null'
          });
         }
            setJobFunctionNames(modifyData.length ? modifyData : []);
            setFormLoader(false);
        
      })
      .catch((e) => setFormLoader(false));
  }

  // const fetchAllJonFunctionCategories = async (id, clientId) => {
  //   setLoading(true);
  //   let query = {
  //     agencyId: id,
  //     clientId: clientId,
  //   };
  //   await getAllJobCategoriesByClient(query)
  //     .then((data) => {
  //       let modifyData=[];
  //       let finalData= data[0].jobCategory;
  //       finalData.forEach((item)=>{
  //           item.forEach(subItem=>{
  //             if(modifyData.filter(tempData=>tempData.value===subItem).length===0){
  //               modifyData.push(
  //                   {
  //                       label:subItem,
  //                       value:subItem
  //                   }
  //               );
  //             }
  //           })
  //       })
  //       setJobFunctionCategories(modifyData.length ? modifyData : []);
  //       setLoading(false);
  //     })
  //     .catch((e) => setLoading(false));
  // };

  // fetch all client locations
  const fetchAllLocations = async (id) => {
    setFormLoader(true);
    let query = {
      clientId: id,
    };
    await getAllLocationsByClient(query)
      .then((data) => {
        let modifyData = [];
        data.clientAddress.forEach((item) => {
          modifyData.push({
            label: item.location,
            value: item.location,
          });
        });
        setlocations(modifyData.length ? modifyData : []);
        setallLocations(data.clientAddress);

        setFormLoader(false);
      })
      .catch((e) => setFormLoader(false));
  };

  const fetchingAllLocation = ()=>{
    setFormLoader(true);
    let modifyData = [];
    userInfo?.clientAddress?.forEach((item) => {
      modifyData.push({
        label: `${item.location.length >= 66 ? `${item.location.slice(0, 66)}...` : item.location}
                ${item?.address ? ` (${item.address.length >= 17 ? `${item.address.slice(0, 17)}...` : item.address})` : ''}`,
        value: item?.addressId ? item.addressId :'',
        addressId:item?.addressId ? item.addressId : null,
        siteCode:item?.address ? item.address : null,
        location:item.location
      });
    });
    setlocations(modifyData.length ? modifyData : []);
    setallLocations(userInfo?.clientAddress);
    setFormLoader(false)
    

  } 


  useEffect(()=> {
    const temp = {...edit}
     if(allAgenciesoptions.length == 1 && selected.length == 0){
       setSelected([allAgenciesoptions[0]])
       temp.agencyName = allAgenciesoptions[0]?.label
     }
     if(allAgenciesoptions.length == 0 && selected.length == 1){
       setSelected([]);
     }
     if(allAgenciesoptions.length > 1 && selected.length == 1){
        let value= selected[0].label;
        temp.agencyName = value;
     }
     if(selected.length >= 1 && allAgenciesoptions.length >= 1 && (jobFunctionNames.length == 1 || jobFunctionNames.length == 2)){
       temp.jobName = jobFunctionNames[0].value
     }
     if(selected.length == 0 && allAgenciesoptions.length == 0 && jobFunctionNames.length == 0){
       temp.jobName = ''
     }
     if(locations.length == 1){
       let value = locations[0].value;
       let dataObj = locations[0];
       let obj = allLocations[allLocations.findIndex((ar) => ar?.addressId === value)];
       temp.location = dataObj?.location
       temp.zipCode= obj?.zipCode
       temp.longLat= obj?.longLat
       temp.clientSiteTimeZone = obj?.timeZone
       temp.holidays= obj?.holidays
       temp.siteCode=dataObj?.siteCode
       temp.addressId=value
     }
   setEdit(temp)
   },[allAgenciesoptions, selected, jobFunctionNames , locations, allLocations ])


  // set Default values
  const setDefault = () => {
    validator.current.hideMessages();
    setEdit(initState);
    setDocument([]);
    setUpdeteID("");
    setFile([]);
    setVisible(false);
    setSelected([]);
    setAutoSplitWR(false)
    setStartDateWRType(null)
    setEndDateWRType(null)
    setSearchKey('');
    setFilteredJobCategories(jobCategories);
  };

  //handle input change

  // Temporary backup code in case the useEffect method doesn't work as expected
  // The WR type doesn't change if the client location is added/changed after the start/end date is selected in this version of the code
  // const handleInput = ({ target: { name, value } }) => {
  //   value = name === 'staffNeeded' ? value : value;
  
  //   if (name === 'jobCategory') {
  //     fetchAllJobFunction(value);
  //     setEdit({ ...edit, [name]: value, jobName: '' });
  //   } else if (name === 'endDate') {
  //     setEdit({ ...edit, [name]: value });
  //     if (startDate) {
  //       const start = new Date(startDate);
  //       const end = new Date(value);
  
  //       const isStartWeekend = start.getDay() === 6 || start.getDay() === 0;
  //       const isEndWeekend = end.getDay() === 6 || end.getDay() === 0;
  
  //       const isStartHoliday = edit.holidays && checkHoliday(start); // true or false
  //       const isEndHoliday = edit.holidays && checkHoliday(end); // true or false 
  
  //       let wrType;
  //       if (isStartHoliday || isEndHoliday) {
  //         wrType = 3; // Holiday
  //       } else if (isStartWeekend || isEndWeekend) {
  //         wrType = 2; // Weekend
  //       } else {
  //         wrType = 1; // Weekday
  //       }
  
  //       setEdit((prevEdit) => ({ ...prevEdit, wrType }));
  //     }
  //   } else {
  //     setEdit({ ...edit, [name]: value });
  //   }
  // };

  const handleInput = ({ target: { name, value } }) => {
    value = name === "staffNeeded" ? value : value;
    // value = name === 'startDate' ? moment(value).format('YYYY-MM-DD[T]HH:mm:ss.SSSS') : value;
    // value = name === 'endDate' ? moment(value).format('YYYY-MM-DD[T]HH:mm:ss.SSSS') : value;
    if(name === 'jobCategory'){
      fetchAllJobFunction(value);
      setEdit({ ...edit, [name]: value, 'jobName' : '' });
    } else {
      setEdit({ ...edit, [name]: value });
      if(name === 'startDate' && value === null) {
        setStartDateWRType(null)
    }
    if(name === 'endDate' && value === null) {
        setEndDateWRType(null)
    }
    }
  };

  useEffect(() => {
    if (edit.startDate && edit.endDate) {
      const start = new Date(edit.startDate);
      const end = new Date(edit.endDate);
  
      const isStartWeekend = start.getDay() === 6 || start.getDay() === 0;
      const isEndWeekend = end.getDay() === 6 || end.getDay() === 0;
  
      const selectedLocation = allLocations.find((location) => location.location === edit.location);
  
      const isStartHoliday = selectedLocation && selectedLocation.holidays && checkHoliday(start); // returns boolean value
      const isEndHoliday = selectedLocation && selectedLocation.holidays && checkHoliday(end); // returns boolean value
  
      let wrType;
      if (isStartHoliday || isEndHoliday) {
        wrType = 3; // Holiday
      } else if (isStartWeekend || isEndWeekend) {
        wrType = 2; // Weekend
      } else {
        wrType = 1; // Weekday
      }
   
      const startDateWrType = isStartHoliday ? "3" : isStartWeekend ? "2" : "1"
      const endDateWrType = isEndHoliday ? "3" : isEndWeekend ? "2" : "1"

      setStartDateWRType(startDateWrType)
      setEndDateWRType(endDateWrType)

      if(edit.startDate && edit.endDate && !moment(edit.startDate).isSame(edit.endDate) && startDateWrType !== endDateWrType) {
        setAutoSplitWR(true)
        setEdit(prevEdit => ({ ...prevEdit, repeat:false, repeatData:{} }));
      }
      else {
        setAutoSplitWR(false)
      }
      setEdit((prevEdit) => ({ ...prevEdit, wrType }));
    }
  }, [edit.startDate, edit.endDate, edit.location]);  

  const checkHoliday = (date) => {
    const selectedLocation = allLocations.find((location) => location.location === edit.location);
  
    // Check if the given date matches any of the holiday dates (The API received date is converted to string for comparison with the picked date)
    if (selectedLocation && selectedLocation.holidays) {
      const holidays = selectedLocation.holidays.map((holiday) => new Date(holiday));
  
      return holidays.some((holiday) => date.toDateString() === holiday.toDateString());
    }

    return false;
  };

  // submit form method
  const handleSubmit = async () => {
    const formValid = validator.current.allValid();
    const body = edit;
    if(edit.jobName == "null"){
      delete body.jobName;
    }
    body["clientId"] = clientId;
    body["agencyId"] = !!visible.agencyId?visible.agencyId: selected[0]?.value;
    body.documents = document.length > 0 ? document : body.documents;
    body.startDate = edit.startDate ? moment(edit.startDate).format("YYYY-MM-DD[T]HH:mm:ss.SSSS") : "";
    body.endDate = edit.endDate ? moment(edit.endDate).format("YYYY-MM-DD[T]HH:mm:ss.SSSS"): "";
    body.repeatData.endsOn = edit.repeatData.endsOn ? moment(edit.repeatData.endsOn).format("YYYY-MM-DD[T]HH:mm:ss.SSSS"):"";

    if(moment(endDate).diff(moment(startDate), 'hours') >= 24){
      let message = "Shift duration should be less than 24 Hours."
      Toast({ type: "error", message, time: 5000 });
      return;
    }
    
    // console.log(body);
    if (updeteID) {
      body["id"] = updeteID;
    }
    var api = visible.id ? updateWork : addWork;
    if (formValid) {
      if(!autoSplitWR) {
      setFormLoader(true);
      let workRequestPromises =  visible.id ? [api(body)] : selected.map((agency, index) => {
        body["agencyId"] = agency.value;
        body["agencyName"] = agency.label;
        body.documents = document?.[index] ? [document?.[index]] : []
        return api(body)
      }) 
       await Promise.all(workRequestPromises)
        .then((data) => {
          Toast({ type: 'success', message: 'Work Request has been '+ (visible?.id ? 'updated' : 'added') + ' successfully' })
          setFormLoader(false);
          setDefault();
          // handleReload();
          // fetchAllData();
          setstartTime("");
          setendTime("");
          // setSelected([]);
        })
        .catch((e) => setFormLoader(false));
      }
      else {
        setShowAutoSplitWRConfirmation(true)
      }
    } else {
      validator.current.showMessages();
      validatorUpdate();
      setFormLoader(false);
    }
    setFormLoader(false);
  };

  const handleFileUpload = async (e) => {
    if (e) {
      let agencyID = !!visible.agencyId?visible.agencyId: selected[0]?.value;
      setFormLoader(true);
      setFile(e);
      setIsUpload(true);
      let formData = new FormData();
      formData.append("file", e[0]);
      let fileUploadPromises =  visible?.id ? [uploadFileToMDB(formData,{agencyId:agencyID,entity:null})] : selected.map((agency) => {
        return uploadFileToMDB(formData,{agencyId:agency.value,entity:null})
      }) 
       await Promise.all(fileUploadPromises)
        .then((res) => {
          // console.log(data);
          setIsUpload(false);
          let copy = [...document];
          res?.forEach((singleFileResponse, index) => copy[index] = `${singleFileResponse.fileId}|${singleFileResponse.title}|${singleFileResponse.contentType}`)
          // copy[0] = `${res.title}|data:${res.contentType};base64,${res.image}`;
          setEdit({ ...edit, documents: copy })
          setDocument(copy);
          // setDefault();
          // fetchAllData();
        })
        .catch(() => setIsUpload(false));
        setFormLoader(false);
    }
    // console.log(e);
    setLoading(false);
  };

  const deleteFile = async () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirmation = async (confirmed) => {
    setShowDeleteConfirmation(false);

    // let copy = [...document];

    if (confirmed) {
        const agencyID = !!visible.agencyId ? visible.agencyId : selected[0]?.value;
        const fileId = edit?.documents[0]?.split('|')[0];
        let fileDeletePromises =  visible?.id ? [deleteFileFromMDB({ fileId, agencyId: agencyID})] : selected.map((agency, index) => {
          const fileDeleteId = edit?.documents?.[index]?.split('|')[0];
          return deleteFileFromMDB({ fileId:fileDeleteId, agencyId: agency?.value});
        }) 
         await Promise.all(fileDeletePromises)
          .then((res) => {
                setDocument([])
                setEdit({ ...edit, documents: [] })
            })
            .catch ((error) => {
            console.log(error)
        })
        }
    };

  const handleDelete = async () => {
    let body = {
      workId: visible.id,
      reason: "Date changed",
    };
    setFormLoader(true);
    await deleteWork(body)
      .then((data) => {
        setFormLoader(false);
        setDefault();
        handleReload();
      })
      .catch(() => {
        setFormLoader(false);
      });
  };

  // handle place changes
  const handleSelectAddress = (data) => {
    // if(data===''){
    //     setEdit({ ...edit, zipCode: '',location: data  })
    // }else{
    //     setEdit({ ...edit, location: data })
    // }
    let value = data.target.value;
    let dataObj = data.target.data;
    let obj =
      allLocations[allLocations.findIndex((ar) => ar?.addressId === value)];
    setEdit({
      ...edit,
      location: dataObj?.location,
      zipCode: obj?.zipCode,
      longLat: obj?.longLat,
      clientSiteTimeZone : obj?.timeZone,
      holidays: obj?.holidays,
      siteCode:dataObj?.siteCode,
      addressId:value
    });
  };
  // set lat long value
  const handleLatLng = (data) => {
    let newData = [];
    Object.keys(data).forEach((item) => newData.push(data[item]));
    // console.log(newData);
    newData.reverse();
    setEdit({ ...edit, longLat: newData });
  };

  // set pin code value
  const handleAddress = (data) => {
    let { pincode } = data;
    setEdit({ ...edit, zipCode: pincode });
  };

  const handleCloseRecurring = () => {
     setRecurring((prev)=>{
      return{
        ...prev,
         status:false
      }
     })
  }

  const handleUpdateRecurring = (data) =>{
    let modifyData = data;
    if(modifyData?.repeat && modifyData.repeatData?.endsOn && edit?.startDate !==""){

      const originalDate = new Date(edit?.startDate);

      const hours = originalDate.getHours();
      const minutes = originalDate.getMinutes();
      const seconds = originalDate.getSeconds();
      

      const targetDate = new Date(modifyData.repeatData.endsOn);

      targetDate.setHours(hours);
      targetDate.setMinutes(minutes);
      targetDate.setSeconds(seconds)


      modifyData.repeatData.endsOn = targetDate
    }
  setEdit((prev)=>({
   ...prev,
   ...modifyData
  }))
   handleCloseRecurring()
  }

  const getWrPromises = (data) => {
    return data.map((item,index) => {
        const body = item;


        body["clientId"] = clientId;
        
        body.startDate = moment(item.startDate).format('YYYY-MM-DD[T]HH:mm:ss.SSSS')
        body.endDate = moment(item.endDate).format('YYYY-MM-DD[T]HH:mm:ss.SSSS')

        if (index === 0 && updeteID) {
          body['id'] = updeteID;
          var api = updateWork;

          return api(body)
      }
      else {
          var api = addWork;

          return api(body)
      }
    })
 }

  const autoSplitWorkRequest = () => {
    if (moment(edit.startDate).isBefore(moment(edit.endDate))) {
      setLoadingKey('auto-split')
      const firstWrEndDate = moment(edit.startDate).set({ 'hour': 23, 'minute': 59, 'second': 0 });
      const secondWrStartDate = moment(edit.endDate).set({ 'hour': 0, 'minute': 0, 'second': 0 });

      let autoSplitWrArr = []
      if(visible.id) {
      const firstWr = { ...edit, wrType: startDateWRType, endDate: firstWrEndDate, agencyId:visible?.agencyId, documents : document?.length > 0 ? document : edit.documents }
      const secondWr = { ...edit, wrType: endDateWRType, startDate: secondWrStartDate, agencyId:visible?.agencyId, documents : document?.length > 0 ? document : edit.documents }
      autoSplitWrArr = [firstWr, secondWr]
      }
      else {
        selected?.forEach((agency, index) => {
        let firstWr = { ...edit, wrType: startDateWRType, endDate: firstWrEndDate, agencyId:agency?.value, agencyName:agency?.label, documents: document?.[index] ? [document?.[index]] : [] }
        let secondWr = { ...edit, wrType: endDateWRType, startDate: secondWrStartDate,  agencyId:agency?.value, agencyName:agency?.label, documents: document?.[index] ? [document?.[index]] : [] }
        autoSplitWrArr = [...autoSplitWrArr,firstWr, secondWr]
      })
      }

      const wrPromises = getWrPromises(autoSplitWrArr)

      Promise.all(wrPromises)
        .then((promise) => {
          Toast({ type: 'success', message: 'Work Request has been added successfully' })
          setLoadingKey('')
          setDefault()
          setShowAutoSplitWRConfirmation(false)
        })
        .catch((error) => {
          setLoadingKey('')
          setDefault()
          setShowAutoSplitWRConfirmation(false)
        })
    } else {
      Toast({ type: 'error', message: 'End date must be greater than start date' })
    }
  }

 const createOrUpdateWorkRequest = async() => {
        
        const body = edit;

        body.documents=document.length > 0 ? document : body.documents;

        body["clientId"] = clientId;
        body["agencyId"] = !!visible.agencyId?visible.agencyId: selected[0]?.value;
        
        body.startDate = moment(edit.startDate).format('YYYY-MM-DD[T]HH:mm:ss.SSSS')
        body.endDate = moment(edit.endDate).format('YYYY-MM-DD[T]HH:mm:ss.SSSS')

        if (updeteID) {
            body['id'] = updeteID;
        }

        var api = visible.id ? updateWork : addWork;

        let workRequestPromises =  visible?.id ? [api(body)] : selected?.map((agency, index) => {
          body["agencyId"] = agency.value;
          body["agencyName"] = agency.label;
          body.documents = document?.[index] ? [document?.[index]] : []
          return api(body)
        }) 
        await Promise.all(workRequestPromises)
            .then((data) => {
                Toast({ type: 'success', message: 'Work Request has been '+ (visible?.id ? 'updated' : 'added') + ' successfully' })
                setShowAutoSplitWRConfirmation(false);
                setDefault();
                setstartTime("");
                setendTime("");
            })
            .catch((e) => {});
 }

 const handleAction = (action) => {
    action.value ? autoSplitWorkRequest() : createOrUpdateWorkRequest()
}

  const onJobCategorySearch = (searchValue) => {
    setSearchKey(searchValue)
    if (searchValue) {
      const searchedJobCategories = jobCategories?.filter((item) => item.value?.toLowerCase().includes(searchValue?.toLowerCase()))
      setFilteredJobCategories(searchedJobCategories)
    } else {
      setFilteredJobCategories(jobCategories)
    }
  }

  let {
    agencyName,
    clientName,
    location,
    longLat,
    zipCode,
    workRequest,
    startDate,
    endDate,
    repeat,
    priority,
    engagementType,
    wrType,
    staffNeeded,
    jobCategory,
    jobName,
    notes,
    documents,
    clientSiteTimeZone ,
    rateFrequency
  } = edit;
  
  // console.log(edit, "edit...................");
  let tempStartDate = new Date(startDate);
  let tempEndDate = new Date(endDate);

  return (
    <NormalModal
      className={"modal-fill"}
      isOpen={visible.value}
      size="lg"
      toggle={setDefault}
      renderHeader={(e) => (
        <div className=" d-flex justify-content-between">
          <h6 className="fw-600 line-height-24 fs-16">Work Request</h6>
          <div className="d-flex ">
            {/* <img className=" mr-4 " src={editIcon} /> */}
            <img
              className={`mr-2 addWork-dlt ${
                visible.id ? "cursor-pointer" : "disabled"
              }`}
              // src={deleteIcon}
              onClick={() => handleDelete()}
              alt=""
            />
          </div>
        </div>
      )}
      bodyClass="add-staff-box"
      headerClass="border-0 bg-violet pt-4 px-4"
    >
      {!isLoading ? (
        <>
         <div className="row  mb-3">
            <div className="col-12 pr-2">
              <div className="form-group">
              <label className="add-wr-label">Select Job Category From Overall List <sup>*</sup></label>
             
                <CustomDropdown
                placeholder="Select or search job category"
                options={filteredJobCategories}
                value={edit.jobCategory || ''}
                onChange={handleSelectJobCategory}
                disabled={!!visible.id}
                onJobCategorySearch={onJobCategorySearch}
                />
                {validator.current.message(
                  "Job Category",
                  jobCategory,
                  "required"
                )}
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-12 ">
            <div className="form-group">
                <label className='add-wr-label'>Publish To Agency <sup>*</sup> {allAgenciesoptions.length === 0 && edit.jobCategory ?  <div className="agencies-info" message={`For Selected Category you don't have registered agencies, go to Agency Management & connect to Agency`}><img src={infoIcon}/></div> : null }</label>
                <div className="workreq">
               {!visible.id &&  <MultiSelect 
                  options = {allAgenciesoptions}
                  value={ selected }
                  onChange={(e)=>{
                    if(e.length){
                      setSelected(e)
                    }else{
                      setSelected([])
                      setEdit({...edit, jobName:''})
                    }
                  }}
                  labelledBy="Select"
                  ItemRenderer={ItemRenderer}
                  valueRenderer={valueRenderer}
                  disableSearch="true"
                  className='dropdown-container'
                  hasSelectAll={false}
                  disabled={!!visible.agencyId || !edit.jobCategory ? true:false}
             />
             }
             {validator.current.message(
                "Agency name",
                agencyName,
                "required"
             )}
             { !!visible.id &&  <NormalInput
                  disabled={true}
                  placeholder="Agency name"
                  maxlength="100"
                  minlength="5"
                  name="Agency Name"
                  value={agencyName}
                  className="form-control rounded-0"
                />}
             </div>
              </div>
              {/* <div className="form-group">
                <label>Client Name <sup>*</sup></label>
                <NormalInput
                  disabled={true}
                  placeholder="Client name"
                  name="clientName"
                  value={clientName}
                  className="form-control rounded-0"
                  onChange={handleInput}
                 
                />
                {validator.current.message(
                  "Client name",
                  clientName,
                  "required"
                )}
              </div> */}
            </div>
          </div>
          <div className="row  mb-2">
            <div className="col-8 pr-1">
              <div className="form-group">
              <label className='add-wr-label'>Site Location <sup>*</sup></label>
                {/* <label>Location</label>
                                    <div className="w-100">
                                        <LocationSearchInput
                                            address={location}
                                            handleAddress={handleAddress}
                                            handleSelect={(address) => handleSelectAddress(address)}
                                            handleLatLng={handleLatLng}
                                        />
                                    </div> */}

                <NormalSelect
                  disabled={!!visible.id && (enableWR.hasOwnProperty('status') && enableWR.status !== 1)}
                  placeholder="Select site location"
                  name="location"
                  value={edit?.addressId ? edit?.addressId : ''}
                  className="form-control rounded-0 "
                  handleChange={handleSelectAddress}
                  options={locations}
                
                />
                {validator.current.message("location", location, "required")}
                {/* {
                                        zipCode === '' && location !== '' ?
                                            <div class="srv-validation-message">Select correct Address for PinCode</div>
                                            :
                                            ''
                                    } */}
              </div>
            </div>
            <div className="col-4 pl-1">
              <div className="form-group">
              <label className='add-wr-label'>Zip Code <sup>*</sup></label>
                <NormalInput
                  disabled={true}
                  placeholder="Zip Code"
                  name="zipCode"
                  type="number"
                  value={zipCode}
                  className="form-control rounded-0"
                  onChange={handleInput}
                 
                />
                {validator.current.message(
                  "Zip Code",
                  zipCode,
                  "required"
                )}
              </div>
            </div>
          </div>
          <p className="work-req-details">Work Request Details</p>
          <hr />
          <div className="row  mb-3">
            <div className="col-6 pr-1">
              <div className="form-group">
              <label className='add-wr-label'>Job Category <sup>*</sup></label>
              <NormalSelect
                  disabled={true}
                  placeholder="Select job category"
                  name="jobCategory"
                  value={edit.jobCategory}
                  className="form-control rounded-0"
                  handleChange={handleInput}
                  options={disabledJobCategories}
                 
                />
                {validator.current.message(
                  "Job Category",
                  jobCategory,
                  "required"
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
              <div className='d-flex justify-content-between'>
              <label className='add-wr-label'>Job Name <sup>*</sup></label>
              <OverlayTrigger
                    delay={{ hide: 450, show: 300 }} 
                    overlay={(props) => ( 
                      <Tooltip {...props}> 
                       Job name selection is to help your agency for assigning staff with relative experience or skills.
                      </Tooltip> 
                    )} 
                    placement="top">
                        <img src={infoIcon} alt='info-icon'></img>
                    </OverlayTrigger>
                </div>
              <NormalSelect
                  disabled={!!visible.id &&  (enableWR.hasOwnProperty('jobName') && enableWR.jobName !== null) && (enableWR.hasOwnProperty('status') && enableWR.status !== 1)}
                  placeholder="Select job name"
                  name="jobName"
                  value={jobName? jobName : ""}
                  className="form-control rounded-0"
                  handleChange={handleInput}
                  options={jobFunctionNames}
                 
                />
                {validator.current.message(
                  "Job Name",
                  jobName,
                  "required"
                )}
              </div>
            </div>
          </div>
          <div className="row  mb-2">
            <div className="col-4 pr-1">
              <div className="form-group">
              <label className='add-wr-label'>Start Date <sup>*</sup></label>
                <NormalDate
                  timeInput={true}
                  mindate={true}
                  placeholder={`${localStorage.getItem('dateFormat')}`}
                  name="startDate"
                  value={startDate ? new Date(startDate.toString()) : initialStartTime}
                  className="form-control rounded-0"
                  onChange={handleInput}
              
                />
                {validator.current.message("Start Date", startDate, "required")}
              </div>
            </div>
            <div className="col-4 pr-1">
              <div className="form-group">
              <label className='add-wr-label'>Start Time <sup>*</sup></label>
              <NormalDate
                      disabled={startDate ? isNaN(new Date(startDate).getTime()) :<>{initialStartTime?false:true}</>}
                      showTimeSelect={true}
                      timeIntervals={15}
                      timeInput={true}
                      mindate={true}
                      placeholder="HH:MM"
                      name="startTime"
                      value={startDate ? new Date(startDate.toString()) : initialStartTime}
                      className="form-control rounded-0"
                      onChange={(e) => {
                        let {
                          target: { value },
                        } = e;
                        if (value != undefined) {
                          setstartTime(value);
                          let time = moment(value).format("HH:mm:ss");
                          time = time.split(":");
                          tempStartDate.setHours(time[0]);
                          tempStartDate.setMinutes(time[1]);
                          tempStartDate.setSeconds(0);
                          // console.log(startDate);
                          setEdit({
                            ...edit,
                            startDate: new Date(tempStartDate),
                          });
                        }
                      }}
                     
                    />
                    {validator.current.message(
                      "Start Time",
                      startDate ? new Date(startDate.toString()) : initialStartTime,
                      "required"
                    )}

               
              </div>
            </div>
            <div className="col-4">
                <div className="form-group">
                    <div className="fs-13 text-black font-Gilroy pb-1 mb-1 line-height-20 add-wr-label">
                    Client Site Timezone <sup>*</sup>
                    </div>
                    <NormalSelect
                    // arrow={true}
                    placeholder="Time Zone"
                    disabled={true}
                    name="clientSiteTimeZone"
                    value={clientSiteTimeZone }
                    options={[{label:clientSiteTimeZone , value:clientSiteTimeZone }]}
                    className="form-control rounded-0 time-zone"
                    />
                </div>
            </div>
            {/*<div className="col-4 pl-3 pt-5">
              <div className="form-group  position-relative">
                <NormalCheckbox
                  name="isRepeat"
                  checked={isRepeat}
                  label="Recurring Shifts"
                  id="isKeepMe"
                  onChange={handleInput}
	      	        checked={false}
                />
              </div>
            </div>*/}
          </div>
              <div className="row  mb-2">
                <div className="col-4 pr-1">
                  <div className="form-group mb-1">
                  <label className='add-wr-label'>End Date <sup>*</sup></label>
                  <NormalDate
                  timeInput={true}
                  mindate={true}
                  placeholder={`${localStorage.getItem('dateFormat')}`}
                  name="endDate"
                  value={endDate ? new Date(endDate.toString()) : initialEndTime}
                  className="form-control rounded-0"
                  onChange={handleInput}
               
                />
                {validator.current.message("End Date", endDate, "required")}
                  </div>
                </div>
                <div className="col-4 pr-1">
                  <div className="form-group mb-1">
                  <label className='add-wr-label'>End Time <sup>*</sup></label>
                    <NormalDate
                      disabled={endDate ? !Moment(new Date(endDate)).isValid :<>{initialEndTime?false:true}</>}
                      showTimeSelect={true}
                      timeIntervals={15}
                      timeInput={true}
                      mindate={true}
                      placeholder="HH:MM"
                      name="endTime"
                      value={endDate ? new Date(endDate.toString()) : initialEndTime}
                      className="form-control rounded-0"
                      onChange={(e) => {
                        let {
                          target: { value },
                        } = e;
                        if (value != undefined) {
                          setendTime(value);
                          let time = moment(value).format("HH:mm:ss");
                          time = time.split(":");
                          tempEndDate.setHours(time[0]);
                          tempEndDate.setMinutes(time[1]);
                          tempEndDate.setSeconds(0);
                          // console.log(tempEndDate);
                          setEdit({ ...edit, endDate: new Date(tempEndDate) });
                        }
                      }}
                     
                    />
                    {validator.current.message("End Time", endDate ? new Date(endDate.toString()) : initialEndTime, "required")}
                  </div>
                </div>
                <div className="col-4">
                <div className="form-group">
                    <div className="fs-13 text-black font-Gilroy pb-1 mb-1 line-height-20 add-wr-label">
                    Client Site Timezone <sup>*</sup>
                    </div>
                    <NormalSelect
                    // arrow={true}
                    placeholder="Time Zone"
                    disabled={true}
                    name="clientSiteTimeZone"
                    value={clientSiteTimeZone }
                    options={[{label:clientSiteTimeZone , value:clientSiteTimeZone }]}
                    className="form-control rounded-0 time-zone"
                    />
                </div>
            </div>
              </div>
              <div className="row  mb-2">
                 <div className="col-4 pr-1">
              <div className="form-group">
              <label className='add-wr-label'>Engagement Type <sup>*</sup></label>
                   <NormalSelect
                   disabled={!!visible.id && (enableWR.hasOwnProperty('status') && enableWR.status !== 1)}
                  placeholder="Select engagement type"
                  name="engagementType"
                  value={engagementType}
                  className="form-control rounded-0"
                  handleChange={handleInput}
                  options={[
                    { label: "Contract", value: "1" },
                    { label: "Regular", value: "2" },
                  ]}
              
                />
                {validator.current.message("Engagement", engagementType, "required")}
              </div>
            </div>
            <div className="col-4 ">
              <div className="form-group">
              <label className='add-wr-label'>Work Request Type <sup>*</sup></label>
              <NormalSelect
                  disabled={!!visible.id && (enableWR.hasOwnProperty('status') && enableWR.status !== 1)}
                  placeholder="Select WR type"
                  name="wrType"
                  value={wrType}
                  className="form-control rounded-0"
                  handleChange={handleInput}
                  options={[
                    { label: "Weekday", value: 1 },
                    { label: "Weekend", value: 2 },
                    { label: "Holiday", value: 3 }
                  ]}
                />
                {validator.current.message("Work Request Type", wrType, "required")}
              </div>
            </div>
            
            <div className="col-4 d-flex pt-3">
              {!autoSplitWR && <div class="frame-1647" id={`${repeat == false ? "" : "recurrence-container"}`}
                onClick={()=> setRecurring((prev)=>{
                return{
                  ...prev,
                  status:true,
                }
              })}>
                  <img src={recurrenceIcon} className='flip-image' alt='Recurrence' width={40} />
                  <div className={`add-recurrence`}>Recurrence</div>
            </div>}
            {repeat === true && !autoSplitWR &&
            <div className="cursor-pointer d-flex align-items-center recurrence-delete-button" onClick={()=> setEdit((prev)=>({...prev, repeat:false, repeatData:{}}))}>
            <img src={deleteFileIcon} alt="delete-recurrence"/>
            </div> }
              
            </div>
          </div>
          <div className="row  mb-2">
          <div className="col-4 pr-1">
              <div className="form-group">
              <label className='add-wr-label'>Priority <sup>*</sup></label>
                {/* <NormalInput
                                        placeholder="ICU Nursing "
                                        name="jobFunction"
                                        value={jobFunction}
                                        className="form-control rounded-0"
                                        onChange={handleInput}
                                        label={"Job Function*"}
                                    /> */}
                                     <NormalSelect
                  placeholder="Select work priority"
                  name="priority"
                  value={priority}
                  className="form-control rounded-0"
                  handleChange={handleInput}
                  options={[
                    { label: "Normal", value: "normal" },
                    { label: "Urgent", value: "urgent" },
                    { label: "Critical", value: "critical" },
                  ]}
              
                />
                {validator.current.message("Priority", priority, "required")}
              </div>
            </div>
            <div className="col-4 pr-2">
                    <div className="form-group">
                        <label className='add-wr-label'>Billing Type <sup>*</sup></label>
                            <NormalSelect
                                placeholder="Select billing type"
                                name="rateFrequency"
                                value={rateFrequency}
                                className="form-control rounded-0 bg-ash setmultibox fs-13"
                                handleChange={handleInput}
                                options={billingTypeOptions}
                                disabled={!!visible.id}
                            />
                            {validator.current.message("Billing type", rateFrequency, "required")}
                    </div>    
                </div>
            <div className="col-4 pr-2">
              <div className="form-group">
              <label className='add-wr-label'>Staff Needed <sup>*</sup></label>
                <NormalInput
                  placeholder="Enter staff count required"
                  maxlength="3"
                  minlength="1"
                  type={"number"}
                  name="staffNeeded"
                  value={staffNeeded}
                  className="form-control rounded-0"
                  onChange={handleInput}
                  
                />
                {validator.current.message(
                  "Staff(s) Needed",
                  staffNeeded,
                  "required"
                )}
              </div>
            </div>

          </div>


          <div className="row align-items-start mb-3 height-add">
              <div className="col-8 pr-1">
                <div className="form-group">
                  <label className='add-wr-label'>Work Request Title</label>
                    <div className="form-group">
                      <NormalInput
                        placeholder="Enter work request title"
                        maxlength="200"
                        name="workRequest"
                        value={workRequest}
                        className="form-control rounded-0 bg-ash"
                        onChange={handleInput}
                      
                      />
                      {/* {validator.current.message(
                        "work Request",
                        workRequest,
                        "required"
                      )} */}
                    </div>
                </div>
                <div className="form-group">
                  <NormalTextarea
                    placeholder="Enter special instructions (if any)"
                    maxlength = "500"
                    name="notes"
                    rows={2}
                    value={notes}
                    className="form-control rounded-0"
                    onChange={handleInput}
                    label={"Special Notes"}
                  />
                  {/*validator.current.message("Note", notes, "required")*/}
                </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <div className="file-upload1" onClick={() => {
                  if(selected.length === 0 && !visible.agencyId) {
                    Toast({type: "info", message: "Please select job category and agency first"})
                  }
                }}>
                  <DragFileUpload
                    className=" rounded-0 file-uploader drag-drop-container"
                    handleFileUpload={handleFileUpload}
                    label={"Attach Documents (if any)"}
                    disabled={!visible.agencyId && selected.length === 0}
                  >
                    <div className="p-3 icon">
                     
                                    {
                                        !!documents && documents.length > 0 ?
                                        <>
                                            {
                                               <DisplayFile file={documents} agencyId={!!visible.agencyId?visible.agencyId:selected[0]?.value} />
                                                // documents.map((item) =>  { return (<img className="p-2 doc-upload-img" src={item.split("|").length>1 && item.split("|")[1]} alt="uploadImg" />) })  //alt={item.split("|").length>1 && item.split("|")[0]}
                                            }
                                        </>
                                        :
                                        <>
                                        <img className="pt-4" src={fileUploadIcon}  alt="fileUploadIcon"  />
                                        <p className="p-2 drag-drop">Drag & Drop (or) Click</p>
                                      </>
                                    }
                                </div> 
                  </DragFileUpload>
                  {documents?.length > 0 &&
                    <img 
                      className='cursor-pointer'
                      style={{position: "relative", bottom: "45px"}} 
                      src={deleteFileIcon}
                      onClick={deleteFile}
                    />
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-end mt-2 pb-3 pr-3 justify-content-end ">
            <NormalButton
              onClick={handleSubmit}
              id="Confirm"
              label={`${!!visible.id ? "UPDATE" : "CONFIRM"}`}
              outline={false}
              disabled={isFormLoader || isUpload}
              loader={isFormLoader || isUpload}
              className="mb-2 btn fs-12 btn-violet-primary btn-block form-btn"
            />
          </div>
      <RecurringModel
      data={edit}
      show={recurring.status}
      handleClose={handleCloseRecurring}
      repeat={edit?.repeat}
      repeatData={edit?.repeatData}
      handleUpdateRecurring={handleUpdateRecurring}
      /> 
        </>
      ) : (
        <CommonPageLoader />
      )}
      {showDeleteConfirmation && (
        <DeleteFileConfirmationModal
          isOpen={true}
          handleAction={handleDeleteConfirmation}
          toggle={() => setShowDeleteConfirmation(false)}
        />
      )}
      { showAutoSplitWRConfirmation ? <ConfirmModal
          toggle={() => setShowAutoSplitWRConfirmation(!showAutoSplitWRConfirmation)}
          isOpen={showAutoSplitWRConfirmation}
          handleAction={(e) => handleAction(e)}
          loadingCheckKey = {`auto-split`}
          loadingKey={loadingKey}
          message='Work request type is different for start date and end date. Do you want to split work request?'
          type='auto-split'
        /> : null }
    </NormalModal>
  );
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addWork,
      fileUploadOnMDB,
      uploadFileToMDB,
      deleteFileFromMDB,
      deleteWork,
      getSingleWork,
      updateWork,
      getAllJobFunctions,
      getAllJobCategoriesByClient,
      getClientById,
      getAllLocationsByClient,
      getAllJobNamensByClient,
      getAgencies,
      getAllJobCategories,
    },
    dispatch
  );
};

export default connect(
  mapDispatchToProps,
  mapDispatchToProps
)(withRouter(AddWorkRequest));

const { UPDATE_CONNECTION_STATUS, UPDATE_NOTIFICATION_COUNT, UPDATE_NEW_WORK_REQUEST, UPDATE_REGISTERED_AGENCY, REQUESTED_AGENCY  } = require("service/actionType");



const initialState = {
    isConnected:false,
    notificationCount:false,
    workRequest:null,
    registeredAgency:null,
    requestedAgency:null,
  };

  export function updateNewRecord(data){
    return {
        type: UPDATE_NEW_WORK_REQUEST,
        payload: data,
    }
}

  export default  (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_CONNECTION_STATUS:
        return {
          ...state,
          isConnected: action.payload,
        };
        case UPDATE_NOTIFICATION_COUNT:
          return {
            ...state,
            notificationCount: action.payload,
          };
      case UPDATE_NEW_WORK_REQUEST:
        return {
          ...state,
          workRequest: action.payload,
        };
        case UPDATE_REGISTERED_AGENCY:
        return {
          ...state,
          registeredAgency: action.payload,
        };
        case REQUESTED_AGENCY:
        return {
          ...state,
          requestedAgency: action.payload,
        };
      default:
        return state;
    }
  };
  
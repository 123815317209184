import React, {memo, useEffect, useState} from 'react'
import './style.scss'
import Modal from './Modal/Modal';
import Joyride , {  ACTIONS, LIFECYCLE } from 'react-joyride';
import {  settingsPageSteps} from './tourGuideSteps/settingsPageSteps';
import { workSchedulePageSteps, workScheduleCalendarSteps} from './tourGuideSteps/workSchedulePageSteps';
import { feedbackPageSteps, addFeedbackSteps} from './tourGuideSteps/feedbackPageSteps';
import { agencymanagementSteps} from './tourGuideSteps/agencymanagementSteps';
import { dashboardSteps } from './tourGuideSteps/dashboardSteps';
import {  changePlanSteps, managePlansSteps, subscriptionDetails, paymentTransactions } from './tourGuideSteps/subscriptionManagement';
import { useHistory } from 'react-router-dom';
import CustomComponent from './CustomComponent';
import { store } from 'service/helpers';
import { useSelector } from 'react-redux';
import tourGuide, { startTourGuide } from 'reducer/tourGuide';
import { useDispatch } from 'react-redux';

const TourGuide = (props) => {
  let dispatch = useDispatch()

  const [tour, setTour] = useState({
    run:false,
    steps:[],
    stepIndex:0,
  })
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("")
  
  // const [stepIndex, setStepIndex] = useState(0)
  let tourGuideData = useSelector((state) => state.tourGuideReducer.tourGuideData);
  let pathName = window.location.pathname
  
  const history = useHistory();

  const getTourSteps = () => {
    if (pathName === '/admin/settings') {
      return settingsPageSteps
    }
    if (pathName === '/admin/WorkSchedule/list') {
      return workSchedulePageSteps
    }
    if(pathName === '/admin/WorkSchedule/calendar'){
      return [ ...workSchedulePageSteps.slice(0,9), ...workScheduleCalendarSteps]
    }
    if (pathName === "/admin/feedback") {
      return feedbackPageSteps
    }  
    if (pathName === "/admin/feedback/addFeedback/") {
      return addFeedbackSteps
    }  
    if (pathName === "/admin/AgencyManagement") {
      return agencymanagementSteps
    } 
    if(pathName === '/admin/subscription/manageplans'){
      const targetElement = document.getElementById('subscription-details-tab');
      if(targetElement) {
        return [...subscriptionDetails, ...managePlansSteps]
      } else {
        return [...managePlansSteps]
      }
    }
    if(pathName.includes('/admin/subscription/manageplans/changeplan')){
      return changePlanSteps
    }
    if (pathName === "/admin/subscription/paymenttransactions") {
      const transactionDetailElement = document.querySelector('.transaction-detail');
      if (transactionDetailElement) {
          return paymentTransactions;
      } else {
          return paymentTransactions.slice(0, 4); 
      }
    }
    if (pathName === "/admin/dashboard") {
      return dashboardSteps
    } 
  }

  // Checking if the click event's target is not a part of the Joyride tooltip and turning the tour guide off if it is not
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (tour.run) {
        const tooltipElement = document.querySelector('.react-joyride__tooltip');
        if (tooltipElement && !tooltipElement.contains(event.target)) {
          dispatch(startTourGuide({ run: false, stepIndex: 0 }));
        }
      }
    };
  
    // click event listener for the document body
    document.body.addEventListener('click', handleOutsideClick);
  
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, [tour.run, dispatch]);  

  useEffect(()=>{
    setTour({
              ...tour,
              run: tourGuideData.run,
              steps: getTourSteps(),
              stepIndex: tourGuideData?.stepIndex ? tourGuideData?.stepIndex : 0
            })
  },[tourGuideData,pathName])
  
  const handleJoyrideCallback = (data) => {
    const { status, index, action, lifecycle, type, size} = data;

    // View Plan

    if(size === 0 && pathName === '/admin/subscription/manageplans/viewplan/features' || pathName === '/admin/subscription/manageplans/viewplan/agency') {
      dispatch(startTourGuide({run:false, stepIndex:0}))
      setShowModal(true)
      setModalMessage("To change your plan -> Go back to manage plans. Click on change plan and then select your desired plan")
      return;
    }

    // Change Plan

    if(pathName.includes('/admin/subscription/manageplans/changeplan')  && index === 0 && action === 'start' && lifecycle === 'init'){
      history.replace('features')
    }
    if(pathName.includes('/admin/subscription/manageplans/changeplan')  && index === 0 && action === 'next' && lifecycle === 'complete'){
      dispatch(startTourGuide({run: true, stepIndex: 1}))
    }

    if(pathName === '/admin/subscription/manageplans/changeplan/features' && index === 3 && action === 'next'){
      history.replace('agency')
      dispatch(startTourGuide({run:true, stepIndex:3}))
      return 
    }
    if(pathName === '/admin/subscription/manageplans/changeplan/agency' && index === 8 && action === 'next'){
      history.replace('paymentdetails')
      dispatch(startTourGuide({run:true, stepIndex:8}))
      return 
    }
    
    if(pathName === '/admin/subscription/manageplans/changeplan/agency' && index === 3 && action === 'prev' && lifecycle === 'complete'){
      history.replace('features')
      dispatch(startTourGuide({run:true, stepIndex:2}))
      return 
    }
    if(pathName === '/admin/subscription/manageplans/changeplan/paymentdetails' && index === 8 && action === 'prev' && lifecycle === 'complete'){
      history.replace('agency')
      dispatch(startTourGuide({run:true, stepIndex:7}))
      return 
    }

    // if(size === 0) {
    //   history.push("/admin/feedback")
    //   window.location.reload()
    // }

    if(!data.step){
      dispatch(startTourGuide({run:false, stepIndex:0}))
      return;
    }

    if (action === ACTIONS.SKIP && pathName.includes('/admin/subscription/manageplans/changeplan')) {
      dispatch(startTourGuide({run: false, stepIndex: 0}))
      return;
    }

    if (action === ACTIONS.SKIP) {
      dispatch(startTourGuide({run: false, stepIndex: index}))
      return;
    }

    if (action === ACTIONS.PREV && lifecycle ===  LIFECYCLE.COMPLETE) {
      setTour((prevTour)=>{
        return{
          ...prevTour,
          stepIndex: prevTour.stepIndex -1
        }
      })
      return;
    }

    if (action === ACTIONS.NEXT && lifecycle ===  LIFECYCLE.COMPLETE) {
      setTour((prevTour)=>{
        return{
          ...prevTour,
          stepIndex: prevTour.stepIndex +1
        }
      })
      return;
    }

    // Disable the tour guide once all the steps have been completed successfully
    // This is to prevent the tour guide from running everytime the user navigates to another route
    if (type === 'tour:end') {
      dispatch(startTourGuide({run:false, stepIndex:0}))
      if(pathName === "/admin/WorkSchedule/list"){
        localStorage.setItem('workScheduleTourCompleted', 'true')
        return;
      }
      if(pathName === "/admin/feedback"){
        localStorage.setItem('feedbackTourCompleted', 'true')
        return;
      }
      if(pathName === "/admin/AgencyManagement"){
        localStorage.setItem('agencyManagementTourCompleted', 'true')
        return;
      }
      if(pathName === "/admin/subscription/manageplans"){
        localStorage.setItem('subscriptionTourCompleted', 'true')
        return;
      }
      if(pathName === "/admin/settings"){
        localStorage.setItem('settingsTourCompleted', 'true')
        return;
      }

      return;
    }

    if (type === 'tour:end') {
      dispatch(startTourGuide({run: false, stepIndex: 0}))
      return;
    }
    if(type === 'error:target_not_found' && pathName === "/admin/WorkSchedule/list"){
      dispatch(startTourGuide({run:false, stepIndex:0}))
      setShowModal(true);
      setModalMessage("Oops! No steps ahead as there are no work requests yet!")
      return;
    }
    if(type === 'error:target_not_found' && pathName === "/admin/feedback"){
      dispatch(startTourGuide({run:false, stepIndex:0}))
      setShowModal(true);
      setModalMessage("Oops! No steps ahead as there's no feedback yet!")
      return;
    }
    if(type === 'error:target_not_found' && pathName.includes('/admin/subscription/manageplans/changeplan')){
           setTour((prev)=>{
            return {
              ...prev,
              stepIndex:index+1
            }
           })
      return;
    }
    if(type === 'error:target_not_found' && pathName.includes('/admin/subscription')){
      dispatch(startTourGuide({run:false, stepIndex:0}))
      setShowModal(true);
      setModalMessage("Oops! No steps ahead as there's no subscription plan.")
      return;
    }
  };

  const handleGoBack = () => {
    setShowModal(false);
    history.push("/admin/subscription/manageplans")
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    handleCloseModal()
  }, [pathName])

  return (
    <>
      {showModal && (
      <Modal
        message={modalMessage}
        onClose={handleCloseModal}
        goBack={handleGoBack}
      />
    )}
    <Joyride
    run={tour.run}
    steps={tour.steps}
    stepIndex={tour.stepIndex}
    spotlightPadding={0}
    disableOverlayClose
    continuous
    hideCloseButton
    scrollToFirstStep
    showProgress
    showSkipButton
    callback={handleJoyrideCallback}
    floaterProps={{
      disableAnimation:false,
     }}
    styles={{
      options: {
        arrowColor: 'white',
        overlayColor: 'rgba(0, 0, 0, 0.5)',
        primaryColor: "#033C28",
        textColor: 'black',
        width: 'max-content',
        zIndex: 1000,
      },
      buttonBack: {
        color: "#000",
      },
      buttonNext: {
        color: "#fff",
        backgroundColor: "#033C28"
      },
      buttonSkip: {
        color: "#000",
      },
      tooltip: {
        padding: "30px",
        width: "300px",
        backgroundColor: '#fff',
        color: '#000',
      }
    }}
      locale={{
        last: "Finish"
      }}
      
    />
    </>
  )
}

export default memo(TourGuide)
import { documentUpload, documentUploadOnMDB } from '../service/apiVariables';
// import {  addQuery } from '../service/helperFunctions';

 export const fileUpload = (body) => (dispatch, getState, { api, Toast }) => {
    
    return new Promise((resolve, reject) => {
      api({ ...documentUpload.create, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const fileUploadOnMDB = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...documentUploadOnMDB.create, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
import React, { useState } from 'react';
import './style.scss'
import viewCredentials from "assets/svg/mdi_eye-outline.svg";
import view from "assets/svg/view (1).svg"
import DisplayCredentialsImage from '../DisplayCredentialsImage';

const ViewCredentials = ({ docId, imageId, agencyId,onViewClick }) => {
 
  const [credentialsview, setCredentialsView] = useState(false);

  const handleButtonClick = () => {
    setCredentialsView(!credentialsview);
  };

  return (
    <div style={{display:"flex",alignItems:"center"}}>
      {/* { credentialsview && <DisplayCredentialsImage navbar={true} key={docId} imageId={imageId} agencyId={agencyId? agencyId:""}/>} */}
      <div key={docId} 
      //  class="frame-2483"
       onClick={() => onViewClick(docId)}>
      <img src={view}/>
     </div>
    </div>
  );
};

export default ViewCredentials;

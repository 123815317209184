import { logOut } from "action/AuthAct";
import { getUserLoginCount } from "action/AuthAct";
import axios from "axios";
import { store } from "./helpers";

// import { history } from "../service/helpers";

//var feURL = 'http://staffiqdev.qutrix.io';
//var feURL = 'http://staffatom.com';
var feURL = process.env.REACT_APP_FEURL? process.env.REACT_APP_FEURL : 'http://staffiqdev.qutrix.io';
var hostMode = process.env.REACT_APP_HOSTMODE? process.env.REACT_APP_HOSTMODE : 'LOCAL';

export const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  headers: {},
});

export const checkUserLoginCount = async () => {
  let userMail = encodeURIComponent(JSON.parse(localStorage.getItem('userData')).emailId)
    if(userMail)
      await store.dispatch(getUserLoginCount({ userMail }))
        .then((data)=>{})
          .catch(err =>{
            console.log(err)
          })
}

export const logout = async(value) => {
  if(value){
    let auditId = localStorage.getItem('auditId')
    await store.dispatch(logOut({auditId:auditId ? auditId : null}))
    .then(()=>{
      localStorage.clear()
      let redirectURL = '';
      if ( hostMode == 'LIVE' ) {
        redirectURL = (feURL) + '/auth/login';
      } else {
        redirectURL = (feURL) + ':2238/auth/login';
      }
      window.location.assign(redirectURL);
    })
    .catch((err)=>{
      logout()
    })
    return;
  }
  localStorage.clear()
  let redirectURL = '';
  if ( hostMode == 'LIVE' ) {
    redirectURL = (feURL) + '/auth/login';
  } else {
    redirectURL = (feURL) + ':2238/auth/login';
  }
  window.location.assign(redirectURL);
}


export const planFeatures = [
  {
    title : 'Client Portal',
    message:'Streamline your billing process with professional invoices. Generate, manage, and deliver invoices effortlessly. Ensure accuracy, get paid faster, and project a professional image to clients. Sign up now and simplify your invoicing experience.'
  },
  {
    title : 'Work Schedule',
    message:'Effortlessly track, manage, and analyze your teams time. Streamline project progress, enhance productivity, and make informed decisions. Sign up now for efficient time management'
  },
  {
    title : 'Agency Management',
    message:'Empower your team with a user-friendly app. Streamline communication, simplify task management, and boost productivity. Experience seamless collaboration and efficiency. Sign up now and transform your workforce.'
  },
]

export const freeTrialPlan ={
  "id": "#1234567890123",
  "planCategory": "Free Trial",
  "planCode": "ewre",
  "planName": "Free Trial Plan",
  "planType": 0,
  "features": [
      {
          "value": "Agency Management",
          "status": true
      },
  ],
  "billingType": 1,
  "status": 1,
  "subscriberType": 7,
  "baseUserLimit": 5,
  "baseAmount": 0,
  "currency": null,
  "symbol": null,
  "pricingModel": 1,
}

export const standardPlanFeatures = [
  'Work Shift Scheduling',
  'Multi-Site Handling',
  'Multi-Agency Engagement',
  'Recurring Job Listing'
]

export const standardPlanFeaturesDescription = [
  {
    title : 'Work Shift Scheduling',
    value : 'Work Shift Scheduling',
    message:'Organizing and optimizing work schedules for efficient and effective operational coverage.'
  },
  {
    title : 'Multi-Site Handling',
    value : 'Multi-Site Handling',
    message:'Efficient management of operations across multiple sites to ensure seamless coordination and optimal performance.'
  },
  {
    title : 'Multi-Agency Engagement',
    value : 'Multi-Agency Engagement',
    message:'Engaging with multiple agencies in a coordinated manner to promote collaboration and achieve shared outcomes'
  },
  {
    title : 'Recurring Job Listing',
    value : 'Recurring Job Listing',
    message:'Systematic management of recurring work schedules to ensure consistency and efficiency in operational staffing'
  },
]

export const statesList = [
	{ label: "[JK] - Jammu & Kashmir", value: "Jammu & Kashmir" },
	{ label: "[HP] - Himachal Pradesh", value: "Himachal Pradesh" },
	{ label: "[PB] - Punjab", value: "Punjab" },
	{ label: "[CH] - Chandigarh", value: "Chandigarh" },
	{ label: "[UT] - Uttarakhand", value: "Uttarakhand" },
	{ label: "[HR] - Haryana", value: "Haryana" },
	{ label: "[DL] - Delhi", value: "Delhi" },
	{ label: "[RJ] - Rajasthan", value: "Rajasthan" },
	{ label: "[UP] - Uttar Pradesh", value: "Uttar Pradesh" },
	{ label: "[BH] - Bihar", value: "Bihar" },
	{ label: "[SK] - Sikkim", value: "Sikkim" },
	{ label: "[AR] - Arunachal Pradesh", value: "Arunachal Pradesh" },
	{ label: "[NL] - Nagaland", value: "Nagaland" },
	{ label: "[MN] - Manipur", value: "Manipur" },
	{ label: "[MI] - Mizoram", value: "Mizoram" },
	{ label: "[TR] - Tripura", value: "Tripura" },
	{ label: "[ME] - Meghalaya", value: "Meghalaya" },
	{ label: "[AS] - Assam", value: "Assam" },
	{ label: "[WB] - West Bengal", value: "West Bengal" },
	{ label: "[JH] - Jharkhand", value: "Jharkhand" },
	{ label: "[OR] - Odisha", value: "Odisha" },
	{ label: "[CT] - Chattisgarh", value: "Chattisgarh" },
	{ label: "[MP] - Madhya Pradesh", value: "Madhya Pradesh" },
	{ label: "[GJ] - Gujarat", value: "Gujarat" },
	{ label: "[DD] - Daman & Diu", value: "Daman & Diu" },
	{ label: "[DN] - Dadra & Nagar Haveli", value: "Dadra & Nagar Haveli" },
	{ label: "[MH] - Maharashtra", value: "Maharashtra" },
	{ label: "[AP] - Andhra Pradesh", value: "Andhra Pradesh" },
	{ label: "[KA] - Karnataka", value: "Karnataka" },
	{ label: "[GA] - Goa", value: "Goa" },
	{ label: "[LD] - Lakshadweep", value: "Lakshadweep" },
	{ label: "[KL] - Kerala", value: "Kerala" },
	{ label: "[TN] - Tamil Nadu", value: "Tamil Nadu" },
	{ label: "[PY] - Puducherry", value: "Puducherry" },
	{ label: "[AN] - Andaman & Nicobar Islands", value: "Andaman & Nicobar Islands" },
	{ label: "[TL] - Telangana", value: "Telangana" },
	{ label: "[AD] - Hyderabad GST Commissionerate", value: "Hyderabad GST Commissionerate" },
	{ label: "[LA] - Kurnool GST Commissionerate", value: "Kurnool GST Commissionerate" }
  ];
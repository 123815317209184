import { settingsApi, subscriptionApi, stripeSubscriptionApi, razorPaySubscriptionApi  } from '../service/apiVariables';

import { addQuery } from '../service/helperFunctions';

// export const getBillingPlans = (query) => (dispatch, getState, { api, Toast }) => {
//     return new Promise((resolve, reject) => {
//         addQuery(query, subscriptionApi.getBillingType);
//         api({ ...subscriptionApi.getBillingType })
//             .then(({ data }) => {
//                 resolve(data);
//             })
//             .catch(({ message }) => {
//                 // reject(Toast({ type: 'error', message }));
//             });
//     });
// };

export const getSubscriptionList = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, settingsApi.getSubscriptions);
        api({ ...settingsApi.getSubscriptions })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const paymentCheckOutSession = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery( query, subscriptionApi.paymentCheckOutSession);
        api({ ...subscriptionApi.paymentCheckOutSession})
            .then(({ data }) => {
                resolve(data);
            })
            .catch((data) => {
                reject(data);
            });
    });
};

export const stripeCheckout = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery( query, stripeSubscriptionApi.checkout);
        api({ ...stripeSubscriptionApi.checkout, })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const razorPayCheckout = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery( query, razorPaySubscriptionApi.checkout);
        api({ ...razorPaySubscriptionApi.checkout, })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const getPaymentTransactions = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery( query, subscriptionApi.paymentTransactions);
        api({ ...subscriptionApi.paymentTransactions, })
            .then(( data ) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const getSingleSubscription = (query, disableToaster=false) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery( query, subscriptionApi.singleSubscription);
        api({ ...subscriptionApi.singleSubscription, })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                if(disableToaster){
                    reject(message)
                }else if(message === 'Id not found'){
                    reject(message)
                    Toast({ type: 'error', message:'Your subscribed plan is either discontinued or not supported anymore. Kindly change the plan from the active list below.', time:5000  })
                    return;
                }else{
                    Toast({ type: 'error', message });
                }
            });
    });
};

export const transactionLogsCSV = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, subscriptionApi.gettransactionLogsCSV);
        api({ ...subscriptionApi.gettransactionLogsCSV })
            .then(( data ) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
  };

  export const pauseResumePlan = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, subscriptionApi.pauseResumePlan);
        api({ ...subscriptionApi.pauseResumePlan })
            .then(({ data, message }) => {
                resolve(data);
                Toast({ type: 'success', message })
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};  


export const getLogs = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, subscriptionApi.getLogs);
        api({ ...subscriptionApi.getLogs })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
}; 



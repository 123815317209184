import React, { useState, useEffect } from "react";
// import { NormalButton, NormalSelect, NormalDate } from "component/common";
// import Moment from "moment";
import TimeSheetMark from "component/workShedule/timeSheetMark/index";
import timeSheetMarkNew from "../timeSheetMark new";
import { getFileFromMDB } from "action/fileHandler"
import "./style.scss";
import leftArrowIcon from "assets/svg/left_arrow.svg";
import rightArrowIcon from "assets/svg/right_arrow.svg";
// import deleteIcon from "assets/svg/delete.svg";
// import profile from "../../../assets/svg/Ellipse.png";
import useMapDataParser from 'component/common/interactiveMap/useMapDataParser';
import InteractiveMap from 'component/common/interactiveMap';
import TimeSheetMarkNew from "../timeSheetMark new";
import { useDispatch } from "react-redux";

export function StaffDetails(props) {
  const [active, setActive] = useState(0);
  const { timeData: staffTimeData, staffName, staffLatLong, showMap, toggleMap } = useMapDataParser({ data: props?.data?.staffWorkData?.[active] });
  const [activePointsFromStaffDetails, setActivePointsFromStaffDetails] = useState('');
  const [clientSitePicture, setClientSitePicture] = useState(null)

  const dispatch = useDispatch()

  const handleStaffDetais = (val) => {
    setActive(active + val);
  };
  let { staffWorkData} = props.data;    //, workId, edit 
  let { staffNeeded } = props.edit;
  // let{staffWorkData,workId}=props.locationData;
  // console.log(staffWorkData[active].clockOut);
  // console.log(
  //   Moment(staffWorkData[active].clockIn).format("hh:mm a") -
  //     Moment(staffWorkData[active].clockOut).format("hh:mm a")
  // );

  const getClientLoc = (latLng) => {
    let clientLoc = {lat: '', lng:''};
    if(!!latLng)
    {
      clientLoc.lng = latLng?.[0];
      clientLoc.lat = latLng?.[1]
    }
    return clientLoc;
  }

  // Client site picture for InteractiveMap
  const handleImageDownload = async (sitePicture) => {
    let imageData;
    let fileId = sitePicture.split('/')[1]
    await dispatch(getFileFromMDB({entity: 'client', clientId: props?.edit?.clientId ? props?.edit?.clientId : ''}, fileId))
      .then((response)=> {
        // console.log(response)
        imageData = `data:${response.contentType};base64,${response?.fileContent.data}`
        setClientSitePicture(imageData.toString())
        return imageData
      })
      .catch((error) => {
        setClientSitePicture(null);
        console.log('Error fetching image:', error);
      });
  };

  useEffect(()=>{
    if(props?.edit?.sitePicture) {
      handleImageDownload(props?.edit?.sitePicture)
    } else {
      setClientSitePicture(null)
    }
  },[props?.edit?.sitePicture])

  return (
    <>
     {/* Map */}
     <InteractiveMap 
        timeData={staffTimeData} 
        activePointsFromStaffDetails={activePointsFromStaffDetails} 
        staffName={staffName} 
        staffLatLong={staffLatLong} 
        open={showMap} 
        toggle={toggleMap} 
        sitePicture={clientSitePicture} 
        clientLatLng={getClientLoc(props?.edit?.longLat)} />
           
      <div class="line-74"></div>
      <div className="staff-shiftdetails-header">
        <div className="row align-items-center justify-content-space-evenly mt-3 newTimesheetbtns">
          {!!(staffNeeded >= 1) && (
            <>
            <div class="frame-2202" id={active === 0 ? "cursor-notallowed": "cursor-allowed"} onClick={() => (active === 0 ? {} : handleStaffDetais(-1))}>
            <svg
              class="material-symbols-arrow-back-ios-new"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_70_1214)">
                <path
                  d="M14.25 14.5L8 8.25L14.25 2L15.3594 3.10938L10.2188 8.25L15.3594 13.3906L14.25 14.5Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_70_1214">
                  <rect width="15" height="15" fill="white" />
                </clipPath>
              </defs>
            </svg>
          
            <div class="frame-2253">
              <div class="show-previous cta-3-m" id={active === 0 ? "no-drop" : "cursor-pointer"}>Show Previous</div>
            </div>
          </div>
            {/* <div className="col-4 align-self-start">
              <div className="form-group">
                <img src={leftArrowIcon} alt="leftArrowIcon"/>
                <span
                  className={`navi-link sub-text green-href fs-10 ml-2 ${
                    active === 0 ? "disabled" : "cursor-pointer"
                  }`}
                  onClick={() => (active === 0 ? {} : handleStaffDetais(-1))}
                >
                  Show Previous
                </span>
              </div>
            </div> */}
           </>
          )}
          <div className="frame-2204">
               <div class = "staff-shift-details"> Staff Timesheet Details </div>
          </div>
          {!!(staffNeeded >= 1) && (
            <>
            <div class="frame-2203" id={active + 1 === staffWorkData.length? "cursor-notallowed": "cursor-allowed"} onClick={() =>active + 1 === staffWorkData.length? {}: handleStaffDetais(1)}>
              <div class="frame-2254">
              <div class="show-next cta-3-m" id={ active + 1 === staffWorkData.length? "cursor-notallowed": "cursor-pointer"}>Show Next</div>
              </div>

             <svg
             class="material-symbols-arrow-back-ios-new1"
             width="15"
             height="15"
             viewBox="0 0 15 15"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             >
           <path
           d="M1.10938 1.25L7.35938 7.5L1.10938 13.75L2.38419e-07 12.6406L5.14062 7.5L2.38419e-07 2.35937L1.10938 1.25Z"
           fill="white"
          />
          </svg>
          </div>
            {/* <div className="col-4 align-self-end">
              <div className="form-group text-right">
                <span
                  className={`navi-link sub-text green-href fs-10 mr-2 ${
                    active + 1 === staffWorkData.length
                      ? "disabled"
                      : "cursor-pointer"
                  }`}
                  onClick={() =>
                    active + 1 === staffWorkData.length
                      ? {}
                      : handleStaffDetais(1)
                  }
                >
                  {" "}
                  Show next
                </span>
                <img src={rightArrowIcon} alt="rightArrowIcon" />
              </div>
            </div> */}
          </>
          )}
        </div>
        <div className="row align-items-center justify-content-center">
          {/*<div className="col-4">
            <div className="form-group">
              <p className="sub-text fs-16">Work Request ID</p>
            </div>
          </div>
          <div className="col-8">
            <div className="form-group">
              <p className="text-black fs-16 ">
                {" "}
                {`WR${workId.slice(workId.length - 6, workId.length)}`}
              </p>
            </div>
          </div>*/}

<                   div class="frame-243999">
                      <div className="d-flex justify-content-center align-items-center">
                       <svg
                       class="polygon-15"
                       width="31"
                       height="30"
                       viewBox="0 0 31 30"
                       fill="none"
                       xmlns="http://www.w3.org/2000/svg"
                      >
                    <path
                    d="M17.2321 27C16.4622 28.3333 14.5377 28.3333 13.7679 27L4.24167 10.5C3.47187 9.16666 4.43412 7.5 5.97372 7.5L25.0263 7.5C26.5659 7.5 27.5281 9.16667 26.7583 10.5L17.2321 27Z"
                    fill="#E7E7E7"
                    />
                   </svg>
                   </div>
                   <div class="frame-2434">
                   <div class="connor-james">{staffWorkData[active].staffName} </div>
                   </div>
                </div>
          {/* <div className="col-4">
            <div className="form-group">
              <p className="sub-text fs-16">Staff Status/p>
            </div>
          </div>
          <div className="col-8">
            <div className="form-group">
              <p className="text-black fs-16 ">
                {" "}
                {staffWorkData[active].status === 1
                  ? "Yet to Accept"
                  : staffWorkData[active].status === 2
                  ? "Yet to approve by Agency"
                  : staffWorkData[active].status === 3
                  ? "Yet to approve by Client"
                  : staffWorkData[active].status === 4 &&
                    props.edit.status === 3
                  ? "Pending ClockIn"
                  : staffWorkData[active].status === 4 &&
                    props.edit.status === 3
                  ? "Yet to approve by Client"
                  : staffWorkData[active].status === 3 &&
                    props.edit.status === 4
                  ? "Pending ClockIn"
                  : staffWorkData[active].status === 4 &&
                    props.edit.status === 4 &&
                    staffWorkData[active].clockIn != null
                  ? "In Progress"
                  : staffWorkData[active].status === 4 &&
                    props.edit.status === 4 &&
                    staffWorkData[active].clockIn != null &&
                    staffWorkData[active].pauseResumeData > 0 &&
                    staffWorkData[active].pauseResumeData[
                      staffWorkData[active].pauseResumeData.length() - 1
                    ].key === "pause"
                  ? "Paused"
                  : staffWorkData[active].status === 5 &&
                    props.edit.status > 5 &&
                    staffWorkData[active].clockOut != null
                  ? "Completed By Staff"
                  : staffWorkData[active].status === 5 && props.edit.status > 3
                  ? "Completed By Staff"
                  : staffWorkData[active].status === 5 &&
                    props.edit.status === 4
                  ? "Completed By Staff"
                  : staffWorkData[active].status === 4
                  ? "In Progress"
                  : ""}
              </p>
            </div>
          </div> */}
          {/* <div className="col-4">
            <div className="form-group">
              <p className="sub-text fs-16">Work Hours</p>
            </div>
          </div>
          <div className="col-8">
            <div className="form-group">
              <p className="text-black fs-16 ">
                {" "}
                {staffWorkData[active].clockIn
                  ? Moment(staffWorkData[active].clockIn).format(
                      "MM/DD/YYYY hh:mm:ss a"
                    ) +
                    " - " +
                    Moment(staffWorkData[active].clockOut).format(
                      "MM/DD/YYYY hh:mm:ss a"
                    )
                  : "Not assigned"}
              </p>
            </div>
          </div> */}
          {/*<div className="col-4">
            <div className="form-group">
              <p className="sub-text fs-16">Location</p>
            </div>
          </div>
          <div className="col-8">
            <div className="form-group">
              <p className="text-black fs-16 mb-0">{props.locationData} </p>
	      <p className="btn-gray-clr fs-12 ">
                {" "}
                15km away from client’s location{" "}
              </p>
            </div>
          </div> */}
          
        </div> 
      </div>
      {staffWorkData?
            (staffWorkData[active].status >3 || (staffWorkData[active].status === 0 && staffWorkData[active].clockIn))?
            <TimeSheetMarkNew key={staffWorkData[active].staffId} status={props.status} data={staffWorkData[active]} startDate={props.startDate} endDate={props.endDate} edit={props.edit} toggleMap={toggleMap} sitePicture={props?.edit?.sitePicture} setActivePointsFromStaffDetails={setActivePointsFromStaffDetails} onFeedbackChange={props.onFeedbackChange}
            onRatingChange={props.onRatingChange} ratingData={props.ratingData}
            feedbackData={props.feedbackData} wrData={props.edit}
            editStaffRatingId={props.editStaffRatingId}
            setEditStaffRatingId={props.setEditStaffRatingId}
            saveRatings={props.saveRatings}/>
            :<p style={{marginTop:"2rem"}}>No TimeSheet Details..</p>
            :"" }
      </>
  );
}

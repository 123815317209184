// import CustomComponent from "../CustomComponent";

export const settingsPageSteps = [
    // {
    //     target:'#settings-title',
    //     content:'Settings Module',
    //     placement:'auto'
    // },
    {
        target:'#my-profile-accordion',
        content: 'Displays all the profile details of the current user. You can also edit the details by clicking on the "edit" button.',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'#company-settings-accordion',
        content:'Displays all the company details including details for each location. You can also "add", "edit", and "delete" locations.',
        placement:'auto',
    },
    {
        target:'#tax-settings-accordion',
        content:'Displays all the tax details. You can also edit the details by clicking on "update" button.',
        placement:'auto',
    },
    {
        target:'#manage-account-accordion',
        content:'Displays the details for managing your account. You can "delete", "recover" and "download data" of your account here.',
        placement:'auto',
    },
    // {
    //     target:'#subscription-accordion',
    //     content:'Check you subscription details & unlock premium features and maximize your experience by upgrading your subscription.',
    //     placement:'auto',
    // },
]
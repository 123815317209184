import React, { useState, useEffect } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { NormalDate } from "component/common";
import { Toast } from "service/toast";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import RecurringConfirmationModal from "./RecurringConfirmationModal";
import closeButton from "assets/svg/closeButton.svg";
import recurrenceIcon from "assets/svg/recurrenceIcon.svg";
import upArrow from "assets/svg/arrow-up-solid.svg";
import downArrow from "assets/svg/arrow-down-solid.svg";
import info from "assets/svg/info.svg";
import Calendar from "assets/svg/calendar solid.svg"
import moment from "moment";
import CustomLoader from "component/common/CustomLoader";

const dropdownOptions = [
  { label: "Day", value: 1 },
  { label: "Week", value: 2 },
  { label: "Month", value: 3 },
  // { label: "Year", value: 4 },
];
const weekList = [
  { label: "S", value: 7 },
  { label: "M", value: 1 },
  { label: "T", value: 2 },
  { label: "W", value: 3 },
  { label: "T", value: 4 },
  { label: "F", value: 5 },
  { label: "S", value: 6 },
];
const RecurringModel = (props) => {
  let {
    viewWr=false,
    show = false,
    data,
    handleClose = () => {},
    disabled = false,
    repeat = false,
    repeatData = {
      repeatFrequency:0,
      days:[],
      endsOn:'',
      noOfOccurrences:0
    },
    handleUpdateRecurring = () => {},
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownSelectedOption, setDropdownSelectedOption] = useState({
    label: "",
    value: "",
  });
  const [selectedType, setSelectedType] = useState('end-date');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [repeatCounter, setRepeatCounter] = useState(1);
  const [occurrences, setOccurrences] = useState(0);
  const [endsOnDate, setEndsOnDate] = useState("");
  const [repeatOn, setRepeatOn] = useState("");

  const [loading, setLoading] = useState(false)
  const [loaderKey, setLoadingKey] = useState('')
  const [saveConfirmModal, setSaveConfirmModal] = useState(false);

  const handleUpdateData = (type, days, endsOn, noOfOccurrences, repeatOn) => {
    if(type && type > 0){
      setDropdownSelectedOption(dropdownOptions.filter((item)=>item.value === type)[0])
    }
    if(repeatOn && repeatOn){
      setRepeatOn(moment().date(repeatData.repeatOn).toDate());
  }
    if(endsOn && moment(endsOn).isValid()){
      setEndsOnDate(repeatData.endsOn);
    }else if(noOfOccurrences && noOfOccurrences > 0){
         setSelectedType('occurences');
         setOccurrences(noOfOccurrences)
    }
    if(type === 2){
      setSelectedOptions(days ? days : [])
    }
   
  }
  
  useEffect(() => {
    if(repeat && repeatData)
    handleUpdateData(repeatData.repeatFrequency,  repeatData.days, repeatData.endsOn ? repeatData.endsOn : null, repeatData.noOfOccurrences , repeatData.repeatOn ? repeatData.repeatOn : null,)
   
  }, [repeat, repeatData]);
  const handleOptionChange = (event) => {
    let value = event.target.value
    setSelectedType(value);
    if(value === 'end-date'){
      setOccurrences(0)
    }else{
      setEndsOnDate('')
    }
  };

  const handleWeekToggle = (value) => {
    if (!disabled) {
      if (selectedOptions.includes(value)) {
        setSelectedOptions(selectedOptions.filter((option) => option !== value));
      } else {
        setSelectedOptions([...selectedOptions, value]);
      }
    }
  };

  const handleAction = (action) => {
    if(action.type === 'acknowledge-recurrence'){
      action.value? handleSave(): setSaveConfirmModal(!saveConfirmModal)
    }
  }

  const handleSave = () => {
    if(data.startDate== "") {
      Toast({ type: "error", message: "Please Select the Start Date first.." });
      return;
    }
    if (!dropdownSelectedOption.value) {
      Toast({ type: "error", message: "Select the Repeat Mode. \n  Day/Week/Month/Year" });
      return;
    }
    if (dropdownSelectedOption.value === 2) {
      if(selectedOptions.length < 1){
        Toast({ type: "error", message: "Select atleast one day in a week" });
        return;
      }
      
    }
    if (dropdownSelectedOption.value === 3 || dropdownSelectedOption.value === 4) {
      if(!repeatOn){
        Toast({ type: "error", message: "Select Repeat on date " });
        return;
      }
    }
    if (selectedType === 'end-date') {
      if(!endsOnDate){
      Toast({ type: "error", message: "Select Ends On Date" });
      return;
      }
  }else{
    if(!occurrences){
      Toast({ type: "error", message: "Set the number of occurrences" });
      return;
      }
  }
  
  setLoadingKey('acknowledge-recurrence')
  let repeatData = {
    repeatFrequency: dropdownSelectedOption.value,
  }
  if(dropdownSelectedOption.value === 2){
    repeatData.days = selectedOptions
  }
  if(dropdownSelectedOption.value === 3 || dropdownSelectedOption.value === 4){
    repeatData.repeatOn = parseInt(moment(repeatOn).startOf('day').format('DD'))
  }
  if(selectedType === 'end-date'){
    repeatData.endsOn = endsOnDate
  }else{
    repeatData.noOfOccurrences = occurrences
  }

    let recurrenceData = {
      repeat: true,
      repeatData,
    };
    setTimeout(() => {
      setLoadingKey('');
      setSaveConfirmModal(!saveConfirmModal)
      handleUpdateRecurring(recurrenceData);
    },1000)
  };

  const handleFocus = () => {
    if(dropdownOpen){
      setDropdownOpen(!dropdownOpen)
    }
  }

  return (
    <>
    <Modal
      show={show}
      onHide={handleClose}
      className="recurring-global-modal w-100 h-100 d-flex justify-content-center align-items-center"
    >
      <ModalBody className="personalized-recurrence" onClick={handleFocus}>
        <div className="frame-2022">
          <div className="frame-2030">
            <img src={recurrenceIcon} alt="Recurrence" />
            <span className="shift-recurrence-pattern d-flex justify-content-center">
              
              Shift Recurrence Pattern
              {disabled && <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Edit is not allowed. This option is to review only</Tooltip>}
              >
                <img
                className="info-recurrence"
                src={info} alt="Info" />
            </OverlayTrigger>}
            </span>
          </div>
          <div
            onClick={handleClose}
            aria-label="close-button"
            className="cursor-pointer"
          >
            <img src={closeButton} alt="close-button" />
          </div>
        </div>

        {/* repeat box */}

        <div className="repeat-count">
          <div className="repeat">Repeat</div>
          <div className="repeat-box">
            <div className="frame-2038">
              <div className="every">Every</div>
              {/* <div className="repeat-counter disabled-field">
                <input
                  type="number"
                  className="repeat-input"
                  value={repeatCounter}
                  onChange={() => setRepeatCounter((prev) => prev + 1)}
                  disabled
                />
                <div
                  className="d-flex flex-column justify-content-center align-items-center mb-3"
                  style={{ height: "30px", gap: "10px" }}
                >
                  <div
                    className="count-action-button"
                    // onClick={() => setRepeatCounter((prev) => prev + 1)}
                  >
                    
                    <img src={upArrow} alt="decrease" />
                  </div>
                  <div
                    className="count-action-button"
                    // onClick={() => setRepeatCounter((prev) => (prev > 1 ? prev - 1 : 1))}
                  >
                    
                    <img src={downArrow} alt="increase" />
                  </div>
                </div>
              </div> */}

              <div
                className={`recurring-dropdown  ${
                  disabled ? "disabled-label" : ""
                }`}
              >
                <div
                  className={`recurring-selected-label ${disabled ? 'no-drop' : 'cursor-pointer'}`}
                  onClick={() => {
                    if (disabled) {
                      return;
                    }
                    setDropdownOpen(!dropdownOpen);
                  }}
                >
                  {dropdownSelectedOption.value ? (
                    <span>{dropdownSelectedOption.label}</span>
                  ) : (
                    <div className="day-week-month-year">
                      Day, Week, Month
                    </div>
                  )}
                  {
                    <img
                      src={dropdownOpen ? upArrow : downArrow}
                      alt="drop-down"
                    />
                  }
                </div>
                {dropdownOpen && (
                  <div
                    className="d-flex flex-column position-absolute bg-white w-100  reccuring-type-dropdown-box"
                    style={{ top: "40px", left: "0px", zIndex: 999 }}
                  >
                    {dropdownOptions.map((option, index) => (
                      <div
                        key={index}
                        className={`dropdown-option ${dropdownSelectedOption.value === option.value ? "selected-dropdown-option"
                            : ""
                        }`}
                        onClick={() => {
                          setDropdownSelectedOption(option);
                          setDropdownOpen(false);
                        }}
                      >
                        <div className="recurring-name-label">
                          
                          {option.label}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {dropdownSelectedOption.value === 2 && (
            <div className="d-flex w-100 px-3 mt-3 justify-content-around align-items-center">
              {weekList.map((day) => {
                return (
                  <div
                    className={`week-button ${
                      selectedOptions.includes(day.value) ? "selected-week" : ""
                    } ${ disabled ? "disabled-label" : ""}`}
                    key={day.value}
                    onClick={() => handleWeekToggle(day.value)}
                  >
                    {day.label}
                  </div>
                );
              })}
            </div>
          )}

{(dropdownSelectedOption.value === 3 || dropdownSelectedOption.value === 4) && (
            <div className="d-flex w-100  mt-3 justify-content-start align-items-center repeat-on-date">
              <div className="every cursor-pointer" htmlFor="repeat-on" style={{whiteSpace:'no-wrap'}}>Repeat On :</div>
              {repeatOn ? 
              <label className="repeat-on-date-text mb-0 " htmlFor="repeat-on">{repeatOn ? `${parseInt(moment(repeatOn).startOf('day').format('DD'))}/${parseInt(moment(repeatOn).startOf('day').format('MM'))}/${parseInt(moment(repeatOn).startOf('day').format('YYYY'))}` : 0}<img src={Calendar} height={22} width={22} style={{marginLeft:"2rem"}}></img></label>
              :
              <label className="mb-0 ml-2 repeat-on-date-place-holder" htmlFor="repeat-on">Select Date<img src={Calendar} height={22} width={22} style={{marginLeft:"1.6rem"}}></img></label>}
              <NormalDate
              id={'repeat-on'}
            placeholder={localStorage.getItem('dateFormat')}
            disabled={disabled}
            className="ends-on-date ml-3 position-absolute"
            value={repeatOn}
            onChange={(e) =>{ 
              setRepeatOn(e.target.value)
            }}
          />
            </div>
          )}
        </div>

        <div className="recurrence-ends-on ">Ends On</div>

        <label for="endsOn-recurrecce"><div className="d-flex align-items-center pl-3">
          <input
            type="radio"
            id="endsOn-recurrecce"
            className={`custom-radio ${disabled ? 'recurrence-radio' : 'cursor-pointer'}`}
            disabled={disabled}
            name="radioGroup"
            value="end-date"
            checked={selectedType === "end-date"}
            onChange={handleOptionChange}
          />
          <NormalDate
            id="endsOn-recurrecce"
            placeholder={localStorage.getItem('dateFormat')}
            disabled={!disabled ? selectedType === "occurences" : disabled}
            className={`ends-on-date ml-3 ${disabled || selectedType === "occurences" ? 'recurrence-radio' : 'cursor-pointer'}`}
            value={endsOnDate  ? new Date(endsOnDate.toString()) : ""}
            onChange={(e) =>{ 
              if(data.startDate && data.endDate){
                if(moment(data.endDate).format('YYYY-MM-DD') < moment(e?.target?.value).format('YYYY-MM-DD')){
                  setEndsOnDate(e.target.value)
                }
                else{
                  Toast({type:"error", message: "The ends on date cannot be lesser than or same as the WR end date."})
                }
              }
              else{
                Toast({type: "error", message: "Please select both start and end date for the work request."})
              }
            }}
          />
        </div></label>

        <label><div className="d-flex align-items-center pl-3">
          <input
            type="radio"
            className={`custom-radio ${disabled ? 'after-radio' : 'cursor-pointer'}`}
            name="radioGroup"
            value="occurences"
            checked={selectedType === "occurences"}
            onChange={handleOptionChange}
            disabled={disabled}
          />

          <div
            className="d-flex align-items-center ml-3"
            style={{ gap: "1rem" }}
          >
            <span className="after">After</span>

            <div
              className={`frame-2031 ${
                selectedType !== "Occurrences" ? "disabled-field" : ""
              }`}
            >
              <input
                type="number"
                name="Occurrences"
                className={`occurence-counter ${disabled || selectedType === "end-date" ? 'after-radio' : 'cursor-pointer'}`}
                disabled={!disabled ? selectedType === "end-date" : disabled}
                value={occurrences}
                onChange={(e) => {
                  if(e.target.value >= 0)
                  setOccurrences(e.target.value)
                }}
              />
              {  !viewWr? 
              <div
                className="d-flex flex-column justify-content-center align-items-center mb-3"
                style={{ height: "30px", gap: "10px", marginLeft:"1.5rem", marginTop:"-3.2rem" }}
              >
                <div
                  className={`count-action-button ${disabled ? 'after-radio' : 'cursor-pointer'}`}
                  onClick={() =>{
                    if(selectedType === "occurences")
                    setOccurrences((prev) => Number(prev) + 1)
                  }}
                >
                  
                  <img src={upArrow} alt="decrease" />
                </div>
                <div
                  className={`count-action-button ${disabled ? 'after-radio' : 'cursor-pointer'}`}
                  onClick={() => {
                    if(selectedType === "occurences")
                    setOccurrences((prev) => (prev > 0 ? Number(prev) - 1 : 0))
                  }}
                >
                  
                  <img src={downArrow} alt="increase" />
                </div>
              </div> 
              :""
              }
            </div>
            <div class="occurrences">Occurrences</div>
          </div>
        </div></label>
        { !viewWr?
        <div className="save-recurrence" onClick={ () => setSaveConfirmModal(true)}>
          Save Recurrence Setting
        </div> 
        :""
       }
      </ModalBody>
    </Modal>
    {saveConfirmModal &&
      <div className="recurring-confirm">
      <RecurringConfirmationModal
      toggle={() => setSaveConfirmModal(!saveConfirmModal)}
      isOpen={saveConfirmModal}
      handleAction={(e) => handleAction(e)}
      loadingCheckKey = 'acknowledge-recurrence'
      loadingKey={loaderKey}
      message='Recurrence preference is indicative settings only. Your agency will assess and create recurrence shifts that would appear on your shift calendar only after the completion of all staff assignments'
      type='acknowledge-recurrence'
    /> 
    </div>
     }
    </>
  );
};

export default RecurringModel;

import React from 'react'
import './style.scss'

const CustomComponent = (props) => {
 
  let {
   content,
   classNames
  } = props

  return (
    <div className={`rotate-scale-up d-flex justify-content-center align-items-center  ${classNames}`}>
        <div>
            {content}
        </div>
    </div>
  )
}

export default CustomComponent
const routers = [
  {
    path: "/",
    exact: false,
    redirect: "/admin",
  },
  {
    path: '/auth/login',
    exact: false,
    redirect: '/admin/subscription/manageplans',
 },
  {
    path: '/admin/WorkSchedule',
    exact: false,
    redirect: '/admin/WorkSchedule/list',
  },
  // {
  //    component: 'AuthLayout',
  //    path: '/auth',
  //    auth: false,
  //    name: 'Auth',
  //    exact: false,
  //    redirect: '/auth/login',
  //    childrens: [
  //       {
  //          component: 'Login',
  //          componentPath: 'pages/Auth/Login',
  //          path: '/login',
  //          name: 'Login',
  //          auth: false,
  //          exact: true,
  //       },
  //       {
  //          component: 'ForgetPassword',
  //          componentPath: 'pages/Auth/ForgetPassword',
  //          path: '/forgetPassword',
  //          name: 'ForgetPassword',
  //          auth: false,
  //          exact: true,
  //       },
  //    ],
  // },

  {
    component: "PlainLayout",
    path: "/plain",
    auth: false,
    name: "Plain",
    redirect: "/plain/token/:token",
    exact: false,
    childrens: [
      {
        component: "UserVerify",
        componentPath: "pages/Auth/UserVerify",
        path: "/token/:token/:id",
        name: "Token",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "AdminLayout",
    path: "/admin",
    auth: false,
    name: "AdminLayout",
    redirect: "/admin/workSchedule/list",
    exact: false,
    childrens: [
      {
        component: "WorkSchedule",
        path: "/workSchedule/:view",
        componentPath: "pages/Admin/workSchedule",
        name: "WorkSchedulePanel",
        auth: false,
        exact: true,
      },
      {
        component: "WorkScheduleList",
        path: "/workSchedule/workScheduleList",
        componentPath: "pages/Admin/workSchedule/workScheduleList",
        name: "workScheduleList",
        auth: false,
        exact: true,
      },
      {
        component: "WorkReport",
        path: "/workSchedule/workReport",
        componentPath: "pages/Admin/WorkReport",
        name: "workManagement",
        auth: false,
        exact: false,
      },
      {
        component: "Feedback",
        path: "/feedback",
        componentPath: "pages/Admin/Feedback",
        name: "feedbackManagement",
        auth: false,
        exact: true,
      },
      {
        component: "FeedbackForm",
        path: "/feedback/addFeedback",
        componentPath: "pages/Admin/Feedback/FeedbackForm",
        name: "AddFeedback",
        auth: false,
        exact: true,
      },
      {
        component: 'Subscription',
        path: '/subscription',
        componentPath: "pages/Admin/Subscription",
        name: 'subscription ',
        // auth: false,
        exact: true,
     },
     {
        component: 'Subscription',
        path: '/subscription/:view',
        componentPath: 'pages/Admin/Subscription',
        name: 'subscription ',
        // auth: false,
        // exact: true,
     },
     {
      component: "AgencyManagement",
      path: "/AgencyManagement",
      componentPath: "pages/Admin/AgencyManagement",
      name: "AgencyManagement",
      auth: false,
      exact: false,
    },
      // {
      //   component: "Notification",
      //   path: "/notification",
      //   componentPath: "pages/Admin/Notification",
      //   name: "Notification",
      //   auth: false,
      //   exact: false,
      // },
      {
        component: "Settings",
        path: "/settings",
        componentPath: "pages/Admin/Settings",
        name: "Settings",
        auth: false,
        exact: false,
      },
      {
        component: "Dashboard",
        path: "/dashboard",
        componentPath: "pages/Admin/Dashboard",
        name: "Dashboard",
        //auth: false,
        exact: true,
      },
    ],
  },
];

export default routers;


export const subscriptionDetails = [
    {
        target:'#subscription-details-tab',
        content:'Check your subscription details.',
        disableBeacon: true,
        disableScrolling: true,
    }, 
]

export const managePlansSteps = [
    {
        target:'.plan-type-switch',
        content:'To switch between a monthly and yearly plan, click here.',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.subscription-plan-box-container',
        content:'Here you can check the details of each plan. Choose the perfect plan for your needs. ',
        disableBeacon: true,
        placement:'right',
        disableScrolling: false,
    },

]

export const changePlanSteps = [
    {
        target:'#features-tab',
        content:'In this tab you can check the plan details',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'#features-tab-plan-details',
        content:'List of plan features & available users',
        disableBeacon: true,
        disableScrolling: true,
        spotlightPadding: 10
    },
    {
        target:'.plan-features',
        content:'Check the summary of each feature',
        disableBeacon: true,
        disableScrolling: true,
    },

    //staff tab steps

    {
        target:'#agency-tab',
        content:'In this Tab you can check the additional agency details',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'#available-staff-status',
        content:'Check the available agency slots to addon',
        disableBeacon: true,
        disableScrolling: true,
        spotlightPadding: 5
    },
    {
        target:'#current-staff-slots',
        content:'Your current Agency slots',
        disableBeacon: true,
        disableScrolling: true,
        spotlightPadding: 5
    },
    {
        target:'.staff-controller',
        content:'You can Add additional Agency slots from the available Agency slots.',
        disableBeacon: true,
        disableScrolling: true,
        spotlightPadding: 2
    },
    {
        target:'.subscription-charges',
        content:'Check the prices of your plan & additonal addons in the pricing table',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'#payment-details-tab',
        content:'Here you can select payment provider.',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.paymet-methods-container',
        title:'Select payment Provider',
        content:'We are supporting multiple payment providers. select the one which is convenient for you.',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.subscription-total-charges',
        content:'Check the Total billing amount.',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.pay-now-button',
        content:'Click on pay now to make the payment.',
        disableBeacon: true,
        disableScrolling: true,
    },
]

export const paymentTransactions = [
    {
        target:'.tour-date-range',
        content:'Select a specific date range to view transactions within that time frame.',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.tour-transaction-filter',
        content:'Filter transactions based on their status. "All" displays all transactions, "Success" shows only successful transactions, and "Failed" shows only failed transactions.',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.tour-transaction-search',
        content:'Search for transactions by amount, transaction ID, or tracking ID. Enter the relevant details and click "Search".',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.table-header-row',
        content:`These headings represent key information for each transaction.`,
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.transaction-detail',
        content:`This record provides a comprehensive overview of the transaction, including 'TRACKING ID' and 'TRANSACTION ID', subscription period details, subscription plan specifics, and its.`,
        disableBeacon: true,
        disableScrolling: true,
    },
]
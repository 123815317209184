// import React, { useEffect, useState,useRef } from 'react';
import React from 'react';
// import { useDispatch } from 'react-redux';
// import SimpleReactValidator from 'simple-react-validator';
// import { useCustomReducer, useComponentWillMount} from "service/helpers/hooks"
// import {  pageNavigationByName } from "service/helpers"
// import "./style.scss"   
// import OtpInput from 'react-otp-input';
// import { TableWrapper } from 'component/common';
// import { NormalButton, NormalModal,NormalInput } from 'component/common';
import { NormalButton, NormalModal } from 'component/common';

// import { resetpassCode, login, otplogin } from 'action/AuthAct';

// import sort from 'assets/svg/sort.svg';
// import search from 'assets/svg/search.svg';
// import viewIcon from 'assets/svg/view.svg';
// import deleteIcon from 'assets/svg/delete.svg';
// import editIcon from 'assets/svg/edit.svg';
// import viewActivity from 'assets/svg/Viewactivity.svg';


// const initState = {
//    oldpassword : '',
//    newpassword : ''
//  };
 
 
export default function CancelWorkModel({handleAction,isOpen,toggle, loadingKey = '', loadingCheckKey = '',}) {
    
    return (
      <>
         <NormalModal
            isOpen={isOpen}
            size="md"
            renderHeader={''}
            headerClass="border-0 pt-1 px-1"
            toggle={toggle}
        >
             <>
                  <div className=" text-center mb-5 px-5">
                     {/* <p className="fs-20 mb-0">
                        Your work is <b>In-progress. </b>
                     </p> */}
                     <p style={{fontSize:"20px"}}>
                     This action cannot be reversed. Are you sure to cancel?
                     </p>
                  </div>
                  <div className="d-flex justify-content-center mb-4">
                     <NormalButton
                        onClick={()=>{
                            handleAction(false)
                        }}
                        id="cancel"
                        label="No"
                        outline={false}
                        // loader={isFormLoder}
                        className="pl-4 pr-4 mb-2 btn fs-16 btn btn-dark-primary  btn-block"
                     />
                     <NormalButton
                        onClick={()=>{
                            handleAction(true)
                        }}
                        id="confirm"
                        label="Confirm"
                        outline={false}
                        // loader={isFormLoder}
                        loader={loadingKey === loadingCheckKey}
                        disabled={loadingKey === loadingCheckKey}
                        className="mb-2 ml-2 btn fs-16 btn-purple-outline   btn-block "
                     />
                  </div>
                     
               </>        
        </NormalModal> 
      </>
    )
}



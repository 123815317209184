import React from 'react';

const Modal = ({ message, onClose, goBack }) => {

  let pathName = window.location.pathname

  return (
    <div className="tour-modal">
      <div className="tour-modal-content">
        <p>{message}</p>
        <div 
        style=
          {{
            gap: "5px", 
            width: "fit-content",
          }} className='d-flex mx-auto'>
          {pathName === "/admin/subscription/manageplans/viewplan/features" ||
          pathName === '/admin/subscription/manageplans/viewplan/agency' ? 
          <button 
          style=
              {{
                backgroundColor: "white",
                color: "#033C28"
              }} onClick={goBack}>Go back</button> : ""}
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
